import React, { useEffect, useState } from "react";
import { useGetProjectDetails } from "../../../../../queries/query-hooks/workspace/workspace-hook";
import DashboardDropdown from "../../../../../components/DashboardDropdown";
import Skeleton from "../../../../../components/skeleton";
import { useParams } from "react-router-dom";
import { Col } from "react-bootstrap";

function FilterBy({ handleDropdownChange, type, needHeading = false }) {
  const [options, setOptions] = useState([]);
  const { project_id } = useParams();
  const { data: projectDetails, isLoading: isProjectDetailsLoading } =
    useGetProjectDetails(project_id);

  useEffect(() => {
    if (
      projectDetails &&
      projectDetails?.length > 0 &&
      "project_attributes" in projectDetails[0] &&
      projectDetails[0]?.project_attributes.length > 0
    ) {
      const tempOptions = [];

      projectDetails[0]?.project_attributes.forEach((item) => {
        if (item.attribute_name === "show_view") {
          tempOptions.push("Views");
        } else if (item.attribute_name === "show_read") {
          tempOptions.push("Reads");
        }
      });

      setOptions(tempOptions);
      handleDropdownChange(tempOptions[0]);
    } else {
      setOptions(["Views", "Reads"]);
      handleDropdownChange("Views");
    }
  }, [projectDetails]);

  return (
    <Col md={12} className="d-flex mb-2">
      {needHeading && (
        <div className="d-flex align-items-center justify-content-start gap-3">
          <h5>Overall Demographic Details</h5>
        </div>
      )}
      <div className="d-flex gap-3 align-items-center justify-content-end ms-auto">
        <div className="fs-6 text-black">Filter By</div>
        <div className="dropdown bg-white border border-1 rounded-3 px-3 py-2">
          {!isProjectDetailsLoading ? (
            <DashboardDropdown
              constantFilterOption={options}
              handleDropdownChange={handleDropdownChange}
              type={type}
            />
          ) : (
            <Skeleton height={30} />
          )}
        </div>
      </div>
    </Col>
  );
}

export default FilterBy;
