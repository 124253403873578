import React from "react";
import { Button, Image } from "react-bootstrap";
import dayjs from "dayjs";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import ImageWithFallback from "../../../../../components/ImageWithFallback";

const SummeryHeaderContent = ({ summaryContentEventDetails = {} }) => {
  const sessionStartDateTime = dayjs(
    summaryContentEventDetails?.session_date
  ).format("DD MMMM, YYYY | hh:mmA");

  const sessionDoctorDetails =
    summaryContentEventDetails?.session_doctor_details;

  const visitLink = summaryContentEventDetails?.visit_link;

  return (
    <div>
      <div className="position-relative">
        <Image
          src={summaryContentEventDetails?.session_image_src}
          rounded
          className="ratio ratio-16x9 rounded-3 w-100 img-fluid object-fit-fill"
          style={{ height: "20rem" }}
        />
        <Button
          className="position-absolute bottom-0 end-0 mb-3 me-3 rounded-3"
          variant="success"
          href={visitLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          Visit link
        </Button>
      </div>

      <p className="my-3 text-success fw-semibold">{sessionStartDateTime}</p>
      <h4 className="mb-3 fw-semibold">{summaryContentEventDetails?.topic}</h4>
      <div>
        <div className="overflow-hidden d-slider1 flex-grow-1 me-0 me-md-0">
          <Swiper
            as="ul"
            className="p-0 m-0 mb-0 swiper-wrapper list-inline tableHeaderSwiper pb-1"
            modules={[Navigation]}
            data-aos="fade-up"
            data-aos-delay="700"
            slidesPerView="auto"
          >
            {sessionDoctorDetails?.map((sessionDoctorDetail, index) => {
              return (
                <SwiperSlide
                  className="cursor-pointer pb-1 pb-md-0 me-5"
                  key={sessionDoctorDetail.doctor_id}
                >
                  <div className="d-flex align-items-center gap-3 mb-3">
                    <ImageWithFallback
                      src={sessionDoctorDetail?.profile_image}
                      style={{ height: 50 }}
                      alt={sessionDoctorDetail?.first_name}
                      className="object-fit-cover rounded-circle"
                    />
                    <div>
                      <p className="mb-0 text-capitalize fw-semibold fs-6 text-dark lh-base">
                        {`${sessionDoctorDetail?.first_name} ${sessionDoctorDetail?.middle_name} ${sessionDoctorDetail?.last_name}`}
                      </p>
                      <p className="m-0 fs-6">General Surgeon</p>
                      {/* FIXME: API speciality data is missing*/}
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
        <div
          dangerouslySetInnerHTML={{
            __html: summaryContentEventDetails?.session_description,
          }}
        ></div>
      </div>
    </div>
  );
};

export default SummeryHeaderContent;
