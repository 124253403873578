import React, { Fragment, useState } from "react";
import { Card, Tab, Nav, Row, Col, Image } from "react-bootstrap";
import Chart from "react-apexcharts";
// import dayjs from "dayjs";
import {
  dateFilterOptions,
  videoAnalyticsFilterOptions,
} from "../../Constant/date-filter-constant";
import { GRAPH_FILL_COLOR } from "../../Constant/graph-color-scheme";
import { useGetVideoDetails } from "../../../../../../../queries/query-hooks/workspace/channel-video-hook";
import { fallbackImages } from "../../../../../../../common/constants/fallback-images";
import { useParams, useSearchParams } from "react-router-dom";

function VideoAnalyticsOverview({ data: overviewData }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const initialDateFilterOption = videoAnalyticsFilterOptions.find(
    (item) => item.initial
  );
  const sortOption = searchParams.get("sort") || initialDateFilterOption.value;

  const { video_id } = useParams();
  const { data: videoDetailsData, isLoading } = useGetVideoDetails(
    video_id,
    () => {}
  );
  const { video_detail: videoDetails } = videoDetailsData || {
    video_detail: {},
  };

  const {
    avg_views_duration,
    avg_views_percentage,
    total_views,
    total_impression,
    views: totalViewGraphdata,
    retentaion_data,
    hourly_data,
  } = overviewData || {
    hourly_data: "",
    views: "",
    avg_views_duration: "",
    avg_views_percentage: "",
    total_views: "",
    total_impression: "",
    retentaion_data: "",
  };

  const formatTotalViewsData = (input) => {
    return input.map((item) => ({
      x: item.level,
      y: item.views,
    }));
  };

  // console.log("formatTotalViewsData", formatTotalViewsData(totalViewGraphdata));
  const totalViewsOptions = {
    series: [
      {
        name: "View Count",
        data:
          totalViewGraphdata?.length > 0
            ? formatTotalViewsData(totalViewGraphdata)
            : [],
      },
    ],

    // labels: totalViewGraphdata?.length > 0 ? formatTotalViewsData(totalViewGraphdata).label : [],
    options: {
      colors: GRAPH_FILL_COLOR,
      chart: {
        type: "bar",
        height: 380,
        toolbar: {
          show: false, // Set to false to hide the toolbar
        },
      },
      stroke: {
        width: 2,
        curve: "smooth",
      },
      markers: {
        size: [4, 7],
      },
      xaxis: {
        // type: "Year",
        // labels: {
        //   formatter: function (val) {
        //     return "Q" + dayjs(val).quarter();
        //   },
        // },
        group: {
          style: {
            fontSize: "10px",
            fontWeight: 700,
          },
          // groups: [
          //   { title: "2019", cols: 4 },
          //   { title: "2020", cols: 4 },
          // ],
        },
      },
      // tooltip: {
      //   x: {
      //     formatter: function (val) {
      //       return "Q" + dayjs(val).quarter() + " " + dayjs(val).format("YYYY");
      //     },
      //   },
      // },
    },
  };
  console.log("totalViewGraphdata", totalViewGraphdata);
  function transformGraphData(data, seriesKey, labelKey) {
    console.log("transformGraphData", data);
    if (data && data?.length <= 0) {
      return { series: [], label: [] };
    } else {
      return data.reduce(
        (acc, item) => ({
          series: [...acc.series, parseInt(item[seriesKey])],
          label: [...acc.label, item[labelKey]],
        }),
        { series: [], label: [] }
      );
    }
  }
  const watchHoursOptions = {
    series: [
      {
        name: "Viewed Time (Mins)",
        data:
          hourly_data?.length > 0
            ? transformGraphData(hourly_data, "total_hour", "level")?.series
            : [],
      },
    ],
    colors: GRAPH_FILL_COLOR,
    chart: {
      type: "area",
      height: 350,
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false, // Set to false to hide the toolbar
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 2,
      curve: "smooth",
    },
    // markers: {
    //   size: [4, 7],
    // },

    labels:
      hourly_data?.length > 0
        ? transformGraphData(hourly_data, "total_hour", "level")?.label
        : [],
    // xaxis: {
    //   // type: "datetime",
    // },
    yaxis: {
      opposite: true,
    },
    legend: {
      horizontalAlign: "left",
    },
  };

  const videoRetentionOptions = {
    series: [
      {
        name: "Viewed percentage",
        data:
          retentaion_data?.length > 0
            ? transformGraphData(retentaion_data, "percentage", "time_slab")
                ?.series
            : [],
      },
    ],
    colors: GRAPH_FILL_COLOR,
    chart: {
      type: "area",
      height: 350,
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false, // Set to false to hide the toolbar
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 2,
      curve: "smooth",
    },
    // markers: {
    //   size: [4, 7],
    // },
    labels:
      retentaion_data?.length > 0
        ? transformGraphData(retentaion_data, "percentage", "time_slab")?.label
        : [],
    xaxis: {
      labels: {
        rotate: 0, // no need to rotate since hiding labels gives plenty of room
        hideOverlappingLabels: true, // all labels must be rendered
      },
    },
    yaxis: {
      opposite: true,
    },
  };

  console.log("retention data ", videoRetentionOptions);
  function getMaxViews(data) {
    return Math.max(...data.map((item) => parseInt(item.views, 10)));
  }
  return (
    <Fragment>
      <Row>
        <Col md={6}>
          <Card>
            <Card.Header className="d-flex align-items-center justify-content-between">
              <h5>Total Views</h5>
            </Card.Header>

            <div className="d-flex flex-column">
              <Card.Body className="p-0">
                <div>
                  <div id="chart">
                    <Chart
                      options={totalViewsOptions.options}
                      series={totalViewsOptions.series}
                      type="bar"
                      height={250}
                    />
                  </div>
                  <div id="html-dist"></div>
                </div>
              </Card.Body>
            </div>
          </Card>
        </Col>

        <Col md={6}>
          {" "}
          <Card>
            <Card.Header className="d-flex align-items-center justify-content-between">
              <h5>{`Watch Time (Minutes)`}</h5>
            </Card.Header>
            <div className="d-flex flex-column">
              <Card.Body className="p-0">
                <div>
                  <div id="chart">
                    <Chart
                      options={watchHoursOptions}
                      series={watchHoursOptions.series}
                      type="line"
                      height={250}
                    />
                  </div>
                </div>
              </Card.Body>
            </div>
          </Card>
        </Col>

        <Col md={12}>
          {" "}
          <Card className="p-2 border bg-light bg-opacity-50 border-secondary border-opacity-10">
            <Card.Body className="py-3">
              {totalViewGraphdata?.length > 0 && (
                <h5 className="text-center m-0 fs-5 fw-normal">
                  This Video has received{" "}
                  {totalViewGraphdata?.length > 0
                    ? totalViewGraphdata[totalViewGraphdata?.length - 1]?.views
                    : 0}{" "}
                  views since last{" "}
                  {sortOption == "daily"
                    ? "day"
                    : sortOption == "weekly"
                    ? "week"
                    : sortOption == "yearly"
                    ? "year"
                    : sortOption == "monthly"
                    ? "month"
                    : ""}
                </h5>
              )}
            </Card.Body>
          </Card>{" "}
        </Col>

        <Col md={6}>
          <Card className="p-5">
            <div className="m-3 text-center pb-3 mt-3">
              <p className="text-center">
                Interest in your video since Publishing
              </p>
              <h5 className="m-0">Key moments for audience retention</h5>
            </div>
            <Row>
              <div className="p-5 d-flex justify-content-around">
                <div className="flex-grow-1">
                  <div>
                    <h6 className="fs-7 fw-normal">Average View Duration</h6>
                    {avg_views_duration ? (
                      <h4 className="fs-5 fw-medium">{avg_views_duration}</h4>
                    ) : (
                      <h4>0</h4>
                    )}
                  </div>

                  <div>
                    <h6 className="fs-7 fw-normal">Average percentage View</h6>
                    {avg_views_percentage ? (
                      <h4 className="fs-5 fw-medium">
                        {Math.round(avg_views_percentage)}
                      </h4>
                    ) : (
                      <h4>0</h4>
                    )}
                  </div>
                </div>

                <Image
                  src={
                    !isLoading && videoDetails?.content_to_image?.length > 0
                      ? videoDetails?.content_to_image[0]?.content_image_id
                          ?.thumbnail
                      : ""
                  }
                  onError={(e) => (e.target.src = fallbackImages.noVideo)}
                  roundedSquare
                  style={{ height: "100px", width: "200px" }}
                  fluid
                  className="rounded-3"
                />
              </div>
            </Row>
          </Card>
        </Col>
        <Col md={6}>
          <Card>
            <Card.Header className="d-flex align-items-center justify-content-between">
              <h5>Video Retention</h5>
            </Card.Header>
            <Card.Body className="p-0">
              <div>
                <div id="chart">
                  <Chart
                    options={videoRetentionOptions}
                    series={videoRetentionOptions.series}
                    type="line"
                    height={250}
                  />
                </div>
                <div id="html-dist"></div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
}

export default VideoAnalyticsOverview;
