import React from "react";
import { Badge, Col, Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import SupportImage from "./SupportImage";
import dayjs from "dayjs";

const statusToBg = (status) => {
  switch (status) {
    case 1:
      return "danger";
    case 2:
      return "secondary";
    case 3:
      return "primary";
    case 4:
      return "warning";
    case 5:
      return "info";
    case 6:
      return "success";
    case 7:
      return "dark";
    case "Pending":
      return "warning";
    case "Closed":
      return "info";
    default:
      return "secondary";
  }
};

const HelpAndSupportModal = ({ onHide, show, data = {} }) => {
  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
      onHide={onHide}
    >
      <Modal.Header closeButton>
        <div>
          <div className="d-flex gap-2 align-items-center">
            <div>
              <Badge
                bg={statusToBg(7)}
                style={{ fontSize: "14px", padding: "0.5rem" }}
              >
                {data?.support_module_id?.module_title}
              </Badge>
            </div>

            <div>
              <Modal.Title id="contained-modal-title-vcenter">
                {data?.support_name}
              </Modal.Title>
            </div>
          </div>
          <div>
            <p className="mt-2 mb-0 p-0">
              Date: {dayjs(data?.created_at).format("DD MMM YYYY")}
            </p>
          </div>
        </div>
      </Modal.Header>
      <div className="d-flex flex-column gap-2 border-bottom px-3 py-2 align-items-center">
        <Col md={12} className="d-flex gap-4 align-items-center">
          <span>
            Priority:{" "}
            <Badge
              bg={statusToBg(data?.support_priority_id?.priority_id)}
              style={{ fontSize: "14px", padding: "0.4rem" }}
            >
              {data?.support_priority_id?.priority_name}
            </Badge>
          </span>
          <span>
            Status:{" "}
            <Badge
              bg={statusToBg(data?.status?.value)}
              style={{ fontSize: "14px", padding: "0.4rem" }}
            >
              {data?.status?.value}
            </Badge>
          </span>
          <span>
            Raised By:{" "}
            <Badge
              bg={statusToBg(7)}
              style={{ fontSize: "14px", padding: "0.4rem" }}
            >
              {data?.created_by?.name}
            </Badge>
          </span>
        </Col>
      </div>
      <Modal.Body>
        <Row>
          <Col md={12}>
            <p>{data?.support_description}</p>
          </Col>
          <Col md={12} className="border-top pt-3">
            <h5>Attachments</h5>
            <SupportImage image={data?.support_path} />
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default HelpAndSupportModal;
