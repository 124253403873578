import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import { findIcon } from "../../../../dashboard/icons";

function GridListView({ isListView, setIsListView }) {
  return (
    <div className="d-flex justify-content-between align-items-center pb-0">
      <div className="d-flex gap-3 align-items-center">
        <ul
          className="nav nav-pills mb-0 iq-product-filter d-flex bg-transparent align-items-center flex-nowrap"
          id="pills-tab"
          role="tablist"
        >
          <li
            className="nav-item ms-2"
            role="presentation"
            title="List View"
            onClick={() => setIsListView(true)}
          >
            <Button
              bsPrefix={
                "nav-link btn-sm btn-icon rounded-pill" +
                (isListView ? " active" : "")
              }
              id="list-view-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-list-view"
              to="#"
              role="tab"
              aria-controls="pills-list-view"
              aria-selected="false"
            >
              <span className="btn-inner d-inline-flex">
                {findIcon("Rows", "dual-tone")}
              </span>
            </Button>
          </li>
          <li
            className="nav-item ms-2"
            role="presentation"
            title="Grid View"
            onClick={() => setIsListView(false)}
          >
            <Button
              bsPrefix={
                "nav-link btn-sm btn-icon rounded-pill" +
                (isListView ? "" : " active")
              }
              id="grid-view-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-grid-view"
              to="#"
              role="tab"
              aria-controls="pills-grid-view"
              aria-selected="true"
            >
              <span className="btn-inner d-inline-flex">
                {findIcon("View Grid", "dual-tone")}
              </span>
            </Button>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default GridListView;
