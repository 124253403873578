import React, { useEffect, useRef, useState } from "react";
import Titlebar from "../components/Titlebar";
import { Card, Col, Row } from "react-bootstrap";
import TreeMapChart from "../../../../../components/chart/TreeMapChart";
import TextPillCard from "../components/TextPillCard";
import ReactTable from "../../../../../lib/table/react-table";
import IndiaMap from "../../../../../components/chart/IndiaMap";
import {
  useGetAllProjectEventsList,
  useGetLocationDistribution,
  useGetLocationReport,
  useGetMajorCities,
  useGetZonalDistribution,
} from "../../../../../queries/query-hooks/workspace/events-hook";
import { useParams } from "react-router-dom";
import { transformIndiaMapData } from "../utils/utils";
import MapAnalyticsSkeleton from "../../../../../skeleton/workspace/clirnet/brand-ads/map-analytics-skeleton";
import SquareChartSkeleton from "../../../../../skeleton/workspace/clirnet/brand-ads/square-chart-skeleton";
import CommonTableLoader from "../../../../../skeleton/common-table-loader";
import { findIcon } from "../../../../dashboard/icons";
import MiniLineChart from "../../../../../components/chart/MiniLineChart";
import CardNotFound from "../components/CardNotFound";
import { downloadTablePNG } from "../../../../../common/utils/utils";
import useCsvDownloader from "../../../../../components/custom-hooks/use-csv-downloader";

const LOCATION_REPORT_TABLE_COLUMNS = [
  {
    columnId: 1,
    Header: "State",
    accessor: "state",
    disableFilters: true,
    Cell: (value) => {
      if (!value.value) return "---";
      return value.value;
    },
  },
  {
    columnId: 2,
    Header: "Registration",
    accessor: "registration_count",
    disableFilters: true,
  },
  {
    columnId: 3,
    Header: "Participations",
    accessor: "participant_count",
    disableFilters: true,
  },
  {
    columnId: 4,
    Header: "Conversion (in %)",
    accessor: "covertion_rate",
    disableFilters: true,
    Cell: (value) => <div>{value.value}%</div>,
    // Cell: (value) => (
    //   <div className="d-flex align-items-center gap-2">
    //     <div>
    //       <MiniLineChart series={[value.value, 100 - value.value]} />
    //     </div>

    //     <div>
    //       {value.value}%{" "}
    //       {value.value > 100 - value.value ? (
    //         <span className="text-success">
    //           {findIcon("Arrow Down", "outline", 22)}
    //         </span>
    //       ) : (
    //         <span>{findIcon("Arrow Up", "outline", 22)}</span>
    //       )}
    //     </div>
    //   </div>
    // ),
  },
];

const LocationInsights = () => {
  const { project_id } = useParams();
  const [filterBy, setFilterBy] = useState("Registrations");
  const [activeFilter, setActiveFilter] = useState({
    id: 0,
    index: 0,
  });
  const [eventFilterPayload, setEventFilterPayload] = useState({
    type: "all",
  });
  const locationTableRef = useRef(null);
  const { downloadCSV } = useCsvDownloader();

  useEffect(() => {
    if (activeFilter?.id === 0) {
      setEventFilterPayload({
        type: "all",
      });
    } else {
      setEventFilterPayload({
        event_id: activeFilter?.id,
      });
    }
  }, [activeFilter]);

  const {
    data: locationDistribution,
    isLoading: isLocationDistributionLoading,
  } = useGetLocationDistribution(project_id, {
    ...eventFilterPayload,
    analysis_type: filterBy?.slice(0, -1)?.toLowerCase() || "registration", // participation || registration
  });

  const { data: zonalDistribution, isLoading: isZonalDistributionLoading } =
    useGetZonalDistribution(project_id, {
      ...eventFilterPayload,
      analysis_type: filterBy?.slice(0, -1)?.toLowerCase() || "registration", // participation || registration
    });

  const { data: majorCities, isLoading: isMajorCitiesLoading } =
    useGetMajorCities(project_id, {
      ...eventFilterPayload,
      analysis_type: filterBy?.slice(0, -1)?.toLowerCase() || "registration", // participation || registration
    });

  const { data: allProjectList, isLoading: isAllProjectListLoading } =
    useGetAllProjectEventsList(project_id);

  const { data: locationReport, isLoading: isLocationReportLoading } =
    useGetLocationReport(project_id);

  const locationTableCsvFormat = {
    header: ["State", "Registration", "Participations", "Conversion (in %)"],
    column: [
      "state",
      "registration_count",
      "participant_count",
      "covertion_rate",
    ],
    rows: locationReport,
  };

  const handleTableCsvDownload = () => {
    downloadCSV(locationTableCsvFormat);
  };

  const updateFilterHandler = (value) => {
    setFilterBy(value);
  };

  function formatZonalDistibutionData(inputArray = []) {
    if (!Array.isArray(inputArray)) return [];
    return inputArray.map(({ level, percentage }) => ({
      x: level || "Other",
      y: percentage,
    }));
  }

  function majorCitiesFormatter(obj = {}) {
    return Object.keys(obj).map((key) => ({ name: key }));
  }

  const indiaMapFormattedData = transformIndiaMapData(locationDistribution);

  const zonalDistributionChartOptions =
    formatZonalDistibutionData(zonalDistribution);

  const majorCitiesFormattedData = majorCitiesFormatter(majorCities);

  return (
    <div>
      <div className="mb-3 mb-md-0">
        <Titlebar
          title={"Location Insights"}
          projectType={"Events"}
          filterType={"all"}
          filterBy={filterBy}
          isLoading={isAllProjectListLoading}
          eventsFilters={allProjectList}
          activeFilter={activeFilter}
          setActiveFilter={setActiveFilter}
          updateFilterHandler={updateFilterHandler}
        />
      </div>
      <div className="d-flex flex-column gap-4">
        <Row>
          <Col md={6} sm={12} className="d-flex flex-column mb-4 mb-md-0">
            {!isLocationDistributionLoading &&
            locationDistribution?.length > 0 ? (
              <IndiaMap
                title={`${filterBy} by Location`}
                stateData={indiaMapFormattedData}
              />
            ) : isLocationDistributionLoading ? (
              <MapAnalyticsSkeleton />
            ) : (
              <CardNotFound
                title={`${filterBy} by Location`}
                isTitleCenter={true}
              />
            )}
          </Col>
          <Col md={6} sm={12} className="d-flex flex-column gap-3">
            <Col md={12}>
              {!isMajorCitiesLoading && Object.keys(majorCities)?.length > 0 ? (
                <TextPillCard
                  data={majorCitiesFormattedData}
                  title={"Major Cities"}
                />
              ) : isMajorCitiesLoading ? (
                <SquareChartSkeleton />
              ) : (
                <CardNotFound
                  title={"Major Cities"}
                  isTitleCenter={true}
                  height={350}
                />
              )}
            </Col>
            <Col md={12}>
              {!isZonalDistributionLoading && zonalDistribution?.length > 0 ? (
                <TreeMapChart
                  title={`${filterBy} by Zone`}
                  series={zonalDistributionChartOptions}
                  height={350}
                />
              ) : isZonalDistributionLoading ? (
                <SquareChartSkeleton height={350} />
              ) : (
                <CardNotFound
                  title={`${filterBy} by Zone`}
                  isTitleCenter={true}
                  height={350}
                />
              )}
            </Col>
          </Col>
        </Row>

        <Row>
          <Col sm={12}>
            <div className="d-flex">
              <h5 className="m-0 text-primary border-bottom border-primary border-2 w-auto px-3 pb-2 ms-4">
                Location
              </h5>
            </div>
            <Card className="rounded-3">
              <Card.Header>
                <div className="d-flex flex-row justify-content-end gap-3 flex-shrink-0">
                  <div
                    className="text-success cursor-pointer"
                    onClick={() => downloadTablePNG(locationTableRef)}
                  >
                    {findIcon("DownloadReport", "dual-tone", "18")}
                    <span className="text-success fs-7 px-2 d-none d-md-inline-block fw-semibold">
                      Download Report
                    </span>
                  </div>
                  <div
                    className="text-primary cursor-pointer"
                    onClick={handleTableCsvDownload}
                  >
                    {findIcon("ExportReport", "dual-tone", "20")}
                    <span className="text-primary fs-7 px-2 d-none d-md-inline-block fw-semibold">
                      Export
                    </span>
                  </div>
                </div>
              </Card.Header>
              <Card.Body>
                <div ref={locationTableRef}>
                  {!isLocationReportLoading ? (
                    <ReactTable
                      data={locationReport}
                      columns={LOCATION_REPORT_TABLE_COLUMNS}
                      isPaginationShow={false}
                      bordered
                      noDataText="No Data Available"
                    />
                  ) : (
                    <CommonTableLoader
                      tableRowLength={5}
                      hasSearch={false}
                      hasActions={false}
                    />
                  )}
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default LocationInsights;
