import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import Plotly from "plotly.js-dist";
import { Dropdown } from "react-bootstrap";
import CustomToggle from "../../../../../components/dropdowns";
import Chart from "react-apexcharts";
import { useAnalytisTopInternationalCountries } from "../../../../../queries/query-hooks/hcp-profile-hook";
import Skeleton from "../../../../../components/skeleton";
import HcpAnalysisSkeleton from "../../../../../skeleton/hcp/HcpAnalysisSkeleton";
import { yearlyFilterOptions } from "../../constant/hcp-constatnt";

const yearlyFilteInitial = yearlyFilterOptions.find((option) => option.initial);

var options = {
  optionsMixedChart: {
    chart: {
      id: "basic-bar",
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        columnWidth: "50%",
      },
    },
    stroke: {
      width: [4, 0, 0],
    },
    xaxis: {
      categories: [
        "Year 2018",
        "Year 2019",
        "Year 2020",
        "Year 2021",
        "Year 2022",
        "Year 2023",
        "Year 2024",
      ],
    },
    markers: {
      size: 6,
      strokeWidth: 3,
      fillOpacity: 0,
      strokeOpacity: 0,
      hover: {
        size: 8,
      },
    },
    yaxis: {
      tickAmount: 5,
      min: 0,
      max: 100,
    },
  },
  seriesMixedChart: [
    {
      name: "series-1",
      type: "line",
      data: [30, 40, 25, 50, 49, 21, 70, 51],
    },
    {
      name: "series-2",
      type: "column",
      data: [23, 12, 54, 61, 32, 56, 81, 19],
    },
    {
      name: "series-3",
      type: "column",
      data: [62, 12, 45, 55, 76, 41, 23, 43],
    },
    {
      name: "series-3",
      type: "column",
      data: [62, 12, 45, 55, 76, 41, 23, 43],
    },
  ],
  optionsRadial: {
    plotOptions: {
      radialBar: {
        startAngle: -135,
        endAngle: 225,
        hollow: {
          margin: 0,
          size: "70%",
          background: "#fff",
          image: undefined,
          imageOffsetX: 0,
          imageOffsetY: 0,
          position: "front",
          dropShadow: {
            enabled: true,
            top: 3,
            left: 0,
            blur: 4,
            opacity: 0.24,
          },
        },
        track: {
          background: "#fff",
          strokeWidth: "67%",
          margin: 0, // margin is in pixels
          dropShadow: {
            enabled: true,
            top: -3,
            left: 0,
            blur: 4,
            opacity: 0.35,
          },
        },

        dataLabels: {
          showOn: "always",
          name: {
            offsetY: -20,
            show: true,
            color: "#888",
            fontSize: "13px",
          },
          value: {
            formatter: function (val) {
              return val;
            },
            color: "#111",
            fontSize: "30px",
            show: true,
          },
        },
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "dark",
        type: "horizontal",
        shadeIntensity: 0.5,
        gradientToColors: ["#ABE5A1"],
        inverseColors: true,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 100],
      },
    },
    stroke: {
      lineCap: "round",
    },
    labels: ["Percent"],
  },
  seriesRadial: [76],
  optionsBar: {
    chart: {
      stacked: true,
      stackType: "100%",
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    dataLabels: {
      dropShadow: {
        enabled: true,
      },
    },
    stroke: {
      width: 0,
    },
    xaxis: {
      categories: ["Fav Color"],
      labels: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    fill: {
      opacity: 1,
      type: "gradient",
      gradient: {
        shade: "dark",
        type: "vertical",
        shadeIntensity: 0.35,
        gradientToColors: undefined,
        inverseColors: false,
        opacityFrom: 0.85,
        opacityTo: 0.85,
        stops: [90, 0, 100],
      },
    },

    legend: {
      position: "bottom",
      horizontalAlign: "right",
    },
  },
  // seriesBar: [
  //   {
  //     name: "blue",
  //     data: [32],
  //   },
  //   {
  //     name: "green",
  //     data: [41],
  //   },
  //   {
  //     name: "yellow",
  //     data: [12],
  //   },
  //   {
  //     name: "red",
  //     data: [65],
  //   },
  // ],
};

export default function TopInternationalCountriesAnalytics() {
  const [payload, setPayload] = useState({
    year: yearlyFilteInitial.value,
    month: "",
    isRefresh: "",
  });

  const [dropdownType, setDropdownType] = useState(yearlyFilteInitial.label);

  const { data, isLoading } = useAnalytisTopInternationalCountries(
    payload,
    dropdownType
  );

  const handleSelect = (eventKey, event) => {
    setDropdownType(event.target.innerText);
  };

  // const [chartOptions, setChartOptions] = useState({
  //   chart: {
  //     id: "basic-bar",
  //     toolbar: {
  //       show: false,
  //     },
  //   },
  //   plotOptions: {
  //     bar: {
  //       columnWidth: "50%",
  //     },
  //   },
  //   stroke: {
  //     width: [4, 0, 0],
  //   },
  //   xaxis: {
  //     categories: [],
  //   },
  //   markers: {
  //     size: 6,
  //     strokeWidth: 3,
  //     fillOpacity: 0,
  //     strokeOpacity: 0,
  //     hover: {
  //       size: 8,
  //     },
  //   },
  // });
  // const [chartSeries, setChartSeries] = useState([]);

  // useEffect(() => {
  //   if (data && data?.data) {
  //     let mixedSeries = [
  //       {
  //         name: "India",
  //         type: "area",
  //         data: data?.data?.top_ten_data ? data?.data.top_ten_data : [],
  //       },
  //     ];
  //     setChartSeries(mixedSeries);
  //     setChartOptions({
  //       ...chartOptions,
  //       xaxis: { categories: data.data.labels },
  //     });
  //   }
  // }, [data]);

  const maxYPlot = data ? Math.max(...data?.data?.top_ten_data) : 100;

  let options = {
    optionsMixedChart: {
      chart: {
        id: "basic-bar",
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          columnWidth: "50%",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
      },
      xaxis: {
        categories: data?.data?.labels ? data?.data?.labels : [],
        labels: {
          show: true,
          trim: true,
          style: {
            fontSize: "10px",
          },
        },
        tickAmount: data?.data?.labels.length,
      },
      markers: {
        size: 6,
        strokeWidth: 3,
        fillOpacity: 0,
        strokeOpacity: 0,
        hover: {
          size: 8,
        },
      },
      yaxis: {
        min: 0,
        max: maxYPlot,
      },
    },
    seriesMixedChart: [
      {
        name: "India",
        type: "area",
        data: data?.data?.top_ten_data ? data?.data.top_ten_data : [],
      },
    ],
  };

  if (isLoading) {
    return <HcpAnalysisSkeleton />;
  }

  return (
    <Card className="flex-grow-1">
      <Card.Header className="flex-wrap d-flex align-items-center justify-content-between text-capitalize">
        <Card.Title>
          <h5 className="mb-0"> Top 10 International Countries Analytics</h5>
          <p className="mb-0 fs-7 text-capitalize mt-2">{dropdownType}</p>
        </Card.Title>

        <Dropdown
          className="d-flex justify-content-center"
          onSelect={handleSelect}
        >
          <Dropdown.Toggle
            variant="text-secondary text-capitalize dropdown-toggle"
            as={CustomToggle}
          >
            {dropdownType}
          </Dropdown.Toggle>
          <Dropdown.Menu aria-labelledby="dropdownMenuButton1">
            {yearlyFilterOptions.map((option, index) => (
              <Dropdown.Item
                key={index}
                eventKey={`action-${index + 1}`}
                onClick={() => {
                  setPayload({
                    year: option.value,
                    month: "",
                    isRefresh: "",
                  });
                }}
              >
                {option.label}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </Card.Header>
      <Card.Body className="p-2 p-md-4">
        {/* {!isLoading && chartSeries ? (
          <Chart options={chartOptions} series={chartSeries} type="area" />
        ) : (
          <Skeleton height={350} width={550} />
        )} */}
        <Chart
          options={options.optionsMixedChart}
          series={options.seriesMixedChart}
          type="area"
        />
      </Card.Body>
    </Card>
  );
}
