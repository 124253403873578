import React, { memo, Fragment } from "react";
//React-bootstrap
import { Image, Button, Dropdown, Badge } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
//React-router

import useRedirect from "../../../../../../../components/custom-hooks/use-redirect";
import { findIcon } from "../../../../../../dashboard/icons";
import { useNavigate, Link, redirect } from "react-router-dom";
import {
  getFormatedDate,
  getRelativeDate,
} from "../../../../../../../common/utils/utils";
import { useDispatch, useSelector } from "react-redux";
import { updateProject } from "../../../../../../../store/project/project.action";
import { selectProject } from "../../../../../../../store/project/project.selector";
import * as dayjs from "dayjs";

const newLocal = "bg-white rounded-3 p-3 gap-2 mb-4 d-flex flex-column";
const ProjectCard = memo(
  ({
    project_id,
    title,
    tags,
    projectType,
    publishDate,
    channel_logo,
    projectTypeId,
    projectTypeCode,
    endDate,
  }) => {
    // let navigate = useNavigate();
    const dispatch = useDispatch();
    const project = useSelector(selectProject);

    const { redirectTo } = useRedirect();

    const onManageClick = (projectTypeCode) => {
      dispatch(
        updateProject({
          project_id,
          title,
          tags,
          projectType,
          projectTypeCode,
          publishDate,
          channel_logo,
        })
      );
      switch (projectTypeCode) {
        case "brand_ads":
          redirectTo(`brand-ads/summary/${project_id}`);
          break;

        case "doctube_channel":
          redirectTo(`project/channels`);
          break;

        case "sponsored_content":
          redirectTo(`sponsored-content/summary/${project_id}`);
          break;

        case "events":
          redirectTo(`events/summary/${project_id}`);
          break;

        default:
          break;
      }
    };

    const handleSelect = (eventKey) => {
      redirectTo(`workspace/edit-project/${project_id}`, {
        eventKey: eventKey || {},
      });
    };

    const userActions = [
      {
        label: "Edit Project",
        onClick: () => onManageClick(projectTypeCode),
      },
    ];

    function generateData(date) {
      const isEndDatePassed = dayjs(date).isBefore(dayjs());
      if (isEndDatePassed) {
        return getFormatedDate(date);
      } else {
        return getFormatedDate(dayjs().subtract(1, "day"));
      }
    }

    let project_icon = findIcon("Channel Icon", "dual-tone", 16);

    switch (projectTypeCode) {
      case "brand_ads":
        project_icon = findIcon("start-up", "solid", 16);
        break;
      case "sponsored_content":
        project_icon = findIcon("bulb", "solid", 16);
        break;
      case "events":
        project_icon = findIcon("video-chat", "solid", 16);
        break;
    }

    return (
      <Fragment>
        <div className={newLocal} to="#">
          <div className="d-flex align-items-start justify-content-start">
            {projectType && (
              <div
                className="bg-light rounded-pill px-2 py-1 text-primary d-flex gap-1 bg-opacity-25 d-flex align-items-end"
                onClick={() => onManageClick(projectTypeCode)}
              >
                <span className="fill-primary">{project_icon}</span>
                <span className="fs-7 fw-semibold">{projectType}</span>
              </div>
            )}
            {projectTypeId !== 1 && (
              <div className="ms-auto">
                <Dropdown onSelect={handleSelect}>
                  <Dropdown.Toggle
                    as="svg"
                    width="5"
                    viewBox="0 0 5 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    role="button"
                    // id={`dropdownMenu-${props.id}`}
                  >
                    <path
                      d="M2.49927 2.50444V2.49544"
                      stroke="currentColor"
                      strokeWidth="3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M2.49927 7.50438V7.49538"
                      stroke="currentColor"
                      strokeWidth="3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M2.49927 12.5044V12.4954"
                      stroke="currentColor"
                      strokeWidth="3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </Dropdown.Toggle>
                  <Dropdown.Menu
                    className="dropdown-menu-end rounded-3"
                    aria-labelledby="news-dropdown"
                  >
                    {userActions?.length > 0 &&
                      userActions.map((option, index) => (
                        <Dropdown.Item
                          key={index}
                          eventKey={index + 1}
                          onClick={option.onClick}
                        >
                          {option.label}
                        </Dropdown.Item>
                      ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            )}
          </div>
          <div
            onClick={() => onManageClick(projectTypeCode)}
            className="d-flex flex-row gap-3 align-items-center border-bottom border-light border-opacity-50 pb-3 mt-1"
          >
            <div className="avatar-45 border border-opacity-25 rounded-circle overflow-hidden">
              <img
                className="img-thumbnail shadow-none p-1 border-0 rounded-0"
                src={channel_logo}
                alt="Channel Logo"
              ></img>
            </div>
            <div className="d-flex flex-column text-break">
              {title && (
                <Link
                  className="text-capitalize m-0 text-black fs-5 fw-semibold one-line-clamp"
                  title={title}
                >
                  {title}
                </Link>
              )}
              {/* {publishDate && (
              <span className="fs-7 d-flex justify-content-start align-items-center">
                {publishDate} 13th June 2025
              </span>
            )} */}
              {publishDate && (
                <span className="fs-7 d-flex justify-content-start align-items-center text-black fw-medium">
                  <b className="fw-normal text-black-50">Updated on</b>&nbsp;
                  {generateData(endDate)}
                </span>
              )}
            </div>
          </div>
          {tags && tags?.length > 0 && (
            <div>
              <Swiper
                as="ul"
                className="p-0 m-0 swiper-wrapper list-inline"
                slidesPerView={4}
                spaceBetween={15}
                breakpoints={{
                  320: { slidesPerView: 4 },
                  550: { slidesPerView: 4 },
                  991: { slidesPerView: 4 },
                  1400: { slidesPerView: 4 },
                  1500: { slidesPerView: 5 },
                  1920: { slidesPerView: 6 },
                  2040: { slidesPerView: 7 },
                  2440: { slidesPerView: 8 },
                }}
                data-aos="fade-up"
                data-aos-delay="700"
              >
                {tags &&
                  tags?.length > 0 &&
                  tags?.project_tag_data?.length > 0 && (
                    <SwiperSlide>
                      <span className="text-success fs-7 bg-light bg-opacity-25 rounded-pill px-2 pb-1 pt-1">
                        {tags?.project_tag_data[0]?.project_tag_name}
                      </span>
                    </SwiperSlide>
                  )}
              </Swiper>
            </div>
          )}
        </div>
      </Fragment>
    );
  }
);

ProjectCard.displayName = "ProjectCard";
export default ProjectCard;
