import React, { useCallback, useEffect, useRef, useState } from "react";
import { Badge, Button, Card, Col, Row } from "react-bootstrap";
import CommonTableLoader from "../../../../skeleton/common-table-loader";
import dayjs from "dayjs";
import PageNotFound from "../../../PageNotFound";
import ReactTable from "../../../../lib/table/react-table";
import { useGetSupportList } from "../../../../queries/query-hooks/help-and-support-hook";
import {
  changeTableStateToPayload,
  statusToBg,
} from "../../../../common/utils/utils";
import { fallbackImages } from "../../../../common/constants/fallback-images";
import { findIcon } from "../../../dashboard/icons";
import HelpAndSupportModal from "../components/HelpAndSupportModal";
import SupportImage from "../components/SupportImage";

const INITIAL_PAYLOAD = {
  filter: {},
  pagination: {
    limit: 10,
    offset: 0,
  },
  sort: {
    support_id: "desc",
  },
};

const TicketLists = () => {
  const [modalShow, setModalShow] = useState(false);
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [payload, setPayload] = useState(INITIAL_PAYLOAD);
  const post_json_ticket_list = useRef(INITIAL_PAYLOAD);

  const {
    data: ticketLists,
    isLoading: isTicketListsLoading,
    refetch: refetchTicketLists,
  } = useGetSupportList(payload);

  useEffect(() => {
    refetchTicketLists();
  }, []);

  const viewTicketModalHandler = (data) => {
    setModalShow(true);
    setSelectedTicket(data);
  };
  const hideTicketModalHandler = () => {
    setModalShow(false);
    setSelectedTicket(null);
  };

  const onTableStateChange = useCallback(
    async (state) => {
      let payload = changeTableStateToPayload(state);
      let modifiedPayload = { ...post_json_ticket_list.current, ...payload };
      post_json_ticket_list.current = modifiedPayload;
      console.log(modifiedPayload);
      setPayload(modifiedPayload);
    },
    [post_json_ticket_list.current]
  );

  const COLUMNS = [
    {
      Header: "ID",
      accessor: "support_id",
      disableFilters: true,
    },
    {
      Header: "Subject",
      accessor: "support_name",
      disableFilters: true,
    },
    {
      Header: "Category",
      accessor: "support_module_id",
      disableFilters: true,
      Cell: ({ value }) => {
        if (value) {
          return value.module_title;
        }
      },
    },
    {
      Header: "Raised By",
      accessor: "created_by",
      disableFilters: true,
      Cell: ({ value }) => {
        if (value) {
          return value.name;
        }
      },
    },
    {
      Header: "Date",
      accessor: "created_at",
      disableFilters: true,
      Cell: ({ value }) => {
        if (value) {
          return dayjs(value).format("DD MMM YYYY");
        }
      },
    },
    {
      Header: "Attachments",
      accessor: "support_path",
      disableFilters: true,
      Cell: ({ value }) => {
        if (value) return <SupportImage image={value} />;
      },
    },
    {
      Header: "Priority",
      accessor: "support_priority_id",
      disableFilters: true,
      Cell: ({ value }) => {
        if (value) {
          return (
            <Badge bg={statusToBg(value?.priority_id)} className="me-2 ">
              <span className="btn-inner text-capitalize">
                {value.priority_name}
              </span>
            </Badge>
          );
        }
      },
    },
    {
      Header: "Status",
      accessor: "status",
      disableFilters: true,
      Cell: ({ value }) => {
        if (value) {
          return value.value;
        }
      },
    },
    {
      Header: "Actions",
      Cell: ({ row }) => {
        const data = row.original;
        return (
          <Button
            className="btn btn-sm rounded-pill"
            onClick={(e) => viewTicketModalHandler(data)}
          >
            {findIcon("Eye", "solid", 16)}
          </Button>
        );
      },
    },
  ];

  return (
    <Row>
      <Col lg="12 mb-4">
        <div className="d-flex flex-column">
          <h3 className="m-0">Support Ticket Lists</h3>
        </div>
      </Col>
      <Col lg="12">
        <Card>
          <Card.Body>
            <div className="fancy-table table-responsive rounded">
              {!isTicketListsLoading && ticketLists?.rows?.length > 0 ? (
                <ReactTable
                  data={ticketLists?.rows}
                  columns={COLUMNS}
                  recordsTotal={ticketLists?.totalCount}
                  onTableStateChange={onTableStateChange}
                  initialState={INITIAL_PAYLOAD}
                  recordsPerPage={10}
                  noDataText="You don't have any raised tickets"
                  bordered={true}
                />
              ) : isTicketListsLoading ? (
                <CommonTableLoader />
              ) : (
                <PageNotFound />
              )}
            </div>
          </Card.Body>
        </Card>
      </Col>

      <HelpAndSupportModal
        show={modalShow}
        onHide={hideTicketModalHandler}
        data={selectedTicket}
      />
    </Row>
  );
};

export default TicketLists;
