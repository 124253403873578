import React, { useState, useEffect } from "react";
import Titlebar from "../components/Titlebar";
import BasicColumnChart from "../../../../../components/chart/BasicColumnChart";
import { Card, Row, Col, Button, Badge } from "react-bootstrap";
import { GRAPH_FILL_COLOR } from "../constant/graph-color-scheme";
import DonutChart from "../../../../../components/chart/DonutChart";
import IndiaMap from "../components/IndiaMap";
import TreeMapChart from "../../../../../components/chart/TreeMapChart";
import PolarChart from "../../../../../components/chart/PolarChart";
import {
  useGetLocationStateMap,
  useGetOverviewBrandClickReport,
  useGetOverviewBrandViewReport,
  useGetOverviewBrowserDistribution,
  useGetOverviewDeviceDistribution,
  useGetOverviewLandingCategory,
  useGetOverviewLocationMap,
  useGetOverviewMobileDistribution,
  useGetOverviewViewCount,
  useGetOverviewZoneDistribution,
  useGetSummaryCampaignGroupList,
} from "../../../../../queries/query-hooks/workspace/brand-ads-hook";

import { useGetProjectDetails } from "../../../../../queries/query-hooks/workspace/workspace-hook";

import {
  formatLocalizedDate,
  formatNumberWithCommas,
  transformGraphData,
  transformHeatMapData,
} from "../../../../../common/utils/utils";
import TableHeaderSwiper from "../components/TableHeaderSwiper";
import { initialDate } from "./../constant/date-range-constant";

import BarChartSkeleton from "../../../../../skeleton/workspace/clirnet/brand-ads/bar-chart-skeleton";
import SquareChartSkeleton from "../../../../../skeleton/workspace/clirnet/brand-ads/square-chart-skeleton";
import MapAnalyticsSkeleton from "../../../../../skeleton/workspace/clirnet/brand-ads/map-analytics-skeleton";
import NavSwiperSkeleton from "../../../../../skeleton/workspace/clirnet/brand-ads/nav-swiper-skeleton";
import PageNotFound from "../../../../PageNotFound";
import { findIcon } from "../../../../dashboard/icons";
import { useParams } from "react-router-dom";
import Skeleton from "../../../../../components/skeleton";
import PieChart from "../../../../../components/chart/PieChart";
import * as dayjs from "dayjs";

function Overview() {
  function getGroupIdsCommaSeparated(data) {
    return data.map((item) => item.group_id).join(",");
  }

  const { project_id } = useParams();
  const { data: projectDetails, isLoading: isProjectDetailsLoading } =
    useGetProjectDetails(project_id);

  const [activeCampaignGroup, setActiveCampaignGroup] = useState({});

  const [dateRangePayload, setDateRangePayload] = useState({
    start_date:
      projectDetails?.[0]?.project_start_date || initialDate.start_date,
    end_date: projectDetails?.[0]?.project_end_date || initialDate.end_date,
  });

  const { data: viewReportdata, isLoading: isViewReportLoading } =
    useGetOverviewBrandViewReport(
      project_id,
      {
        start_date: dateRangePayload.start_date,
        end_date: dateRangePayload.end_date,
        campaign_list: activeCampaignGroup?.id,
      },
      activeCampaignGroup?.id ? true : false
    );

  const { data: viewCountdata, isLoading: isViewCountLoading } =
    useGetOverviewViewCount(
      project_id,
      {
        start_date: dateRangePayload.start_date,
        end_date: dateRangePayload.end_date,
        campaign_list: activeCampaignGroup?.id,
      },
      activeCampaignGroup?.id ? true : false
    );

  const { data: clickReportdata, isLoading: isClickReportLoading } =
    useGetOverviewBrandClickReport(
      project_id,
      {
        start_date: dateRangePayload.start_date,
        end_date: dateRangePayload.end_date,
        campaign_list: activeCampaignGroup?.id,
      },
      activeCampaignGroup?.id ? true : false
    );

  const { data: stateMapData, isLoading: isStateMapLoading } =
    useGetLocationStateMap(project_id, {
      start_date: dateRangePayload.start_date,
      end_date: dateRangePayload.end_date,
      campaign_list:
        projectDetails && projectDetails?.length > 0
          ? getGroupIdsCommaSeparated(projectDetails[0]?.project_group_data)
          : "",
      type: "percentage",
    });

  const {
    data: deviceDistributionData,
    isLoading: isDeviceDistributionLoading,
  } = useGetOverviewDeviceDistribution(project_id, {
    start_date: dateRangePayload.start_date,
    end_date: dateRangePayload.end_date,
    campaign_list:
      projectDetails && projectDetails?.length > 0
        ? getGroupIdsCommaSeparated(projectDetails[0]?.project_group_data)
        : "",
  });

  const {
    data: mobileDistributionData,
    isLoading: isMobileDistributionLoading,
  } = useGetOverviewMobileDistribution(project_id, {
    start_date: dateRangePayload.start_date,
    end_date: dateRangePayload.end_date,
    campaign_list:
      projectDetails && projectDetails?.length > 0
        ? getGroupIdsCommaSeparated(projectDetails[0]?.project_group_data)
        : "",
  });

  const { data: zoneDistributionData, isLoading: isZoneDistributionLoading } =
    useGetOverviewZoneDistribution(project_id, {
      start_date: dateRangePayload.start_date,
      end_date: dateRangePayload.end_date,
      campaign_list:
        projectDetails && projectDetails?.length > 0
          ? getGroupIdsCommaSeparated(projectDetails[0]?.project_group_data)
          : "",
    });

  const {
    data: browserDistributionData,
    isLoading: isBrowserDistributionLoading,
  } = useGetOverviewBrowserDistribution(project_id, {
    start_date: dateRangePayload.start_date,
    end_date: dateRangePayload.end_date,
    campaign_list:
      projectDetails && projectDetails?.length > 0
        ? getGroupIdsCommaSeparated(projectDetails[0]?.project_group_data)
        : "",
  });

  const { data: landingCategoryData, isLoading: isLandingCategoryLoading } =
    useGetOverviewLandingCategory(project_id, {
      start_date: dateRangePayload.start_date,
      end_date: dateRangePayload.end_date,
      campaign_list:
        projectDetails && projectDetails?.length > 0
          ? getGroupIdsCommaSeparated(projectDetails[0]?.project_group_data)
          : "",
    });

  const { data: campaignGroupList, isLoading: isCampaignGroupListLoading } =
    useGetSummaryCampaignGroupList(project_id, {
      campaign_list:
        projectDetails && projectDetails?.length > 0
          ? getGroupIdsCommaSeparated(projectDetails[0]?.project_group_data)
          : "",
    });

  // useEffect(() => {
  //   setEnable(true);
  // }, [campaignGroupList]);

  const transformStateMapData = (data = [], key) => {
    return [
      data?.reduce((acc, item) => {
        if (item.name && item[key] !== null) {
          acc[item.name] = item[key];
        }
        return acc;
      }, {}),
    ];
  };

  const stateMapViewsData = transformStateMapData(
    stateMapData,
    "views_percent"
  );

  const zonalChartOptions =
    zoneDistributionData && zoneDistributionData?.length > 0
      ? transformHeatMapData(zoneDistributionData, "name", "user_count")
      : [];

  const totalViewsChartOptions = {
    colors: GRAPH_FILL_COLOR,
    series: [
      {
        name: "View Count",
        data:
          viewReportdata?.length > 0
            ? transformGraphData(viewReportdata, "Impression", "formatted_date")
                ?.series
            : [],
        color: "#3256b8", // Customize color for Net Profit
      },
    ],

    label:
      viewReportdata?.length > 0
        ? transformGraphData(viewReportdata, "Impression", "formatted_date")
            ?.label
        : [],
    title: "Views",
  };

  const totalClickChartOptions = {
    series: [
      {
        name: "Click Count",
        data:
          clickReportdata?.length > 0
            ? transformGraphData(clickReportdata, "click", "formatted_date")
                ?.series
            : [],
        color: "#1aa053",
      },
    ],
    label:
      clickReportdata?.length > 0
        ? transformGraphData(clickReportdata, "click", "formatted_date")?.label
        : [],
    title: "Clicks",
  };

  const deviceDistributionChartOptions = {
    series:
      deviceDistributionData?.length > 0
        ? transformGraphData(deviceDistributionData, "Device_count", "new_os")
            ?.series
        : [],
    label:
      deviceDistributionData?.length > 0
        ? transformGraphData(deviceDistributionData, "Device_count", "new_os")
            ?.label
        : [],
    title: "Device Distribution",
  };

  const pageCategoryChartOptions = {
    label:
      landingCategoryData && landingCategoryData?.length > 0
        ? transformGraphData(
            landingCategoryData,
            "pct_count",
            "Landing_page_type",
            false
          )?.label
        : [],
    series:
      landingCategoryData && landingCategoryData?.length > 0
        ? transformGraphData(
            landingCategoryData,
            "pct_count",
            "Landing_page_type"
          )?.series
        : [],
    title: "Impression by Landing Page Type",
  };

  const mobileDistributionChartOptions = {
    series:
      mobileDistributionData?.length > 0
        ? transformGraphData(mobileDistributionData, "os_count", "os")?.series
        : [],
    label:
      mobileDistributionData?.length > 0
        ? transformGraphData(mobileDistributionData, "os_count", "os")?.label
        : [],
    title: "Mobile Device Distribution",
  };

  const browserDistributionChartOptions = {
    label:
      browserDistributionData?.length > 0
        ? transformGraphData(
            browserDistributionData,
            "percentage",
            "new_browser"
          )?.label
        : [],
    series:
      browserDistributionData?.length > 0
        ? transformGraphData(
            browserDistributionData,
            "percentage",
            "new_browser"
          )?.series
        : [],
    title: "Browser Distribution",
  };

  const handleNavClick = (index) => {
    // setActiveNavIndex(index);
  };

  useEffect(() => {
    if (projectDetails && projectDetails?.length > 0) {
      const today = dayjs();
      const projectEndDate = dayjs(projectDetails[0]?.project_end_date);
      let result = projectDetails[0]?.project_end_date;

      if (projectEndDate && projectEndDate.isAfter(today)) {
        result = today.subtract(1, "day");
      } else {
        result = projectEndDate;
      }

      setDateRangePayload({
        start_date: formatLocalizedDate(
          new Date(projectDetails[0]?.project_start_date),
          "YYYY-MM-DD[T]00:00:00.000Z"
        ),
        end_date: formatLocalizedDate(
          new Date(result),
          "YYYY-MM-DD[T]23:59:59.SSSZ"
        ),
      });
    }
  }, [projectDetails]);

  return (
    <>
      <Row>
        <Col md={12} sm={12}>
          <Titlebar
            title={"Analytics"}
            projectType={"Brand Ads"}
            setDateRangePayload={setDateRangePayload}
            projectStartDate={
              projectDetails?.length > 0
                ? projectDetails[0]?.project_start_date
                : "   "
            }
            projectEndDate={
              projectDetails?.length > 0
                ? projectDetails[0]?.project_end_date
                : "   "
            }
            dateLabel={dateRangePayload}
            isProjectDetailsLoading={isProjectDetailsLoading}
          />
        </Col>

        <Col md={12}>
          {!isCampaignGroupListLoading && campaignGroupList.length > 0 ? (
            <TableHeaderSwiper
              title={campaignGroupList}
              activeCampaignGroup={activeCampaignGroup}
              setActiveCampaignGroup={setActiveCampaignGroup}
            />
          ) : (
            <NavSwiperSkeleton />
          )}
        </Col>

        <Col md={6} sm={12} className="mb-4">
          {!isViewReportLoading && viewReportdata?.length > 0 ? (
            <BasicColumnChart
              title={totalViewsChartOptions.title}
              series={totalViewsChartOptions.series}
              labels={totalViewsChartOptions.label}
            />
          ) : isViewReportLoading ? (
            <BarChartSkeleton />
          ) : (
            <PageNotFound
              title="No data found"
              // message="Upload one to start analyzing data!"
              backgroundImage={findIcon("Empty-folder", "dual-tone", 100)}
            />
          )}
        </Col>
        <Col md={6} sm={12} className="mb-4">
          {!isClickReportLoading && clickReportdata?.length > 0 ? (
            <BasicColumnChart
              title={totalClickChartOptions.title}
              series={totalClickChartOptions.series}
              labels={totalClickChartOptions.label}
            />
          ) : isClickReportLoading ? (
            <BarChartSkeleton />
          ) : (
            <PageNotFound
              title="No data found"
              // message="Upload one to start analyzing data!"
              backgroundImage={findIcon("Empty-folder", "dual-tone", 100)}
            />
          )}
        </Col>

        <Col md={12} sm={12} className="mb-4">
          <Card className="p-2 border bg-light bg-opacity-50 border-secondary border-opacity-10 mb-0">
            <Card.Body className="py-3">
              {isViewCountLoading ? (
                <Skeleton variant="text" height={30} width={"100%"} />
              ) : (
                <h5 className="text-center m-0 fs-5 fw-normal">
                  This Creative has gotten{" "}
                  {viewCountdata?.total_impressions > 0
                    ? formatNumberWithCommas(viewCountdata?.total_impressions)
                    : 0}{" "}
                  views since last {"month"}
                </h5>
              )}
            </Card.Body>
          </Card>
        </Col>

        <Col md={12} lg={6} sm={12} className="d-flex flex-column pe-2 mb-4">
          {!isStateMapLoading && stateMapData?.length > 0 ? (
            <IndiaMap
              stateData={stateMapViewsData}
              title={"Location"}
              valueSuffix="%"
            />
          ) : isStateMapLoading ? (
            <MapAnalyticsSkeleton />
          ) : (
            <PageNotFound
              title="No data found"
              // message="Upload one to start analyzing data!"
              backgroundImage={findIcon("Empty-folder", "dual-tone", 100)}
            />
          )}
        </Col>

        <Col md={12} lg={6}>
          <Row>
            <Col md={12} sm={12} className="mb-4">
              {!isDeviceDistributionLoading &&
              deviceDistributionData?.length > 0 ? (
                <DonutChart
                  series={deviceDistributionChartOptions.series}
                  labels={deviceDistributionChartOptions.label}
                  title={deviceDistributionChartOptions.title}
                  graphColor={GRAPH_FILL_COLOR}
                />
              ) : isDeviceDistributionLoading ? (
                <SquareChartSkeleton />
              ) : (
                <PageNotFound
                  title="No data found"
                  // message="Upload one to start analyzing data!"
                  backgroundImage={findIcon("Empty-folder", "dual-tone", 100)}
                />
              )}
            </Col>
            <Col md={12} sm={12} className="mb-4">
              {!isZoneDistributionLoading &&
              zoneDistributionData?.length > 0 ? (
                <TreeMapChart
                  title={"Zonal Distribution"}
                  series={zonalChartOptions}
                />
              ) : isZoneDistributionLoading ? (
                <SquareChartSkeleton />
              ) : (
                <PageNotFound
                  title="No data found"
                  // message="Upload one to start analyzing data!"
                  backgroundImage={findIcon("Empty-folder", "dual-tone", 100)}
                />
              )}
            </Col>
          </Row>
        </Col>

        <Col md={4} sm={12} className="mb-4">
          {!isLandingCategoryLoading && landingCategoryData?.length > 0 ? (
            <PieChart
              labels={pageCategoryChartOptions.label}
              series={pageCategoryChartOptions.series}
              title={pageCategoryChartOptions.title}
              graphColor={GRAPH_FILL_COLOR}
              height={400}
              width={320}
            />
          ) : isLandingCategoryLoading ? (
            <SquareChartSkeleton />
          ) : (
            <PageNotFound
              title="No data found"
              // message="Upload one to start analyzing data!"
              backgroundImage={findIcon("Empty-folder", "dual-tone", 100)}
            />
          )}
        </Col>

        <Col md={4} sm={12} className="mb-4">
          {!isMobileDistributionLoading &&
          mobileDistributionData?.length > 0 ? (
            <DonutChart
              series={mobileDistributionChartOptions.series}
              labels={mobileDistributionChartOptions.label}
              title={mobileDistributionChartOptions.title}
              graphColor={GRAPH_FILL_COLOR}
              height={350}
            />
          ) : isMobileDistributionLoading ? (
            <SquareChartSkeleton />
          ) : (
            <PageNotFound
              title="No data found"
              // message="Upload one to start analyzing data!"
              backgroundImage={findIcon("Empty-folder", "dual-tone", 100)}
            />
          )}
        </Col>

        <Col md={4} sm={12} className="mb-4">
          {!isBrowserDistributionLoading &&
          browserDistributionData?.length > 0 ? (
            <PolarChart
              labels={browserDistributionChartOptions.label}
              series={browserDistributionChartOptions.series}
              title={browserDistributionChartOptions.title}
              graphColor={GRAPH_FILL_COLOR}
              height={350}
            />
          ) : isBrowserDistributionLoading ? (
            <SquareChartSkeleton />
          ) : (
            <PageNotFound
              title="No data found"
              // message="Upload one to start analyzing data!"
              backgroundImage={findIcon("Empty-folder", "dual-tone", 100)}
            />
          )}
        </Col>
      </Row>
    </>
  );
}

export default Overview;
