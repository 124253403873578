import React, { useMemo, useCallback, useState } from "react";
import { Form, Button } from "react-bootstrap";
import { findIcon } from "../../../../dashboard/icons";

const MappedSpecialities = ({
  masterSpecialities = [],
  specialitiesGroups = [],
  setSpecialitiesGroups = () => {},
}) => {
  const [newSpecialty, setNewSpecialty] = useState("");
  const statesArray = useMemo(
    () => [
      ...masterSpecialities.map((item) => ({
        code: item.speciality_name,
        name: item.speciality_name,
      })),
      {
        code: "ADD_NEW_SPECIALITY",
        name: "Add new Speciality",
      },
    ],
    [masterSpecialities]
  );
  const addDropdownGroup = useCallback(() => {
    setSpecialitiesGroups((prevGroups) => {
      const updatedGroups = prevGroups.map((group, index) => {
        if (
          index === prevGroups.length - 1 &&
          group.secondSelect === "ADD_NEW_SPECIALITY" &&
          newSpecialty
        ) {
          return {
            ...group,
            secondSelect: newSpecialty,
            isLocked: true,
            isCustomSpecialty: true,
          };
        }
        return { ...group, isLocked: true };
      });

      const newGroup = {
        id:
          updatedGroups.length > 0
            ? updatedGroups[updatedGroups.length - 1].id + 1
            : 1,
        firstSelect: "",
        secondSelect: "",
        isLocked: false,
      };

      setNewSpecialty("");

      return [...updatedGroups, newGroup];
    });
  }, [setSpecialitiesGroups, newSpecialty]);

  const removeDropdownGroup = useCallback(
    (id) => {
      setSpecialitiesGroups((prevGroups) =>
        prevGroups.length > 1
          ? prevGroups.filter((group) => group.id !== id)
          : prevGroups
      );
    },
    [setSpecialitiesGroups]
  );

  const removeCustomSpecialty = useCallback(
    (groupId) => {
      setSpecialitiesGroups((prevGroups) =>
        prevGroups.map((group) => {
          if (
            group.id === groupId &&
            group.secondSelect === "ADD_NEW_SPECIALITY"
          ) {
            return { ...group, secondSelect: "", isCustomSpecialty: false };
          }
          return group;
        })
      );
      setNewSpecialty("");
    },
    [setSpecialitiesGroups]
  );

  const handleSelectChange = useCallback(
    (groupId, isFirstSelect, value) => {
      setSpecialitiesGroups((prevGroups) =>
        prevGroups.map((group) => {
          if (group.id === groupId) {
            if (isFirstSelect) {
              return { ...group, firstSelect: value, secondSelect: "" };
            } else {
              return { ...group, secondSelect: value };
            }
          }
          return group;
        })
      );
    },
    [setSpecialitiesGroups]
  );

  const getAvailableFirstDropdownStates = useCallback(
    (currentGroupId) => {
      const selectedStates = new Set();

      specialitiesGroups.forEach((group) => {
        if (group.id !== currentGroupId && group.firstSelect) {
          selectedStates.add(group.firstSelect);
        }
      });

      specialitiesGroups.forEach((group) => {
        if (group.secondSelect) {
          selectedStates.add(group.secondSelect);
        }
      });

      return statesArray.filter(
        (state) =>
          state.code !== "ADD_NEW_SPECIALITY" &&
          (!selectedStates.has(state.code) ||
            state.code ===
              specialitiesGroups.find((g) => g.id === currentGroupId)
                ?.firstSelect)
      );
    },
    [specialitiesGroups, statesArray]
  );

  const getAvailableSecondDropdownStates = useCallback(() => {
    const selectedStates = new Set();

    specialitiesGroups.forEach((group) => {
      if (group.firstSelect) {
        selectedStates.add(group.firstSelect);
      }
    });

    return statesArray.filter((state) => !selectedStates.has(state.code));
  }, [specialitiesGroups, statesArray]);

  const isAddButtonDisabled = useCallback(() => {
    const lastGroup = specialitiesGroups[specialitiesGroups.length - 1];
    return !(lastGroup.firstSelect && lastGroup.secondSelect);
  }, [specialitiesGroups]);

  return (
    <div>
      <div className="d-flex flex-column">
        {specialitiesGroups.map((group, index) => (
          <div
            key={`${group.id}-${index}`}
            className="d-flex align-items-center mb-3 w-100"
          >
            <div className="d-flex flex-grow-1 me-2">
              <Form.Select
                aria-label={`First Select ${group.id}`}
                className="flex-grow-1 me-2"
                value={group.firstSelect}
                onChange={(e) =>
                  handleSelectChange(group.id, true, e.target.value)
                }
                disabled={group.isLocked}
              >
                <option value="">Select Speciality...</option>
                {getAvailableFirstDropdownStates(group.id).map(
                  ({ code, name }, index) => (
                    <option key={`${code}-${index}`} value={code}>
                      {name}
                    </option>
                  )
                )}
              </Form.Select>

              {group.isLocked && group.isCustomSpecialty ? (
                <Form.Control
                  readOnly
                  value={group.secondSelect}
                  className="flex-grow-1"
                />
              ) : group.secondSelect === "ADD_NEW_SPECIALITY" ? (
                <Form.Control
                  as="textarea"
                  placeholder={"Enter Custom Speciality"}
                  rows={1}
                  value={newSpecialty}
                  onChange={(e) => setNewSpecialty(e.target.value)}
                />
              ) : (
                <Form.Select
                  aria-label={`Second Select ${group.id}`}
                  className="flex-grow-1"
                  value={group.secondSelect}
                  onChange={(e) =>
                    handleSelectChange(group.id, false, e.target.value)
                  }
                  disabled={!group.firstSelect || group.isLocked}
                >
                  <option value="">Select Speciality...</option>
                  {getAvailableSecondDropdownStates(group.id).map(
                    ({ code, name }, index) => (
                      <option key={`${code}-${index}`} value={code}>
                        {name}
                      </option>
                    )
                  )}
                  {!getAvailableSecondDropdownStates(group.id).some(
                    (option) => option.code === group.secondSelect
                  ) &&
                    group.secondSelect && (
                      <option value={group.secondSelect} disabled>
                        {group.secondSelect}
                      </option>
                    )}
                </Form.Select>
              )}
            </div>

            {group.secondSelect === "ADD_NEW_SPECIALITY" ? (
              <>
                <Button
                  variant="outline-danger"
                  className="border-dashed me-3"
                  onClick={() => removeCustomSpecialty(group.id)}
                >
                  {findIcon("Square X", "dual-tone", 25)}
                </Button>

                {index === specialitiesGroups.length - 1 && (
                  <Button
                    variant="outline-primary"
                    className="border-dashed"
                    onClick={addDropdownGroup}
                    disabled={isAddButtonDisabled()}
                  >
                    {findIcon("Square Tick", "dual-tone", 25)}
                  </Button>
                )}
              </>
            ) : (
              <>
                <Button
                  variant="outline-danger"
                  className="border-dashed me-3"
                  onClick={() => removeDropdownGroup(group.id)}
                  disabled={specialitiesGroups.length === 1}
                >
                  -
                </Button>

                {index === specialitiesGroups.length - 1 && (
                  <Button
                    variant="outline-primary"
                    className="border-dashed"
                    onClick={addDropdownGroup}
                    disabled={isAddButtonDisabled()}
                  >
                    +
                  </Button>
                )}
              </>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default MappedSpecialities;
