import React, { useState, useEffect } from "react";
import Titlebar from "../components/Titlebar";
import IndiaMap from "../components/IndiaMap";
import { Card, Row, Col, Button } from "react-bootstrap";
import TreeMapChart from "../../../../../components/chart/TreeMapChart";
import ReactTable from "../../../../../lib/table/react-table";
import TableHeaderSwiper from "../components/TableHeaderSwiper";
import { findIcon } from "../../../../dashboard/icons";
import SparlLineBar from "../../../../../components/chart/SparlLineBar";
import { initialDate } from "./../constant/date-range-constant";
import {
  useGetLocationMajorCities,
  useGetLocationMap,
  useGetLocationZonalDistribution,
  useGetSummaryCampaignGroupList,
  useGetLocationReport,
  useGetLocationReportCtr,
  useGetLocationStateMap,
} from "../../../../../queries/query-hooks/workspace/brand-ads-hook";
import MapAnalyticsSkeleton from "../../../../../skeleton/workspace/clirnet/brand-ads/map-analytics-skeleton";
import SquareChartSkeleton from "../../../../../skeleton/workspace/clirnet/brand-ads/square-chart-skeleton";
import CommonTableLoader from "../../../../../skeleton/common-table-loader";
import NavSwiperSkeleton from "../../../../../skeleton/workspace/clirnet/brand-ads/nav-swiper-skeleton";
import PageNotFound from "../../../../PageNotFound";
import { fallbackImages } from "../../../../../utilities/fallback-images";
import { useParams } from "react-router-dom";
import { useGetProjectDetails } from "../../../../../queries/query-hooks/workspace/workspace-hook";
import Skeleton from "../../../../../components/skeleton";
import useCsvDownloader from "../../../../../components/custom-hooks/use-csv-downloader";
import { formatLocalizedDate } from "../../../../../common/utils/utils";
import * as dayjs from "dayjs";
const DAYS_OF_WEEK = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

function Location() {
  const { project_id } = useParams();
  const { downloadCSV } = useCsvDownloader();

  const handleTableCsvDownload = () => {
    downloadCSV(tableCsvFormat);
  };

  const [loactionCtrApiEnable, setLoactionCtrApiEnable] = useState(false);

  const [activeCampaignGroup, setActiveCampaignGroup] = useState({});
  function getGroupIdsCommaSeparated(data) {
    return data.map((item) => item.group_id).join(",");
  }

  const { data: projectDetails, isLoading: isProjectDetailsLoading } =
    useGetProjectDetails(project_id);

  const [dateRangePayload, setDateRangePayload] = useState({
    start_date:
      projectDetails?.[0]?.project_start_date || initialDate.start_date,
    end_date: projectDetails?.[0]?.project_end_date || initialDate.end_date,
  });

  const { data: stateMapData, isLoading: isStateMapLoading } =
    useGetLocationStateMap(project_id, {
      start_date: dateRangePayload.start_date,
      end_date: dateRangePayload.end_date,
      campaign_list:
        projectDetails && projectDetails?.length > 0
          ? getGroupIdsCommaSeparated(projectDetails[0]?.project_group_data)
          : "",
      type: "percentage",
    });

  const { data: majorCities, isLoading: isMajorCitiesLoading } =
    useGetLocationMajorCities(project_id, {
      start_date: dateRangePayload.start_date,
      end_date: dateRangePayload.end_date,
      campaign_list:
        projectDetails && projectDetails?.length > 0
          ? getGroupIdsCommaSeparated(projectDetails[0]?.project_group_data)
          : "",
    });
  const { data: zonalDistibution, isLoading: isZonalDistributionLoding } =
    useGetLocationZonalDistribution(project_id, {
      start_date: dateRangePayload.start_date,
      end_date: dateRangePayload.end_date,
      campaign_list:
        projectDetails && projectDetails?.length > 0
          ? getGroupIdsCommaSeparated(projectDetails[0]?.project_group_data)
          : "",
    });

  const { data: locationReport, isLoading: isLocationReportLoading } =
    useGetLocationReport(
      project_id,
      {
        start_date: dateRangePayload.start_date,
        end_date: dateRangePayload.end_date,
        campaign_list: activeCampaignGroup?.id,
      },
      activeCampaignGroup?.id ? true : false
    );

  const { data: locationReportCtr, isLoading: isLocationReportCtrLoading } =
    useGetLocationReportCtr(
      project_id,
      {
        start_date: dateRangePayload.start_date,
        end_date: dateRangePayload.end_date,
        campaign_list: activeCampaignGroup?.id,
      },
      loactionCtrApiEnable && activeCampaignGroup?.id ? true : false
    );

  const { data: campaignGroupList, isLoading: isCampaignGroupListLoading } =
    useGetSummaryCampaignGroupList(project_id, {
      campaign_list:
        projectDetails && projectDetails?.length > 0
          ? getGroupIdsCommaSeparated(projectDetails[0]?.project_group_data)
          : "",
    });

  useEffect(() => {
    if (locationReport?.length > 0) {
      setLoactionCtrApiEnable(true);
    } else {
      setLoactionCtrApiEnable(false);
    }
  }, [locationReport]);

  function mergedLocationData(locationData, locationMatrix) {
    if (!locationMatrix && locationData) {
      return locationData.map((location) => ({
        ...location,
        unique_views: "loading",
        unique_clicks: "loading",
        ctr: "loading",
      }));
    }
    if (locationData && locationData?.length > 0) {
      const mergedData = locationData.map((location) => {
        const matchingMatrix = locationMatrix.find(
          (matrix) => matrix.name === location.name
        );
        return {
          ...location,
          ...(matchingMatrix || {}),
        };
      });
      return mergedData && Array.isArray(mergedData)
        ? mergedData.sort((a, b) => b.views - a.views)
        : [];
    }
  }

  const locationTableData = mergedLocationData(
    locationReport,
    locationReportCtr
  );

  const TableColumnData = [
    {
      Header: "States",
      columnId: 1,
      accessor: "name",
      disableFilters: true,
      Cell: ({ value }) => (value ? value : "-"),
    },
    {
      Header: "Viewed",
      columnId: 2,
      accessor: "views",
      disableFilters: true,
      Cell: ({ value }) => (value ? value : 0),
    },
    {
      Header: "Avg. Frequency",
      columnId: 3,
      accessor: "average_fre",
      disableFilters: true,
      Cell: ({ value }) => (value ? value : 0),
    },
    // {
    //   Header: "Mobile impr",
    //   columnId: 4,
    //   accessor: "mobile_imp",
    //   disableFilters: true,
    //   Cell: ({ value }) => (value ? value : 0),
    // },
    // {
    //   Header: "Web impr (%)",
    //   columnId: 5,
    //   accessor: "web_imp",
    //   disableFilters: true,
    //   Cell: ({ value }) => (value ? value : 0),
    // },
    // {
    //   Header: "Total impr",
    //   columnId: 6,
    //   accessor: "total_impr",
    //   disableFilters: true,
    //   Cell: ({ value }) => (value ? value : 0),
    // },
    {
      Header: "Total CTR",
      columnId: 9,
      accessor: "ctr",
      disableFilters: true,
      Cell: ({ value, row }) => {
        return value == "loading" ? (
          <Skeleton width={100} />
        ) : value ? (
          `${value} %`
        ) : (
          0
        );
      },
    },
    {
      Header: "Trends",
      columnId: 10,
      accessor: "date_Data",
      disableFilters: true,
      // Cell: ({ value, row }) => <SparlLineBar id={row.original.banner_id} series={[348155, 348155, 348155, 339279, 339197, 339006, 323634]} labels={DAYS_OF_WEEK} />
      Cell: ({ value }) =>
        value && value?.length > 0 ? (
          <SparlLineBar series={value.split(",")} labels={DAYS_OF_WEEK} />
        ) : (
          "-"
        ),
    },
  ];

  function formatZonalDistibutionData(inputArray) {
    if (!Array.isArray(inputArray)) return [];
    return inputArray.map(({ name, user_count }) => ({
      x: name,
      y: user_count,
    }));
  }

  const transformMapData = (inputArray) => {
    const result = inputArray.reduce((acc, { User_count, name }) => {
      acc[name] = User_count;
      return acc;
    }, {});
    return [result];
  };

  const dimentionChartData =
    zonalDistibution && zonalDistibution?.length > 0
      ? formatZonalDistibutionData(zonalDistibution)
      : [];

  const transformStateMapData = (data = [], key) => {
    return [
      data?.reduce((acc, item) => {
        if (item.name && item[key] !== null) {
          acc[item.name] = item[key];
        }
        return acc;
      }, {}),
    ];
  };
  const stateMapViewsData = transformStateMapData(
    stateMapData,
    "views_percent"
  );

  const tableCsvFormat = {
    header: [
      "States",
      "Viewed",
      "Avg. Frequency",
      "Total CTR",
      // "Mobile Impr",
      // "Web Impr",
      // "Total impr",
    ],
    column: [
      "name",
      "views",
      "average_fre",
      "ctr",
      // "mobile_imp",
      // "web_imp",
      // "total_impr",
    ],
    // chart: {
    //   header: "Trends",
    //   column: "date_Data",
    // },
    rows: locationTableData,
  };

  // console.log("locationTableData", locationTableData);

  useEffect(() => {
    if (projectDetails && projectDetails?.length > 0) {
      const today = dayjs();
      const projectEndDate = dayjs(projectDetails[0]?.project_end_date);
      let result = projectDetails[0]?.project_end_date;

      if (projectEndDate && projectEndDate.isAfter(today)) {
        result = today.subtract(1, "day");
      } else {
        result = projectEndDate;
      }

      setDateRangePayload({
        start_date: formatLocalizedDate(
          new Date(projectDetails[0]?.project_start_date),
          "YYYY-MM-DD[T]00:00:00.000Z"
        ),
        end_date: formatLocalizedDate(
          new Date(result),
          "YYYY-MM-DD[T]23:59:59.SSSZ"
        ),
      });
    }
  }, [projectDetails]);

  return (
    <>
      <Row>
        <Col md={12} sm={12}>
          <Titlebar
            title={"Location Insights"}
            projectType={"Brand Ads"}
            setDateRangePayload={setDateRangePayload}
            projectStartDate={
              projectDetails?.length > 0
                ? projectDetails[0]?.project_start_date
                : "   "
            }
            projectEndDate={
              projectDetails?.length > 0
                ? projectDetails[0]?.project_end_date
                : "   "
            }
            dateLabel={dateRangePayload}
            isProjectDetailsLoading={isProjectDetailsLoading}
          />
        </Col>

        <Col md={12} lg={6} sm={12} className="d-flex flex-column pe-2 mb-4">
          {!isStateMapLoading && stateMapData?.length > 0 ? (
            <IndiaMap
              title={"Location"}
              stateData={stateMapViewsData}
              valueSuffix="%"
            />
          ) : isStateMapLoading ? (
            <MapAnalyticsSkeleton />
          ) : (
            <PageNotFound
              title="No data found"
              // message="Upload one to start analyzing data!"
              backgroundImage={findIcon("Empty-folder", "dual-tone", 100)}
            />
          )}
        </Col>

        <Col md={12} lg={6}>
          <Row>
            <Col md={12} sm={12} className="mb-2">
              {
                !isMajorCitiesLoading && majorCities?.length > 0 ? (
                  <Card>
                    <Card.Header className="">
                      <h5 className="text-center subHeading position-relative">
                        Major Cities
                      </h5>
                    </Card.Header>
                    <Card.Body className="d-flex flex-wrap justify-content-center gap-2 text-black-50">
                      {majorCities?.length > 0 &&
                        majorCities.map((item, index) => {
                          return (
                            <span
                              key={`${index}`}
                              className="border rounded-pill px-3 py-2"
                            >
                              {item.name}
                            </span>
                          );
                        })}
                    </Card.Body>
                  </Card>
                ) : isMajorCitiesLoading ? (
                  <SquareChartSkeleton />
                ) : null
                // <PageNotFound
                //   title="No data found"
                //   // message="Upload one to start analyzing data!"
                //   backgroundImage={findIcon("Empty-folder", "dual-tone", 100)}
                // />
              }
            </Col>
            <Col md={12} sm={12} className="mb-4">
              {!isZonalDistributionLoding && zonalDistibution?.length > 0 ? (
                <TreeMapChart
                  title={"Zonal Distribution"}
                  series={dimentionChartData}
                />
              ) : isZonalDistributionLoding ? (
                <SquareChartSkeleton />
              ) : (
                <PageNotFound
                  title="No data found"
                  // message="Upload one to start analyzing data!"
                  backgroundImage={findIcon("Empty-folder", "dual-tone", 100)}
                />
              )}
            </Col>
          </Row>
        </Col>

        <Col md={12} sm={12}>
          <div className="d-flex">
            <h5 className="m-0 text-primary border-bottom border-primary border-2 w-auto px-3 pb-2 ms-4">
              Locations
            </h5>
          </div>
          <Card>
            <Card.Header className="d-flex flex-row flex-md-row">
              {!isCampaignGroupListLoading && campaignGroupList.length > 0 ? (
                <TableHeaderSwiper
                  title={campaignGroupList}
                  activeCampaignGroup={activeCampaignGroup}
                  setActiveCampaignGroup={setActiveCampaignGroup}
                />
              ) : (
                <NavSwiperSkeleton />
              )}

              {!isLocationReportLoading && locationReport?.length > 0 ? (
                <div className="d-flex flex-row gap-3 flex-shrink-0">
                  {/* <div className="text-success">
                    {findIcon("DownloadReport", "dual-tone", "18")}
                    <span className="text-black fs-7 px-2 d-none d-md-inline-block">
                      Download Report
                    </span>
                  </div> */}
                  <div
                    className="text-success cursor-pointer "
                    onClick={handleTableCsvDownload}
                  >
                    {findIcon("ExportReport", "dual-tone", "20")}
                    <span className="text-black fs-7 px-2 d-none d-md-inline-block">
                      Export
                    </span>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </Card.Header>
            <Card.Body>
              {!isLocationReportLoading && locationReport?.length > 0 ? (
                <ReactTable
                  key={"location-report"}
                  data={
                    // locationTableData?.length > 30
                    //   ? locationTableData.slice(0, 30)
                    //   : locationTableData

                    locationTableData
                  }
                  columns={TableColumnData}
                  isPaginationShow={false}
                  bordered
                  noDataText="no data"
                  noDataImage={fallbackImages.noActionData}
                />
              ) : isLocationReportLoading ? (
                <CommonTableLoader tableRowLength={5} />
              ) : (
                <PageNotFound
                  title="No data found"
                  // message="Upload one to start analyzing data!"
                  backgroundImage={findIcon("Empty-folder", "dual-tone", 100)}
                />
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default Location;
