export const STATE_LIST_PAYLOAD = {
  filter: {
    state_country_id: {
      type: "eq",
      value: "99",
    },
  },
  pagination: {
    limit: 50,
    offset: 0,
  },
  sort: {
    state_id: "asc",
  },
};
