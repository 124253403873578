import { memo, Fragment, useEffect, useState } from "react";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
// React-bootstrap
import { Row, Col, Form, Button, Container, Image } from "react-bootstrap";

//Components
import Autheffect from "../components/auth-effect";
import Card from "../../../../components/bootstrap/card";
import ciplaUnityLogo from "../../../../assets/images/client-logo/ciplaUnityLogo.png";

import postService from "../../../../api/services/post-service";
import {
  CREATE_PASSWORD_POST,
  CREATE_PASSWORD_REFRESH_TOKEN_POS,
} from "../../../../api/endpoints/auth-endpoints";
import PaginationLoader from "../../../../components/loader/pagination-loader";
import { updateStorage } from "../../../../utilities/setting";
import { storages } from "../../../../common/utils/utils";
import useRedirect from "../../../../components/custom-hooks/use-redirect";
import toast from "react-hot-toast";
import { getService } from "../../../../api/services/get-services";
import { useFormik } from "formik";
import * as yup from "yup";
import ReactPlayer from "react-player";
const bgvideo =
  "https://client.clirnet.com/themes/client//videos/WhatsApp%20Video%202022-11-09%20at%207.21.28%20PM%20(1).mp4";

const CreatePassword = memo(() => {
  const [loader, showLoader] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [tokenValidate, setTokenValidate] = useState(true);
  const { redirectTo } = useRedirect();
  const params = useParams();
  const location = useLocation();

  const initialValues = {
    password: "",
    confirmPassword: "",
  };
  const schema = yup.object().shape({
    password: yup.string().min(6).required("Please enter you password"),
    confirmPassword: yup
      .string()
      .required("Please re-enter you password")
      .oneOf(
        [yup.ref("password"), null],
        "Confirm password not match with password"
      ),
  });
  const { values, errors, handleChange, handleSubmit } = useFormik({
    initialValues: initialValues,
    validationSchema: schema,
    onSubmit: (value, action) => {
      createPassword(value, action);
    },
  });

  useEffect(() => {
    checkTokenValidation();
  }, []);

  const checkTokenValidation = async () => {
    let token = params?.token;
    let email = params?.email;
    // console.log('token at checkTokenValidation =>', token);
    // console.log('email at checkTokenValidation =>', email);
    let response = await postService(
      CREATE_PASSWORD_REFRESH_TOKEN_POS + "/" + token + "/mail/" + email
    );
    if (response.isError) {
      // console.log('here1');
      // setTokenValidate(false);
      showLoader(false);
      toast.success(response.error);
    } else {
      // console.log('here2', response);
      if (response.data?.success === true) {
        setTokenValidate(true);
      } else {
        setTokenValidate(false);
      }
      showLoader(false);

      //   toast.success(response.data?.message);
    }
  };

  const createPassword = async (value, action) => {
    let token = params?.token;
    let email = params?.email;

    // console.log('token at checkTokenValidation =>', token);
    // console.log('email at checkTokenValidation =>', email);
    // console.log('password at checkTokenValidation =>', value.password);
    // console.log('password_confirmation at checkTokenValidation =>', value.confirmPassword);
    showLoader(true);

    let formData = new FormData();
    formData.append("email", email);
    formData.append("password", value.password);
    formData.append("password_confirmation", value.confirmPassword);
    formData.append("token", token);

    let response = await postService(CREATE_PASSWORD_POST, formData);
    if (response.isError) {
      showLoader(false);
      toast.success(response.error);
    } else {
      showLoader(false);
      console.log("change pass data", response.data);
      toast.success(response.data?.message);
      if (response.data?.success == true) {
        action.resetForm();
        redirectTo();
      }
    }
  };

  return (
    <Fragment>
      <div className="iq-auth-page h-auto">
        <div className="landing">
          <div className="landing__banner_area w-100 d-flex position-relative overflow-hidden py-5">
            <ReactPlayer
              url={bgvideo}
              muted
              controls={false}
              playing={true}
              loop
              className="w-100 h-100 top-0 start-0 object_fit_cover position-absolute"
            />
            <div className="overlay"></div>
            <div className="landing__bannerContent px-md-0 px-5  w-100 position-relative">
              <div className="container h-100 position-relative">
                <Row className="d-flex align-items-center iq-auth-container justify-content-center">
                  <div className="col-md-6 col-12">
                    <Image
                      src="https://ik.imagekit.io/clirnetPlatform/client-admin/logo_Oxadv6aGVq.png?ik-sdk-version=javascript-1.4.3&updatedAt=1668528150127"
                      className="landing_logo d-inline mb-md-5 mb-4"
                      alt="logo"
                    />
                    <h1 className="fw-light fs-3 text-white bannerContentTtl text-uppercase">
                      Create on India's
                      <br />{" "}
                      <span className="fw-medium">
                        fastest growing community
                      </span>{" "}
                      of doctors today!
                    </h1>
                  </div>

                  {tokenValidate && (
                    <div className="col-md-6 d-flex col-12 align-items-center justify-content-md-end justify-content-center mt-md-0 mt-5">
                      <div className="position-relative loginArea">
                        <div className="loginAreaIn radius-10 bgColorWhite">
                          <h4 className="text-black fw-medium mb-3 fs-3">
                            Create Password
                          </h4>
                          <p className="text-black fs-7">
                            Enter your email address and we’ll send you an email
                            with instructions to change your password.
                          </p>

                          <Form onSubmit={handleSubmit}>
                            <Col lg="12" className="mb-3">
                              <Form.Group>
                                <Form.Control
                                  type="password"
                                  placeholder="Enter Password"
                                  name="password"
                                  value={values.password}
                                  onChange={handleChange}
                                  isInvalid={!!errors.password}
                                />

                                <Form.Control.Feedback type="invalid">
                                  {errors.password}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>

                            <Col lg="12" className="mb-3">
                              <Form.Group>
                                <Form.Control
                                  type="password"
                                  placeholder="Re-Enter Password"
                                  name="confirmPassword"
                                  value={values.confirmPassword}
                                  onChange={handleChange}
                                  isInvalid={!!errors.confirmPassword}
                                />

                                <Form.Control.Feedback type="invalid">
                                  {errors.confirmPassword}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>

                            <Button type="submit" className="mt-3">
                              {loader ? <PaginationLoader /> : "Change"}
                            </Button>
                          </Form>
                        </div>
                      </div>
                    </div>
                  )}

                  {!tokenValidate && (
                    <div className="col-md-6 d-flex col-12 align-items-center justify-content-md-end justify-content-center mt-md-0 mt-5">
                      <div className="position-relative loginArea">
                        <div className="loginAreaIn radius-10 bgColorWhite">
                          <h4 className="text-black fw-medium mb-3 fs-3">
                            Your token got expired.
                          </h4>
                          <p className="text-black fs-7">
                            Token Validation Problem
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                </Row>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
});

CreatePassword.displayName = "CreatePassword";
export default CreatePassword;
