import React from "react";
import { Card } from "react-bootstrap";
import Chart from "react-apexcharts";

function PieChart({
  title,
  series,
  labels,
  graphColor,
  height = 350,
  width = 350,
}) {
  const chartOptions = {
    series: series || [],
    options: {
      chart: {
        width: 350,
        type: "pie",
      },
      labels: labels || [],
      colors: graphColor || [],
      legend: {
        position: "bottom",
        formatter: function (label, { w }) {
          const index = w?.config?.labels?.indexOf(label) || 0;
          const value = w?.config?.series[index] || 0;
          return `${label} (${value}%)`;
        },
      },
      responsive: [
        {
          breakpoint: 430,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              show: false,
            },
          },
        },
      ],
      yaxis: {
        labels: {
          formatter: function (value) {
            return value + "%";
          },
        },
      },
    },
  };

  return (
    <>
      <Card className="h-100 m-0">
        <Card.Header className=" align-items-center justify-content-between">
          <h5 className="text-center subHeading position-relative">{title}</h5>
        </Card.Header>
        <Card.Body className="p-2">
          <div
            id="chart"
            className="d-flex align-items-center justify-content-center mt-0 mt-md-5"
          >
            <Chart
              options={chartOptions.options}
              series={chartOptions.series}
              type="pie"
              width={width}
              height={height}
            />
          </div>
        </Card.Body>
      </Card>
    </>
  );
}

export default PieChart;
