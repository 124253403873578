import React, { memo, useRef } from "react";
import { Col, FloatingLabel, Form, Row, Spinner } from "react-bootstrap";
import FilePicker from "../../../../../components/form-components/file-picker";
import useBackdrop from "../../../../../components/custom-hooks/use-backdrop";
import uploadService from "../../../../../api/services/upload-service";
import { RESOURCES_FILE_UPLOAD } from "../../../../../api/endpoints/help-and-support-endpoints";
import { AsyncTypeahead, ClearButton } from "react-bootstrap-typeahead";
import AsyncSelect from "react-select/async";
import styled from "styled-components";
import Select from "react-select";

const CustomFloatingLabel = styled.label`
  position: absolute;
  top: 0px;
  left: 0;
  padding: 1rem 0.75rem;
  pointer-events: none;
  border: 1px solid transparent;
  transform-origin: 0 -15px;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
  color: #6c757d;
  background-color: #fff;
  line-height: 1px;
  ${(props) =>
    props.isFloating &&
    `
    transform: scale(0.80) translateY(-1rem) translateX(0.15rem);
  `}
`;

const SelectContainer = styled.div`
  position: relative;

  .react-select__control {
    height: calc(3.5rem + 2px);
    padding: 1rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid ${(props) => (props.isInvalid ? "#c33d2d" : "#eee")};
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  .react-select__control--is-focused {
    color: #212529;
    background-color: #fff;
    border-color: #ddd;
    outline: 0;
  }

  .react-select__value-container {
    padding: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  .react-select__single-value {
    top: 50%;
    padding: 0;
    margin: 0;
    opacity: 0.6;
    font-weight: 500;
  }

  .react-select__indicator-separator {
    display: none;
  }

  .react-select__indicator {
    padding: 0 0.5rem;
  }

  .react-select__menu {
    margin-top: 3px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.175);
  }

  .react-select__option {
    padding: 0.5rem 1rem;
  }

  .react-select__option--is-focused {
    background-color: #f8f9fa;
  }

  .react-select__option--is-selected {
    background-color: #0d6efd;
    color: white;
  }

  .react-select__placeholder {
    color: transparent;
  }

  .react-select__input-container {
    color: ${(props) => (props.isDisabled ? "#6c757d" : "#212529")};
  }

  .react-select__control--is-disabled {
    color: #6c757d;
    background-color: #e9ecef;
  }
`;
function RenderField({
  type,
  label,
  placeholder,
  field_name,
  handleChange,
  values,
  touched,
  errors,
  options,
  setFieldValue,
  isLoading,
  onSearch,
  selected = [],
  required,
  disabled = false,
  loadOptions,
  searchText,
}) {
  const { showBackdrop, hideBackdrop } = useBackdrop();
  const typeheadRef = useRef(null);
  async function handleFileChange(e) {
    const { name, files } = e.target;

    const url = await uploadFiles([...files]);
    setFieldValue(name, url);
  }

  function getNestedValue(errors, path) {
    if (!errors || !path) return undefined;
    const parts = path.split(".");

    return parts.reduce((currentObject, part) => {
      if (currentObject === undefined || typeof currentObject !== "object") {
        return undefined; // Path is incorrect or leads to a non-object
      }

      const [key, index] = part.split("["); // Split key and optional index

      if (index) {
        // If index exists, access array element
        const closingBracketIndex = part.indexOf("]");
        if (closingBracketIndex === -1) {
          return undefined; // Invalid path with missing closing bracket
        }
        const arrayIndex = parseInt(index); // Convert index string to number
        return currentObject[key] ? currentObject[key][arrayIndex] : undefined; // Check if array exists and access element
      } else {
        return currentObject[key]; // Access normal property
      }
    }, errors);
  }

  function uploadFiles(files) {
    showBackdrop();
    return new Promise(async (resolve, reject) => {
      // Check if files array is empty
      if (files.length === 0) {
        reject("No files to upload.");
        hideBackdrop();
        return;
      }

      let formdata = new FormData();
      files.forEach((file) => {
        formdata.append("files[]", file);
      });
      // 1. Upload files to server
      let response = await uploadService(RESOURCES_FILE_UPLOAD, formdata);
      if (response.data?.success) {
        // 2. Simulating successful upload and generating URLs
        const url = response.data.data["0"].url;
        resolve(url);
        hideBackdrop();
      } else {
        reject("Something went wrong");
        hideBackdrop();
      }
    });
  }

  switch (type) {
    case "searchable":
      return (
        <Form.Group
          className="form-floating"
          isInvalid={
            getNestedValue(touched, field_name) &&
            !!getNestedValue(errors, field_name)
          }
        >
          <SelectContainer
            isDisabled={disabled}
            isInvalid={
              getNestedValue(touched, field_name) &&
              !!getNestedValue(errors, field_name)
            }
          >
            <AsyncSelect
              isDisabled={disabled}
              // isClearable={true}
              cacheOptions
              loadOptions={loadOptions}
              onChange={handleChange}
              isLoading={isLoading}
              noOptionsMessage={({ inputValue }) =>
                inputValue ? "No options found" : "Type to search"
              }
              classNamePrefix="react-select"
              placeholder={placeholder}
              value={selected}
            />
            <CustomFloatingLabel
              isFloating={selected || searchText != "" ? true : false}
              className={
                getNestedValue(touched, field_name) &&
                !!getNestedValue(errors, field_name)
                  ? "is-invalid"
                  : ""
              }
            >
              {label}
            </CustomFloatingLabel>
            <Form.Control.Feedback type="invalid">
              {getNestedValue(errors, field_name)}
            </Form.Control.Feedback>
          </SelectContainer>
        </Form.Group>
      );

    case "select":
      return (
        <FloatingLabel
          className="mb-3 custom-form-floating "
          controlId="floatingSelect"
          label={
            <>
              {label} {required && <span className="text-danger">*</span>}
            </>
          }
        >
          <Form.Select
            aria-label={label}
            name={field_name}
            onChange={handleChange}
            value={values[field_name] || getNestedValue(values, field_name)}
            isInvalid={
              getNestedValue(touched, field_name) &&
              !!getNestedValue(errors, field_name)
            }
            disabled={disabled}
          >
            <option value="">Select Target Category</option>
            {options?.length > 0 &&
              options.map((data, key) => (
                <option key={key} value={data.value}>
                  {data.label}
                </option>
              ))}
          </Form.Select>

          <Form.Control.Feedback type="invalid">
            {getNestedValue(errors, field_name)}
          </Form.Control.Feedback>
        </FloatingLabel>
      );
    case "switch":
      return (
        <Form.Group
          className="mb-3 d-flex justify-content-between"
          controlId="formBasicEmail"
        >
          <Form.Label>
            {label} {required && <span className="text-danger">*</span>}
          </Form.Label>
          <Form.Check
            className="flex-shrink-0"
            name={field_name}
            onChange={(e) => {
              setFieldValue(field_name, e.target.checked ? 1 : 0);
            }}
            checked={values[field_name] === 1 ? true : false}
            type="switch"
            aria-controls="collapse-text"
          />
        </Form.Group>
      );
    case "multiselect":
      return (
        <Form.Group controlId="ControlSelectMultiple" className="mb-3">
          <Form.Label>
            {label} {required && <span className="text-danger">*</span>}
          </Form.Label>
          <AsyncSelect
            cacheOptions
            defaultOptions={options}
            loadOptions={loadOptions}
            placeholder={placeholder}
            name={field_name}
            isMulti
            isLoading={isLoading}
            value={values[field_name]}
            onChange={(value) => {
              setFieldValue(
                field_name,
                value?.map(({ value }) => {
                  const data = JSON.parse(value);
                  return {
                    label: `${data.event_name} - ${data.event_id}`,
                    value: value,
                  };
                }) || []
              );
            }}
          />
        </Form.Group>
      );
    case "file":
      return (
        <Row>
          <Col md={3} className="mb-3">
            <FilePicker
              title={
                <>
                  {values[field_name] ? `Change ${label}` : `Upload ${label}`}{" "}
                  {required && <span className="text-danger">*</span>}
                </>
              }
              onUpdate={(val) =>
                handleFileChange({
                  target: {
                    files: val,
                    name: field_name,
                  },
                })
              }
              accepts="image/*"
              fileName={values[field_name] ? values[field_name]?.name : null}
              type="image"
              source={values[field_name]}
              className={
                getNestedValue(touched, field_name) &&
                !!getNestedValue(errors, field_name)
                  ? "is-invalid"
                  : ""
              }
            />
            <Form.Control.Feedback type="invalid">
              {getNestedValue(errors, field_name)}
            </Form.Control.Feedback>
          </Col>
        </Row>
      );
    case "textarea":
      return (
        <FloatingLabel
          controlId={label}
          label={
            <>
              {label} {required && <span className="text-danger">*</span>}
            </>
          }
          className="mb-3 custom-form-floating "
        >
          <Form.Control
            as="textarea"
            placeholder={label}
            rows={5}
            name={field_name}
            onChange={handleChange}
            value={values[field_name]}
            isInvalid={
              getNestedValue(touched, field_name) &&
              !!getNestedValue(errors, field_name)
            }
          />
          <Form.Control.Feedback type="invalid">
            {getNestedValue(errors, field_name)}
          </Form.Control.Feedback>
        </FloatingLabel>
      );
    case "number":
      return (
        <FloatingLabel
          controlId={label}
          label={
            <>
              {label} {required && <span className="text-danger">*</span>}
            </>
          }
          className="mb-3 custom-form-floating "
        >
          <Form.Control
            type={type}
            placeholder={placeholder}
            name={field_name}
            onChange={handleChange}
            value={getNestedValue(values, field_name)}
            isInvalid={
              getNestedValue(touched, field_name) &&
              !!getNestedValue(errors, field_name)
            }
            min={0}
          />

          <Form.Control.Feedback type="invalid">
            {getNestedValue(errors, field_name)}
          </Form.Control.Feedback>
        </FloatingLabel>
      );
    default:
      return (
        <FloatingLabel
          controlId={label}
          label={
            <>
              {label} {required && <span className="text-danger">*</span>}
            </>
          }
          className="mb-3 custom-form-floating "
        >
          <Form.Control
            type={type}
            placeholder={placeholder}
            name={field_name}
            onChange={handleChange}
            value={getNestedValue(values, field_name)}
            isInvalid={
              getNestedValue(touched, field_name) &&
              !!getNestedValue(errors, field_name)
            }
          />

          <Form.Control.Feedback type="invalid">
            {getNestedValue(errors, field_name)}
          </Form.Control.Feedback>
        </FloatingLabel>
      );
  }
}

export default memo(RenderField);
