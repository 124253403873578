import React from "react";
import { Col, Row } from "react-bootstrap";
import RadialBarChart from "../../../../../components/chart/RadialBarChart";
import { findIcon } from "../../../../dashboard/icons";
import { formatNumberWithCommas } from "../../../../../common/utils/utils";
import SummeryMatrixSvgUI from "./SummeryMatrixSvgUI";

const SummeryMatrix = ({
  readCount = 0,
  totalCount = 0,
  contentCount = 0,
  reachCount = 0,
  avgDurationChart = 0,
  projectToTargets = {},
  hasViewAccess = false,
  hasReadAccess = false,
  hasHCPAccess = false,
  page = "",
}) => {
  function formatTime(secondsInput) {
    const totalSeconds = Number(secondsInput);

    if (isNaN(totalSeconds) || totalSeconds < 0) {
      return "Invalid input";
    }

    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = Math.floor((totalSeconds % 3600) % 60);

    let result = "";

    if (hours > 0) {
      result += `${hours} hour${hours > 1 ? "s" : ""} `;
    }

    if (minutes > 0) {
      result += `${minutes} minute${minutes > 1 ? "s" : ""} `;
    }

    if (seconds > 0 || result === "") {
      result += `${seconds} second${seconds > 1 ? "s" : ""}`;
    }

    return result.trim();
  }

  return (
    <Row className="m-0">
      <Col
        md={4}
        sm={12}
        className="projectDetailsMatrix p-0 bg-white rounded-3"
      >
        <div className="h-100 matrixGradient d-flex align-items-center rounded-3 p-3 position-relative overflow-hidden">
          {page === "content-details" ? (
            <h4 className="fw-semibold text-white m-0 z-2">
              Content Target
              <br /> Analytics
            </h4>
          ) : (
            <h4 className="fw-semibold text-white m-0 z-2">
              Project Details <br /> Overview
            </h4>
          )}

          <SummeryMatrixSvgUI page={page} />
        </div>
      </Col>

      <Col md={8} sm={12}>
        <Row>
          {hasReadAccess && (
            <Col md={3}>
              <RadialBarChart
                max={projectToTargets?.project_reads_target || 0}
                data={readCount}
                labels={["Reads"]}
                color={"#1aa053"}
                title={
                  <span className="fs-7 mt-n2 text-center">
                    Total Reads
                    {projectToTargets?.project_reads_target &&
                      projectToTargets?.project_reads_target !== 0 && (
                        <>
                          <br />
                          {findIcon("ImpressionTarget", "dual-tone", 12)}{" "}
                          {formatNumberWithCommas(
                            projectToTargets?.project_reads_target
                          )}
                        </>
                      )}
                  </span>
                }
              />
            </Col>
          )}
          {hasViewAccess && (
            <Col md={3}>
              <RadialBarChart
                max={projectToTargets?.impressions_target || 0}
                data={totalCount}
                labels={["Views"]}
                color={"#3256b8"}
                title={
                  <span className="fs-7 mt-n2 text-center">
                    Views
                    {projectToTargets?.impressions_target &&
                      projectToTargets?.impressions_target !== 0 && (
                        <>
                          <br />
                          {findIcon("ImpressionTarget", "dual-tone", 12)}{" "}
                          {formatNumberWithCommas(
                            projectToTargets?.impressions_target
                          )}
                        </>
                      )}
                  </span>
                }
              />
            </Col>
          )}
          {hasHCPAccess && (
            <Col md={3}>
              <RadialBarChart
                max={projectToTargets?.unique_reach_target || 0}
                data={reachCount}
                labels={["Hcp's Engaged"]}
                color={"#1aa053"}
                title={
                  <span className="fs-7 mt-n2 text-center">
                    HCP's Engaged
                    {projectToTargets?.unique_reach_target &&
                      projectToTargets?.unique_reach_target !== 0 && (
                        <>
                          <br />
                          {findIcon("ImpressionTarget", "dual-tone", 12)}{" "}
                          {formatNumberWithCommas(
                            projectToTargets?.unique_reach_target
                          )}
                        </>
                      )}
                  </span>
                }
              />
            </Col>
          )}
          <Col
            md={3}
            className="d-flex justify-content-center align-items-center avgDuration"
          >
            <div className="h-rem-8 w-rem-8 rounded-circle bg-primary-light bg-opacity-5 fs-7 d-flex flex-column align-items-center justify-content-center lh-base">
              {page === "content-details" ? (
                <>
                  <span className="text-center px-2">
                    <i className="text-primary fw-semibold fs-6 fst-normal">
                      {formatTime(avgDurationChart)}
                    </i>
                  </span>
                  <span className="text-black-50">Avg Duration</span>
                </>
              ) : (
                <>
                  <span className="text-center px-2">
                    <i className="text-primary fw-semibold fs-4 fst-normal">
                      {contentCount}
                    </i>
                  </span>
                  <span className="text-black-50">Contents</span>
                </>
              )}
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default SummeryMatrix;
