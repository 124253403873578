import React, { useEffect, useState } from "react";
import Titlebar from "../components/Titlebar";
import { Col, Row } from "react-bootstrap";
import BasicColumnChart from "../../../../../components/chart/BasicColumnChart";
import {
  EVENTS_DROPDOWN_FILTERS,
  EVENTS_PILL_FILTERS,
  REGISTRATION_BY_ZONE,
} from "../data/audience-insights-data";
import TreeMapChart from "../../../../../components/chart/TreeMapChart";
import DonutChart from "../../../../../components/chart/DonutChart";
import { GRAPH_FILL_COLOR } from "../constant/graph-color-scheme";
import PolarChart from "../../../../../components/chart/PolarChart";
import IndiaMap from "../../../../../components/chart/IndiaMap";
import DashboardDropdown from "../../../../../components/DashboardDropdown";
import {
  useGetAllProjectEventsList,
  useGetBrowserDistribution,
  useGetContentRegistration,
  useGetContentViews,
  useGetDeviceDistribution,
  useGetLocationDistribution,
  useGetOsDistribution,
  useGetRegistrationBySlot,
  useGetZonalDistribution,
} from "../../../../../queries/query-hooks/workspace/events-hook";
import { useParams } from "react-router-dom";
import {
  transformGraphData,
  transformToPolarGraphData,
} from "../../../../../common/utils/utils";
import { transformIndiaMapData } from "../utils/utils";
import BarChartSkeleton from "../../../../../skeleton/workspace/clirnet/brand-ads/bar-chart-skeleton";
import MapAnalyticsSkeleton from "../../../../../skeleton/workspace/clirnet/brand-ads/map-analytics-skeleton";
import SquareChartSkeleton from "../../../../../skeleton/workspace/clirnet/brand-ads/square-chart-skeleton";
import PageNotFound from "../../../../PageNotFound";
import { findIcon } from "../../../../dashboard/icons";
import CardNotFound from "../components/CardNotFound";

const Analytics = () => {
  const { project_id } = useParams();
  const [filterBy, setFilterBy] = useState("Registrations");
  const [activeFilter, setActiveFilter] = useState({
    id: 0,
    index: 0,
  });
  const [eventFilterPayload, setEventFilterPayload] = useState({
    type: "all",
  });

  useEffect(() => {
    if (activeFilter?.id === 0) {
      setEventFilterPayload({
        type: "all",
      });
    } else {
      setEventFilterPayload({
        event_id: activeFilter?.id,
      });
    }
  }, [activeFilter]);

  const { data: contentViews, isLoading: isContentViewsLoading } =
    useGetContentViews(project_id, eventFilterPayload);

  const { data: registrationViews, isLoading: isRegistrationLoading } =
    useGetContentRegistration(project_id, eventFilterPayload);

  const {
    data: locationDistribution,
    isLoading: isLocationDistributionLoading,
  } = useGetLocationDistribution(project_id, {
    ...eventFilterPayload,
    analysis_type: filterBy?.slice(0, -1)?.toLowerCase() || "registration", // participation || registration
  });

  const { data: deviceDistribution, isLoading: isDeviceDistributionLoading } =
    useGetDeviceDistribution(project_id, {
      ...eventFilterPayload,
      analysis_type: filterBy?.slice(0, -1)?.toLowerCase() || "registration", // participation || registration
    });

  const { data: zonalDistribution, isLoading: isZonalDistributionLoading } =
    useGetZonalDistribution(project_id, {
      ...eventFilterPayload,
      analysis_type: filterBy?.slice(0, -1)?.toLowerCase() || "registration", // participation || registration
    });

  const { data: osDistribution, isLoading: isOsDistributionLoading } =
    useGetOsDistribution(project_id, {
      ...eventFilterPayload,
      analysis_type: filterBy?.slice(0, -1)?.toLowerCase() || "registration", // participation || registration
    });

  const { data: browserDistribution, isLoading: isBrowserDistributionLoading } =
    useGetBrowserDistribution(project_id, {
      ...eventFilterPayload,
      analysis_type: filterBy?.slice(0, -1)?.toLowerCase() || "registration", // participation || registration
    });

  const { data: registrationBySlot, isLoading: isRegistrationBySlotLoading } =
    useGetRegistrationBySlot(project_id, {
      ...eventFilterPayload,
      analysis_type: filterBy?.slice(0, -1)?.toLowerCase() || "registration", // participation || registration
    });

  const { data: allProjectList, isLoading: isAllProjectListLoading } =
    useGetAllProjectEventsList(project_id);

  const updateFilterHandler = (value) => {
    setFilterBy(value);
  };

  const contentViewsChartOptions = {
    colors: GRAPH_FILL_COLOR,
    series: [
      {
        name: "View Count",
        data:
          contentViews?.length > 0
            ? transformGraphData(contentViews, "count", "count")?.series
            : [],
        color: "#3256b8",
      },
    ],

    labels:
      contentViews?.length > 0
        ? transformGraphData(contentViews, "level", "level")?.label
        : [],
  };

  const contentRegistrationChartOptions = {
    colors: GRAPH_FILL_COLOR,
    series: [
      {
        name: "Registrations",
        data:
          registrationViews?.length > 0
            ? transformGraphData(registrationViews, "count", "count")?.series
            : [],
        color: "#15946C",
      },
    ],

    labels:
      registrationViews?.length > 0
        ? transformGraphData(registrationViews, "level", "level")?.label
        : [],
  };

  const deviceDistributionChartOptions = {
    series:
      deviceDistribution?.length > 0
        ? transformGraphData(deviceDistribution, "percentage", "level")?.series
        : [],
    labels:
      deviceDistribution?.length > 0
        ? transformGraphData(deviceDistribution, "percentage", "level")?.label
        : [],
  };

  const slotRegistrationChartOptions =
    transformToPolarGraphData(registrationBySlot);

  const osDistributionChartOptions = {
    series:
      osDistribution?.length > 0
        ? transformGraphData(
            osDistribution?.sort((a, b) => b?.percentage - a?.percentage),
            "percentage",
            "level"
          )?.series
        : [],
    labels:
      osDistribution?.length > 0
        ? transformGraphData(
            osDistribution?.sort((a, b) => b?.percentage - a?.percentage),
            "percentage",
            "level"
          )?.label
        : [],
  };

  const browserDistributionChartOptions = {
    series:
      browserDistribution?.length > 0
        ? transformGraphData(
            browserDistribution?.sort((a, b) => b?.percentage - a?.percentage),
            "percentage",
            "level"
          )?.series
        : [],
    labels:
      browserDistribution?.length > 0
        ? transformGraphData(
            browserDistribution?.sort((a, b) => b?.percentage - a?.percentage),
            "percentage",
            "level"
          )?.label
        : [],
  };

  function formatZonalDistibutionData(inputArray = []) {
    if (!Array.isArray(inputArray)) return [];
    return inputArray.map(({ level, percentage }) => ({
      x: level || "Other",
      y: percentage,
    }));
  }

  const indiaMapFormattedData = transformIndiaMapData(locationDistribution);

  const zonalDistributionChartOptions =
    formatZonalDistibutionData(zonalDistribution);

  return (
    <div className="mb-5">
      <Titlebar
        title={"Analytics"}
        projectType={"Events"}
        filterType={"events"}
        isLoading={isAllProjectListLoading}
        eventsFilters={allProjectList}
        activeFilter={activeFilter}
        setActiveFilter={setActiveFilter}
      />
      <div className="d-flex flex-column gap-4">
        <Row>
          <Col md={6} sm={12} className="mb-4 mb-md-0">
            {!isContentViewsLoading && contentViews?.length > 0 ? (
              <BasicColumnChart
                title={"Content Views"}
                labels={contentViewsChartOptions.labels}
                series={contentViewsChartOptions.series}
              />
            ) : isContentViewsLoading ? (
              <BarChartSkeleton />
            ) : (
              <CardNotFound title={"Content Views"} height={360} />
            )}
          </Col>
          <Col md={6} sm={12}>
            {!isRegistrationLoading && registrationViews?.length > 0 ? (
              <BasicColumnChart
                title={"Registrations"}
                labels={contentRegistrationChartOptions.labels}
                series={contentRegistrationChartOptions.series}
              />
            ) : isRegistrationLoading ? (
              <BarChartSkeleton />
            ) : (
              <CardNotFound title={"Registrations"} height={360} />
            )}
          </Col>
        </Row>

        <Row>
          <Col sm={12}>
            <div className="d-flex gap-3 align-items-center justify-content-end">
              <div className="fs-6 text-black">Filter By</div>
              <div className="dropdown bg-white border border-1 rounded-3 px-3 py-2">
                <DashboardDropdown
                  constantFilterOption={EVENTS_DROPDOWN_FILTERS}
                  handleDropdownChange={updateFilterHandler}
                  type={filterBy}
                />
              </div>
            </div>
          </Col>
        </Row>

        <Row>
          <Col md={7} sm={12} className="d-flex flex-column mb-4 mb-md-0">
            {!isLocationDistributionLoading &&
            locationDistribution?.length > 0 ? (
              <IndiaMap
                title={`${filterBy} by Location`}
                stateData={indiaMapFormattedData}
              />
            ) : isLocationDistributionLoading ? (
              <MapAnalyticsSkeleton />
            ) : (
              <CardNotFound
                title={`${filterBy} by Location`}
                isTitleCenter={true}
              />
            )}
          </Col>
          <Col md={5} sm={12} className="d-flex flex-column gap-3">
            <Col md={12}>
              {!isDeviceDistributionLoading &&
              deviceDistribution?.length > 0 &&
              deviceDistribution?.filter((item) => item?.percentage !== 0)
                ?.length > 0 ? (
                <DonutChart
                  title={`${filterBy} by Device`}
                  graphColor={GRAPH_FILL_COLOR}
                  labels={deviceDistributionChartOptions.labels}
                  series={deviceDistributionChartOptions.series}
                />
              ) : isDeviceDistributionLoading ? (
                <SquareChartSkeleton />
              ) : (
                <CardNotFound
                  title={`${filterBy} by Device`}
                  isTitleCenter={true}
                  height={300}
                />
              )}
            </Col>
            <Col md={12}>
              {!isZonalDistributionLoading && zonalDistribution?.length > 0 ? (
                <TreeMapChart
                  title={`${filterBy} by Zone`}
                  series={zonalDistributionChartOptions}
                />
              ) : isZonalDistributionLoading ? (
                <SquareChartSkeleton />
              ) : (
                <CardNotFound
                  title={`${filterBy} by Zone`}
                  isTitleCenter={true}
                  height={300}
                />
              )}
            </Col>
          </Col>
        </Row>

        {/* FIXME: creative data is not available in database */}
        {/* <Row>
          <Col sm={12}>
            <div
              className="bg-primary-light border border-1 border-primary border-opacity-25 rounded-3 text-center text-primary mb-0 py-3 fs-5 fw-light"
              role="alert"
            >
              This creative has gotten
              <span className="text-primary fw-semibold "> 1200 </span>
              views since last month
            </div>
          </Col>
        </Row> */}

        <Row>
          <Col md={4} sm={12} className="mb-4 mb-md-0">
            {!isRegistrationBySlotLoading &&
            Object.keys(registrationBySlot)?.length > 0 &&
            Object.values(registrationBySlot)?.filter((item) => item !== 0)
              ?.length > 0 ? (
              <PolarChart
                title={`${filterBy} by Time Slot`}
                graphColor={GRAPH_FILL_COLOR}
                labels={slotRegistrationChartOptions.labels}
                series={slotRegistrationChartOptions.series}
              />
            ) : isRegistrationBySlotLoading ? (
              <SquareChartSkeleton height={320} />
            ) : (
              <CardNotFound
                title={`${filterBy} by Time Slot`}
                isTitleCenter={true}
                height={320}
              />
            )}
          </Col>
          <Col md={4} sm={12} className="mb-4 mb-md-0">
            {!isOsDistributionLoading &&
            osDistribution?.length > 0 &&
            osDistribution?.filter((item) => item?.percentage !== 0)?.length >
              0 ? (
              <DonutChart
                title={`${filterBy} by Mobile Device`}
                graphColor={GRAPH_FILL_COLOR}
                labels={osDistributionChartOptions.labels}
                series={osDistributionChartOptions.series}
              />
            ) : isOsDistributionLoading ? (
              <SquareChartSkeleton height={320} />
            ) : (
              <CardNotFound
                title={`${filterBy} by Mobile Device`}
                isTitleCenter={true}
                height={320}
              />
            )}
          </Col>
          <Col md={4} sm={12}>
            {!isBrowserDistributionLoading &&
            browserDistribution?.length > 0 &&
            browserDistribution?.filter((item) => item?.percentage !== 0)
              ?.length > 0 ? (
              <PolarChart
                title={`${filterBy} by Browser`}
                graphColor={GRAPH_FILL_COLOR}
                labels={browserDistributionChartOptions.labels}
                series={browserDistributionChartOptions.series}
              />
            ) : isBrowserDistributionLoading ? (
              <SquareChartSkeleton height={320} />
            ) : (
              <CardNotFound
                title={`${filterBy} by Browser`}
                isTitleCenter={true}
                height={320}
              />
            )}
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Analytics;
