import React, { useEffect, useState } from "react";
import Titlebar from "../components/Titlebar";
import { Card, Col, Row, Image } from "react-bootstrap";
import { initialDate } from "../constant/date-range-constant";
import TextPillCard from "../components/TextPillCard";
import CommentSwiper from "../components/CommentSwiper";
import { findIcon } from "../../../../dashboard/icons";
import {
  useGetAnalyticsBrowserViewsReport,
  useGetAnalyticsContentReach,
  useGetAnalyticsContentViews,
  useGetAnalyticsDeviceViews,
  useGetAnalyticsLocationDistribution,
  useGetAnalyticsMobileDeviceViews,
  useGetAnalyticsZoneViews,
  useGetAudienceViewsByScreensize,
  useGetCreativeDetails,
  useGetLocationStateMap,
} from "../../../../../queries/query-hooks/workspace/sponsored-content-hook";
import { useParams } from "react-router-dom";
import SquareChartSkeleton from "../../../../../skeleton/workspace/clirnet/brand-ads/square-chart-skeleton";
import PageNotFound from "../../../../PageNotFound";
import ImageWithFallback from "../../../../../components/ImageWithFallback";
import { fallbackImages } from "../../../../../common/constants/fallback-images";
import { useGetProjectDetails } from "../../../../../queries/query-hooks/workspace/workspace-hook";
import {
  formatLocalizedDate,
  getFormatedDate,
  transformGraphData,
  transformHeatMapData,
} from "../../../../../common/utils/utils";
import SummeryMatrix from "../components/SummeryMatrix";
import MatrixSkeleton from "../../../../../skeleton/workspace/clirnet/sponsoredContent/MatrixSkeleton";
import { checkProjectAccess } from "../utils/common";
import BarChartSkeleton from "../../../../../skeleton/workspace/clirnet/brand-ads/bar-chart-skeleton";
import BasicColumnChart from "../../../../../components/chart/BasicColumnChart";
import { GRAPH_FILL_COLOR } from "../constant/graph-color-scheme";
import FilterBy from "../components/FilterBy";
import { filterOptions } from "../constant/filter-options";
import IndiaMap from "../../../../../components/chart/IndiaMap";
import MapAnalyticsSkeleton from "../../../../../skeleton/workspace/clirnet/brand-ads/map-analytics-skeleton";
import DonutChart from "../../../../../components/chart/DonutChart";
import TreeMapChart from "../../../../../components/chart/TreeMapChart";
import PolarChart from "../../../../../components/chart/PolarChart";
import dayjs from "dayjs";

function ContentDetails() {
  const { content_id = "", project_id = "" } = useParams();
  const [type, setType] = useState(filterOptions?.initial);

  const { data: projectDetails, isLoading: isProjectDetailsLoading } =
    useGetProjectDetails(project_id);

  const [dateRangePayload, setDateRangePayload] = useState({
    start_date:
      projectDetails?.[0]?.project_start_date || initialDate.start_date,
    end_date: projectDetails?.[0]?.project_end_date || initialDate.end_date,
  });

  const { data: contentDetails, isLoading: isContentDetailsLoading } =
    useGetCreativeDetails(project_id, {
      content_id: content_id,
      start_date: dateRangePayload.start_date,
      end_date: dateRangePayload.end_date,
    });

  const { data: contentViews, isLoading: isContentViewsLoading } =
    useGetAnalyticsContentViews(project_id, {
      start_date: dateRangePayload.start_date,
      end_date: dateRangePayload.end_date,
      content_id: content_id,
    });

  const { data: contentReach, isLoading: isContentReachLoading } =
    useGetAnalyticsContentReach(project_id, {
      start_date: dateRangePayload.start_date,
      end_date: dateRangePayload.end_date,
      content_id: content_id,
    });

  const { data: stateMapData, isLoading: isStateMapLoading } =
    useGetLocationStateMap(project_id, type.toLowerCase(), {
      start_date: dateRangePayload.start_date,
      end_date: dateRangePayload.end_date,
      content_id: content_id,
      type: "percentage",
    });

  const { data: deviceChartData, isLoading: isDeviceViewLoading } =
    useGetAnalyticsDeviceViews(project_id, type.toLowerCase(), {
      start_date: dateRangePayload.start_date,
      end_date: dateRangePayload.end_date,
      content_id: content_id,
      type,
    });

  const { data: zoneDistributionData, isLoading: isZoneViewLoading } =
    useGetAnalyticsZoneViews(project_id, type.toLowerCase(), {
      start_date: dateRangePayload.start_date,
      end_date: dateRangePayload.end_date,
      content_id: content_id,
      type,
    });

  const { data: mobileDeviceData, isLoading: isMobileDeviceViewsLoading } =
    useGetAnalyticsMobileDeviceViews(project_id, type.toLowerCase(), {
      start_date: dateRangePayload.start_date,
      end_date: dateRangePayload.end_date,
      content_id: content_id,
      type,
    });

  const { data: browserData, isLoading: isBrowserViewsReportLoading } =
    useGetAnalyticsBrowserViewsReport(project_id, type.toLowerCase(), {
      start_date: dateRangePayload.start_date,
      end_date: dateRangePayload.end_date,
      content_id: content_id,
      type,
    });

  useEffect(() => {
    if (projectDetails && projectDetails?.length > 0) {
      const today = dayjs();
      const projectEndDate = dayjs(projectDetails[0]?.project_end_date);
      let result = projectDetails[0]?.project_end_date;

      if (projectEndDate && projectEndDate.isAfter(today)) {
        result = today.subtract(1, "day");
      } else {
        result = projectEndDate;
      }

      setDateRangePayload({
        start_date: formatLocalizedDate(
          new Date(projectDetails[0]?.project_start_date),
          "YYYY-MM-DD[T]00:00:00.000Z"
        ),
        end_date: formatLocalizedDate(
          new Date(result),
          "YYYY-MM-DD[T]23:59:59.SSSZ"
        ),
      });
    }
  }, [projectDetails]);

  const { data: screenSizeData, isLoading: isContentCategoryLoading } =
    useGetAudienceViewsByScreensize(project_id, type.toLowerCase(), {
      start_date: dateRangePayload.start_date,
      end_date: dateRangePayload.end_date,
      content_id: content_id,
      type,
    });

  const projectToTargets = {
    impressions_target: contentDetails?.content_target?.at(0)?.view_target,
    unique_reach_target:
      contentDetails?.content_target?.at(0)?.unique_reach_target,
    project_reads_target: contentDetails?.content_target?.at(0)?.read_target,
  };

  const hasViewAccess = checkProjectAccess("show_View", projectDetails);
  const hasReadAccess = checkProjectAccess("show_read", projectDetails);
  const hasHCPAccess = checkProjectAccess("show_hcp", projectDetails);

  const contentViewsChartOptions = {
    colors: GRAPH_FILL_COLOR,
    series: [
      {
        name: "View Count",
        data:
          contentViews?.length > 0
            ? transformGraphData(contentViews, "total_count", "formatted_date")
                ?.series
            : [],
        color: "#3256b8",
      },
    ],

    label:
      contentViews?.length > 0
        ? transformGraphData(contentViews, "total_count", "formatted_date")
            ?.label
        : [],
  };
  const contentReachChartOptions = {
    series: [
      {
        name: "Click Count",
        data:
          contentReach?.length > 0
            ? transformGraphData(contentReach, "reach_count", "formatted_date")
                ?.series
            : [],
        color: "#1aa053",
      },
    ],
    label:
      contentReach?.length > 0
        ? transformGraphData(contentReach, "reach_count", "formatted_date")
            ?.label
        : [],
  };

  const screenSizeChartOptions = {
    label:
      screenSizeData && screenSizeData?.length > 0
        ? transformGraphData(
            screenSizeData,
            "impression_percentage",
            "screen_ratio"
          )?.label
        : [],
    series:
      screenSizeData && screenSizeData?.length > 0
        ? transformGraphData(
            screenSizeData,
            "impression_percentage",
            "screen_ratio"
          )?.series
        : [],
  };

  const deviceChartOptions = {
    series:
      deviceChartData?.length > 0
        ? transformGraphData(
            deviceChartData,
            "percentagebrowser_count",
            "Device"
          )?.series
        : [],
    label:
      deviceChartData?.length > 0
        ? transformGraphData(
            deviceChartData,
            "percentagebrowser_count",
            "Device"
          )?.label
        : [],
  };

  const mobileDeviceChartOptions = {
    series:
      mobileDeviceData?.length > 0
        ? transformGraphData(
            mobileDeviceData,
            "percentagebrowser_count",
            "Device"
          )?.series
        : [],
    label:
      mobileDeviceData?.length > 0
        ? transformGraphData(
            mobileDeviceData,
            "percentagebrowser_count",
            "Device"
          )?.label
        : [],
    title: "Mobile Device Distribution",
  };

  const browserChartOptions = {
    series:
      browserData?.length > 0
        ? transformGraphData(
            browserData,
            "percentagebrowser_count",
            "new_browser"
          )?.series
        : [],
    label:
      browserData?.length > 0
        ? transformGraphData(
            browserData,
            "percentagebrowser_count",
            "new_browser"
          )?.label
        : [],
  };

  const transformStateMapData = (data = [], key) => {
    return [
      data?.reduce((acc, item) => {
        if (item.name && item[key] !== null) {
          acc[item.name] = item[key];
        }
        return acc;
      }, {}),
    ];
  };

  const stateMapReadsData = transformStateMapData(
    stateMapData,
    "reads_percent"
  );
  const stateMapViewsData = transformStateMapData(
    stateMapData,
    "views_percent"
  );

  const zonalChartOptions =
    zoneDistributionData && zoneDistributionData?.length > 0
      ? transformHeatMapData(
          zoneDistributionData,
          "name",
          type === "Reads" ? "read_count" : "view_count"
        )
      : [];

  const handleDropdownChange = (type = "Views") => {
    setType(type);
  };

  const readCount =
    contentDetails?.content_data?.at(0)?.content_stats?.reads || 0;
  const totalCount =
    contentDetails?.content_data?.at(0)?.content_stats?.views || 0;
  const reachCount =
    contentDetails?.content_data?.at(0)?.content_stats?.unique_reach || 0;
  const avgDurationChart =
    contentDetails?.content_data?.at(0)?.content_stats?.average_duration || 0;

  return (
    <>
      <Row>
        <Col md={12}>
          {!isProjectDetailsLoading && (
            <Titlebar
              title={"Content Detail"}
              projectType={"Sponsored Content"}
              setDateRangePayload={setDateRangePayload}
              projectStartDate={projectDetails[0]?.project_start_date || ""}
              projectEndDate={projectDetails[0]?.project_end_date || ""}
              dateLabel={dateRangePayload}
              showDatePicker={true}
            />
          )}
        </Col>
        <Col
          md={
            !isContentDetailsLoading &&
            contentDetails?.content_data &&
            contentDetails?.content_data?.at(0)?.fetch_with_contents &&
            Array.isArray(
              contentDetails?.content_data?.at(0)?.fetch_with_contents
            ) &&
            contentDetails?.content_data?.at(0)?.fetch_with_contents
              .content_tags &&
            Array.isArray(
              contentDetails?.content_data?.at(0)?.fetch_with_contents
                ?.content_tags
            ) &&
            contentDetails?.content_data?.at(0)?.fetch_with_contents
              ?.content_tags?.length > 0
              ? 7
              : 12
          }
          className="mb-4"
        >
          {!isContentDetailsLoading &&
          Object.keys(contentDetails?.content_data?.at(0)).length > 0 ? (
            <div className="sponsoredContentCard">
              <Card className="m-0 p-0">
                <Card.Body className="d-flex flex-column flex-md-row gap-2">
                  <div className="cardImage">
                    <ImageWithFallback
                      className="rounded-3 w-100 h-100 object-fit-cover"
                      src={
                        contentDetails?.content_data?.at(0)
                          ?.fetch_with_contents[0]?.content_image_data[0]
                          ?.content_image_data?.base
                      }
                      fallbackSrc={fallbackImages?.noImage}
                    />
                  </div>

                  <div className="cardDetails d-flex flex-column w-100 flex-grow-1 ps-2">
                    <div className="detailsHeader d-flex justify-content-between align-items-center gap-3 mb-3">
                      <div className="d-flex gap-2">
                        <div>
                          <img
                            src={
                              contentDetails?.content_data?.at(0)
                                ?.fetch_with_contents[0]?.content_category
                                ?.content_category_icon
                            }
                            alt=""
                            className="icon-22"
                          />
                        </div>
                        <p>
                          {
                            contentDetails?.content_data?.at(0)
                              ?.fetch_with_contents[0]?.content_category
                              ?.content_category_name
                          }
                        </p>
                      </div>
                      {contentDetails?.content_data?.at(0)
                        ?.fetch_with_contents[0]?.content_sponsors[0]
                        ?.sponsor_data?.client_logo && (
                        <div className="cardLogo icon-30">
                          <Image
                            className="w-100 h-100 object-fit-contain rounded-circle"
                            src={
                              contentDetails?.content_data?.at(0)
                                ?.fetch_with_contents[0]?.content_sponsors &&
                              Array.isArray(
                                contentDetails?.content_data?.at(0)
                                  ?.fetch_with_contents[0]?.content_sponsors
                              ) &&
                              contentDetails?.content_data?.at(0)
                                ?.fetch_with_contents[0]?.content_sponsors[0]
                                ?.sponsor_data?.client_logo
                            }
                          />
                        </div>
                      )}
                    </div>

                    <div>
                      <h6 className="fs-5 two-line-clamp ">
                        {contentDetails?.content_data?.at(0)
                          ?.fetch_with_contents[0]?.content_title || ""}
                      </h6>
                      <p className="fs-7 ">
                        {getFormatedDate(
                          contentDetails?.content_data?.at(0)
                            ?.fetch_with_contents[0]?.created_at
                        )}
                      </p>
                      {/* <p className="m-0 text-black-50 two-line-clamp ">
                        {contentDetails?.content_data?.at(0)?.fetch_with_contents[0]
                          ?.content_description || ""}
                      </p> */}
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </div>
          ) : isContentDetailsLoading ? (
            <SquareChartSkeleton />
          ) : (
            <PageNotFound
              title="No data found"
              // message="Upload one to start analyzing data!"
              backgroundImage={findIcon("Empty-folder", "dual-tone", 100)}
            />
          )}
        </Col>
        <Col md={12}>
          <Card>
            <Card.Body className="border border-success border-2 rounded-3 border-opacity-25 p-0">
              {!isContentDetailsLoading ? (
                <SummeryMatrix
                  readCount={readCount}
                  totalCount={totalCount}
                  reachCount={reachCount}
                  avgDurationChart={avgDurationChart}
                  projectToTargets={projectToTargets}
                  hasViewAccess={hasViewAccess}
                  hasReadAccess={hasReadAccess}
                  hasHCPAccess={hasHCPAccess}
                  page="content-details"
                />
              ) : (
                <MatrixSkeleton />
              )}
            </Card.Body>
          </Card>
        </Col>
        <Col md={6} sm={12} className={`mb-4 ${hasViewAccess ? "" : "d-none"}`}>
          {!isContentViewsLoading && contentViews?.length > 0 ? (
            <BasicColumnChart
              title={`Content Views`}
              series={contentViewsChartOptions.series}
              labels={contentViewsChartOptions.label}
            />
          ) : isContentViewsLoading ? (
            <BarChartSkeleton />
          ) : (
            <PageNotFound
              title="No data found"
              // message="Upload one to start analyzing data!"
              backgroundImage={findIcon("Empty-folder", "dual-tone", 100)}
            />
          )}
        </Col>
        <Col md={hasViewAccess ? 6 : 12} sm={12} className="mb-4">
          {!isProjectDetailsLoading &&
          !isContentReachLoading &&
          contentReach?.length > 0 ? (
            <BasicColumnChart
              title={"Content Reach"}
              series={contentReachChartOptions.series}
              labels={contentReachChartOptions.label}
            />
          ) : isContentReachLoading || isProjectDetailsLoading ? (
            <BarChartSkeleton />
          ) : (
            <PageNotFound
              title="No data found"
              // message="Upload one to start analyzing data!"
              backgroundImage={findIcon("Empty-folder", "dual-tone", 100)}
            />
          )}
        </Col>
        <FilterBy handleDropdownChange={handleDropdownChange} type={type} />
        <Col md={12} lg={6} sm={12} className="d-flex flex-column pe-2 mb-4">
          {!isProjectDetailsLoading &&
          !isStateMapLoading &&
          stateMapData?.length > 0 ? (
            <IndiaMap
              stateData={
                type === "Reads" ? stateMapReadsData : stateMapViewsData
              }
              title={`${type} By Location`}
              valueSuffix="%"
            />
          ) : isStateMapLoading || isProjectDetailsLoading ? (
            <MapAnalyticsSkeleton />
          ) : (
            <PageNotFound
              title="No data found"
              backgroundImage={findIcon("Empty-folder", "dual-tone", 100)}
            />
          )}
        </Col>
        <Col md={12} lg={6}>
          <Row>
            <Col md={12} sm={12} className="mb-4">
              {!isProjectDetailsLoading &&
              !isDeviceViewLoading &&
              deviceChartData?.length > 0 ? (
                <DonutChart
                  series={deviceChartOptions.series}
                  labels={deviceChartOptions.label}
                  title={`${type} By Device`}
                  graphColor={GRAPH_FILL_COLOR}
                />
              ) : isDeviceViewLoading || isProjectDetailsLoading ? (
                <SquareChartSkeleton />
              ) : (
                <PageNotFound
                  title="No data found"
                  // message="Upload one to start analyzing data!"
                  backgroundImage={findIcon("Empty-folder", "dual-tone", 100)}
                />
              )}
            </Col>
            <Col md={12} sm={12} className="mb-4">
              {!isProjectDetailsLoading &&
              !isZoneViewLoading &&
              zoneDistributionData?.length > 0 ? (
                <TreeMapChart
                  title={`${type} By Zone`}
                  series={zonalChartOptions}
                />
              ) : isZoneViewLoading || isProjectDetailsLoading ? (
                <SquareChartSkeleton />
              ) : (
                <PageNotFound
                  title="No data found"
                  backgroundImage={findIcon("Empty-folder", "dual-tone", 100)}
                />
              )}
            </Col>
          </Row>
        </Col>

        <Col md={4} sm={12} className="mb-4">
          {!isProjectDetailsLoading &&
          !isContentCategoryLoading &&
          screenSizeData?.length > 0 ? (
            <PolarChart
              labels={screenSizeChartOptions.label}
              series={screenSizeChartOptions.series}
              title={`${type} by Screen Size`}
              graphColor={GRAPH_FILL_COLOR}
              height={335}
            />
          ) : isContentCategoryLoading || isProjectDetailsLoading ? (
            <SquareChartSkeleton />
          ) : (
            <PageNotFound
              title="No data found"
              // message="Upload one to start analyzing data!"
              backgroundImage={findIcon("Empty-folder", "dual-tone", 100)}
            />
          )}
        </Col>

        <Col md={4} sm={12} className="mb-4">
          {!isProjectDetailsLoading &&
          !isMobileDeviceViewsLoading &&
          mobileDeviceData?.length > 0 ? (
            <DonutChart
              series={mobileDeviceChartOptions.series}
              labels={mobileDeviceChartOptions.label}
              title={`${type} by Mobile Device`}
              graphColor={GRAPH_FILL_COLOR}
              height={335}
            />
          ) : isMobileDeviceViewsLoading || isProjectDetailsLoading ? (
            <SquareChartSkeleton />
          ) : (
            <PageNotFound
              title="No data found"
              // message="Upload one to start analyzing data!"
              backgroundImage={findIcon("Empty-folder", "dual-tone", 100)}
            />
          )}
        </Col>

        <Col md={4} sm={12} className="mb-4">
          {!isProjectDetailsLoading &&
          !isBrowserViewsReportLoading &&
          browserData?.length > 0 ? (
            <PolarChart
              labels={browserChartOptions.label}
              series={browserChartOptions.series}
              title={`${type} by Browser`}
              graphColor={GRAPH_FILL_COLOR}
              height={335}
            />
          ) : isBrowserViewsReportLoading || isProjectDetailsLoading ? (
            <SquareChartSkeleton />
          ) : (
            <PageNotFound
              title="No data found"
              // message="Upload one to start analyzing data!"
              backgroundImage={findIcon("Empty-folder", "dual-tone", 100)}
            />
          )}
        </Col>

        <Col
          md={5}
          className={`mb-4 ${
            !isContentDetailsLoading &&
            contentDetails?.content_data?.at(0) &&
            contentDetails?.content_data?.at(0)?.fetch_with_contents &&
            Array.isArray(
              contentDetails?.content_data?.at(0)?.fetch_with_contents
            ) &&
            contentDetails?.content_data?.at(0)?.fetch_with_contents?.length >
              0 &&
            contentDetails?.content_data?.at(0)?.fetch_with_contents[0]
              ?.content_tags &&
            Array.isArray(
              contentDetails?.content_data?.at(0)?.fetch_with_contents[0]
                ?.content_tags
            ) &&
            contentDetails?.content_data?.at(0)?.fetch_with_contents[0]
              ?.content_tags?.length < 1 &&
            "d-none"
          }`}
        >
          {!isContentDetailsLoading &&
          contentDetails?.content_data?.at(0) &&
          contentDetails?.content_data?.at(0)?.fetch_with_contents &&
          Array.isArray(
            contentDetails?.content_data?.at(0)?.fetch_with_contents
          ) &&
          contentDetails?.content_data?.at(0)?.fetch_with_contents?.length >
            0 &&
          contentDetails?.content_data?.at(0)?.fetch_with_contents[0]
            ?.content_tags &&
          Array.isArray(
            contentDetails?.content_data?.at(0)?.fetch_with_contents[0]
              ?.content_tags
          ) &&
          contentDetails?.content_data?.at(0)?.fetch_with_contents[0]
            ?.content_tags?.length > 0 ? (
            <TextPillCard
              data={
                contentDetails?.content_data?.at(0)?.fetch_with_contents[0]
                  ?.content_tags &&
                Array.isArray(
                  contentDetails?.content_data?.at(0)?.fetch_with_contents[0]
                    ?.content_tags
                )
                  ? contentDetails?.content_data
                      ?.at(0)
                      ?.fetch_with_contents[0]?.content_tags?.map((item) => {
                        return { name: item?.tag_data?.content_tag_name };
                      })
                  : []
              }
              title={"Tags"}
              textSuccess={true}
              bgSecondary={true}
            />
          ) : isContentDetailsLoading ? (
            <SquareChartSkeleton />
          ) : (
            // <PageNotFound
            //   title="No data found"
            //   // message="Upload one to start analyzing data!"
            //   backgroundImage={findIcon("Empty-folder", "dual-tone", 100)}
            // />
            <></>
          )}
        </Col>
        <Col md={12}>
          {!isContentDetailsLoading &&
          contentDetails?.content_data?.at(0) &&
          Array.isArray(
            contentDetails?.content_data?.at(0)?.fetch_with_contents
          ) &&
          contentDetails?.content_data?.at(0)?.fetch_with_contents?.length >
            0 &&
          contentDetails?.content_data?.at(0)?.fetch_with_contents[0]
            ?.content_comments &&
          Array.isArray(
            contentDetails?.content_data?.at(0)?.fetch_with_contents[0]
              ?.content_comments
          ) &&
          contentDetails?.content_data?.at(0)?.fetch_with_contents[0]
            ?.content_comments?.length > 0 ? (
            <CommentSwiper
              comments={
                contentDetails?.content_data?.at(0) &&
                Array.isArray(
                  contentDetails?.content_data?.at(0)?.fetch_with_contents
                ) &&
                contentDetails?.content_data?.at(0)?.fetch_with_contents
                  ?.length > 0 &&
                contentDetails?.content_data?.at(0)?.fetch_with_contents[0]
                  ?.content_comments &&
                Array.isArray(
                  contentDetails?.content_data?.at(0)?.fetch_with_contents[0]
                    ?.content_comments
                )
                  ? contentDetails?.content_data
                      ?.at(0)
                      ?.fetch_with_contents[0]?.content_comments?.map(
                        (item) => item.comment
                      )
                  : []
              }
              title={"Comments"}
            />
          ) : isContentDetailsLoading ? (
            <SquareChartSkeleton height={130} />
          ) : (
            <PageNotFound
              title="No data found"
              // message="Upload one to start analyzing data!"
              backgroundImage={findIcon("Empty-folder", "dual-tone", 100)}
            />
          )}
        </Col>
      </Row>
    </>
  );
}

export default ContentDetails;
