import React from "react";
import { fallbackImages } from "../../../../common/constants/fallback-images";
import { findIcon } from "../../../dashboard/icons";

const SupportImage = ({ image }) => {
  const fileType = image?.split(".")?.pop();

  return (
    <div className="d-flex flex-wrap flex-row gap-3 mt-3">
      <div className="position-relative">
        <a href={image} target="_blank" rel="noopener noreferrer">
          {fileType === "pdf" ? (
            findIcon("pdf", "solid", 80)
          ) : ["txt", "doc", "docx"].includes(fileType) ? (
            findIcon("doc", "solid", 80)
          ) : (
            <img
              className="rounded img-fluid avatar-120"
              src={image}
              alt="profile"
              loading="lazy"
              onError={(e) => (e.target.src = fallbackImages.noVideo)}
            />
          )}
        </a>
      </div>
    </div>
  );
};

export default SupportImage;
