import React, { useEffect, useState } from "react";
import Titlebar from "../components/Titlebar";
import { Card, Row, Col, Button, Badge } from "react-bootstrap";
import Chart from "react-apexcharts";
import ReactTable from "../../../../../lib/table/react-table";
import { findIcon } from "../../../../dashboard/icons";
// import Table from "../components/Table";
import TreeMapChart from "../../../../../components/chart/TreeMapChart";
import BarChart from "../../../../../components/chart/BarChart";
import BoxPlotChart from "../../../../../components/chart/BoxPlotChart";
import SparlLineBar from "../../../../../components/chart/SparlLineBar";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import TableHeaderSwiper from "../components/TableHeaderSwiper";
import {
  useGetCreativeDataList,
  useGetCreativeDeviceLatencyReport,
  useGetCreativeDimensionReport,
  useGetCreativeReportTableData,
  useGetCreativeReportTableDataCtr,
  useGetOsLatencyReport,
  useGetSummaryCampaignGroupList,
  useGetSummaryCreativeList,
} from "../../../../../queries/query-hooks/workspace/brand-ads-hook";
import {
  formatLocalizedDate,
  titleCase,
  transformGraphData,
  transformMapData,
} from "../../../../../common/utils/utils";
import { initialDate } from "./../constant/date-range-constant";
import CommonTableLoader from "../../../../../skeleton/common-table-loader";
import SquareChartSkeleton from "../../../../../skeleton/workspace/clirnet/brand-ads/square-chart-skeleton";
import BarChartSkeleton from "../../../../../skeleton/workspace/clirnet/brand-ads/bar-chart-skeleton";
import NavSwiperSkeleton from "../../../../../skeleton/workspace/clirnet/brand-ads/nav-swiper-skeleton";
import PageNotFound from "../../../../PageNotFound";
import { fallbackImages } from "../../../../../utilities/fallback-images";
import { useParams } from "react-router-dom";
import useRedirect from "../../../../../components/custom-hooks/use-redirect";
import { useGetProjectDetails } from "../../../../../queries/query-hooks/workspace/workspace-hook";
import useCsvDownloader from "../../../../../components/custom-hooks/use-csv-downloader";
import * as dayjs from "dayjs";

const DAYS_OF_WEEK = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

function Creative() {
  const { redirectTo } = useRedirect();
  const { project_id } = useParams();
  const { downloadCSV } = useCsvDownloader();

  const handleTableCsvDownload = () => {
    downloadCSV(tableCsvFormat);
  };

  const [activeCampaignGroup, setActiveCampaignGroup] = useState({});

  function getGroupIdsCommaSeparated(data) {
    return data.map((item) => item.group_id).join(",");
  }

  const { data: projectDetails, isLoading: isProjectDetailsLoading } =
    useGetProjectDetails(project_id);

  const [dateRangePayload, setDateRangePayload] = useState({
    start_date:
      projectDetails?.[0]?.project_start_date || initialDate.start_date,
    end_date: projectDetails?.[0]?.project_end_date || initialDate.end_date,
  });

  const { data: creativeDataReport, isLoading: isLoadingCreativeDataReport } =
    useGetCreativeReportTableData(
      project_id,
      {
        start_date: dateRangePayload.start_date,
        end_date: dateRangePayload.end_date,
        campaign_list: activeCampaignGroup?.id,
      },
      activeCampaignGroup?.id ? true : false
    );
  const {
    data: creativeDataReportCtr,
    isLoading: isLoadingCreativeDataReportCtr,
  } = useGetCreativeReportTableDataCtr(
    project_id,
    {
      start_date: dateRangePayload.start_date,
      end_date: dateRangePayload.end_date,
      campaign_list: activeCampaignGroup?.id,
    },
    activeCampaignGroup?.id ? true : false
  );

  const { data: creativeDataList, isLoading: isCreativeDataListLoading } =
    useGetSummaryCreativeList(project_id, {
      start_date: dateRangePayload.start_date,
      end_date: dateRangePayload.end_date,
      campaign_list:
        projectDetails && projectDetails?.length > 0
          ? getGroupIdsCommaSeparated(projectDetails[0]?.project_group_data)
          : "",
    });
  const { data: deviceLatencyReport, isLoading: isLoadingDeviceLAtency } =
    useGetCreativeDeviceLatencyReport(project_id, {
      start_date: dateRangePayload.start_date,
      end_date: dateRangePayload.end_date,
      campaign_list:
        projectDetails && projectDetails?.length > 0
          ? getGroupIdsCommaSeparated(projectDetails[0]?.project_group_data)
          : "",
    });

  const { data: dimensionReport, isLoading: isDimensionLoading } =
    useGetCreativeDimensionReport(project_id, {
      start_date: dateRangePayload.start_date,
      end_date: dateRangePayload.end_date,
      campaign_list:
        projectDetails && projectDetails?.length > 0
          ? getGroupIdsCommaSeparated(projectDetails[0]?.project_group_data)
          : "",
    });

  const { data: osLatencyReport, isLoading: isOsLatencyLoading } =
    useGetOsLatencyReport(project_id, {
      start_date: dateRangePayload.start_date,
      end_date: dateRangePayload.end_date,
      campaign_list:
        projectDetails && projectDetails?.length > 0
          ? getGroupIdsCommaSeparated(projectDetails[0]?.project_group_data)
          : "",
    });

  // console.log("osLatencyReport",osLatencyReport)

  const { data: campaignGroupList, isLoading: isCampaignGroupListLoading } =
    useGetSummaryCampaignGroupList(project_id, {
      campaign_list:
        projectDetails && projectDetails?.length > 0
          ? getGroupIdsCommaSeparated(projectDetails[0]?.project_group_data)
          : "",
    });

  const extractWeekData = (data) => {
    if (data?.length <= 0) {
      return [];
    }
    const days = ["day1", "day2", "day3", "day4", "day5", "day6", "day7"];
    return days.map((day) => data[day]);
  };

  const TableColumnData = [
    {
      Header: "Thumbnail",
      columnId: 1,
      accessor: "file",
      disableFilters: true,
      Cell: ({ value, row }) => {
        return row.original.type == "image" ? (
          <img
            onClick={() =>
              redirectTo(
                `brand-ads/creative/details/${project_id}/${row.original.creative_id}/${row.original.campaign_id}`
              )
            }
            src={value}
            className="rounded-3 border border-1 cursor-pointer"
            style={{ maxWidth: "12rem", height: "auto" }}
          />
        ) : row.original.type == "video" ? (
          <video
            onClick={() =>
              redirectTo(
                `brand-ads/creative/details/${project_id}/${row.original.creative_id}/${row.original.campaign_id}`
              )
            }
            autoPlay
            loop
            src={value}
            className="rounded-3 border border-1 cursor-pointer"
            style={{ maxWidth: "10rem", height: "auto" }}
          />
        ) : (
          "N/A"
        );
      },
    },
    {
      Header: "Type",
      columnId: 2,
      accessor: "type",
      disableFilters: true,
      Cell: ({ value }) => `${value ? titleCase(value) : 0}`,
    },
    {
      Header: "Dimension",
      columnId: 3,
      accessor: "dimension",
      disableFilters: true,
      Cell: ({ row }) =>
        `${row.original.creative_width} x ${row.original.creative_height}`,
    },
    {
      Header: "Viewed",
      columnId: 4,
      accessor: "views",
      disableFilters: true,
      Cell: ({ value }) => `${value ? value : 0}`,
    },
    // {
    //   Header: "Mobile Impr.",
    //   columnId: 5,
    //   accessor: "mobile_imp",
    //   disableFilters: true,
    //   Cell: ({ value }) => `${value ? value : 0}`,
    // },
    // {
    //   Header: "Web Impr. (%)",
    //   columnId: 6,
    //   accessor: "web_imp_pct",
    //   disableFilters: true,
    //   Cell: ({ value }) => `${value ? value : 0}`,
    // },
    // {
    //   Header: "Total Impr.",
    //   columnId: 7,
    //   accessor: "total_impr",
    //   disableFilters: true,
    //   Cell: ({ value }) => `${value ? value : 0}`,
    // },
    {
      Header: "Total CTR (%)",
      columnId: 8,
      accessor: "ctr",
      disableFilters: true,
      Cell: ({ value }) => `${value ? `${value} %` : "0 %"}`,
    },
    {
      Header: "Trends",
      columnId: 9,
      accessor: "date_Data",
      disableFilters: true,
      Cell: ({ cell: { value } }) => {
        if (value && value.split(",")?.length > 0) {
          return (
            <SparlLineBar
              series={value?.split(",") || []}
              labels={DAYS_OF_WEEK}
              height={50}
            />
          );
        } else {
          return "N/A";
        }
      },
    },
    {
      Header: "Actions",
      Cell: ({ row }) => {
        const redirectURL = `brand-ads/creative/details/${project_id}/${row.original.creative_id}/${row.original.campaign_id}`;
        return (
          <Button
            className="btn btn-sm rounded-pill"
            onClick={() => redirectTo(redirectURL)}
          >
            {findIcon("Eye", "solid", 16)}
          </Button>
        );
      },
    },
  ];

  function formatDimensionData(inputArray) {
    return inputArray.map(({ Dimensions, dimension_count }) => ({
      x: Dimensions,
      y: dimension_count,
    }));
  }

  const dimentionChartData =
    dimensionReport && dimensionReport?.length > 0
      ? formatDimensionData(dimensionReport)
      : [];

  const deviceLatencyChartData = {
    series:
      deviceLatencyReport && deviceLatencyReport?.length > 0
        ? transformGraphData(deviceLatencyReport, "avg_latency", "Device")
            ?.series
        : [],
    labels:
      deviceLatencyReport && deviceLatencyReport?.length > 0
        ? transformGraphData(deviceLatencyReport, "avg_latency", "Device")
            ?.label
        : [],
  };

  function formatOsChartData(inputArr) {
    if (inputArr?.length < 0) {
      return [];
    } else {
      return inputArr.map((item) => ({
        x: item.os_name == null ? "Others" : item.os_name,
        y: [item.minimum, item.Q1, item.median, item.Q3, item.maximum],
      }));
    }
  }

  // console.log(formatOsChartData(osLatencyReport),"data")

  const OperatingSystemChartData =
    osLatencyReport && osLatencyReport?.length > 0
      ? formatOsChartData(osLatencyReport)
      : [];

  // console.log("OperatingSystemChartData",OperatingSystemChartData)

  function mergedCreativeData(creativeList, creativeReport, creativeReportCtr) {
    console.log("checking", creativeList, creativeReport, creativeReportCtr);
    if (
      creativeList &&
      creativeList?.length > 0 &&
      !Array.isArray(creativeReport) &&
      !Array.isArray(creativeReportCtr)
    ) {
      return creativeList.map((items) => ({
        ...items,
        views: "loading",
        unique_clicks: "loading",
        ctr: "loading",
      }));
    }

    if (
      creativeList &&
      creativeList?.length > 0 &&
      creativeReport &&
      creativeReport?.length > 0
    ) {
      var mergedData = creativeList.map((items) => {
        const matchingMatrix = creativeReport.find(
          (matrix) => matrix.creative_id == items.creative_id
        );

        const matchingCtr =
          creativeReportCtr && Array.isArray(creativeReportCtr)
            ? creativeReportCtr.find(
                (matrix) => matrix.creative_id == items.creative_id
              )
            : [];
        console.log("matchingCtr", matchingCtr);

        return {
          ...items,
          ...(matchingMatrix || {}),
          ...(matchingCtr || {}),
          campaign_id: activeCampaignGroup?.id,
        };
      });

      mergedData = mergedData.map((item) => {
        return {
          ...item,
          dimention_str: `${item.creative_width} x ${item.creative_height}`,
        };
      });

      return mergedData && Array.isArray(mergedData)
        ? mergedData.sort((a, b) => b.views - a.views)
        : [];
    }

    if (
      creativeList &&
      creativeList?.length > 0 &&
      creativeReport &&
      creativeReport?.length > 0 &&
      creativeReportCtr &&
      creativeReportCtr?.length > 0
    ) {
      const mergedData = creativeList.map((items) => {
        const matchingMatrix = creativeReport.find(
          (matrix) => matrix.creative_id == items.creative_id
        );
        const matchingCtr = creativeReportCtr.find(
          (matrix) => matrix.creative_id == items.creative_id
        );
        console.log("matchingCtr", matchingCtr);

        return {
          ...items,
          ...(matchingMatrix || {}),
          ...(matchingCtr || {}),
          campaign_id: activeCampaignGroup?.id,
        };
      });
      mergedData =
        mergedData && Array.isArray(mergedData)
          ? mergedData
              .map((item) => {
                return {
                  ...item,
                  dimention_str: `${item.creative_width} x ${item.creative_height}`,
                };
              })
              .sort((a, b) => b.views - a.views)
          : [];

      return mergedData;
    }

    return creativeList && Array.isArray(creativeList)
      ? creativeList.sort((a, b) => b.views - a.views)
      : creativeList;
  }

  const creativeTableData = mergedCreativeData(
    creativeDataList,
    creativeDataReport,
    creativeDataReportCtr
  );

  console.log("creativeTableData", creativeTableData);

  const tableCsvFormat = {
    header: [
      "Thumbnail",
      "Creative Type",
      "Dimension",
      "Viewed",
      // "Mobile imp",
      // "Web impr",
      // "Total impr",
      "Total CTR (%)",
    ],
    column: [
      "file",
      "type",
      "dimention_str",
      "views",
      // "mobile_imp",
      // "web_imp_pct",
      // "total_impr",
      "ctr",
    ],
    // chart: {
    //   header: "Trends",
    //   column: "date_Data",
    // },
    rows: creativeTableData,
  };

  useEffect(() => {
    if (projectDetails && projectDetails?.length > 0) {
      const today = dayjs();
      const projectEndDate = dayjs(projectDetails[0]?.project_end_date);
      let result = projectDetails[0]?.project_end_date;

      if (projectEndDate && projectEndDate.isAfter(today)) {
        result = today.subtract(1, "day");
      } else {
        result = projectEndDate;
      }

      setDateRangePayload({
        start_date: formatLocalizedDate(
          new Date(projectDetails[0]?.project_start_date),
          "YYYY-MM-DD[T]00:00:00.000Z"
        ),
        end_date: formatLocalizedDate(
          new Date(result),
          "YYYY-MM-DD[T]23:59:59.SSSZ"
        ),
      });
    }
  }, [projectDetails]);

  return (
    <>
      <Row>
        <Col md={12} sm={12}>
          <Titlebar
            title={"Creative"}
            projectType={"Brand Ads"}
            setDateRangePayload={setDateRangePayload}
            projectStartDate={
              projectDetails?.length > 0
                ? projectDetails[0]?.project_start_date
                : "   "
            }
            projectEndDate={
              projectDetails?.length > 0
                ? projectDetails[0]?.project_end_date
                : "   "
            }
            dateLabel={dateRangePayload}
            isProjectDetailsLoading={isProjectDetailsLoading}
          />
        </Col>

        <Col md={12} sm={12}>
          <div className="d-flex">
            <h5 className="m-0 text-primary border-bottom border-primary border-2 w-auto px-3 pb-2 ms-4">
              Creative
            </h5>
          </div>
          <Card>
            <Card.Header className="d-flex flex-row flex-md-row">
              {!isCampaignGroupListLoading && campaignGroupList.length > 0 ? (
                <TableHeaderSwiper
                  title={campaignGroupList}
                  activeCampaignGroup={activeCampaignGroup}
                  setActiveCampaignGroup={setActiveCampaignGroup}
                />
              ) : (
                <NavSwiperSkeleton />
              )}

              {!isLoadingCreativeDataReport &&
              creativeDataReport?.length > 0 &&
              creativeTableData?.length > 0 ? (
                <div className="d-flex flex-row gap-3 flex-shrink-0">
                  {/* <div className="text-success">
                    {findIcon("DownloadReport", "dual-tone", "18")}
                    <span className="text-black fs-7 px-2 d-none d-md-inline-block">
                      Download Report
                    </span>
                  </div> */}
                  <div
                    className="text-success cursor-pointer"
                    onClick={() => downloadCSV(tableCsvFormat)}
                  >
                    {findIcon("ExportReport", "dual-tone", "20")}
                    <span className="text-black fs-7 px-2 d-none d-md-inline-block">
                      Export
                    </span>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </Card.Header>
            {console.log(
              "creativeTableData",
              creativeTableData,
              creativeDataList
            )}
            <Card.Body>
              {!isProjectDetailsLoading &&
              !isLoadingCreativeDataReport &&
              creativeDataList?.length > 0 ? (
                <ReactTable
                  data={
                    // creativeTableData?.length > 10
                    //   ? creativeTableData.slice(0, 10)
                    //   : creativeTableData
                    creativeTableData
                  }
                  columns={TableColumnData}
                  isPaginationShow={false}
                  bordered
                  noDataText="no data"
                  noDataImage={fallbackImages.noActionData}
                  contentCenter={true}
                />
              ) : isProjectDetailsLoading || isLoadingCreativeDataReport ? (
                <CommonTableLoader tableRowLength={5} />
              ) : (
                <PageNotFound
                  title="No data found"
                  // message="Upload one to start analyzing data!"
                  backgroundImage={findIcon("Empty-folder", "dual-tone", 100)}
                />
              )}
            </Card.Body>
          </Card>
        </Col>

        {/* device latency */}
        <Col md={6} sm={12} className="mb-4">
          {!isLoadingDeviceLAtency && deviceLatencyReport?.length > 0 ? (
            <BarChart
              title={"Device Latency"}
              series={deviceLatencyChartData.series}
              labels={deviceLatencyChartData.labels}
              name="ms"
            />
          ) : isLoadingDeviceLAtency ? (
            <SquareChartSkeleton />
          ) : (
            <PageNotFound
              title="No data found"
              // message="Upload one to start analyzing data!"
              backgroundImage={findIcon("Empty-folder", "dual-tone", 100)}
            />
          )}
        </Col>

        {/* dimention */}
        <Col md={6} sm={12} className="mb-4">
          {!isDimensionLoading && dimensionReport?.length > 0 ? (
            <TreeMapChart title={"Dimensions"} series={dimentionChartData} />
          ) : isDimensionLoading ? (
            <SquareChartSkeleton />
          ) : (
            <PageNotFound
              title="No data found"
              // message="Upload one to start analyzing data!"
              backgroundImage={findIcon("Empty-folder", "dual-tone", 100)}
            />
          )}
        </Col>

        {/* operating system */}
        <Col md={12} sm={12} className="mb-4">
          {!isOsLatencyLoading && osLatencyReport?.length > 0 ? (
            <BoxPlotChart
              series={OperatingSystemChartData}
              title={"Operating System Latency"}
            />
          ) : isOsLatencyLoading ? (
            <BarChartSkeleton />
          ) : (
            <PageNotFound
              title="No data found"
              // message="Upload one to start analyzing data!"
              backgroundImage={findIcon("Empty-folder", "dual-tone", 100)}
            />
          )}
        </Col>
      </Row>
    </>
  );
}

export default Creative;
