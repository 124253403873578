import React, { useEffect, useState } from "react";
import Titlebar from "../components/Titlebar";
import { initialDate } from "../constant/date-range-constant";
import TableHeaderSwiper from "../components/TableHeaderSwiper";
import NavSwiperSkeleton from "../../../../../skeleton/workspace/clirnet/brand-ads/nav-swiper-skeleton";
import { Card, Col, Row } from "react-bootstrap";
import {
  formatLocalizedDate,
  transformGraphData,
  transformHeatMapData,
} from "../../../../../common/utils/utils";
import { GRAPH_FILL_COLOR } from "../constant/graph-color-scheme";
import BasicColumnChart from "../../../../../components/chart/BasicColumnChart";
import BarChartSkeleton from "../../../../../skeleton/workspace/clirnet/brand-ads/bar-chart-skeleton";
import PageNotFound from "../../../../PageNotFound";
import IndiaMap from "../../../../../components/chart/IndiaMap";
import TreeMapChart from "../../../../../components/chart/TreeMapChart";
import DonutChart from "../../../../../components/chart/DonutChart";
import PolarChart from "../../../../../components/chart/PolarChart";
import DashboardDropdown from "../../../../../components/DashboardDropdown";
import { filterOptions } from "../constant/filter-options";

import {
  useGetAnalyticsContentViews,
  useGetAnalyticsContentReach,
  useGetAnalyticsLocationDistribution,
  useGetAnalyticsDeviceViews,
  useGetAnalyticsZoneViews,
  useGetAnalyticsContentCategory,
  useGetAnalyticsMobileDeviceViews,
  useGetAnalyticsBrowserViewsReport,
  useGetAudienceViewsByScreensize,
  useGetSummaryList,
  useGetLocationStateMap,
} from "../../../../../queries/query-hooks/workspace/sponsored-content-hook";
import { useParams } from "react-router-dom";
import SquareChartSkeleton from "../../../../../skeleton/workspace/clirnet/brand-ads/square-chart-skeleton";
import { findIcon } from "../../../../dashboard/icons";
import MapAnalyticsSkeleton from "../../../../../skeleton/workspace/clirnet/brand-ads/map-analytics-skeleton";
import { useGetProjectDetails } from "../../../../../queries/query-hooks/workspace/workspace-hook";
import FilterBy from "../components/FilterBy";
import { checkProjectAccess } from "../utils/common";
import dayjs from "dayjs";

function Analytics() {
  const { project_id } = useParams();

  const [type, setType] = useState(filterOptions?.initial);

  const { data: projectDetails, isLoading: isProjectDetailsLoading } =
    useGetProjectDetails(project_id);

  const [dateRangePayload, setDateRangePayload] = useState({
    start_date:
      projectDetails?.[0]?.project_start_date || initialDate.start_date,
    end_date: projectDetails?.[0]?.project_end_date || initialDate.end_date,
  });
  const [activeCampaignGroup, setActiveCampaignGroup] = useState({});

  const { data: contentViews, isLoading: isContentViewsLoading } =
    useGetAnalyticsContentViews(project_id, {
      start_date: dateRangePayload.start_date,
      end_date: dateRangePayload.end_date,
      content_id: activeCampaignGroup?.value,
    });

  const { data: contentReach, isLoading: isContentReachLoading } =
    useGetAnalyticsContentReach(project_id, {
      start_date: dateRangePayload.start_date,
      end_date: dateRangePayload.end_date,
      content_id: activeCampaignGroup?.value,
    });

  const { data: stateMapData, isLoading: isStateMapLoading } =
    useGetLocationStateMap(project_id, type.toLowerCase(), {
      start_date: dateRangePayload.start_date,
      end_date: dateRangePayload.end_date,
      content_id: activeCampaignGroup?.value,
      type: "percentage",
    });

  const { data: deviceChartData, isLoading: isDeviceViewLoading } =
    useGetAnalyticsDeviceViews(project_id, type.toLowerCase(), {
      start_date: dateRangePayload.start_date,
      end_date: dateRangePayload.end_date,
      content_id: activeCampaignGroup?.value,
      type,
    });

  const { data: zoneDistributionData, isLoading: isZoneViewLoading } =
    useGetAnalyticsZoneViews(project_id, type.toLowerCase(), {
      start_date: dateRangePayload.start_date,
      end_date: dateRangePayload.end_date,
      content_id: activeCampaignGroup?.value,
      type,
    });

  const { data: screenSizeData, isLoading: isContentCategoryLoading } =
    useGetAudienceViewsByScreensize(project_id, type.toLowerCase(), {
      start_date: dateRangePayload.start_date,
      end_date: dateRangePayload.end_date,
      content_id: activeCampaignGroup?.value,
      type,
    });

  const { data: mobileDeviceData, isLoading: isMobileDeviceViewsLoading } =
    useGetAnalyticsMobileDeviceViews(project_id, type.toLowerCase(), {
      start_date: dateRangePayload.start_date,
      end_date: dateRangePayload.end_date,
      content_id: activeCampaignGroup?.value,
      type,
    });

  const { data: browserData, isLoading: isBrowserViewsReportLoading } =
    useGetAnalyticsBrowserViewsReport(project_id, type.toLowerCase(), {
      start_date: dateRangePayload.start_date,
      end_date: dateRangePayload.end_date,
      content_id: activeCampaignGroup?.value,
      type,
    });

  const { data: summaryList, isLoading: isSummaryListLoading } =
    useGetSummaryList(project_id, {
      start_date: dateRangePayload.start_date,
      end_date: dateRangePayload.end_date,
    });
  // json
  const sponsoredContentList =
    summaryList && Array.isArray(summaryList) && summaryList.length > 0
      ? summaryList?.map((item) => {
          return {
            id: item?.content_id,
            group_title: item?.fetch_with_image[0]?.content_title,
          };
        })
      : [];

  const zonalChartOptions =
    zoneDistributionData && zoneDistributionData?.length > 0
      ? transformHeatMapData(
          zoneDistributionData,
          "name",
          type === "Reads" ? "read_count" : "view_count"
        )
      : [];

  const transformStateMapData = (data = [], key) => {
    return [
      data?.reduce((acc, item) => {
        if (item.name && item[key] !== null) {
          acc[item.name] = item[key];
        }
        return acc;
      }, {}),
    ];
  };

  const stateMapReadsData = transformStateMapData(
    stateMapData,
    "reads_percent"
  );
  const stateMapViewsData = transformStateMapData(
    stateMapData,
    "views_percent"
  );

  const contentViewsChartOptions = {
    colors: GRAPH_FILL_COLOR,
    series: [
      {
        name: "View Count",
        data:
          contentViews?.length > 0
            ? transformGraphData(contentViews, "total_count", "formatted_date")
                ?.series
            : [],
        color: "#3256b8", // Customize color for Net Profit
      },
    ],

    label:
      contentViews?.length > 0
        ? transformGraphData(contentViews, "total_count", "formatted_date")
            ?.label
        : [],
  };

  const contentReachChartOptions = {
    series: [
      {
        name: "Click Count",
        data:
          contentReach?.length > 0
            ? transformGraphData(contentReach, "reach_count", "formatted_date")
                ?.series
            : [],
        color: "#1aa053",
      },
    ],
    label:
      contentReach?.length > 0
        ? transformGraphData(contentReach, "reach_count", "formatted_date")
            ?.label
        : [],
  };

  const deviceChartOptions = {
    series:
      deviceChartData?.length > 0
        ? transformGraphData(
            deviceChartData,
            "percentagebrowser_count",
            "Device"
          )?.series
        : [],
    label:
      deviceChartData?.length > 0
        ? transformGraphData(
            deviceChartData,
            "percentagebrowser_count",
            "Device"
          )?.label
        : [],
  };

  const screenSizeChartOptions = {
    label:
      screenSizeData && screenSizeData?.length > 0
        ? transformGraphData(
            screenSizeData,
            "impression_percentage",
            "screen_ratio"
          )?.label
        : [],
    series:
      screenSizeData && screenSizeData?.length > 0
        ? transformGraphData(
            screenSizeData,
            "impression_percentage",
            "screen_ratio"
          )?.series
        : [],
  };

  const mobileDeviceChartOptions = {
    series:
      mobileDeviceData?.length > 0
        ? transformGraphData(
            mobileDeviceData,
            "percentagebrowser_count",
            "Device"
          )?.series
        : [],
    label:
      mobileDeviceData?.length > 0
        ? transformGraphData(
            mobileDeviceData,
            "percentagebrowser_count",
            "Device"
          )?.label
        : [],
    title: "Mobile Device Distribution",
  };

  const browserChartOptions = {
    series:
      browserData?.length > 0
        ? transformGraphData(
            browserData,
            "percentagebrowser_count",
            "new_browser"
          )?.series
        : [],
    label:
      browserData?.length > 0
        ? transformGraphData(
            browserData,
            "percentagebrowser_count",
            "new_browser"
          )?.label
        : [],
  };

  const handleDropdownChange = (type = "Views") => {
    setType(type);
  };

  useEffect(() => {
    if (projectDetails && projectDetails?.length > 0) {
      const today = dayjs();
      const projectEndDate = dayjs(projectDetails[0]?.project_end_date);
      let result = projectDetails[0]?.project_end_date;

      if (projectEndDate && projectEndDate.isAfter(today)) {
        result = today.subtract(1, "day");
      } else {
        result = projectEndDate;
      }

      setDateRangePayload({
        start_date: formatLocalizedDate(
          new Date(projectDetails[0]?.project_start_date),
          "YYYY-MM-DD[T]00:00:00.000Z"
        ),
        end_date: formatLocalizedDate(
          new Date(result),
          "YYYY-MM-DD[T]23:59:59.SSSZ"
        ),
      });
    }
  }, [projectDetails]);

  useEffect(() => {
    if (sponsoredContentList && sponsoredContentList?.length > 0) {
      setActiveCampaignGroup({
        value: sponsoredContentList[0].id,
        label: sponsoredContentList[0].group_title,
      });
    }
  }, [summaryList]);

  const hasViewAccess = checkProjectAccess("show_View", projectDetails);

  return (
    <>
      <Row>
        <Col md={12} sm={12}>
          {!isProjectDetailsLoading && (
            <Titlebar
              title={"Analytics"}
              projectType={"Sponsored Content"}
              setDateRangePayload={setDateRangePayload}
              projectStartDate={projectDetails[0]?.project_start_date || ""}
              projectEndDate={projectDetails[0]?.project_end_date || ""}
              dateLabel={dateRangePayload}
              tabs={summaryList}
              activeSummaryTab={activeCampaignGroup}
              setActiveSummaryTab={setActiveCampaignGroup}
              isSummaryListLoading={isSummaryListLoading}
            />
          )}
        </Col>

        {/* 
        <Col md={12} className="mb-3">
          {!isProjectDetailsLoading &&
          !isSummaryListLoading &&
          sponsoredContentList.length > 0 ? (
            <TableHeaderSwiper
              title={sponsoredContentList}
              activeCampaignGroup={activeCampaignGroup}
              setActiveCampaignGroup={setActiveCampaignGroup}
            />
          ) : (
            <NavSwiperSkeleton />
          )}
        </Col> */}

        <Col md={6} sm={12} className={`mb-4 ${hasViewAccess ? "" : "d-none"}`}>
          {!isContentViewsLoading && contentViews?.length > 0 ? (
            <BasicColumnChart
              title={`Content Views`}
              series={contentViewsChartOptions.series}
              labels={contentViewsChartOptions.label}
            />
          ) : isContentViewsLoading ? (
            <BarChartSkeleton />
          ) : (
            <PageNotFound
              title="No data found"
              // message="Upload one to start analyzing data!"
              backgroundImage={findIcon("Empty-folder", "dual-tone", 100)}
            />
          )}
        </Col>

        <Col md={hasViewAccess ? 6 : 12} sm={12} className="mb-4">
          {!isProjectDetailsLoading &&
          !isContentReachLoading &&
          contentReach?.length > 0 ? (
            <BasicColumnChart
              title={"Content Reach"}
              series={contentReachChartOptions.series}
              labels={contentReachChartOptions.label}
            />
          ) : isContentReachLoading || isProjectDetailsLoading ? (
            <BarChartSkeleton />
          ) : (
            <PageNotFound
              title="No data found"
              // message="Upload one to start analyzing data!"
              backgroundImage={findIcon("Empty-folder", "dual-tone", 100)}
            />
          )}
        </Col>

        <FilterBy handleDropdownChange={handleDropdownChange} type={type} />

        <Col md={12} lg={6} sm={12} className="d-flex flex-column pe-2 mb-4">
          {!isProjectDetailsLoading &&
          !isStateMapLoading &&
          stateMapData?.length > 0 ? (
            <IndiaMap
              stateData={
                type === "Reads" ? stateMapReadsData : stateMapViewsData
              }
              title={`${type} By Location`}
              valueSuffix="%"
            />
          ) : isStateMapLoading || isProjectDetailsLoading ? (
            <MapAnalyticsSkeleton />
          ) : (
            <PageNotFound
              title="No data found"
              // message="Upload one to start analyzing data!"
              backgroundImage={findIcon("Empty-folder", "dual-tone", 100)}
            />
          )}
        </Col>

        <Col md={12} lg={6}>
          <Row>
            <Col md={12} sm={12} className="mb-4">
              {!isProjectDetailsLoading &&
              !isDeviceViewLoading &&
              deviceChartData?.length > 0 ? (
                <DonutChart
                  series={deviceChartOptions.series}
                  labels={deviceChartOptions.label}
                  title={`${type} By Device`}
                  graphColor={GRAPH_FILL_COLOR}
                />
              ) : isDeviceViewLoading || isProjectDetailsLoading ? (
                <SquareChartSkeleton />
              ) : (
                <PageNotFound
                  title="No data found"
                  // message="Upload one to start analyzing data!"
                  backgroundImage={findIcon("Empty-folder", "dual-tone", 100)}
                />
              )}
            </Col>
            <Col md={12} sm={12} className="mb-4">
              {!isProjectDetailsLoading &&
              !isZoneViewLoading &&
              zoneDistributionData?.length > 0 ? (
                <TreeMapChart
                  title={`${type} By Zone`}
                  series={zonalChartOptions}
                />
              ) : isZoneViewLoading || isProjectDetailsLoading ? (
                <SquareChartSkeleton />
              ) : (
                <PageNotFound
                  title="No data found"
                  // message="Upload one to start analyzing data!"
                  backgroundImage={findIcon("Empty-folder", "dual-tone", 100)}
                />
              )}
            </Col>
          </Row>
        </Col>

        {/* <Col md={12} sm={12} className="mb-4">
          <Card className="p-2 border bg-light bg-opacity-50 border-secondary border-opacity-10 mb-0">
            <Card.Body className="py-3">
              {
                <h5 className="text-center m-0 fs-5 fw-normal">
                  This Creative has recieved {0} views since last {"month"}
                </h5>
              }
            </Card.Body>
          </Card>
        </Col> */}

        <Col md={4} sm={12} className="mb-4">
          {!isProjectDetailsLoading &&
          !isContentCategoryLoading &&
          screenSizeData?.length > 0 ? (
            <PolarChart
              labels={screenSizeChartOptions.label}
              series={screenSizeChartOptions.series}
              title={`${type} by Screen Size`}
              graphColor={GRAPH_FILL_COLOR}
              height={335}
            />
          ) : isContentCategoryLoading || isProjectDetailsLoading ? (
            <SquareChartSkeleton />
          ) : (
            <PageNotFound
              title="No data found"
              // message="Upload one to start analyzing data!"
              backgroundImage={findIcon("Empty-folder", "dual-tone", 100)}
            />
          )}
        </Col>

        <Col md={4} sm={12} className="mb-4">
          {!isProjectDetailsLoading &&
          !isMobileDeviceViewsLoading &&
          mobileDeviceData?.length > 0 ? (
            <DonutChart
              series={mobileDeviceChartOptions.series}
              labels={mobileDeviceChartOptions.label}
              title={`${type} by Mobile Device`}
              graphColor={GRAPH_FILL_COLOR}
              height={335}
            />
          ) : isMobileDeviceViewsLoading || isProjectDetailsLoading ? (
            <SquareChartSkeleton />
          ) : (
            <PageNotFound
              title="No data found"
              // message="Upload one to start analyzing data!"
              backgroundImage={findIcon("Empty-folder", "dual-tone", 100)}
            />
          )}
        </Col>

        <Col md={4} sm={12} className="mb-4">
          {!isProjectDetailsLoading &&
          !isBrowserViewsReportLoading &&
          browserData?.length > 0 ? (
            <PolarChart
              labels={browserChartOptions.label}
              series={browserChartOptions.series}
              title={`${type} by Browser`}
              graphColor={GRAPH_FILL_COLOR}
              height={335}
            />
          ) : isBrowserViewsReportLoading || isProjectDetailsLoading ? (
            <SquareChartSkeleton />
          ) : (
            <PageNotFound
              title="No data found"
              // message="Upload one to start analyzing data!"
              backgroundImage={findIcon("Empty-folder", "dual-tone", 100)}
            />
          )}
        </Col>
      </Row>
    </>
  );
}

export default Analytics;
