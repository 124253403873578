import React, { useRef } from "react";
import DatamapsIndia from "react-datamaps-india";
import { Card } from "react-bootstrap";
import { findIcon } from "../../../../dashboard/icons";
import { downloadTablePNG } from "../../../../../common/utils/utils";

function IndiaMap({ stateData, title, valueSuffix = "" }) {
  const mapRef = useRef();
  const formatMapData = (mapData) => {
    const output = {};
    for (const [key, value] of Object.entries(mapData)) {
      if (value && value === 0) return;
      const formattedKey = key.replace(/_/g, " ");
      output[formattedKey] = {
        value: value || 0,
      };
    }
    return output;
  };

  return (
    <>
      <Card className="card h-100 mb-0" data-aos="fade-up" data-aos-delay="500">
        <Card.Header className="">
          <Card.Title className="d-flex justify-content-between align-items-center">
            <h5 className="subHeading position-relative">{title}</h5>
            <div
              className="text-success cursor-pointer text-center"
              onClick={() => downloadTablePNG(mapRef)}
            >
              {findIcon("DownloadReport", "dual-tone", "18")}
              <span className="text-black fs-7 px-2 d-none d-md-inline-block">
                Download
              </span>
            </div>
          </Card.Title>
        </Card.Header>
        <Card.Body className="p-0 text-center india-map position-relative india-map-dashboard">
          {stateData?.length > 0 && (
            <div ref={mapRef}>
              <div>
                <DatamapsIndia
                  regionData={formatMapData(stateData[0])}
                  hoverComponent={({ value }) => {
                    return (
                      <div>
                        <>
                          {value.name}: {value?.value ?? 0} {valueSuffix}
                        </>
                      </div>
                    );
                  }}
                  mapLayout={{
                    // legendTitle: "Darker colour is higher",
                    startColor: "#e2e2fc",
                    endColor: "#005ce6",
                    hoverTitle: "Total",
                    noDataColor: "#fff",
                    borderColor: "#15607A",
                    hoverColor: "#15946C",
                    hoverBorderColor: "gray",
                    height: 10,
                    weight: 30,
                  }}
                  // mapLayout={{
                  //   startColor: "white",
                  //   endColor: "#005ce6",
                  //   hoverTitle: "Total",
                  //   noDataColor: "#13388C",
                  //   borderColor: "#15607A",
                  //   hoverColor: "#15946C",
                  //   hoverBorderColor: "gray",
                  // }}
                />
              </div>

              <div className="legend-container d-flex flex-wrap justify-content-start px-3 mt-2 pb-3">
                {Object.entries(stateData[0])
                  ?.filter(([state, value]) => value !== 0)
                  ?.sort(
                    ([stateA, valueA], [stateB, valueB]) => valueB - valueA
                  )
                  ?.map(([state, value], index) => (
                    <div
                      key={index}
                      className="legend-item d-flex align-items-center mx-2 mb-1 fs-7"
                      style={{ color: "#333" }}
                    >
                      <span
                        className="legend-icon rounded-circle mr-2"
                        style={{
                          backgroundColor: "#3B3EAC",
                          width: "6px",
                          height: "6px",
                          display: "inline-block",
                          marginRight: "6px",
                        }}
                      ></span>

                      <span className="legend-label">
                        {`${state} (${value}${valueSuffix})`}
                      </span>
                    </div>
                  ))}
              </div>
            </div>
          )}
        </Card.Body>
      </Card>
    </>
  );
}

export default IndiaMap;
