import React from "react";
import { Col, Row } from "react-bootstrap";
import Skeleton from "../../../../components/skeleton";
import SummeryMatrixSvgUI from "../../../../views/workspace/clirnet/sponsored-content/components/SummeryMatrixSvgUI";

const MatrixSkeleton = () => {
  return (
    <Row className="m-0">
      <Col
        md={4}
        sm={12}
        className="projectDetailsMatrix p-0 bg-white rounded-3"
      >
        <div className="h-100 matrixGradient p-3 d-flex align-items-center rounded-3 me-5 position-relative">
          <h4 className="fw-semibold text-white m-0 z-2">
            Project Details <br /> Overview
          </h4>
          <SummeryMatrixSvgUI />
        </div>
      </Col>

      <Col md={8} sm={12} className="p-2">
        <Row>
          <Col md={3}>
            <Skeleton variant="circular" height={120} width={120} />
          </Col>
          <Col md={3}>
            <Skeleton variant="circular" height={120} width={120} />
          </Col>
          <Col md={3}>
            <Skeleton variant="circular" height={120} width={120} />
          </Col>
          <Col md={3} className="avgDuration">
            <Skeleton variant="circular" height={120} width={120} />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default MatrixSkeleton;
