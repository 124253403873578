import React, { useEffect, useRef, useState } from "react";
import Titlebar from "../components/Titlebar";
import { Card, Col, Row } from "react-bootstrap";
import HeatMapsChart from "../../../../../components/chart/HeatMapsChart";
import TreeMapChart from "../../../../../components/chart/TreeMapChart";
import {
  AUDIENCE_INSIGHTS_TABLE_DATA,
  EVENTS_PILL_FILTERS,
  REGISTRATION_BY_DEVICE,
  REGISTRATION_BY_TIME_SLOT,
  REGISTRATION_BY_TIME_SLOT_POLAR,
  REGISTRATION_BY_ZONE,
  VIEWERSHIP_WISE_ATTENDEE_SEGMENTATION,
} from "../data/audience-insights-data";
import PolarChart from "../../../../../components/chart/PolarChart";
import { GRAPH_FILL_COLOR } from "../constant/graph-color-scheme";
import DonutChart from "../../../../../components/chart/DonutChart";
import PieChart from "../../../../../components/chart/PieChart";
import BasicColumnChart from "../../../../../components/chart/BasicColumnChart";
import ReactTable from "../../../../../lib/table/react-table";
import {
  useGetAllProjectEventsList,
  useGetDaywiseSlotDistribution,
  useGetDeviceDistribution,
  useGetParticipantList,
  useGetRegistrationBySlot,
  useGetSpecialityBreakup,
  useGetViewBySegment,
  useGetZonalDistribution,
} from "../../../../../queries/query-hooks/workspace/events-hook";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import BarChartSkeleton from "../../../../../skeleton/workspace/clirnet/brand-ads/bar-chart-skeleton";
import SquareChartSkeleton from "../../../../../skeleton/workspace/clirnet/brand-ads/square-chart-skeleton";
import {
  downloadTablePNG,
  transformGraphData,
  transformToPolarGraphData,
} from "../../../../../common/utils/utils";
import CommonTableLoader from "../../../../../skeleton/common-table-loader";
import CardNotFound from "../components/CardNotFound";
import { formatTimeForEvents } from "../utils/utils";
import { findIcon } from "../../../../dashboard/icons";
import useCsvDownloader from "../../../../../components/custom-hooks/use-csv-downloader";
import { useGetProjectDetails } from "../../../../../queries/query-hooks/workspace/workspace-hook";

const AUDIENCE_INSIGHT_TABLE_COLUMNS = [
  {
    columnId: 1,
    Header: "Name",
    accessor: "name",
    disableFilters: true,
    Cell: (value) => {
      if (!value.value) return "---";
      return value.value;
    },
  },
  {
    columnId: 2,
    Header: "Registration No.",
    accessor: "reg_id",
    disableFilters: true,
    Cell: (value) => {
      if (!value.value) return "---";
      return value.value;
    },
  },
  {
    columnId: 3,
    Header: "Registration Body",
    accessor: "reg_body",
    disableFilters: true,
    Cell: (value) => {
      if (!value.value) return "---";
      return value.value;
    },
  },
  {
    columnId: 4,
    Header: "State",
    accessor: "state",
    disableFilters: true,
    Cell: (value) => {
      if (!value.value) return "---";
      return value.value;
    },
  },
  {
    columnId: 5,
    Header: "Speciality",
    accessor: "speciality",
    disableFilters: true,
    Cell: (value) => {
      if (!value.value) return "---";
      return value.value;
    },
  },
  {
    columnId: 6,
    Header: "Attended",
    accessor: "attend_status",
    disableFilters: true,
    Cell: (value) => {
      if (!value.value) return "---";
      return value.value;
    },
  },
  {
    columnId: 7,
    Header: "Duration",
    accessor: "time",
    disableFilters: true,
    Cell: (value) => {
      if (!value.value) return "---";
      return value.value;
    },
  },
];

const AudienceInsights = () => {
  const { project_id } = useParams();
  const [filterBy, setFilterBy] = useState("Registrations");
  const [activeFilter, setActiveFilter] = useState({
    id: 0,
    index: 0,
  });
  const [eventFilterPayload, setEventFilterPayload] = useState({
    type: "all",
  });
  const participationTableRef = useRef(null);
  const { downloadCSV } = useCsvDownloader();

  useEffect(() => {
    if (activeFilter?.id === 0) {
      setEventFilterPayload({
        type: "all",
      });
    } else {
      setEventFilterPayload({
        event_id: activeFilter?.id,
      });
    }
  }, [activeFilter]);

  const {
    data: daywiseSlotDistribution,
    isLoading: isDaywiseSlotDistributionLoading,
  } = useGetDaywiseSlotDistribution(project_id, {
    ...eventFilterPayload,
    analysis_type: filterBy?.slice(0, -1)?.toLowerCase() || "registration", // participation || registration
  });

  const { data: deviceDistribution, isLoading: isDeviceDistributionLoading } =
    useGetDeviceDistribution(project_id, {
      ...eventFilterPayload,
      analysis_type: filterBy?.slice(0, -1)?.toLowerCase() || "registration", // participation || registration
    });

  const { data: registrationBySlot, isLoading: isRegistrationBySlotLoading } =
    useGetRegistrationBySlot(project_id, {
      ...eventFilterPayload,
      analysis_type: filterBy?.slice(0, -1)?.toLowerCase() || "registration", // participation || registration
    });

  const { data: viewBySegment, isLoading: isViewBySegmentLoading } =
    useGetViewBySegment(project_id, {
      ...eventFilterPayload,
      analysis_type: filterBy?.slice(0, -1)?.toLowerCase() || "registration", // participation || registration
    });

  const { data: participantListData, isLoading: isParticipantListLoading } =
    useGetParticipantList(project_id, {
      type: "all",
      pagination: {
        limit: 5,
        offset: 0,
      },
    });

  const { data: zonalDistribution, isLoading: isZonalDistributionLoading } =
    useGetZonalDistribution(project_id, {
      ...eventFilterPayload,
      analysis_type: filterBy?.slice(0, -1)?.toLowerCase() || "registration", // participation || registration
    });

  const { data: projectDetails, isLoading: isProjectDetailsLoading } =
    useGetProjectDetails(project_id);

  const { data: allProjectList, isLoading: isAllProjectListLoading } =
    useGetAllProjectEventsList(project_id);

  const { data: specialityBreakup, isLoading: isSpecialityBreakupLoading } =
    useGetSpecialityBreakup(project_id, {
      ...eventFilterPayload,
      analysis_type: filterBy?.slice(0, -1)?.toLowerCase() || "registration", // participation || registration
    });

  const deviceDistributionChartOptions = {
    series:
      deviceDistribution?.length > 0
        ? transformGraphData(deviceDistribution, "percentage", "level")?.series
        : [],
    labels:
      deviceDistribution?.length > 0
        ? transformGraphData(deviceDistribution, "percentage", "level")?.label
        : [],
  };

  const userSpecialtyChartOptions = {
    series:
      specialityBreakup && specialityBreakup?.length > 0
        ? specialityBreakup?.map((item) => item?.percentage ?? 0)
        : [],
    labels:
      specialityBreakup && specialityBreakup?.length > 0
        ? specialityBreakup?.map((item) => item?.speciality_name ?? "N/A")
        : [],
  };

  const viewBySegmentChartOptions = {
    colors: GRAPH_FILL_COLOR,
    series: [
      {
        name: "View Count",
        data:
          viewBySegment?.length > 0
            ? transformGraphData(viewBySegment, "count", "count")?.series
            : [],
        color: "#15946C",
      },
    ],

    labels:
      viewBySegment?.length > 0
        ? transformGraphData(
            viewBySegment,
            "time_slot_interval",
            "time_slot_interval"
          )?.label
        : [],
  };

  function daywiseSlotDistributionGraphChart(value) {
    if (!value) return {};
    return {
      series: value.map((entry) => ({
        name: dayjs(entry.date).format("MMM DD, YYYY"),
        data: [
          { x: "Morning", y: entry.Morning },
          { x: "Noon", y: entry.Noon },
          { x: "After_noon", y: entry.After_noon },
          { x: "Evening", y: entry.Evening },
          { x: "Night", y: entry.Night },
        ],
      })),
    };
  }

  function formatZonalDistibutionData(inputArray = []) {
    if (!Array.isArray(inputArray)) return [];
    return inputArray.map(({ level, percentage }) => ({
      x: level || "Other",
      y: percentage,
    }));
  }

  const showDoctorRegistrationNo =
    projectDetails
      ?.at(0)
      ?.project_attributes?.find(
        (item) => item.attribute_name === "doctor_registration"
      )?.attribute_value === "0"
      ? false
      : true;

  const participationTableCsvFormat = {
    header: [
      "Name",
      "Registration No.",
      "Registration Body",
      "State",
      "Speciality",
      "Attended",
      "Duration",
    ].filter((item) => showDoctorRegistrationNo || item !== "Registration No."),
    column: [
      "name",
      "reg_id",
      "reg_body",
      "state",
      "speciality",
      "attend_status",
      "time",
    ].filter((item) => showDoctorRegistrationNo || item !== "reg_id"),
    rows: participantListData,
  };

  const handleTableCsvDownload = () => {
    downloadCSV(participationTableCsvFormat);
  };

  const updateFilterHandler = (value) => {
    setFilterBy(value);
  };

  const zonalDistributionChartOptions =
    formatZonalDistibutionData(zonalDistribution);

  const dayWiseSlotDistributionGraphOptions = daywiseSlotDistributionGraphChart(
    daywiseSlotDistribution
  );
  const slotRegistrationChartOptions =
    transformToPolarGraphData(registrationBySlot);

  return (
    <div>
      <div className="mb-3 mb-md-0">
        <Titlebar
          title={"Audience Insights"}
          projectType={"Events"}
          filterType={"all"}
          isLoading={isAllProjectListLoading}
          eventsFilters={allProjectList}
          filterBy={filterBy}
          updateFilterHandler={updateFilterHandler}
          activeFilter={activeFilter}
          setActiveFilter={setActiveFilter}
        />
      </div>

      <div className="d-flex flex-column gap-4">
        <Row>
          <Col md={6} sm={12} className="mb-4 mb-md-0">
            {!isDaywiseSlotDistributionLoading &&
            daywiseSlotDistribution?.length > 0 ? (
              <HeatMapsChart
                title={`${filterBy} by Time Slot`}
                series={dayWiseSlotDistributionGraphOptions?.series}
              />
            ) : isDaywiseSlotDistributionLoading ? (
              <SquareChartSkeleton height={320} />
            ) : (
              <CardNotFound
                title={`${filterBy} by Time Slot`}
                isTitleCenter={true}
                height={360}
              />
            )}
          </Col>
          <Col md={6} sm={12}>
            {!isZonalDistributionLoading && zonalDistribution?.length > 0 ? (
              <TreeMapChart
                title={`${filterBy} by Zone`}
                series={zonalDistributionChartOptions}
              />
            ) : isZonalDistributionLoading ? (
              <SquareChartSkeleton height={320} />
            ) : (
              <CardNotFound
                title={`${filterBy} by Zone`}
                isTitleCenter={true}
                height={360}
              />
            )}
          </Col>
        </Row>

        <Row>
          <Col md={4} sm={12} className="mb-4 mb-md-0">
            {!isRegistrationBySlotLoading &&
            Object.keys(registrationBySlot)?.length > 0 &&
            Object.values(registrationBySlot).filter((item) => item !== 0)
              ?.length > 0 ? (
              <PolarChart
                title={`${filterBy} by Time Slot`}
                graphColor={GRAPH_FILL_COLOR}
                labels={slotRegistrationChartOptions.labels}
                series={slotRegistrationChartOptions.series}
              />
            ) : isRegistrationBySlotLoading ? (
              <SquareChartSkeleton height={320} />
            ) : (
              <CardNotFound
                title={`${filterBy} by Time Slot`}
                isTitleCenter={true}
                height={360}
              />
            )}
          </Col>
          <Col md={4} sm={12} className="mb-4 mb-md-0">
            {!isDeviceDistributionLoading &&
            deviceDistribution?.length > 0 &&
            deviceDistribution?.filter((item) => item?.percentage !== 0)
              ?.length > 0 ? (
              <DonutChart
                title={`${filterBy} by Device`}
                graphColor={GRAPH_FILL_COLOR}
                labels={deviceDistributionChartOptions.labels}
                series={deviceDistributionChartOptions.series}
              />
            ) : isDeviceDistributionLoading ? (
              <SquareChartSkeleton height={320} />
            ) : (
              <CardNotFound
                title={`${filterBy} by Device`}
                isTitleCenter={true}
                height={360}
              />
            )}
          </Col>
          <Col md={4} sm={12}>
            {!isSpecialityBreakupLoading && specialityBreakup?.length > 0 ? (
              <PieChart
                title={"User Specialty Breakup"}
                graphColor={GRAPH_FILL_COLOR}
                labels={userSpecialtyChartOptions.labels}
                series={userSpecialtyChartOptions.series}
              />
            ) : isSpecialityBreakupLoading ? (
              <SquareChartSkeleton height={320} />
            ) : (
              <PieChart
                title={"User Specialty Breakup"}
                graphColor={GRAPH_FILL_COLOR}
                labels={["General Medicine"]}
                series={[100]}
              />
            )}
          </Col>
        </Row>

        <Row>
          <Col sm={12}>
            {!isViewBySegmentLoading &&
            viewBySegment?.length > 0 &&
            viewBySegment.filter((item) => item.count !== 0)?.length > 0 ? (
              <BasicColumnChart
                title={"Viewership wise attendee Segmentation"}
                labels={formatTimeForEvents(
                  viewBySegmentChartOptions.labels || []
                )}
                series={viewBySegmentChartOptions.series}
                showYAxis={false}
                exportCSVHeading={"Time,"}
              />
            ) : isViewBySegmentLoading ? (
              <BarChartSkeleton />
            ) : (
              <CardNotFound
                title={"Viewership wise attendee Segmentation"}
                height={360}
              />
            )}
          </Col>
        </Row>

        <Row>
          <Col sm={12}>
            <div className="d-flex">
              <h5 className="m-0 text-primary border-bottom border-primary border-2 w-auto px-3 py-2 ms-4">
                Participants Table
              </h5>
            </div>
            <Card className="rounded-3">
              <Card.Header>
                <div className="d-flex flex-row justify-content-end gap-3 flex-shrink-0">
                  <div
                    className="text-success cursor-pointer"
                    onClick={() => downloadTablePNG(participationTableRef)}
                  >
                    {findIcon("DownloadReport", "dual-tone", "18")}
                    <span className="text-success fs-7 px-2 d-none d-md-inline-block fw-semibold">
                      Download Report
                    </span>
                  </div>
                  <div
                    className="text-primary cursor-pointer"
                    onClick={handleTableCsvDownload}
                  >
                    {findIcon("ExportReport", "dual-tone", "20")}
                    <span className="text-primary fs-7 px-2 d-none d-md-inline-block fw-semibold">
                      Export
                    </span>
                  </div>
                </div>
              </Card.Header>
              <Card.Body>
                <div ref={participationTableRef}>
                  {!isParticipantListLoading && !isProjectDetailsLoading ? (
                    <ReactTable
                      data={
                        Array.isArray(participantListData)
                          ? participantListData
                          : []
                      }
                      columns={AUDIENCE_INSIGHT_TABLE_COLUMNS?.filter(
                        (item) =>
                          showDoctorRegistrationNo || item.accessor !== "reg_id"
                      )}
                      isPaginationShow={false}
                      bordered
                      noDataText="No Data Available"
                    />
                  ) : (
                    <CommonTableLoader
                      tableRowLength={5}
                      hasSearch={false}
                      hasActions={false}
                    />
                  )}
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default AudienceInsights;
