import { useEffect, useMemo, useState } from "react";
import { Card, Col, Container, Form, Row } from "react-bootstrap";
import useBackdrop from "../../../../../components/custom-hooks/use-backdrop";
import {
  useCreateProject,
  useEditProjectDetails,
  useGetStateList,
} from "../../../../../queries/query-hooks/workspace/workspace-hook";
import PageNotFound from "../../../../PageNotFound";
import { useParams } from "react-router-dom";
import BrandAdsForm from "../components/BrandAdsForm";
import SponsoredContentForm from "../components/SponsoredContentForm";
import EventsForm from "../components/EventsForm";
import { STATE_LIST_PAYLOAD } from "../utils/constants";

function CreateProject({ project_group_id }) {
  const { data } = useCreateProject();
  const [projectType, setProjectType] = useState("");
  const { project_id } = useParams();
  const { showBackdrop, hideBackdrop } = useBackdrop();

  const { data: projectDetails, isLoading: isProjectDetailsLoading } =
    useEditProjectDetails(project_id);
  const { data: stateList, isLoading: isStateListLoading } =
    useGetStateList(STATE_LIST_PAYLOAD);

  useEffect(() => {
    if (
      projectDetails &&
      projectDetails?.project_data &&
      projectDetails?.project_data.length > 0 &&
      "project_type_id" in projectDetails?.project_data[0]
    ) {
      setProjectType(projectDetails?.project_data[0].project_type_id);
    }
    if (project_id && isProjectDetailsLoading) {
      showBackdrop();
    } else {
      hideBackdrop();
    }
  }, [projectDetails]);

  const masterSpecialities = data?.master_specialities || [];

  const stateLists = useMemo(() => {
    return (
      stateList?.rows?.map((item) => ({
        [item?.state_name]: item?.state_name,
      })) || []
    );
  }, [stateList]);

  return (
    <Container>
      <Row>
        <Col className="p-0 p-md-3 pt-md-0">
          <h4 className="mb-4">Create a New Project</h4>
          <Card>
            <Card.Body>
              <Form.Group className="mb-3">
                <Form.Label controlId="projectType">
                  {" "}
                  Select Project type{" "}
                </Form.Label>
                <Form.Select
                  id="projectType"
                  value={projectType}
                  onChange={(e) => {
                    setProjectType(e.target.value);
                  }}
                  disabled={!!project_id}
                >
                  <option>Select Project type</option>
                  {data &&
                    data?.project_type_masters &&
                    Array.isArray(data?.project_type_masters) &&
                    data?.project_type_masters.length > 0 &&
                    data?.project_type_masters.map((item) => (
                      <option key={item.id} value={item?.id}>
                        {item.project_type_name}
                      </option>
                    ))}
                </Form.Select>
              </Form.Group>
              {projectType != "" && (
                <RenderForm
                  projectType={
                    projectType != ""
                      ? data?.project_type_masters.filter(
                          (_item) => _item?.id == projectType
                        )[0]
                      : {}
                  }
                  project_group_id={project_group_id}
                  projectDetails={projectDetails?.project_data}
                  channelData={projectDetails?.channel_data}
                  masterSpecialities={masterSpecialities}
                  stateLists={stateLists}
                />
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

function RenderForm({
  projectType,
  project_group_id,
  projectDetails,
  channelData = [],
  masterSpecialities = [],
  stateLists = [],
}) {
  const project_type_code = projectType?.project_type_code || "";
  const project_type_id = projectType?.id;

  switch (project_type_code) {
    case "brand_ads":
      return (
        <BrandAdsForm
          project_group_id={project_group_id}
          project_type_id={project_type_id}
          project_type_code={project_type_code}
          projectDetails={projectDetails}
          channelData={channelData}
          masterSpecialities={masterSpecialities}
          stateLists={stateLists}
        />
      );
    case "sponsored_content":
      return (
        <SponsoredContentForm
          project_group_id={project_group_id}
          project_type_id={project_type_id}
          project_type_code={project_type_code}
          projectDetails={projectDetails}
          channelData={channelData}
          masterSpecialities={masterSpecialities}
          stateLists={stateLists}
        />
      );
    // case "events":
    //   return (
    //     <EventsForm
    //       project_group_id={project_group_id}
    //       project_type_id={project_type_id}
    //       project_type_code={project_type_code}
    //       projectDetails={projectDetails}
    //       channelData={channelData}
    //     />
    //   );
    default:
      return (
        <PageNotFound title="Currently,we are unable to handle this request" />
      );
  }
}

export default CreateProject;
