import { useQuery } from "@tanstack/react-query";
import axiosInstance from "../../../api/instances/axiosInstance";
import {
  CAMPAIGN_LIST,
  CAMPAIGN_LIST_BY_CHANNEL,
  CREATE_PROJECT_KEY,
  EDIT_PROJECT_KEY,
  GET_ALL_COMMENT_LIST,
  GET_BANNER_GROUP_KEY,
  GET_CAMPAIGN_ANALYTICS,
  GET_CHANNEL_ALL_VIDEO_LIST,
  GET_CHANNEL_ANALYTICS,
  GET_CHANNEL_DETAILS_KEY,
  GET_CHANNEL_LIST,
  GET_CHANNEL_STATE_ANALYTICS,
  GET_CHANNEL_VIDEO_LIST,
  GET_CONTENT_CATEGORY_LIST_KEY,
  GET_PROJECT_DETAILS_KEY,
  GET_PROJECT_LIST,
  GET_SEARCH_LIST,
  GET_TARGET_CATEGORIES,
  GET_TARGET_REGION,
  GET_VIDEO_ANALYTICS,
  POST_PUBLISH_COMMENT,
  POST_STATE_LIST_KEY,
} from "../../query-constants/workspace/workspace-constant";
import {
  CREATE_PROJECT,
  EDIT_PROJECT,
  GET_ALL_CHANNEL_LIST,
  GET_ALL_CHANNEL_VIDEOS_LIST,
  GET_ALL_PROJECT_LIST,
  GET_ALL_VIDEO_COMMENTS_LIST,
  GET_BANNER_GROUP,
  GET_CAMPAIGN_ANALYTICS_DATA,
  GET_CAMPAIGN_lIST,
  GET_CAMPAIGN_lIST_BY_CHANNEL,
  GET_CHANNEL_ANALYTICS_DATA,
  GET_CHANNEL_DETAILS,
  GET_CHANNEL_STATE_ANALYTICS_DATA,
  GET_CHANNEL_VIDEO_ANALYTICS,
  GET_CONTENT_CATEGORY_LIST,
  GET_CONTENT_SEARCH_GROUP_LIST,
  GET_PROJECT_DETAILS,
  GET_TARGET_CATEGORIES_DATA,
  GET_TARGET_REGION_DATA,
  POST_COMMENTS_ACTION,
  POST_COMMENT_ACTION,
  POST_STATE_LIST,
} from "../../../api/endpoints/channel-endpoint";
import { useSelector } from "react-redux";
import * as dayjs from "dayjs";

const staleTime = 300000;
const cacheTime = 600000;

export const useGetChannelDetails = (id) => {
  return useQuery(
    [GET_CHANNEL_DETAILS_KEY, id],
    () => axiosInstance.get(`${GET_CHANNEL_DETAILS}/${id}`),
    {
      refetchOnWindowFocus: false,
      cacheTime,
      staleTime,
      select: (res) => res?.data?.data ?? {},
    }
  );
};

export const useGetProjectDetails = (id) => {
  return useQuery(
    [GET_PROJECT_DETAILS_KEY, id],
    () => axiosInstance.get(`${GET_PROJECT_DETAILS}/${id}`),
    {
      refetchOnWindowFocus: false,
      cacheTime,
      staleTime,
      select: (res) => res?.data?.data ?? {},
    }
  );
};

export const useEditProjectDetails = (id) => {
  return useQuery(
    [EDIT_PROJECT_KEY, id],
    () => axiosInstance.get(`${EDIT_PROJECT}/${id}`),
    {
      refetchOnWindowFocus: false,
      cacheTime: 500,
      // staleTime,
      select: (res) => res?.data?.data ?? {},
      enabled: !!id,
    }
  );
};

export const useAllGetCommentList = (payload) => {
  return useQuery(
    [GET_ALL_COMMENT_LIST, JSON.stringify(payload)],
    () => axiosInstance.post(`${GET_ALL_VIDEO_COMMENTS_LIST}`, payload),
    {
      refetchOnWindowFocus: false,
      cacheTime,
      staleTime,
      select: (res) => res?.data?.data ?? {},
    }
  );
};

export const useGetChannelList = (offset, limit, brand_id, project_id) => {
  return useQuery(
    [GET_CHANNEL_LIST, offset, limit, brand_id, project_id],
    () =>
      axiosInstance.get(
        `${GET_ALL_CHANNEL_LIST}/${offset}/${limit}/${brand_id}/${project_id}`
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime,
      // staleTime,
      // enabled,
      select: (res) => res?.data ?? {},
    }
  );
};

export const useGetProjectList = (type) => {
  return useQuery(
    [GET_PROJECT_LIST, type],
    () => axiosInstance.get(`${GET_ALL_PROJECT_LIST}/${type}`),
    {
      refetchOnWindowFocus: false,
      cacheTime,
      select: (res) => res?.data ?? {},
    }
  );
};

export const useGetAllVideoList = (payload) => {
  return useQuery(
    [GET_CHANNEL_VIDEO_LIST, JSON.stringify(payload)],
    () => axiosInstance.post(`${GET_ALL_CHANNEL_VIDEOS_LIST}`, payload),
    {
      refetchOnWindowFocus: false,
      cacheTime,
      // staleTime,
      select: (res) => {
        let responseData = res?.data?.data?.rows?.filter(
          (_) => !!_.content_title
        );
        return {
          total: res?.data?.data?.totalCount,
          data: responseData?.map((content, index) => {
            return { ...content, index: index + 1 };
          }),
        };
      },
    }
  );
};
export const useGetChannelAllVideoList = (payload) => {
  return useQuery(
    [GET_CHANNEL_ALL_VIDEO_LIST, JSON.stringify(payload)],
    () => axiosInstance.post(`${GET_ALL_CHANNEL_VIDEOS_LIST}`, payload),
    {
      refetchOnWindowFocus: false,
      cacheTime,
      // staleTime,
      select: (res) => {
        return res?.data?.data ?? "";
      },
    }
  );
};

export const useSearchVideoList = (searchText, payload) => {
  return useQuery(
    [GET_SEARCH_LIST, searchText, payload.channel_id],
    () => axiosInstance.post(`${GET_ALL_CHANNEL_VIDEOS_LIST}`, payload),
    {
      refetchOnWindowFocus: false,
      cacheTime,
      staleTime,
      select: (res) => res?.data,
    }
  );
};

export const useGetVideoAnalytics = (payload) => {
  return useQuery(
    [GET_VIDEO_ANALYTICS, JSON.stringify(payload)],
    () => axiosInstance.post(`${GET_CHANNEL_VIDEO_ANALYTICS}`, payload),
    {
      refetchOnWindowFocus: false,
      cacheTime,
      staleTime,
      select: (res) => res?.data?.data ?? {},
    }
  );
};
export const useGetCampaignAnalytics = (payload) => {
  return useQuery(
    [GET_CAMPAIGN_ANALYTICS, JSON.stringify(payload)],
    () => axiosInstance.post(`${GET_CAMPAIGN_ANALYTICS_DATA}`, payload),
    {
      refetchOnWindowFocus: false,
      cacheTime,
      staleTime,
      select: (res) => res?.data?.data ?? {},
    }
  );
};
export const useGetChannelAnalytics = (payload) => {
  return useQuery(
    [GET_CHANNEL_ANALYTICS, JSON.stringify(payload)],
    () => axiosInstance.post(`${GET_CHANNEL_ANALYTICS_DATA}`, payload),
    {
      refetchOnWindowFocus: false,
      cacheTime,
      staleTime,
      select: (res) => res?.data?.data ?? {},
    }
  );
};
export const usePostVideoComment = (payload, enabled) => {
  return useQuery(
    [POST_PUBLISH_COMMENT, payload.comment_id, payload.video_id],
    () => axiosInstance.post(`${POST_COMMENT_ACTION}`, payload),
    {
      refetchOnWindowFocus: false,
      cacheTime,
      staleTime,
      enabled,
      select: (res) => res?.data?.data ?? {},
    }
  );
};
export const useGetCampaignList = (payload, enabled) => {
  return useQuery(
    [CAMPAIGN_LIST, payload.comment_id, payload.video_id],
    () => axiosInstance.post(`${GET_CAMPAIGN_lIST}`, payload),
    {
      refetchOnWindowFocus: false,
      cacheTime,
      // staleTime,
      enabled,
      select: (res) => res?.data?.data ?? {},
    }
  );
};
export const useGetCampaignListByChannel = (payload, enabled) => {
  return useQuery(
    [CAMPAIGN_LIST_BY_CHANNEL, JSON.stringify(payload)],
    () => axiosInstance.post(`${GET_CAMPAIGN_lIST_BY_CHANNEL}`, payload),
    {
      refetchOnWindowFocus: false,
      cacheTime,
      // staleTime,
      enabled,
      select: (res) => res?.data?.data ?? {},
    }
  );
};

export const useGetChannelStateAnalytics = (payload) => {
  return useQuery(
    [GET_CHANNEL_STATE_ANALYTICS, payload.channel_id],
    () => axiosInstance.post(`${GET_CHANNEL_STATE_ANALYTICS_DATA}`, payload), ///${id}
    {
      refetchOnWindowFocus: false,
      cacheTime,
      staleTime,
      select: (res) => res?.data?.data ?? {},
    }
  );
};

export const useGetTargetRegion = () => {
  return useQuery(
    [GET_TARGET_REGION],
    () => axiosInstance.get(`${GET_TARGET_REGION_DATA}`), ///${id}
    {
      refetchOnWindowFocus: false,
      cacheTime,
      staleTime,
      select: (res) => res?.data?.data ?? {},
    }
  );
};

export const useGetTargetCategories = () => {
  return useQuery(
    [GET_TARGET_CATEGORIES],
    () => axiosInstance.get(`${GET_TARGET_CATEGORIES_DATA}`), ///${id}
    {
      refetchOnWindowFocus: false,
      cacheTime,
      staleTime,
      select: (res) => res?.data?.data ?? {},
    }
  );
};

export const useCreateProject = () => {
  return useQuery(
    [CREATE_PROJECT_KEY],
    () => axiosInstance.get(`${CREATE_PROJECT}`),
    {
      refetchOnWindowFocus: false,
      cacheTime,
      staleTime,
      select: (res) => res?.data?.data ?? {},
    }
  );
};

export const useGetSearchData = (payload, projectTypeCode = "") => {
  function getSearchEndPoint() {
    switch (projectTypeCode) {
      case "brand_ads":
        return GET_BANNER_GROUP;
      case "sponsored_content":
        return GET_CONTENT_SEARCH_GROUP_LIST;
      default:
        return "";
    }
  }
  return useQuery(
    [GET_BANNER_GROUP_KEY, JSON.stringify(payload), projectTypeCode],
    () => axiosInstance.post(`${getSearchEndPoint()}`, payload),
    {
      refetchOnWindowFocus: false,
      cacheTime,
      staleTime,
      select: (res) => {
        switch (projectTypeCode) {
          case "brand_ads":
            return res?.data?.data ?? [];
          case "sponsored_content":
            return res?.data?.data[0].content_data ?? [];
          default:
            return [];
        }
      },
      enabled: !!payload?.search_key && payload?.search_key.length > 3,
      // initialData: [
      //   {
      //     title:
      //       "2023 ESICM guideline recommendations on PEEP and recruitment manoeuvres in patients with acute respiratory distress syndrome\n",
      //     id: 1621,
      //     content_category_id: 37,
      //   },
      // ],
    }
  );
};

export const useGetContentCategoryList = () => {
  return useQuery(
    [GET_CONTENT_CATEGORY_LIST_KEY],
    () => axiosInstance.get(`${GET_CONTENT_CATEGORY_LIST}`),
    {
      refetchOnWindowFocus: false,
      cacheTime,
      staleTime,
      select: (res) => res?.data?.data ?? {},
    }
  );
};

export const useGetStateList = (payload) => {
  return useQuery(
    [POST_STATE_LIST_KEY],
    () => axiosInstance.post(`${POST_STATE_LIST}`, payload),
    {
      refetchOnWindowFocus: false,
      cacheTime,
      staleTime,
      select: (res) => res?.data?.data ?? [],
    }
  );
};
