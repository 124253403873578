import React, { useEffect, useState } from "react";
import Badge from "react-bootstrap/Badge";
import HeaderPillFilters from "./HeaderPillFilters";
import DashboardDropdown from "../../../../../components/DashboardDropdown";
import { EVENTS_DROPDOWN_FILTERS } from "../data/audience-insights-data";
import Skeleton from "../../../../../components/skeleton";
import dayjs from "dayjs";

function Titlebar({
  title = "",
  projectType = "",
  projectStartDate = "",
  isLoading = false,
  filterType = "none",
  eventsFilters = {},
  filterBy = "Registrations",
  updateFilterHandler = () => {},
  activeFilter = {
    id: 0,
    index: 0,
  },
  setActiveFilter = () => {},
}) {
  const [updatedAllProjectList, setUpdatedAllProjectList] = useState([]);

  useEffect(() => {
    if (eventsFilters?.event_list?.length > 0) {
      const updatedAllProjectListValue = [
        { event_name: "All", event_id: 0 },
        ...eventsFilters?.event_list,
      ];

      setUpdatedAllProjectList(updatedAllProjectListValue);
    }
  }, [eventsFilters]);

  return (
    <div className="header-title mb-0 mb-md-4 d-flex flex-column flex-md-row justify-content-between align-items-md-center">
      <div className="d-flex flex-row align-items-center justify-content-md-center mb-3 mb-md-0">
        <h4 className="fw-semibold mb-0 mb-md-0 events-title">{title}</h4>
        <Badge
          pill
          bg="primary"
          className="ms-2 me-2 px-2 py-1 fs-7 rounded-pill"
        >
          {projectType}
        </Badge>
        {!isLoading ? (
          <Badge
            pill
            bg="success light text-success"
            className="ms-2 fs-7 bg-opacity-10 px-2"
          >
            Start Date:{" "}
            {title === "Summary"
              ? projectStartDate
              : eventsFilters?.project_details?.project_start_date
              ? dayjs(eventsFilters?.project_details?.project_start_date).format("DD-MM-YYYY")
              : "N/A"}
          </Badge>
        ) : (
          <Skeleton width={150} height={30} className="rounded-3" />
        )}
      </div>

      <div className="d-flex flex-column align-items-start flex-md-row align-items-md-center gap-2">
        {(filterType === "all" || filterType === "events") &&
          (!isLoading ? (
            <HeaderPillFilters
              titles={updatedAllProjectList}
              activeFilter={activeFilter}
              setActiveFilter={setActiveFilter}
            />
          ) : (
            <div className="d-flex gap-3">
              <Skeleton
                height={35}
                width={40}
                className={"m-0 p-0 rounded-pill"}
              />
              <Skeleton
                height={35}
                width={80}
                className={"m-0 p-0 rounded-pill"}
              />
              <Skeleton
                height={35}
                width={80}
                className={"m-0 p-0 rounded-pill"}
              />
            </div>
          ))}

        {filterType === "all" && (
          <div className="d-flex gap-3 align-items-center justify-content-end">
            <div className="fs-6 text-black">Filter By</div>
            <div className="dropdown bg-white border border-1 rounded-3 px-3 py-2">
              <DashboardDropdown
                constantFilterOption={EVENTS_DROPDOWN_FILTERS}
                handleDropdownChange={updateFilterHandler}
                type={filterBy}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Titlebar;
