import { useQuery } from "@tanstack/react-query";
import axiosInstance from "../../api/instances/axiosInstance";
import { GET_SUPPORT_MY_LIST } from "../../api/endpoints/help-and-support-endpoints";
import { KEY_GET_SUPPORT_LIST } from "../query-constants/help-and-support-constants";

const staleTime = 0;
const cacheTime = 600000;

export const useGetSupportList = (payload, enabled = true) => {
  return useQuery(
    [KEY_GET_SUPPORT_LIST, payload],
    () => axiosInstance.post(GET_SUPPORT_MY_LIST, JSON.stringify(payload)),
    {
      enabled: enabled,
      refetchOnWindowFocus: false,
      cacheTime,
      staleTime,

      select: (res) => res?.data?.data ?? {},
    }
  );
};
