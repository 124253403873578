import { useFormik } from "formik";
import { useEffect, useMemo, useRef, useState } from "react";
import {
  Button,
  Col,
  Collapse,
  FloatingLabel,
  Form,
  Row,
  Spinner,
} from "react-bootstrap";
import Flatpickr from "react-flatpickr";
import { useParams } from "react-router-dom";
import Select from "react-select";
import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import * as Yup from "yup";
import Card from "../../../../../../components/bootstrap/card";
import ChoicesJs from "../../../../../../components/choices";
import {
  useGetVideoDetails,
  useGetVideoEdit,
} from "../../../../../../queries/query-hooks/workspace/channel-video-hook";

import MultiselectComponent from "../../../../../../components/form-components/multi-select";
import ThumbnailSelector from "../components/video-details/ThumbnailSelector";
import VideoDescriptionCard from "../components/video-details/VideoDescriptionCard";
import VideoEncodingDetails from "../components/video-details/VideoEncodingDetails";
import { CHANNEL_VIDEO_UPDATE } from "../../../../../../api/endpoints/channel-video-endpoints";
import toast from "react-hot-toast";
import postService from "../../../../../../api/services/post-service";
import useRedirect from "../../../../../../components/custom-hooks/use-redirect";
import VideoDetailsSkeleton from "../../../../../../skeleton/workspace/doctube/channel/video-details-skeleton";
import Skeleton from "../../../../../../components/skeleton";
import uploadService from "../../../../../../api/services/upload-service";
import { FILE_UPLOAD } from "../../../../../../api/endpoints/requisition-endpoints";
import { useSelector } from "react-redux";
import { currentChannel } from "../../../../../../store/channel/channel.selector";
import axiosInstance from "../../../../../../api/instances/axiosInstance";
import { getService } from "../../../../../../api/services/get-services";
import useBackdrop from "../../../../../../components/custom-hooks/use-backdrop";
import parse from "html-react-parser";
import VideoUploadBox from "../components/channel-file-upload/video-upload-box";
import { findIcon } from "../../../../../dashboard/icons";
import app_config from "../../../../../../common/data/app_config";
import dayjs from "dayjs";

const IMAGE_BASE_URL = app_config.upload_file_destination;
const DOCTUBE_BASE_URL = app_config.doctube_url;
function ChannelVideoDetails() {
  const { redirectTo } = useRedirect();
  const [encodingDetails, setEncodingDetails] = useState({});
  const { channel_id = "1" } = useSelector(currentChannel);
  const { showBackdrop, hideBackdrop } = useBackdrop();
  const [videoDuration, setVideoDuration] = useState(0);
  const attributeOptionsLoaded = useRef(false);
  const [publishingStatus, setPublishingStatus] = useState("1");
  const [enableSchedule, setEnableSchedule] = useState(false);
  const [mlStatus, setMlStatus] = useState("");
  const [thumbArray, setThumbArray] = useState([]);
  const [current_attribute_loading, setCurrentAttributeLoading] =
    useState(false);
  const submitValues = async (values, image) => {
    let now = dayjs(new Date()); //todays date
    let end = dayjs(values.schedulePublishDateTime); // another date
    let dateDifference = now.diff(end, "s");

    console.log("submit button", image, thumbnail);
    console.log("values", values);
    console.log("vvv", image ? "if" : "else");
    // return false;
    try {
      let payloadObj = {
        content_id: videoDetails?.content_id,
        content_file_type_id: "3",
        file_type_code: "video",
        content_parent_id: channel_id,
        content_title: values?.title,
        content_description: values.description,
        content_category_id: videoDetails?.content_category_id,
        content_category_code:
          videoDetails?.content_category_code || "doctube_video",
        content_brand_id: content_brand_id[0],
        content_privacy_status: dateDifference < 0 ? "3" : "0", // if feature date 3 else 0
        content_status: "1",
        content_publishing_status:
          publishingStatus == "3" ? (values.isPublishing ? "3" : "1") : "3",
        additional_attributes: {},
        content_license_id: values.license,
        content_featured_status: "0",
        content_publication_date: values.schedulePublishDateTime,
        content_expiration_date: values.scheduleExpiryDateTime,
        video_extension: videoExtension,
        video_duration: videoDuration
          ? videoDuration
          : encodingDetails?.duration
          ? encodingDetails?.duration
          : 0,
        content_to_av: {
          ...videoDetails?.content_to_av,
          main: videoDetails?.content_to_av?.storage_link,
        },
        // content_publishing_status: "0",
        content_language_id: languages?.find(
          (lang) => lang?.lang_name === values.language
        )?.id,
        content_to_images: image
          ? {
              base: image?.replace(IMAGE_BASE_URL, ""),
              thumbnail: image?.replace(IMAGE_BASE_URL, ""),
            }
          : {
              base: thumbnail?.replace(IMAGE_BASE_URL, ""),
              thumbnail: thumbnail?.replace(IMAGE_BASE_URL, ""),
            },
        content_to_attributes: {
          additional_attributes: "",
          encoding_details: JSON.stringify(encodingDetails),
          video_language: values.language,
          enable_transcription: values.enableTranscription,
          transcription_language: values.transcriptionLanguage,
          video_category: values.videoCategory,
          video_sub_category: values.videoSubCategory,
          schedule_publish_date_time: values.schedulePublishDateTime,
          schedule_expiry_date_time: values.scheduleExpiryDateTime,
          license: values.license,
          allow_embedding: values.allowEmbedding,
          allow_notify: values.allowNotify,
          comments: values.comments,
          video_extension: videoExtension,
          video_duration: videoDuration
            ? videoDuration
            : encodingDetails?.duration
            ? encodingDetails?.duration
            : 0,
        },
        video_language: values.language,
      };
      attributeOptions?.forEach((_attr) => {
        if (_attr?.attribute_id && _attr?.selected?.length > 0) {
          payloadObj["additional_attributes"][
            "attribute_" + _attr?.attribute_id
          ] = _attr?.selected?.map((i) => i?.value).join(",");
        }
      });

      if (Object.keys(payloadObj.additional_attributes).length > 0) {
        payloadObj.content_to_attributes.additional_attributes = JSON.stringify(
          payloadObj.additional_attributes
        );
      } else {
        hideBackdrop();
        toast.error("please select atleast one tag");
        return false;
      }

      let response = await postService(CHANNEL_VIDEO_UPDATE, payloadObj);
      if (response.isError) {
        hideBackdrop();
        toast.error(response.error);
      } else {
        hideBackdrop();
        toast.success("Video uploaded successfully");
        redirectTo("channel/videos/" + channel_id);
      }
    } catch (error) {
      hideBackdrop();
    }
  };
  function uploadFiles(files) {
    return new Promise(async (resolve, reject) => {
      // Check if files array is empty
      if (files.length === 0) {
        reject("No files to upload.");
        return;
      }
      //console.log("uploading files", files);
      let formdata = new FormData();
      //console.log("FORMDATA", files);
      files.forEach((file) => {
        formdata.append("files[]", file);
      });

      // 1. Upload files to server
      let response = await uploadService(FILE_UPLOAD, formdata);
      if (response.data?.success) {
        // 2. Simulating successful upload and generating URLs
        const urls = response.data.data.map((file) => file.name);

        resolve(urls);
      } else {
        reject("Something went wrong");
      }
    });
  }
  const formik = useFormik({
    initialValues: {
      title: "",
      description: "",
      language: "",
      enableTranscription: false,
      transcriptionLanguage: "",
      videoCategory: "",
      videoSubCategory: "",
      schedulePublishDateTime: "",
      scheduleExpiryDateTime: "",
      license: "",
      allowEmbedding: true,
      allowNotify: true,
      comments: "",
      isPublishing: false,
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Title is required"),
      description: Yup.string().required("Description is required"),
      language: Yup.string().required("Language is required"),
      enableTranscription: Yup.boolean(),
      videoCategory: Yup.string().required("Video category is required"),
      videoSubCategory: Yup.string().required("Video sub category is required"),
      transcriptionLanguage: Yup.string().when(
        "enableTranscription",
        (enableTranscription, schema) => {
          if (enableTranscription) {
            return schema.required("Transcription language is required");
          }
          return schema;
        }
      ),

      schedulePublishDateTime: Yup.string()
        .nullable()
        .required("Publish date is required"),
      scheduleExpiryDateTime: Yup.string().when(
        "schedulePublishDateTime",
        (schedulePublishDateTime, schema) => {
          return schema.test({
            name: "is-after-publish-date",
            exclusive: false,
            message: "Expiry date and time must be after publish date and time",
            test: function (scheduleExpiryDateTime) {
              if (!scheduleExpiryDateTime || !schedulePublishDateTime) {
                return true; // Return true if one of the dates is not set
              }
              return (
                new Date(scheduleExpiryDateTime) >
                new Date(schedulePublishDateTime)
              );
            },
          });
        }
      ),
      // .nullable()
      // .required("Expiry date is required"),
      license: Yup.string(),
      comments: Yup.string()
        .oneOf(
          ["moderated", "off"],
          "Comments must be either 'moderated' or 'off'"
        )
        .required("Comments is required"),
    }),
    onSubmit: async (values) => {
      if (thumbnail && typeof thumbnail === "object") {
        // showBackdrop()
        const urls = await uploadFiles([thumbnail]);
        if (urls?.length > 0) {
          submitValues(values, urls[0]);
        }
      } else {
        // showBackdrop()
        submitValues(values, null);
      }
    },
  });
  const { video_id } = useParams();
  const [thumbnail, setThumbnail] = useState(null);
  const [attributeOptions, setAttributeOptions] = useState([]);
  const { data: videoEditData, isFetching: isLoading } = useGetVideoEdit(
    video_id,
    onSuccessVideoDetails
  );
  const {
    content_brand_id = [],
    languages = [],
    video_categories = [],
    licenses = [],
    tags = [],
  } = videoEditData?.data || {};
  const videoDetails = videoEditData?.video_detail || {};

  const todayDateTime = dayjs(new Date());
  const schedulePublishDateTime = videoDetails?.content_to_attr?.find(
    (attr) => attr.field === "schedule_publish_date_time"
  )?.value;
  const formatedSchedulePublishDateTime = schedulePublishDateTime
    ? dayjs(schedulePublishDateTime)
    : false;
  const isDisabledSchedule = formatedSchedulePublishDateTime
    ? todayDateTime.isAfter(formatedSchedulePublishDateTime)
    : false;

  useEffect(() => {
    if (isDisabledSchedule) {
      setEnableSchedule(true);
    }
  }, [isDisabledSchedule]);

  useEffect(() => {
    let now1 = dayjs(new Date()); //todays date
    let end1 = dayjs(formik?.values?.schedulePublishDateTime); // another date

    if (end1.isAfter(now1) || publishingStatus == "3") {
      setEnableSchedule(true);
    }
  }, [formik?.values?.schedulePublishDateTime, publishingStatus]);

  useEffect(() => {
    if (thumbArray?.length > 0) {
      setThumbnail(thumbArray[0]);
    }
  }, [thumbArray]);

  const findValueByField = (data, field) => {
    return data?.find((item) => item?.field === field)?.value || "";
  };

  function onSuccessVideoDetails(data) {
    let editOptions = data?.data;
    setPublishingStatus(
      data?.video_detail?.content_publishing_status?.value?.value
    );
    setMlStatus(data?.video_detail?.ml_process_status_id?.value?.value);
    let tempThumbnail =
      data?.video_detail?.content_to_image?.length > 0
        ? data?.video_detail?.content_to_image[0]?.content_image_id?.thumbnail
        : "";
    setThumbnail(tempThumbnail);
    setThumbArray([tempThumbnail]);
    const language =
      findValueByField(data?.video_detail?.content_to_attr, "video_language") ||
      editOptions?.languages[0]?.lang_name;
    const enableTranscription =
      findValueByField(
        data?.video_detail?.content_to_attr,
        "enable_transcription"
      ) || false;
    const transcriptionLanguage =
      findValueByField(
        data?.video_detail?.content_to_attr,
        "transcription_language"
      ) || editOptions?.languages[0]?.lang_name;

    const videoCategory =
      findValueByField(data?.video_detail?.content_to_attr, "video_category") ||
      "";
    const videoSubCategory =
      findValueByField(
        data?.video_detail?.content_to_attr,
        "video_sub_category"
      ) || "";
    const schedulePublishDateTime =
      findValueByField(
        data?.video_detail?.content_to_attr,
        "schedule_publish_date_time"
      ) || dayjs().format("YYYY-MM-DD HH:mm:ss");

    // expiry date is 1 year from current date
    const scheduleExpiryDateTime =
      findValueByField(
        data?.video_detail?.content_to_attr,
        "schedule_expiry_date_time"
      ) || dayjs().add(1, "year").format("YYYY-MM-DD HH:mm:ss");

    const license =
      findValueByField(data?.video_detail?.content_to_attr, "license") ||
      editOptions?.licenses[0]?.id;
    const allowEmbedding =
      findValueByField(
        data?.video_detail?.content_to_attr,
        "allow_embedding"
      ) === "1";
    const allowNotify =
      findValueByField(data?.video_detail?.content_to_attr, "allow_notify") ===
      "1";
    const comments =
      findValueByField(data?.video_detail?.content_to_attr, "comments") ||
      "moderated";

    let selectedAdditionalAttribues = findValueByField(
      data?.video_detail?.content_to_attr,
      "additional_attributes"
    );
    if (editOptions?.tags?.length > 0) {
      let attrOptionsTemp = editOptions?.tags?.map((item) => ({
        attribute_id: item?.id,
        name: item?.english,
        options: [],
      }));
      if (selectedAdditionalAttribues) {
        onFocusAttribute(attrOptionsTemp, (attr) => {
          selectedAdditionalAttribues = JSON.parse(selectedAdditionalAttribues);
          let result = setSelectedAttributesFromDetail(
            attr,
            selectedAdditionalAttribues
          );
          setAttributeOptions(result);
        });
      } else {
        setAttributeOptions(attrOptionsTemp);
      }
    }

    formik.setValues({
      title: data?.video_detail?.content_title,
      description: data?.video_detail?.content_description,
      language: language,
      videoCategory,
      videoSubCategory,
      enableTranscription: enableTranscription == 1,
      transcriptionLanguage: transcriptionLanguage,
      schedulePublishDateTime,
      scheduleExpiryDateTime,
      license,
      allowEmbedding,
      allowNotify,
      comments,
    });
    if (
      findValueByField(data?.video_detail?.content_to_attr, "encoding_details")
    )
      setEncodingDetails(
        JSON.parse(
          findValueByField(
            data?.video_detail?.content_to_attr,
            "encoding_details"
          )
        )
      );
  }

  function setSelectedAttributesFromDetail(attributesArray, detailAttributes) {
    // Iterate over the attributes array to add the selected items
    attributesArray.forEach((attribute) => {
      const attributeKey = `attribute_${attribute.attribute_id}`;
      if (detailAttributes[attributeKey]) {
        const selectedValues = detailAttributes[attributeKey].split(",");
        attribute.selected = selectedValues.map((value) => {
          const option = attribute.options.find((opt) => opt.value === value);
          if (option) {
            return {
              label: `${attribute.name} --> ${option.label}`,
              value: option.value,
            };
          } else {
            return { label: `${attribute.name} --> Unknown`, value: value };
          }
        });
      } else {
        attribute.selected = [];
      }
    });

    return attributesArray;
  }

  const getVideoExtension = (data) => {
    return data?.split(".").pop();
  };
  const videoExtension = getVideoExtension(
    videoDetails?.content_to_av?.storage_link
  );
  const [isDescriptionClicked, setDescriptionClicked] = useState(0);

  const {
    handleBlur,
    handleChange,
    handleSubmit,
    values,
    errors,
    isSubmitting,
  } = formik;

  const languageOptions = languages.map((language) => ({
    value: language.lang_name,
    label: language.lang_lavel,
  }));

  const videolanguageOptions = languages.map((language) => ({
    value: language.lang_name,
    label: language.lang_lavel,
  }));

  const videoCategoryOptions = video_categories?.map((category) => ({
    value: category.id,
    label: category.name,
  }));

  const videoSubCategoryOptions =
    video_categories?.length > 0 &&
    values?.videoCategory &&
    video_categories?.some(
      (videoCategory) => videoCategory.id === values?.videoCategory
    )
      ? video_categories
          ?.find((videoCategory) => videoCategory.id === values?.videoCategory)
          ?.sub_categories?.map((subCategory) => ({
            value: subCategory.id,
            label: subCategory.name,
          }))
      : [];

  const licenseOptions = licenses?.map((license) => ({
    value: license.id,
    label: license.license_name,
  }));

  // video Ai generated description
  const aiGeneratedDescription = [
    // {
    //   title: "Build vertically collapsing accordions",
    //   description:
    //     "Build vertically collapsing accordions in combination with the Collapse component",
    // },
    // {
    //   title: "Build vertically collapsing accordions",
    //   description:
    //     "Build vertically collapsing accordions in combination with the Collapse component",
    // },
  ];

  function handleSchedulePublishDateChange(date) {
    if (values?.scheduleExpiryDateTime) {
      let extractedExpiryDate = dayjs(values?.scheduleExpiryDateTime)?.format(
        "YYYY-MM-DD"
      );
      const updatedExpiryDate = dayjs(date)
        ?.add(1, "year")
        .format("YYYY-MM-DD");

      formik.setFieldValue(
        "scheduleExpiryDateTime",
        values?.scheduleExpiryDateTime?.replace(
          extractedExpiryDate,
          updatedExpiryDate
        )
      );
    }

    let formattedDate = dayjs(date).format("YYYY-MM-DD");
    let extractedDate = dayjs(values?.schedulePublishDateTime)?.format(
      "YYYY-MM-DD"
    );
    formik.setFieldValue(
      "schedulePublishDateTime",
      values?.schedulePublishDateTime?.replace(extractedDate, formattedDate)
    );
  }
  function handleSchedulePublishTimeChange(time) {
    let formattedTime = dayjs(time)?.format("HH:mm:ss");
    let extractedTime = dayjs(values?.schedulePublishDateTime)?.format(
      "HH:mm:ss"
    );
    formik.setFieldValue(
      "schedulePublishDateTime",
      values?.schedulePublishDateTime?.replace(extractedTime, formattedTime)
    );

    let extractedExpiryTime = dayjs(values?.scheduleExpiryDateTime)?.format(
      "HH:mm:ss"
    );
    formik.setFieldValue(
      "scheduleExpiryDateTime",
      values?.scheduleExpiryDateTime?.replace(
        extractedExpiryTime,
        formattedTime
      )
    );
  }
  function handleScheduleExpiryDateChange(date) {
    let formattedDate = dayjs(date)?.format("YYYY-MM-DD");
    let extractedDate = dayjs(values?.scheduleExpiryDateTime)?.format(
      "YYYY-MM-DD"
    );

    formik.setFieldValue(
      "scheduleExpiryDateTime",
      values?.scheduleExpiryDateTime?.replace(extractedDate, formattedDate)
    );
  }
  function handleScheduleExpiryTimeChange(time) {
    let formattedTime = dayjs(time)?.format("HH:mm:ss");
    let extractedTime = dayjs(values?.scheduleExpiryDateTime)?.format(
      "HH:mm:ss"
    );

    formik.setFieldValue(
      "scheduleExpiryDateTime",
      values?.scheduleExpiryDateTime?.replace(extractedTime, formattedTime)
    );
  }
  function handleAllowEmbeddingChange(e) {
    formik.setFieldValue("allowEmbedding", e.target.checked);
  }

  function handleAllowNotifyChange(e) {
    formik.setFieldValue("allowNotify", e.target.checked);
  }

  const changeArrayToLabels = (array) =>
    array.map((_eachElem) => {
      return {
        label: _eachElem.english ? parse(_eachElem.english) : "",
        value: _eachElem.id,
      };
    });
  function setSelectedAttributes(attributesArray, selectedArray) {
    // Create a map to store selected options by attribute_id
    const selectedMap = {};

    // Iterate over the selected array to populate the selectedMap
    selectedArray.forEach((item) => {
      // Extract attribute name from label (e.g., "Procedure --> Bronchoscopy" to "Procedure")
      const attributeName = item.label.split(" --> ")[0];

      // Find the corresponding attribute_id
      const attribute = attributesArray.find(
        (attr) => attr.name === attributeName
      );

      if (attribute) {
        // Initialize the selected array for this attribute if not already initialized
        if (!selectedMap[attribute.attribute_id]) {
          selectedMap[attribute.attribute_id] = [];
        }
        // Add the item to the selected map
        selectedMap[attribute.attribute_id].push(item);
      }
    });

    // Iterate over the attributes array to add the selected items
    attributesArray.forEach((attribute) => {
      // Add the selected items to the attribute, if any
      if (selectedMap[attribute.attribute_id]) {
        attribute.selected = selectedMap[attribute.attribute_id];
      } else {
        attribute.selected = [];
      }
    });

    return attributesArray;
  }
  const onChangeAttributeOption = (selectedValues) => {
    console.log("selectedValues", selectedValues);
    const result = setSelectedAttributes(attributeOptions, selectedValues);
    console.log("result", result);
    setAttributeOptions([...result]);
  };
  async function onFocusAttribute(attributeOptions, callback) {
    if (attributeOptionsLoaded.current) {
      return;
    }
    setCurrentAttributeLoading(true);
    let prev = [...attributeOptions];
    const fetchAttributeOptions = attributeOptions?.map(
      async ({ attribute_id }) => {
        let response = await getService(
          `channel/dt_attributes/${attribute_id}`
        );

        if (!response.data?.isError) {
          prev = prev.map((_attr) => {
            if (_attr.attribute_id === attribute_id) {
              return {
                ..._attr,
                options: changeArrayToLabels(
                  response.data.data?.video_geography
                ),
              };
            } else {
              return _attr;
            }
          });
        }
      }
    );

    // Wait for all fetchAttributeOptions promises to resolve
    await Promise.all(fetchAttributeOptions);
    setAttributeOptions(prev);
    setCurrentAttributeLoading(false);
    attributeOptionsLoaded.current = true;
    callback && callback(prev);
  }

  const attributeViewOptions = useMemo(() => {
    return attributeOptions?.map(
      (_attr) => {
        return {
          label: _attr?.name,
          options: _attr?.options?.map((_opt) => ({
            label: `${_attr.name} --> ${_opt.label}`,
            value: _opt.value,
          })),
        };
      },
      [attributeOptions]
    );
  });
  const groupStyles = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  };
  const groupBadgeStyles = {
    backgroundColor: "#EBECF0",
    borderRadius: "2em",
    color: "#172B4D",
    display: "inline-block",
    fontSize: 12,
    fontWeight: "normal",
    lineHeight: "1",
    minWidth: 1,
    padding: "0.16666666666667em 0.5em",
    textAlign: "center",
  };
  const formatGroupLabel = (data) => (
    <div style={groupStyles}>
      <span>{data.label}</span>
      <span style={groupBadgeStyles}>{data.options.length}</span>
    </div>
  );
  function flattenSelectedAttributes(attributesArray) {
    const flattenedSelected = [];
    attributesArray.forEach((attribute) => {
      if (attribute.selected && attribute.selected.length > 0) {
        flattenedSelected.push(...attribute.selected);
      }
    });
    return flattenedSelected;
  }
  const handleUploadThumbnail = (files, isNewUpdate) => {
    if (!files?.length) return;
    if (isNewUpdate) {
      setThumbnail(files[0]);
      setThumbArray((prev) => [...files, ...prev]);
    } else {
      setThumbnail(files[0]);
    }
  };
  const selectedOptions = useMemo(() => {
    return flattenSelectedAttributes(attributeOptions);
  }, [attributeOptions]);

  const doctubeSlug = videoDetails?.dt_url;

  const onCancelClick = () => {
    redirectTo(`channel/videos/${channel_id}`);
  };

  if (isLoading) {
    return <VideoDetailsSkeleton />;
  }

  const { content_publication_date } = videoDetails || {
    content_publication_date: "",
  };
  let now = dayjs(new Date()); //todays date
  let end = dayjs(content_publication_date); // another date
  let dateDifference = now.diff(end, "s");

  const scheduleReleaseHandler = () => {
    setEnableSchedule((prev) => !prev);

    let todayDate = dayjs().format("YYYY-MM-DD HH:mm:ss");
    const updatedExpiryDateTime = dayjs(todayDate)
      ?.add(1, "year")
      .format("YYYY-MM-DD HH:mm:ss");

    formik.setFieldValue("schedulePublishDateTime", todayDate);
    formik.setFieldValue("scheduleExpiryDateTime", updatedExpiryDateTime);
  };

  const onDeleteSingleThumbnail = (item) => {
    const updatedThumbnails = thumbArray.filter((img) => img !== item);
    setThumbArray(updatedThumbnails);
  };

  return (
    <>
      <div className="d-flex align-items-center justify-content-between mb-4">
        <h4 className="fw-semibold mb-0">{videoDetails?.content_title}</h4>

        {publishingStatus == "3" && dateDifference > 0 && (
          <Button
            variant="success"
            className="rounded-pill"
            onClick={() => {
              window.open(`${doctubeSlug}`);
            }}
          >
            View in DocTube
          </Button>
        )}
      </div>
      <Card>
        <Card.Body className="d-flex flex-column gap-5">
          <div className="w-100">
            {/* video and description  */}
            <Row>
              <Col xs={12} md={5} className="d-flex align-items-start">
                {
                  videoDetails?.content_to_av?.streaming_link && (
                    <VideoUploadBox
                      videoFile={videoDetails?.content_to_av?.streaming_link}
                      thumbnail={thumbnail}
                      setVideoDuration={setVideoDuration}
                    />
                  )
                  // <ReactPlayer
                  //   className="w-100 h-rem-20 d-flex bg-light rounded-3 overflow-auto justify-content-center"
                  //   url={videoDetails?.content_to_av?.storage_link}
                  //   width="auto"
                  //   width="100%"
                  //   height="100%"
                  //   onDuration={(duration) => setVideoDuration(duration)}
                  //   light={
                  //     thumbnail
                  //       ? typeof thumbnail === "string"
                  //         ? thumbnail
                  //         : URL.createObjectURL(thumbnail)
                  //       : false
                  //   }
                  //   controls
                  //   muted
                  //   autoplay
                  // />
                }
              </Col>
              <Col xs={12} md={7} className="mt-4 mt-md-0">
                <Form className="h-100 d-flex flex-column">
                  <FloatingLabel
                    controlId="title"
                    label="Video title"
                    className="mb-4 mb-md-3 custom-form-floating "
                  >
                    <Form.Control
                      type="text"
                      placeholder="Enter video title"
                      name="title"
                      value={formik.values.title}
                      onChange={formik.handleChange}
                    />
                    {formik.touched.title && formik.errors.title ? (
                      <div className="fs-6 text-danger">
                        {formik.errors.title}
                      </div>
                    ) : null}
                  </FloatingLabel>

                  <FloatingLabel
                    controlId="description"
                    label="Video description"
                    className="custom-form-floating flex-grow-1"
                  >
                    <Form.Control
                      as="textarea"
                      rows={4}
                      placeholder="Enter video description"
                      name="description"
                      value={formik.values.description}
                      onChange={formik.handleChange}
                    />
                    {formik.touched.description && formik.errors.description ? (
                      <div className="fs-6 text-danger">
                        {formik.errors.description}
                      </div>
                    ) : null}
                  </FloatingLabel>
                </Form>
              </Col>
            </Row>
          </div>
          {/* Ai generated description */}
          {aiGeneratedDescription?.length > 0 && (
            <div className="w-100 my-5">
              <Row className="bg-soft-dark mx-n4">
                <Col className="p-4">
                  <Row>
                    <Col xs={12} className="mb-4">
                      <h5 className="mb-1">AI Generated</h5>
                      <p className="mb-0">
                        Choose Your Video description from these options
                      </p>
                    </Col>
                    <Col xs={12}>
                      <Swiper
                        as="ul"
                        className="p-0 m-0 mb-2 list-inline swiper-wrapper"
                        slidesPerView={5.5}
                        spaceBetween={15}
                        modules={[Navigation]}
                        breakpoints={{
                          320: { slidesPerView: 2 },
                          550: { slidesPerView: 3 },
                          991: { slidesPerView: 3 },
                          1400: { slidesPerView: 4 },
                          1500: { slidesPerView: 5 },
                          1920: { slidesPerView: 6 },
                        }}
                        data-aos="fade-up"
                        data-aos-delay="700"
                      >
                        {aiGeneratedDescription.map((data, index) => {
                          return (
                            <SwiperSlide
                              key={index}
                              className="cursor-pointer"
                              onClick={() => {
                                formik.setValues({
                                  title: data.title,
                                  description: data.description,
                                });

                                setDescriptionClicked(index);
                              }}
                            >
                              <VideoDescriptionCard
                                title={data.title}
                                description={data.description}
                                isDescriptionClicked={isDescriptionClicked}
                                index={index}
                              />
                            </SwiperSlide>
                          );
                        })}
                      </Swiper>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          )}

          <div className="w-100">
            <Row>
              <Col md={6} className="mb-4 mb-md-0">
                <VideoEncodingDetails
                  fileName={encodingDetails?.fileName}
                  resolution={encodingDetails?.resolution}
                  fileFormat={encodingDetails?.fileFormat}
                  frameRate={encodingDetails?.frameRate}
                  bitrate={encodingDetails?.bitrate}
                  fileSize={encodingDetails?.fileSize}
                  duration={encodingDetails?.duration}
                  createdOn={encodingDetails?.createdOn}
                />
              </Col>

              {thumbArray?.length > 0 && (
                <Col md={6}>
                  <ThumbnailSelector
                    images={thumbArray}
                    selectedImage={thumbnail}
                    onUpdate={handleUploadThumbnail}
                    onDeleteSingleThumbnail={onDeleteSingleThumbnail}
                  />
                </Col>
              )}
            </Row>
          </div>

          {/* tags */}
          <div className="w-100">
            <Row>
              <Col xs={12} className="mb-3">
                <div className="d-flex justify-content-between align-items-center mb-1">
                  <h5 className="mb-0">Tags*</h5>
                </div>
                <p className="mb-0">
                  Tags are used for categorizing and organizing your Videos on
                  Doctube
                </p>
              </Col>
              {!current_attribute_loading && attributeOptions?.length > 0 ? (
                <Col md={12}>
                  <Select
                    isMulti
                    name="attributes"
                    formatGroupLabel={formatGroupLabel}
                    placeholder="Select or Search tags"
                    options={attributeViewOptions || []}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={onChangeAttributeOption}
                    value={selectedOptions}
                    onBlur={(e) => e.preventDefault()}
                    onFocus={() => onFocusAttribute(attributeOptions)}
                    noOptionsMessage={() =>
                      current_attribute_loading ? "Loading..." : "No options"
                    }
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 15,
                    })}
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        padding: "8px",
                        borderRadius: "15px", // Apply custom border radius
                      }),
                    }}
                  />
                </Col>
              ) : current_attribute_loading ? (
                <>
                  <Skeleton variant="text" width={1000} height={54} />
                </>
              ) : (
                <></>
              )}
            </Row>
          </div>

          {/* language */}
          <div className="w-100">
            <Row>
              <Col xs={12} className="mb-3">
                <h5 className="mb-1">Language</h5>
                <p className="mb-0">
                  Select your video’s language and transcription language
                </p>
              </Col>
              <div className="">
                <Row>
                  <Col sm={8} md={4} xs={12}>
                    <div className="form-group custom-choicejs">
                      <ChoicesJs
                        options={languageOptions}
                        className="js-choice"
                        select="one"
                        name="language"
                        value={formik.values.language}
                        onChange={handleChange}
                      />
                    </div>
                    {formik.touched.language && formik.errors.language ? (
                      <div className="fs-6 text-danger">
                        {formik.errors.language}
                      </div>
                    ) : null}
                  </Col>
                </Row>
              </div>
            </Row>
          </div>

          {/* Transcription */}
          <div className="w-100">
            <Row>
              <Col xs={12} className="d-flex gap-4 align-items-center">
                <h5 className="mb-1">
                  {formik.values.enableTranscription
                    ? "Transcription Enabled"
                    : "Enable Transcription"}
                </h5>
                <Form.Check
                  className="flex-shrink-0"
                  name="enableTranscription"
                  defaultChecked={formik.values.enableTranscription}
                  onClick={() =>
                    formik.setFieldValue(
                      "enableTranscription",
                      !formik.values.enableTranscription
                    )
                  }
                  type="switch"
                  aria-controls="collapse-text"
                  aria-expanded={formik.values.enableTranscription}
                />
              </Col>
              {formik.values.enableTranscription && (
                <Col xs={12}>
                  <p className="mb-4">
                    Select Your Video Transcription Language
                  </p>

                  <div className="form-group custom-choicejs">
                    <Row>
                      <Col sm={8} md={4} xs={12}>
                        <ChoicesJs
                          options={videolanguageOptions}
                          className="js-choice"
                          select="one"
                          name="transcriptionLanguage"
                          value={formik.values.transcriptionLanguage}
                          onChange={handleChange}
                        />
                      </Col>
                      {/* <Col className="d-flex justify-content-end">
                        <Button variant="primary">Modify Transcription</Button>
                      </Col> */}
                      {formik.touched.enableTranscription &&
                      formik.errors.transcriptionLanguage ? (
                        <div className="fs-6 text-danger">
                          {formik.errors.transcriptionLanguage}
                        </div>
                      ) : null}
                    </Row>
                  </div>
                </Col>
              )}
            </Row>
          </div>
          {/* Transcription */}
          {/*  */}

          {/* Category and Schedule Video */}
          <div className="w-100">
            <Col xs={12} className="mb-4">
              <h5 className="mb-1">Category and Schedule*</h5>
              <p className="mb-0">
                Select a Category and a Sub-Category for your Video so that
                viewers can find it more easily. Schedule a Publishing Date and
                an Expiry Date.
              </p>
            </Col>
            <Row>
              <Col sm={8} md={4} xs={12}>
                <div className="form-group custom-choicejs">
                  <ChoicesJs
                    options={videoCategoryOptions}
                    className="js-choice"
                    select="one"
                    name="videoCategory"
                    value={formik.values.videoCategory}
                    onChange={handleChange}
                  />
                </div>
                {formik.touched.videoCategory && formik.errors.videoCategory ? (
                  <div className="fs-6 text-danger">
                    {formik.errors.videoCategory}
                  </div>
                ) : null}
              </Col>
            </Row>
            {/* <Collapse in={!!formik.values.videoCategory}> */}
            {formik.values.videoCategory && (
              <Row>
                <Col sm={8} md={4} xs={12}>
                  <div className="form-group custom-choicejs">
                    <ChoicesJs
                      options={videoSubCategoryOptions}
                      className="js-choice"
                      select="one"
                      name="videoSubCategory"
                      value={formik.values.videoSubCategory}
                      onChange={handleChange}
                    />
                  </div>
                  {formik.touched.videoSubCategory &&
                  formik.errors.videoSubCategory ? (
                    <div className="fs-6 text-danger">
                      {formik.errors.videoSubCategory}
                    </div>
                  ) : null}
                </Col>
              </Row>
            )}
            {/* </Collapse> */}
          </div>

          {/* Schedule Publish */}
          <div className="w-100">
            <Row className="mx-md-n5">
              <Col xs={12} className="mb-4 px-md-5 d-flex">
                <h5 className="mb-1">Schedule Release</h5>
                <Form.Check
                  className="flex-shrink-0 ms-3"
                  name="enableSchedule"
                  checked={enableSchedule}
                  onClick={scheduleReleaseHandler}
                  type="switch"
                  aria-controls="collapse-text"
                  aria-expanded={enableSchedule}
                  disabled={
                    (publishingStatus == "3" && dateDifference > 0) ||
                    isDisabledSchedule
                  }
                />
              </Col>
              <Row>
                <Col md={5}>
                  {enableSchedule && (
                    <Col md={12} className="mb-4 px-md-3">
                      <h5 className="mb-3">Publishing Date</h5>
                      <Row className="mx-n2">
                        <Col xs={8} className="px-2 position-relative">
                          <Flatpickr
                            options={{
                              minDate:
                                (publishingStatus == "3" &&
                                  dateDifference > 0) ||
                                isDisabledSchedule
                                  ? null
                                  : "today",
                              defaultDate: formik?.values
                                ?.schedulePublishDateTime
                                ? dayjs(
                                    formik?.values?.schedulePublishDateTime
                                  ).toDate()
                                : "",
                            }}
                            className="form-control range_flatpicker"
                            value={formik.values.schedulePublishDateTime}
                            onChange={handleSchedulePublishDateChange}
                            disabled={
                              (publishingStatus == "3" && dateDifference > 0) ||
                              isDisabledSchedule
                            }
                          />

                          {formik.touched.schedulePublishDateTime &&
                          formik.errors.schedulePublishDateTime ? (
                            <div className="fs-6 text-danger">
                              {formik.errors.schedulePublishDateTime}
                            </div>
                          ) : null}
                          <span className="d-none d-md-inline-block position-absolute end-0 top-0 pt-3 pe-4 mt-n1">
                            {findIcon("Calendar", "outline", 24)}
                          </span>
                        </Col>

                        <Col xs={4} className="px-2 position-relative">
                          <Flatpickr
                            className="form-control range_flatpicker"
                            onChange={handleSchedulePublishTimeChange}
                            options={{
                              enableTime: true,
                              noCalendar: true,
                              dateFormat: "h:i K",
                              defaultDate: formik?.values
                                ?.schedulePublishDateTime
                                ? dayjs(
                                    formik?.values?.schedulePublishDateTime
                                  ).toDate()
                                : "",
                            }}
                            disabled={
                              (publishingStatus == "3" && dateDifference > 0) ||
                              isDisabledSchedule
                            }
                          />
                          <span className="d-none d-md-inline-block position-absolute end-0 top-0 pt-3 pe-4 mt-n1">
                            {findIcon("Clock Circle", "outline", 24)}
                          </span>
                        </Col>
                      </Row>
                    </Col>
                  )}

                  <Col md={12} className="mb-4 px-md-3">
                    <h5 className="mb-3">Expiry</h5>
                    <Row className="mx-n2">
                      <Col xs={8} className="px-2 position-relative">
                        <Flatpickr
                          name="scheduleExpiryDateTime"
                          options={{
                            minDate: "today",
                            defaultDate: formik?.values?.scheduleExpiryDateTime
                              ? dayjs(
                                  formik?.values?.scheduleExpiryDateTime
                                ).toDate()
                              : "",
                          }}
                          className="form-control range_flatpicker"
                          value={formik.values.scheduleExpiryDateTime}
                          onChange={handleScheduleExpiryDateChange}
                        />

                        {formik.touched.scheduleExpiryDateTime &&
                        formik.errors.scheduleExpiryDateTime ? (
                          <div className="fs-6 text-danger">
                            {formik.errors.scheduleExpiryDateTime}
                          </div>
                        ) : null}
                        <span className="d-none d-md-inline-block position-absolute end-0 top-0 pt-3 pe-4 mt-n1">
                          {findIcon("Calendar", "outline", 24)}
                        </span>
                      </Col>

                      <Col xs={4} className="px-2 position-relative">
                        <Flatpickr
                          className="form-control range_flatpicker"
                          // placeholder={dayjs().format("HH:mm")}
                          options={{
                            enableTime: true,
                            noCalendar: true,
                            dateFormat: "h:i K",
                            defaultDate: formik?.values?.scheduleExpiryDateTime
                              ? dayjs(
                                  formik?.values?.scheduleExpiryDateTime
                                ).toDate()
                              : "",
                          }}
                          onChange={handleScheduleExpiryTimeChange}
                          name="scheduleExpiryDateTime"
                          value={dayjs(
                            formik?.values?.scheduleExpiryDateTime
                          ).format("h:mm A")}
                        />
                        <span className="d-none d-md-inline-block position-absolute end-0 top-0 pt-3 pe-4 mt-n1">
                          {findIcon("Clock Circle", "outline", 24)}
                        </span>
                      </Col>
                    </Row>
                  </Col>
                </Col>
              </Row>
            </Row>
          </div>

          {/* Automatic chapters (phase2) */}

          {/* License */}
          <div className="w-100">
            <Col xs={12} className="mb-3">
              <h5>License</h5>
            </Col>
            <Row>
              <Col sm={8} md={4} xs={12}>
                <div className="form-group custom-choicejs">
                  <ChoicesJs
                    options={licenseOptions}
                    className="js-choice"
                    select="one"
                    name="license"
                    value={formik.values.license}
                    onChange={handleChange}
                  />
                </div>
              </Col>
            </Row>
          </div>
          {/*  */}

          {/* Publish */}
          <div className="w-100">
            <Col xs={12} className="mb-3">
              <h5>Publishing Options</h5>
            </Col>
            <Row>
              <Col>
                <Form.Group className="d-flex flex-column gap-2">
                  <Form.Check>
                    <Form.Check.Input
                      className="me-2"
                      type="checkbox"
                      defaultValue=""
                      id="embeddingCheckChecked"
                      name="allowEmbedding"
                      checked={formik.values.allowEmbedding}
                      onChange={handleAllowEmbeddingChange}
                    />
                    <Form.Check.Label htmlFor="embeddingCheckChecked">
                      Allow Embedding
                    </Form.Check.Label>
                  </Form.Check>

                  <Form.Check>
                    <Form.Check.Input
                      className="me-2"
                      type="checkbox"
                      defaultValue=""
                      id="transcriptionCheckChecked"
                      name="allowTranscription"
                      checked={formik.values.allowNotify}
                      onChange={handleAllowNotifyChange}
                    />
                    <Form.Check.Label htmlFor="transcriptionCheckChecked">
                      Publish to feed and notify subscribers
                    </Form.Check.Label>
                  </Form.Check>
                </Form.Group>
              </Col>
            </Row>
          </div>
          {/*  */}

          {/* Comments */}
          <div className="w-100">
            <Col xs={12} className="mb-3">
              <h5 className="mb-1">Comments</h5>
              <p>
                Choose whether you want your Video comment to be moderated or
                disabled
              </p>
            </Col>
            <Row>
              <Col>
                <Form.Group className="d-flex flex-column gap-2">
                  <Form.Check className="d-block">
                    <Form.Check.Input
                      className="me-2"
                      type="radio"
                      name="comments"
                      id="flexRadioDefault1"
                      checked={formik.values.comments === "moderated"}
                      onChange={() =>
                        formik.setFieldValue("comments", "moderated")
                      }
                    />
                    <Form.Check.Label htmlFor="flexRadioDefault1">
                      Moderated
                    </Form.Check.Label>
                  </Form.Check>
                  <Form.Check className="d-block">
                    <Form.Check.Input
                      className="me-2"
                      type="radio"
                      name="comments"
                      id="flexRadioDefault2"
                      checked={formik.values.comments === "off"}
                      onChange={() => formik.setFieldValue("comments", "off")}
                    />
                    <Form.Check.Label htmlFor="flexRadioDefault2">
                      Disable
                    </Form.Check.Label>
                  </Form.Check>
                </Form.Group>
              </Col>
            </Row>
          </div>
          {/*  */}

          <div className="w-100 d-flex">
            {/* publish button */}
            <div className="ms-auto d-flex flex-wrap gap-2">
              <Button variant="light" onClick={onCancelClick}>
                Cancel
              </Button>
              <Button
                variant="success"
                type="submit"
                title={
                  mlStatus == "0" || mlStatus == "1" || mlStatus != "2"
                    ? "Video Is Under Proceessing "
                    : "Click To Update "
                }
                disabled={
                  isSubmitting ||
                  mlStatus == "0" ||
                  mlStatus == "1" ||
                  mlStatus != "2"
                }
                onClick={() => {
                  if (Object.keys(formik.errors).length > 0) {
                    toast.error(Object.values(formik.errors)[0]);
                    return;
                  } else {
                    showBackdrop();
                    if (dateDifference < 0) {
                      formik.setFieldValue("isPublishing", true);
                    } else {
                      formik.setFieldValue("isPublishing", false);
                    }
                    handleSubmit();
                  }
                }}
              >
                {mlStatus == "0" || mlStatus == "1" || mlStatus != "2"
                  ? "Processing"
                  : publishingStatus == "3"
                  ? "Update"
                  : "Publish"}
              </Button>
              {publishingStatus == "3" && dateDifference > 0 && (
                <Button
                  onClick={() => {
                    if (Object.keys(formik.errors).length > 0) {
                      toast.error(Object.values(formik.errors)[0]);
                      return;
                    } else {
                      showBackdrop();
                      formik.setFieldValue("isPublishing", true);
                      handleSubmit();
                    }
                  }}
                >
                  Update &amp; publish
                </Button>
              )}
            </div>
          </div>
        </Card.Body>
      </Card>
    </>
  );
}

export default ChannelVideoDetails;
