export function transformIndiaMapData(data = []) {
  const result = data.reduce((acc, item) => {
    acc[item.level] = item.percentage;
    return acc;
  }, {});
  return [result];
}

export const formatLivePollDataForMasterCluster = (pollData = []) => {
  if (pollData.length < 1) return [];

  const formattedPollData = pollData?.map((item) => {
    return {
      question: item.survey_question,
      options: item.survey_option_jsonarray?.map((value) => {
        return { label: value.option, percentage: value.percentage };
      }),
    };
  });

  return formattedPollData;
};

export const formatTimeForEvents = (value = []) => {
  if (!Array.isArray(value) || value.length === 0) {
    return [];
  }

  const formattedData = value.map((minutes) => {
    const hours = Math.floor(minutes / 60);
    const mins = Math.floor(minutes % 60);
    const secs = Math.floor((minutes % 1) * 60);

    const formattedTime = `${String(hours).padStart(2, "0")}:${String(
      mins
    ).padStart(2, "0")}:${String(secs).padStart(2, "0")}`;
    return formattedTime;
  });

  return formattedData;
};
