export const KEY_OVERVIEW_VIEW_REPORT = "KEY_OVERVIEW_VIEW_REPORT";
export const KEY_OVERVIEW_VIEW_COUNT = "KEY_OVERVIEW_VIEW_COUNT";
export const KEY_OVERVIEW_CLICK_REPORT = "KEY_OVERVIEW_CLICK_REPORT";
export const KEY_OVERVIEW_LOCATION_MAP = "KEY_OVERVIEW_LOCATION_MAP";
export const KEY_OVERVIEW_DEVICE_DISTRIBUTION =
  "KEY_OVERVIEW_DEVICE_DISTRIBUTION";
export const KEY_OVERVIEW_ZONAL_DISTRIBUTION =
  "KEY_OVERVIEW_ZONAL_DISTRIBUTION";
export const KEY_OVERVIEW_MOBILE_DISTRIBUTION =
  "KEY_OVERVIEW_MOBILE_DISTRIBUTION";
export const KEY_OVERVIEW_BROWSER_DISTRIBUTION =
  "KEY_OVERVIEW_BROWSER_DISTRIBUTION";
export const KEY_OVERVIEW_LANDING_CTEGORY = "KEY_OVERVIEW_LANDING_CTEGORY";

export const KEY_AUDIENCE_DAY_WISE_REPORT = "KEY_AUDIENCE_DAY_WISE_REPORT";
export const KEY_AUDIENCE_AGE_WISE_REPORT = "KEY_AUDIENCE_AGE_WISE_REPORT";
export const KEY_AUDIENCE_SCREEN_SIZE_WISE_DATA =
  "KEY_AUDIENCE_SCREEN_SIZE_WISE_DATA";
export const KEY_AUDIENCE_ZONAL_DISTRIBUTION =
  "KEY_AUDIENCE_ZONAL_DISTRIBUTION";
export const KEY_AUDIENCE_DEVICE_DISTRIBUTION =
  "KEY_AUDIENCE_DEVICE_DISTRIBUTION";
export const KEY_AUDIENCE_SPECIALITY_REPORT = "KEY_AUDIENCE_SPECIALITY_REPORT";
export const KEY_AUDIENCE_SPECIALITY_REPORT_CTR_KEY =
  "KEY_AUDIENCE_SPECIALITY_REPORT_CTR_KEY";

export const KEY_LOCATION_MAP = "KEY_LOCATION_MAP";
export const KEY_LOCATION_MAJOR_CITIES = "KEY_LOCATION_MAJOR_CITIES";
export const KEY_LOCATION_ZONAL_DISTRIBUTION =
  "KEY_LOCATION_ZONAL_DISTRIBUTION";
export const KEY_LOCATION_LOCATION_REPORT = "KEY_LOCATION_LOCATION_REPORT";
export const KEY_LOCATION_LOCATION_REPORT_CTR =
  "KEY_LOCATION_LOCATION_REPORT_CTR";

export const KEY_CREATIVE_DATA_LIST = "KEY_CREATIVE_DATA_LIST";
export const KEY_CREATIVE_REPORT_TABLE = "KEY_CREATIVE_REPORT_TABLE";
export const KEY_CREATIVE_REPORT_TABLE_CTR = "KEY_CREATIVE_REPORT_TABLE_CTR";
export const KEY_CREATIVE_DEVICE_LATENCY = "KEY_CREATIVE_DEVICE_LATENCY";
export const KEY_CREATIVE_DIMENSION_REPORT = "KEY_CREATIVE_DIMENSION_REPORT";
export const KEY_CREATIVE_OS_LATENCY_REPORT = "KEY_CREATIVE_OS_LATENCY_REPORT";

export const KEY_LANDING_PAGE_CATEGORY_IMPRESSION =
  "KEY_LANDING_PAGE_CATEGORY_IMPRESSION";
export const KEY_LANDING_PAGE_CATEGORY_TYPE_IMPRESSION =
  "KEY_LANDING_PAGE_CATEGORY_TYPE_IMPRESSION";
export const KEY_LANDING_PAGE_CATEGORY_REPORT =
  "KEY_LANDING_PAGE_CATEGORY_REPORT";
export const KEY_LANDING_PAGE_CATEGORY_REPORT_CTR =
  "KEY_LANDING_PAGE_CATEGORY_REPORT_CTR";

export const KEY_CREATIVE_DETAILS_INFO = "KEY_CREATIVE_DETAILS_INFO";
export const KEY_DETAILS_CREATIVE_LANDING_REPORT_LIST =
  "KEY_DETAILS_CREATIVE_LANDING_REPORT_LIST";

export const KEY_SUMMARY_CAMPAIGN_ANALYTICS = "KEY_SUMMARY_CAMPAIGN_ANALYTICS";
export const KEY_SUMMARY_CREATIVE_LIST = "KEY_SUMMARY_CREATIVE_LIST";
export const KEY_SUMMARY_CAMPAIGN_GROUP_LIST =
  "KEY_SUMMARY_CAMPAIGN_GROUP_LIST";
export const KEY_SUMMARY_GROUP_LIST = "KEY_SUMMARY_GROUP_LIST";
export const KEY_SUMMARY_IMPRESSION_GRAPH = "KEY_SUMMARY_IMPRESSION_GRAPH";
export const KEY_SUMMARY_LOCATION_MAP = "KEY_SUMMARY_LOCATION_MAP";
export const KEY_LOCATION_STATE_MAP = "KEY_LOCATION_STATE_MAP";
export const KEY_SUMMARY_DEVICE_DISTRIBUTION =
  "KEY_SUMMARY_DEVICE_DISTRIBUTION";
export const KEY_SUMMARY_DAYWISE_DISTRIBUTION =
  "KEY_SUMMARY_DAYWISE_DISTRIBUTION";

export const KEY_CREATIVE_DETAILS = "KEY_CREATIVE_DETAILS";
export const KEY_CREATIVE_LANDING_PAGE_REPORT =
  "KEY_CREATIVE_LANDING_PAGE_REPORT";

export const KEY_CREATIVE_PREVIEW = "KEY_CREATIVE_PREVIEW";
