export const GET_EVENTS_SUMMARY_CONTENT_INFO = "event/summary/content-info";
export const GET_EVENTS_CONTENT_VIEWS = "event/analytics/content-views";
export const GET_EVENTS_CONTENT_REGISTRATION =
  "event/analytics/content-registration";
export const GET_EVENTS_LOCATION_DISTRIBUTION =
  "event/analytics/location-distribution";
export const GET_EVENTS_DEVICE_DISTRIBUTION =
  "event/analytics/device-distribution";
export const GET_EVENTS_ZONAL_DISTRIBUTION =
  "event/analytics/zonal-distribution";
export const GET_EVENTS_OS_DISTRIBUTION = "event/analytics/os-distribution";
export const GET_EVENTS_BROWSER_DISTRIBUTION =
  "event/analytics/browser-distribution";
export const GET_EVENTS_DAYWISE_SLOT_DISTRIBUTION =
  "event/audience/daywise-slot-distributions";
export const GET_EVENTS_REGISTRATION_BY_SLOT =
  "event/audience/registration-by-slot";
export const GET_EVENTS_VIEW_BY_SEGMENT = "event/audience/view-by-segment";
export const GET_EVENTS_PARTICIPANT_LIST = "event/audience/participant-list";
export const GET_EVENTS_MAJOR_CITIES = "event/location/major-cities";
export const GET_EVENTS_ALL_PROJECT_EVENTS_LIST =
  "event/summary/all-project-events-list";
export const GET_EVENTS_LOCATION_REPORT = "event/location/location-report";
export const GET_EVENTS_ENGAGEMENT_REPORT =
  "event/engagement/engagement-report";
export const GET_EVENTS_CONTENT_QUESTION = "event/summary/content-question";
export const GET_EVENTS_SPECIALITY_BREAKUP =
  "event/audience/speciality-breakup";
export const GET_EVENTS_ALL_EVENTS_LIST = "event/summary/all-events-list";
export const GET_EVENTS_SEARCH = "/project/event/summary/search-events";
