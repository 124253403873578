import React, { useState, useRef, useEffect, useCallback } from "react";
import { Modal, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import Flatpickr from "react-flatpickr";
// import "flatpickr/dist/themes/material_green.css";
import { findIcon } from "../../../../dashboard/icons";
import "flatpickr/dist/flatpickr.min.css";
import { formatLocalizedDate } from "../../../../../common/utils/utils";
import dayjs from "dayjs";
import toast from "react-hot-toast";

const DateRangePicker = ({
  startDate,
  endDate,
  setDateRangePayload,
  dateLabel,
}) => {
  const [modalShow, setModalShow] = useState(false);

  const onModalHide = useCallback(() => {
    setModalShow(false);
  }, [modalShow]);

  const today = new Date();
  const startDateObj = new Date(dateLabel?.start_date);
  let endDateObj = new Date(dateLabel?.end_date);

  if (endDateObj.getTime() > today.getTime()) {
    endDateObj = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() - 1
    );
  } else if (
    endDateObj.getTime() === today.getTime() &&
    startDateObj.getTime() !== today.getTime()
  ) {
    endDateObj.setDate(endDateObj.getDate() - 1);
  }
  return (
    <>
      <div
        className="border rounded-3 p-2 border-soft-secondary bg-white cursor-pointer d-flex align-items-center gap-2"
        onClick={() => setModalShow(true)}
      >
        {findIcon("Calendar", "solid", 20)}
        <p className="p-0 m-0">
          {formatLocalizedDate(startDateObj, "DD-MM-YYYY")} -{" "}
          {formatLocalizedDate(endDateObj, "DD-MM-YYYY")}
        </p>
      </div>

      <DateRangeModal
        startDate={startDate}
        endDate={endDate}
        show={modalShow}
        onHide={onModalHide}
        setDateRangePayload={setDateRangePayload}
        dateLabel={dateLabel}
      />
    </>
  );
};

export default DateRangePicker;

const DateRangeModal = ({
  startDate,
  endDate,
  show,
  onHide,
  setDateRangePayload,
  dateLabel,
}) => {
  const [modalState, setModalState] = useState(show);
  const [dates, setDates] = useState([]);

  const handleStartDateChange = (e) => {
    setDates([new Date(e.target.value), dates[1]]);
  };

  const handleEndDateChange = (e) => {
    setDates([dates[0], new Date(e.target.value)]);
  };

  const [selectedRange, setSelectedRange] = useState(0);
  const flatpickrRef = useRef(null);

  const [firstOnChange, setFirstOnChange] = useState(false);

  useEffect(() => {
    if (show) {
      const today = new Date();
      let startDate = new Date(dateLabel?.start_date);
      let endDate = new Date(dateLabel?.end_date);

      if (endDate.getTime() > today.getTime()) {
        endDate = new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate() - 1
        );
      } else if (
        endDate.getTime() === today.getTime() &&
        startDate.getTime() !== today.getTime()
      ) {
        endDate.setDate(endDate.getDate() - 1);
      }

      setDates(() => [startDate, endDate]);
      setModalState(true);
    } else {
      setDates(() => []);
      setModalState(false);
    }
  }, [show, dateLabel]);

  const getMaxDate = () => {
    let date = new Date(endDate);
    const today = new Date();

    if (date.getTime() > today.getTime()) {
      date = new Date(today.setDate(today.getDate() - 1));
    } else if (date.getTime() === today.getTime()) {
      date.setDate(date.getDate() - 1);
    }

    const formattedEndDate = formatLocalizedDate(
      date,
      "YYYY-MM-DD[T]23:59:59.000Z"
    );

    return formattedEndDate;
  };

  const getMinDate = () => {
    const formattedStartDate = formatLocalizedDate(
      new Date(startDate),
      "YYYY-MM-DD[T]00:00:00.000Z"
    );

    return formattedStartDate;
  };

  const getLastDate = () => {
    const date = new Date(getMaxDate());
    return date;
  };

  const getFirstDate = (days) => {
    let numOfDays = days;
    const date = new Date(getMaxDate());
    const d1 = dayjs(getMinDate());
    const d2 = dayjs(getMaxDate());

    const differenceInDays = d2.diff(d1, "day") + 1;

    if (differenceInDays < days) {
      numOfDays = differenceInDays;
    }

    date.setDate(date.getDate() - numOfDays + 1);

    return date;
  };

  const isValidDates = (start_date, end_date, startDate, endDate) => {
    const startDateFormat = dayjs(start_date);
    const endDateFormat = dayjs(end_date).startOf("day");
    const projectStartDate = dayjs(startDate);
    const projectEndDate = dayjs(endDate);

    const isValidDate = (date) => dayjs(date).isValid();

    if (
      !isValidDate(startDateFormat) ||
      !isValidDate(endDateFormat) ||
      startDateFormat.isAfter(endDateFormat) ||
      startDateFormat.isBefore(projectStartDate) ||
      endDateFormat.isAfter(projectEndDate)
    ) {
      return false;
    }
    return true;
  };

  const handleApply = () => {
    if (dates?.length > 1) {
      const start_date = dates[0];
      const end_date = dates[1];

      if (!isValidDates(start_date, end_date, startDate, endDate)) {
        toast.error("Invalid date range");
        return;
      }

      if (start_date && end_date) {
        if (end_date.toDateString() == new Date().toDateString()) {
          end_date.setDate(end_date.getDate() - 1);
        } else {
          setDateRangePayload({
            start_date: formatLocalizedDate(
              start_date,
              "YYYY-MM-DD[T]00:00:00.SSSZ"
            ),
            end_date: formatLocalizedDate(
              end_date,
              "YYYY-MM-DD[T]23:59:59.SSSZ"
            ),
          });
        }
        onHide();
      } else {
        console.log("in else ", start_date, end_date);
      }
    }
  };

  const handleDateChange = (selectedDates) => {
    if (!firstOnChange) {
      setFirstOnChange(true);
    }
    setDates(selectedDates);
  };

  const dateOptions = [
    { label: "Last 7 days", value: 7 },
    { label: "Last 28 days", value: 28 },
    { label: "Last 30 days", value: 30 },
    { label: "Last 90 days", value: 90 },
    { label: "custom", value: 0 },
  ];

  const formatDate = (date) => {
    if (!date) return null;
    return dayjs(date).format("YYYY-MM-DD");
  };

  return (
    <Modal show={modalState} onHide={onHide} size="md" centered>
      <Modal.Body>
        <div className="d-flex flex-column flex-md-row">
          <ul className="dateRangeTime list-unstyled mb-2 mb-md-0 flex-shrink-0 flex-grow-1 me-1 d-flex flex-row flex-md-column gap-1 align-items-center align-items-md-start">
            <span className="fw-semibold mb-3 fs-6 lh-base flex-shrink-0 me-3 me-md-0">
              Select Date Range
            </span>

            {dateOptions.map((item, index) => (
              <li
                key={index}
                onClick={() => {
                  const today = new Date().toDateString();
                  const isStartToday =
                    new Date(startDate)?.toDateString() === today;
                  const isEndToday =
                    new Date(endDate)?.toDateString() === today;

                  if (isStartToday && isEndToday) {
                    toast.error("Please contact to your account manager");
                  } else {
                    setDates([getFirstDate(item.value), getLastDate()]);
                    setSelectedRange(item.value);
                    setFirstOnChange(false);
                  }
                }}
                className={`d-flex flex-shrink-0 justify-content-between ps-3 px-1 py-3 rounded-3 cursor-pointer mb-2 btn-light border ${
                  (selectedRange === item.value && !firstOnChange) ||
                  (firstOnChange && item.label === "custom")
                    ? "text-white bg-calendarGreen fw-semibold"
                    : ""
                }`}
              >
                {item.label}
                {(selectedRange === item.value && !firstOnChange) ||
                (firstOnChange && item.label === "custom")
                  ? findIcon("Chevron Right", "outline", "20")
                  : ""}
              </li>
            ))}
          </ul>

          <div className="dateRange w-100">
            <div className="d-flex gap-1 rangeHeader mb-3 mt-2">
              <div className="flex-shrink-0 flex-grow-1 px-1">
                <div className="m-0 mt-n2 fs-7 bg-white position-relative w-fit-content pe-3 ps-1 pb-1 opacity-75">
                  Start Date{" "}
                  <span>
                    <OverlayTrigger
                      placement="bottom"
                      delay={{ show: 50, hide: 200 }}
                      overlay={
                        <Tooltip id="button-tooltip">
                          Please select a date range that falls between the
                          project start date and end date. Future dates are not
                          allowed.
                        </Tooltip>
                      }
                    >
                      <span className="fill-secondary">
                        {findIcon("info", "outline", 12)}
                      </span>
                    </OverlayTrigger>
                  </span>
                </div>
                <div>
                  <input
                    type="date"
                    min={formatDate(startDate)}
                    max={formatDate(dates[1])}
                    value={dates[0] ? formatDate(dates[0]) : ""}
                    onChange={handleStartDateChange}
                    className="form-control mb-2"
                  />
                </div>
              </div>

              <div className="flex-shrink-0 flex-grow-1 rounded-3 px-1">
                <div className="m-0 mt-n2 fs-7 bg-white position-relative w-fit-content pe-3 ps-1 pb-1 opacity-75">
                  End Date{" "}
                  <span>
                    <OverlayTrigger
                      placement="bottom"
                      delay={{ show: 50, hide: 200 }}
                      overlay={
                        <Tooltip id="button-tooltip">
                          Please select a date range that falls between the
                          project start date and end date. Future dates are not
                          allowed.
                        </Tooltip>
                      }
                    >
                      <span className="fill-secondary">
                        {findIcon("info", "outline", 12)}
                      </span>
                    </OverlayTrigger>
                  </span>
                </div>
                <div>
                  <input
                    type="date"
                    min={formatDate(dates[0])}
                    max={formatDate(endDate)}
                    value={dates[1] ? formatDate(dates[1]) : ""}
                    onChange={handleEndDateChange}
                    className="form-control mb-2"
                  />
                </div>
              </div>
            </div>

            <div className="d-flex justify-content-center">
              <Flatpickr
                ref={flatpickrRef}
                value={[
                  formatLocalizedDate(dates[0], "YYYY-MM-DD"),
                  formatLocalizedDate(dates[1], "YYYY-MM-DD"),
                ]}
                onChange={handleDateChange}
                options={{
                  mode: "range",
                  dateFormat: "Y-m-d",
                  inline: true,
                  // defaultDate: [getLastDate(selectedRange), getLastDate(0)],
                  minDate: getMinDate(),
                  maxDate: getMaxDate(),
                }}
              />
            </div>

            <div className="d-flex justify-content-end py-3 gap-3">
              <Button variant="light" onClick={onHide}>
                Cancel
              </Button>
              <Button onClick={handleApply} disabled={dates.length < 2}>
                Apply
              </Button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
