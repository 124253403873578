import React, { memo } from "react";
import { Col } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import FilePicker from "../../../../../../../components/form-components/file-picker";
import VideoThumbnailCard from "../../components/video-details/VideoThumbnailCard";
import { findIcon } from "../../../../../../dashboard/icons";
let demoImages = [
  "https://img.freepik.com/free-psd/election-banner-design-template_23-2149398468.jpg?w=1060&t=st=1714731426~exp=1714732026~hmac=9fe4bab9d8db8622a6fe6d73814e0821e7eb89d24ac7e632f6edd48adf819a30",
  "https://img.freepik.com/free-vector/online-shopping-template-landing-page_23-2148658042.jpg?t=st=1714731426~exp=1714732026~hmac=c9fb2891ba4ec3ecdad06185cf82480720d01ac136eb0cfa933db12eb4ecd973",
];
const ThumbnailSelector = ({
  images = demoImages,
  buttonTitle,
  colorVariant = "bg-soft-primary",
  onUpdate,
  selectedImage: image,
  onDeleteSingleThumbnail = () => {},
}) => {
  return (
    <>
      <Col xs={12} className="mb-3">
        <h5 className="mb-1">Thumbnail</h5>
        <p className="mb-0">
          Select or upload an eye-catching image for video thumbnail
        </p>
      </Col>

      <div className="overflow-hidden d-slider1">
        <Swiper
          as="ul"
          className="p-0 m-0 list-inline swiper-wrapper"
          slidesPerView={"auto"}
          spaceBetween={10}
          freeMode={true}
          grabCursor={true}
          mousewheel={true}
          breakpoints={{
            320: { slidesPerView: 2 },
            550: { slidesPerView: 2 },
            991: { slidesPerView: 3 },
            1400: { slidesPerView: 3.5 },
            1500: { slidesPerView: 3.5 },
            1920: { slidesPerView: 3.5 },
          }}
          data-aos="fade-up"
          data-aos-delay="700"
        >
          <SwiperSlide className="w-auto cursor-pointer">
            <div className="thumbnailSliderArea">
              <FilePicker
                isAddIcon={false}
                icon={"upload"}
                className={`custom-file-picker rounded-3 border-0  bg-opacity-50 ${colorVariant}`}
                title={buttonTitle ?? "Add Thumbnail"}
                onUpdate={(e) => onUpdate(e, true)}
                accepts="image/*"
                type="image"
              />
            </div>
          </SwiperSlide>
          {images?.map((item, index) => (
            <SwiperSlide
              className="w-auto cursor-pointer"
              key={index}
              onClick={() => onUpdate([item])}
            >
              <div style={{ position: "relative" }}>
                <VideoThumbnailCard
                  image={item}
                  isChecked={
                    typeof item === "string"
                      ? image === item
                      : item?.name === image?.name
                  }
                  key={index}
                />
                {images.length > 1 && (
                  <span
                    className="position-absolute top-0 end-0 text-danger z-3 mt-n2 me-n2 cursor-pointer bg-white rounded-circle shadow-sm p-2"
                    onClick={() => onDeleteSingleThumbnail(item)}
                  >
                    {findIcon("Trash", "dual-tone", 22)}
                  </span>
                )}
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </>
  );
};

export default memo(ThumbnailSelector);
