import { useQuery } from "@tanstack/react-query";
import axiosInstance from "../../api/instances/axiosInstance";
import {
  GET_ACTION_DETAILS,
  GET_ACTION_EDIT,
  GET_ACTION_FILTER_DATA,
  GET_ACTION_LIST_DATA,
} from "../query-constants/action-constant";
import {
  ACTION_DETAILS_GET,
  ACTION_MASTER_FILTER,
  EDIT_ACTION_GET,
  MY_ACTIONS_POST,
} from "../../api/endpoints/action-endpoints";

const staleTime = 300000;
const cacheTime = 600000;
export const useFetchActionDetails = (id) => {
  return useQuery(
    [GET_ACTION_DETAILS, id],
    () => axiosInstance.get(ACTION_DETAILS_GET + id),
    {
      refetchOnWindowFocus: false,
      cacheTime: 0,
      staleTime: 0,
      select: (res) => res?.data?.data ?? {},
    }
  );
};
export const useFetchActionEdit = (id) => {
  return useQuery(
    [GET_ACTION_EDIT, id],
    () => axiosInstance.get(EDIT_ACTION_GET + id),
    {
      refetchOnWindowFocus: false,
      cacheTime: 0,
      staleTime: 0,
      select: (res) => res?.data?.data ?? {},
    }
  );
};

export const useGetActionFilterData = () => {
  return useQuery(
    [GET_ACTION_FILTER_DATA],
    () => axiosInstance.get(`${ACTION_MASTER_FILTER}`),
    {
      refetchOnWindowFocus: false,
      cacheTime,
      staleTime,
      select: (res) => res.data.data ?? [],
    }
  );
};

export const useGetActionListData = (payload, onSuccess) => {
  return useQuery(
    [GET_ACTION_LIST_DATA, JSON.stringify(payload)],
    () => axiosInstance.post(`${MY_ACTIONS_POST}`, payload),
    {
      refetchOnWindowFocus: false,
      onSuccess,
      cacheTime,
      staleTime,
      select: (res) => res.data ?? [],
    }
  );
};
