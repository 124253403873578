import React from "react";
import { Button } from "react-bootstrap";
import { findIcon } from "../../views/dashboard/icons";

const FloatingButton = ({
  variant = "success",
  className = "d-flex align-items-center justify-content-center add_floating__button position-fixed rounded-circle p-1 avatar-80 lh-1 border border-white border-2",
  onClick,
  title = "",
  children,
}) => {
  return (
    <Button
      variant={variant}
      className={className}
      onClick={onClick}
      title={title}
    >
      {children ? children : findIcon("Plus", "outline", "32")}
    </Button>
  );
};

export default React.memo(FloatingButton);
