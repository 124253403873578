import React, { useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import { Badge, Card } from "react-bootstrap";
import { findIcon } from "../../../../dashboard/icons";

const CommentSwiper = ({ comments, title }) => {
  return (
    <Card>
      <Card.Header>
        <div className="d-flex align-items-center justify-content-center ">
          <h5 className="m-0 text-primary border-bottom border-primary border-2 w-auto px-3 pb-2 ms-4">
            {title}
          </h5>
        </div>
      </Card.Header>
      <Card.Body>
        <div className="overflow-hidden d-slider1 flex-grow-1 me-0 me-md-3">
          <Swiper
            as="ul"
            className="swiper-wrapper list-inline tableHeaderSwiper p-0 m-0 mb-2"
            modules={[Navigation]}
            data-aos="fade-up"
            data-aos-delay="700"
            slidesPerView="auto"
            spaceBetween={30}
            pagination={{
              dynamicBullets: true,
              clickable: true,
            }}
            // pagination: {
            //   el: '.slider__pagination',
            //   type: 'bullets',
            // },
          >
            {comments &&
              comments.map((item, index) => {
                return (
                  <SwiperSlide
                    className="cursor-pointer mb-1 px-2 py-1 border-1 border-gray border rounded-4"
                    key={index}
                  >
                    <Badge
                      bg="white"
                      className={
                        "ms-0 me-2 px-3 py-2 w-auto p-2 rounded text-dark"
                      }
                    >
                      <span className="text-light me-3">
                        {findIcon("Quote", "dual-tone", "40")}
                      </span>
                      <span className="fs-6">{item}</span>
                    </Badge>
                  </SwiperSlide>
                );
              })}
          </Swiper>
        </div>
      </Card.Body>
    </Card>
  );
};

export default CommentSwiper;
