export const GET_ALL_CHANNEL_LIST = "channel/getChannelList";
export const GET_ALL_PROJECT_LIST = "project/list";
export const GET_CHANNEL_DETAILS = "channel/details";
export const GET_PROJECT_DETAILS = "project/detail";
export const EDIT_PROJECT = "project/edit";
export const CREATE_PROJECT = "project/create";
export const GET_BANNER_GROUP = "project/searchCBGroup";
export const GET_CONTENT_SEARCH_GROUP_LIST = "project/search_content_group";
export const GET_CONTENT_CATEGORY_LIST = "project/content_category_list";
export const ADD_PROJECT = "project/add";
export const UPDATE_PROJECT = "project/update";
export const GET_CHANNEL_STATE_ANALYTICS_DATA = "channel/state_analytics";
export const GET_ALL_CHANNEL_VIDEOS_LIST = "channel/my_video_list";
export const GET_ALL_VIDEO_COMMENTS_LIST = "channel/get_comment_list";
export const POST_COMMENTS_ACTION = "channel/commentAction";
export const GET_CHANNEL_VIDEO_ANALYTICS = "channel/video_analytics";
export const GET_CAMPAIGN_ANALYTICS_DATA = "channel/campaign_analytics";
export const GET_CHANNEL_ANALYTICS_DATA = "channel/analytics";
export const POST_COMMENT_ACTION = "channel/comment_action";
export const GET_CAMPAIGN_lIST = "channel/campaign/list";
export const GET_CAMPAIGN_lIST_BY_CHANNEL = "channel/campaign/channel_list";
export const POST_CAMPAIGN_ADD = "channel/campaign/add";
export const CHANNEL_ADD = "channel/add_channel";
export const EDIT_CHANNEL = "channel/edit_channel";
export const UPDATE_CHANNEL = "channel/update_channel";
export const REQUEST_CONTENT_ADD = "/support/add";
export const GET_TARGET_CATEGORIES_DATA = "/channel/dt_categories";
export const GET_TARGET_REGION_DATA = "/channel/dt_geographies";
export const BASIC_UPDATE = "channel/update_basic";
export const DELETE_VIDEO = "channel/video/delete";
export const POST_STATE_LIST = "state/list";
