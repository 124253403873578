import React, { memo, useState } from "react";
import { Nav, Tab } from "react-bootstrap";
import DropDownMenu from "./DropDownMenu";
import { useSearchParams } from "react-router-dom";

function TabNavigation({ tabs, withSorting, sortOptions, onSelect }) {
  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <>
      <Tab.Container defaultActiveKey={tabs[0].eventKey} onSelect={onSelect}>
        <Nav className="tab-bottom-bordered bg-transparent d-flex allign-items-center gap-3 tab-bottom-bordered--thin">
          <div
            className="mb-0 nav nav-tabs bg-transparent fs-6 gap-5 "
            id="nav-tab1"
            role="tablist"
          >
            {tabs.map(({ title, eventKey }) => (
              <Nav.Link
                key={eventKey}
                className="px-0 py-3"
                eventKey={eventKey}
                data-bs-toggle="tab"
                data-bs-target={`#nav-${eventKey}`}
                type="button"
                role="tab"
                aria-controls={`nav-${eventKey}`}
              >
                {title}
              </Nav.Link>
            ))}
          </div>
          {withSorting && (
            <div className="d-flex gap-3 align-items-center justify-content-center ms-auto">
              <div className="fs-6">Filter</div>
              <div className="dropdown bg-white border border-1 rounded-3 px-3 py-2">
                <DropDownMenu
                  title="sort"
                  className="ms-auto"
                  constantFilterOption={sortOptions}
                  handleDropdownChange={(key, item) => {
                    setSearchParams({ ...searchParams, [item]: key });
                  }}
                />
              </div>
            </div>
          )}
        </Nav>
        <Tab.Content className="mt-4 iq-tab-fade-up" id="nav-tabContent">
          {tabs.map(({ eventKey, Content }) => (
            <Tab.Pane
              key={eventKey}
              eventKey={eventKey}
              id={`nav-${eventKey}`}
              role="tabpanel"
              aria-labelledby={`nav-${eventKey}`}
            >
              <div className="d-flex flex-column">{<Content />}</div>
            </Tab.Pane>
          ))}
        </Tab.Content>
      </Tab.Container>
    </>
  );
}

export default memo(TabNavigation);
