import React, { useState, useRef, useEffect } from "react";
//react-bootstrap
import { Card, Image } from "react-bootstrap";
//router-dom
import {
  statusOptions,
  commentDateOptions,
} from "../Constant/date-filter-constant";
import DropDownMenu from "../components/DropDownMenu";

import CommentCard from "../components/comments/CommentCard";
import { useAllGetCommentList } from "../../../../../../queries/query-hooks/workspace/workspace-hook";
import CommentsSkeleton from "../../../../../../skeleton/workspace/doctube/channel/comments-list-skeleton";
import { useParams, useSearchParams } from "react-router-dom";

import useRedirect from "../../../../../../components/custom-hooks/use-redirect";
import PageNotFound from "../../../../../PageNotFound";
import { findIcon } from "../../../../../dashboard/icons";
import { fallbackImages } from "../../../../../../common/constants/fallback-images";
import { selectProject } from "../../../../../../store/project/project.selector";
import { useSelector } from "react-redux";
export default function ChannelComments() {
  const { redirectTo } = useRedirect();
  const { channel_id } = useParams();
  const project = useSelector(selectProject);
  const [searchParams, setSearchParams] = useSearchParams();
  const initialDateFilterOption = commentDateOptions.find(
    (item) => item.initial
  );
  const commentFiterOptionsType =
    searchParams.get("filter") || initialDateFilterOption.value;
  console.log("filterOption", commentFiterOptionsType);

  // status
  const initialStatusOption = statusOptions.find((item) => item.initial);
  const statusOptionType =
    searchParams.get("status") || initialStatusOption.value;

  console.log("filterOption", statusOptionType);

  const innitialPayloads = useRef({
    default: "all",
    pagination: {
      limit: 10,
      offset: 0,
    },
    filter: {
      content_id: {
        type: "eq",
        value: channel_id,
      },
    },
    sort: {
      comment_id: "desc",
    },
    project_id: project?.project_id,
    identifier: 31,
  });

  const [payload, setPayload] = useState(innitialPayloads.current);

  const {
    data: commentData,
    isLoading: isCommentsLoading,
    refetch: refetchComment,
  } = useAllGetCommentList(payload);

  useEffect(() => {
    setPayload({
      ...payload,
      sort: {
        comment_id: "desc",
        status:
          statusOptionType == "pending"
            ? 0
            : statusOptionType == "hidden"
            ? 2
            : "",
      },
    });
  }, [statusOptionType]);

  useEffect(() => {
    setPayload({
      ...payload,
      filter: {
        content_id: {
          type: "eq",
          value: channel_id,
        },
        type: commentFiterOptionsType,
      },
    });
  }, [commentFiterOptionsType]);

  // const [statusPayload, setStatusPayload] = useState({
  //   type: statusOptions.initial,
  // });

  // const [dateFilterPayload, setDateFilterPayload] = useState({
  //   type: commentDateOptions.initial,
  // });

  // function handleDateFilterDropdownChange(type) {
  //   setDateFilterPayload({
  //     ...dateFilterPayload,
  //     type,
  //   });
  // }

  // function handleStatusDropdownChange(type) {
  //   setStatusPayload({
  //     ...statusPayload,
  //     type,
  //   });
  // }

  const handleDropdownChange = (key, item) => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set(item, key);
    setSearchParams(newSearchParams);
  };

  const onCommentAction = () => {
    refetchComment();
  };

  const onUploadFile = () => {
    redirectTo(`channel/dashboard/${channel_id}`);
  };

  return (
    <>
      <div>
        <div className="header-title mb-3 mb-md-4 d-flex flex-column flex-md-row justify-content-between">
          <h4 className="fw-semibold mb-2 mb-md-0">Video Comments</h4>
          <div className=" ms-auto d-flex gap-3 align-items-center justify-content-center">
            <div className="fs-6">Filter</div>
            <div className="dropdown bg-white border border-1 rounded-3 px-3 py-2">
              <DropDownMenu
                title="filter"
                constantFilterOption={commentDateOptions}
                handleDropdownChange={(key, item) => {
                  // setSearchParams({ ...searchParams, [item]: key });
                  handleDropdownChange(key, "filter");
                }}
              />
            </div>
            <div className="dropdown bg-white border border-1 rounded-3 px-3 py-2">
              <DropDownMenu
                title="status"
                constantFilterOption={statusOptions}
                handleDropdownChange={(key, item) => {
                  // setSearchParams({ ...searchParams, [item]: key });
                  handleDropdownChange(key, "status");
                }}
              />
            </div>
          </div>
        </div>
        <Card>
          <Card.Body className="p-2 p-md-4 overflow-x-scroll">
            {!isCommentsLoading && commentData?.length > 0 ? (
              commentData.map((data, key) => (
                <CommentCard
                  withVideoDetails
                  key={key}
                  comment_id={data.comment_id}
                  video_id={data.video_id}
                  onCommentAction={onCommentAction}
                  comment={data.comment_text}
                  commentingTime={data.comment_date}
                  username={data.user_name}
                  videoTitle={data.video_title}
                  thumbnail={data.video_thubnail}
                  approvalStatus={data.approval_status}
                />
              ))
            ) : isCommentsLoading ? (
              <>
                <CommentsSkeleton />
              </>
            ) : (
              <>
                <PageNotFound
                  title="No comments to preview yet"
                  buttonText="Return To Dashboard"
                  // message="Upload your first video to spark a conversation!"
                  backgroundImage={findIcon("Empty-comments", "dual-tone", 100)}
                  onFallbackClick={onUploadFile}
                />
              </>
            )}
          </Card.Body>
        </Card>
      </div>
    </>
  );
}
