import React, { Fragment } from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";

const NoPermission = () => {
  return (
    <Fragment>
      <div className="gradient">
        <Container>
          <h2 className="mb-0 mt-4 text-white">Unauthorized</h2>
          <p className="mt-2 text-white">
            You don't have permission to access this page
          </p>
          <Link
            className="btn bg-white text-primary d-inline-flex align-items-center"
            to="/workspace/clirnet"
          >
            Go Back
          </Link>
        </Container>
        <div className="box">
          <div className="c xl-circle">
            <div className="c lg-circle">
              <div className="c md-circle">
                <div className="c sm-circle">
                  <div className="c xs-circle"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default NoPermission;
