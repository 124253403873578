import React, { useMemo, useCallback } from "react";
import { Form, Button } from "react-bootstrap";

const MappedStates = ({
  stateLists = [],
  dropdownGroups = [],
  setDropdownGroups = () => {},
}) => {
  const statesArray = useMemo(
    () =>
      stateLists.map((item) => {
        const [key, value] = Object.entries(item)[0];
        return {
          code: key,
          name: value,
        };
      }),
    []
  );

  const addDropdownGroup = useCallback(() => {
    setDropdownGroups((prevGroups) => [
      ...prevGroups.map((group) => ({ ...group, isLocked: true })),
      {
        id:
          prevGroups.length > 0 ? prevGroups[prevGroups.length - 1].id + 1 : 1,
        firstSelect: "",
        secondSelect: "",
        isLocked: false,
      },
    ]);
  }, [setDropdownGroups]);

  const removeDropdownGroup = useCallback(
    (id) => {
      setDropdownGroups((prevGroups) =>
        prevGroups.length > 1
          ? prevGroups.filter((group) => group.id !== id)
          : prevGroups
      );
    },
    [setDropdownGroups]
  );

  const handleSelectChange = useCallback(
    (groupId, isFirstSelect, value) => {
      setDropdownGroups((prevGroups) =>
        prevGroups.map((group) => {
          if (group.id === groupId) {
            if (isFirstSelect) {
              return { ...group, firstSelect: value, secondSelect: "" };
            } else {
              return { ...group, secondSelect: value };
            }
          }
          return group;
        })
      );
    },
    [setDropdownGroups]
  );

  const getAvailableFirstDropdownStates = useCallback(
    (currentGroupId) => {
      const selectedStates = new Set();

      dropdownGroups.forEach((group) => {
        if (group.id !== currentGroupId && group.firstSelect) {
          selectedStates.add(group.firstSelect);
        }
      });

      dropdownGroups.forEach((group) => {
        if (group.secondSelect) {
          selectedStates.add(group.secondSelect);
        }
      });

      return statesArray.filter(
        (state) =>
          !selectedStates.has(state.code) ||
          state.code ===
            dropdownGroups.find((g) => g.id === currentGroupId)?.firstSelect
      );
    },
    [dropdownGroups, statesArray]
  );

  const getAvailableSecondDropdownStates = useCallback(() => {
    const selectedStates = new Set();

    dropdownGroups.forEach((group) => {
      if (group.firstSelect) {
        selectedStates.add(group.firstSelect);
      }
    });

    return statesArray.filter((state) => !selectedStates.has(state.code));
  }, [dropdownGroups, statesArray]);

  const isAddButtonDisabled = useCallback(() => {
    const lastGroup = dropdownGroups[dropdownGroups.length - 1];
    return !(lastGroup.firstSelect && lastGroup.secondSelect);
  }, [dropdownGroups]);

  return (
    <div>
      <div className="d-flex flex-column">
        {dropdownGroups.map((group, index) => (
          <div
            key={`${group.id}-${index}`}
            className="d-flex align-items-center mb-3 w-100"
          >
            <div className="d-flex flex-grow-1 me-2">
              <Form.Select
                aria-label={`First Select ${group.id}`}
                className="flex-grow-1 me-2"
                value={group.firstSelect}
                onChange={(e) =>
                  handleSelectChange(group.id, true, e.target.value)
                }
                disabled={group.isLocked}
              >
                <option value="">Select State...</option>
                {getAvailableFirstDropdownStates(group.id).map(
                  ({ code, name }, index) => (
                    <option key={`${code}-${index}`} value={code}>
                      {name}
                    </option>
                  )
                )}
              </Form.Select>

              <Form.Select
                aria-label={`Second Select ${group.id}`}
                className="flex-grow-1"
                value={group.secondSelect}
                onChange={(e) =>
                  handleSelectChange(group.id, false, e.target.value)
                }
                disabled={!group.firstSelect || group.isLocked}
              >
                <option value="">Select State...</option>
                {getAvailableSecondDropdownStates(group.id).map(
                  ({ code, name }, index) => (
                    <option key={`${code}-${index}`} value={code}>
                      {name}
                    </option>
                  )
                )}
              </Form.Select>
            </div>

            <Button
              variant="outline-danger"
              className="border-dashed me-3"
              onClick={() => removeDropdownGroup(group.id)}
              disabled={dropdownGroups.length === 1}
            >
              -
            </Button>

            {index === dropdownGroups.length - 1 && (
              <Button
                variant="outline-primary"
                className="border-dashed"
                onClick={addDropdownGroup}
                disabled={isAddButtonDisabled()}
              >
                +
              </Button>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default MappedStates;
