export const GET_CHANNEL_OVERVIEW_VIEW_REPORT =
  "branding/reporting/overview/view-report";
export const GET_CHANNEL_OVERVIEW_VIEW_COUNT =
  "branding/reporting/overview/count-summary";
export const GET_CHANNEL_OVERVIEW_CLICK_REPORT =
  "branding/reporting/overview/click-report";
export const GET_CHANNEL_OVERVIEW_LOCATION_MAP =
  "branding/reporting/overview/location-map";
export const GET_CHANNEL_OVERVIEW_DEVICE_DISTRIBUTION =
  "branding/reporting/overview/device-distribution";
export const GET_CHANNEL_OVERVIEW_ZONE_DISTRIBUTION =
  "branding/reporting/overview/zonal-distribution";
export const GET_CHANNEL_OVERVIEW_MOBILE_DISTRIBUTION =
  "branding/reporting/overview/mobile-distribution";
export const GET_CHANNEL_OVERVIEW_BROWSER_DISTRIBUTION =
  "branding/reporting/overview/browser-distribution";
export const GET_CHANNEL_OVERVIEW_LANDING_CATEGORY =
  "branding/reporting/overview/landing-category";

export const GET_CHANNEL_AUDIENCE_DAY_WISE_REPORT =
  "branding/reporting/audience/daywise-report";
export const GET_CHANNEL_AUDIENCE_AGE_WISE_REPORT =
  "branding/reporting/audience/agewise-report";
export const GET_CHANNEL_AUDIENCE_SCREEN_SIZE_WISE_REPORT =
  "branding/reporting/audience/user-screensize";
export const GET_CHANNEL_AUDIENCE_ZONAL_DISTRIBUTION =
  "branding/reporting/audience/zonal-distribution";
export const GET_CHANNEL_AUDIENCE_DEVICE_DISTRIBUTION =
  "branding/reporting/audience/device-distribution";
export const GET_CHANNEL_AUDIENCE_SPECIALITY_REPORT =
  "branding/reporting/audience/specialities-report";
export const GET_CHANNEL_AUDIENCE_SPECIALITY_REPORT_CTR =
  "branding/reporting/audience/specialities-report-ctr";

export const GET_LOCATION_MAP_DATA = "branding/reporting/location/location-map";
export const GET_LOCATION_MAJOR_CITIES =
  "branding/reporting/location/major-cities";
export const GET_LOCATION_ZONAL_DISTRIBUTION =
  "branding/reporting/location/zonal-distribution";
export const GET_LOCATION_REPORT =
  "branding/reporting/location/location-report";
export const GET_LOCATION_REPORT_CTR =
  "branding/reporting/location/location-report-p2";

export const GET_CREATIVE_DATA_LIST =
  "branding/reporting/creative/creativedata-list";
export const GET_CREATIVE_REPORT_TABLE =
  "branding/reporting/creative/report-table";
export const GET_CREATIVE_REPORT_TABLE_CTR =
  "branding/reporting/creative/report-table-p2";
export const GET_CREATIVE_DEVICE_LATENCEY =
  "branding/reporting/creative/devicelatency-report";
export const GET_CREATIVE_DIMENSION_REPORT =
  "branding/reporting/creative/dimension-report";
export const GET_CREATIVE_OS_LATENCY_REPORT =
  "branding/reporting/creative/oslatency-report";

export const GET_LANDING_PAGE_CATEGORY =
  "branding/reporting/landing-page/category-impressions";
export const GET_LANDING_PAGE_TYPE =
  "branding/reporting/creative/categorytype-imrpessions";
export const GET_LANDING_PAGE_REPORT =
  "branding/reporting/creative/category-report";
export const GET_LANDING_PAGE_REPORT_CTR =
  "branding/reporting/creative/category-report-ctr";

export const GET_SUMMARY_CAMPAIGN_ANALYTICS =
  "branding/summary/campaign-analytics";
export const GET_SUMMARY_CREATIVE_LIST = "branding/detail/creative/list";
export const GET_SUMMARY_CAMPAIGN_GROUP_LIST =
  "branding/detail/campaign-group/list";
export const GET_SUMMARY_GROUP_LIST = "branding/summary/campaigngroup/list";
export const GET_SUMMARY_IMPRESSION_GRAPH =
  "branding/summary/impressions-graph";
export const GET_SUMMARY_LOCATION_MAP = "branding/summary/location-map";
export const GET_BRAND_ADS_LOCATION_MAP_DATA =
  "branding/reporting/location/location-report";
export const GET_SUMMARY_DEVICE_DISTRIBUTION =
  "branding/summary/device-distribution";
export const GET_SUMMARY_DAYWISE_DISTRIBUTION =
  "branding/summary/daywise-distribution";
export const GET_CREATIVE_DETAILS = "branding/detail/creative";
export const GET_CREATIVE_PREVIEW = "branding/detail/creative/preview";
