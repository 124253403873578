import React, { Fragment, useEffect, useState, useRef } from "react";
//react-bootstrap
import { Card, Image } from "react-bootstrap";
import DropDownMenu from "../components/DropDownMenu";
import CommentCard from "../components/comments/CommentCard";
import { statusOptions } from "../Constant/date-filter-constant";
import { useAllGetCommentList } from "../../../../../../queries/query-hooks/workspace/workspace-hook";
import CommentsSkeleton from "../../../../../../skeleton/workspace/doctube/channel/comments-list-skeleton";
import { currentChannel } from "../../../../../../store/channel/channel.selector";
import { useSelector } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";
import PageNotFound from "../../../../../PageNotFound";
import { findIcon } from "../../../../../dashboard/icons";
import useRedirect from "../../../../../../components/custom-hooks/use-redirect";
import { selectProject } from "../../../../../../store/project/project.selector";

function ChannelVideoComments() {
  const { redirectTo } = useRedirect();
  const { video_id } = useParams();
  const project = useSelector(selectProject);
  const [searchParams, setSearchParams] = useSearchParams();
  const initialDateFilterOption = statusOptions.find((item) => item.initial);
  const sortOption =
    searchParams.get("status") || initialDateFilterOption.value;

  const innitialPayloads = useRef({
    default: "all",
    pagination: {
      limit: 10,
      offset: 0,
    },
    filter: {
      content_id: {
        // video_id or channel_id
        type: "eq",
        value: video_id,
      },
    },
    sort: {
      comment_id: "desc",
      status: sortOption,
    },
    project_id: project?.project_id,
    identifier: 30,
  });

  const [payload, setPayload] = useState(innitialPayloads.current);

  const {
    data: commentData,
    isLoading: isCommentsLoading,
    refetch: refetchComment,
  } = useAllGetCommentList(payload);

  useEffect(() => {
    setPayload({
      ...payload,
      sort: {
        comment_id: "desc",
        status: sortOption == "pending" ? 0 : sortOption == "hidden" ? 2 : "",
      },
    });
    // refetchComment()
  }, [sortOption]);

  const onCommentAction = () => {
    refetchComment();
  };

  const onUploadFile = () => {
    redirectTo(`channel/video/${video_id}`);
  };

  return (
    <Fragment>
      <div className="header-title mb-4 d-flex justify-content-between align-items-center">
        <h4 className="fw-semibold mb-0">Video Comments</h4>
        <div className="d-flex gap-3 align-items-center justify-content-center">
          <div className="fs-6">Sort By</div>

          <div className="dropdown bg-white border border-1 rounded-3 px-3 py-2">
            <DropDownMenu
              title="status"
              constantFilterOption={statusOptions}
              handleDropdownChange={(key, item) => {
                setSearchParams({ ...searchParams, [item]: key });
              }}
            />
          </div>
        </div>
      </div>

      <Card>
        <Card.Body className="p-4">
          {!isCommentsLoading && commentData?.length > 0 ? (
            commentData.map((data, key) => (
              <CommentCard
                key={key}
                comment_id={data.comment_id}
                video_id={video_id}
                onCommentAction={onCommentAction}
                comment={data.comment_text}
                commentingTime={data.comment_date}
                username={data.user_name}
                videoTitle={data.content_title}
                thumbnail={data.thumbnail}
                approvalStatus={data.approval_status}
              />
            ))
          ) : isCommentsLoading ? (
            <>
              <CommentsSkeleton />
            </>
          ) : (
            <>
              <PageNotFound
                title="No comments to preview yet"
                buttonText="Return To Video Details"
                // message="Upload your first video to spark a conversation!"
                backgroundImage={findIcon("Empty-comments", "dual-tone", 100)}
                onFallbackClick={onUploadFile}
              />
            </>
          )}
        </Card.Body>
      </Card>
    </Fragment>
  );
}

export default ChannelVideoComments;
