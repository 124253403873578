import React, { useState, useRef, useEffect } from "react";
import Flatpickr from "react-flatpickr";
import Badge from "react-bootstrap/Badge";
import DateRangePicker from "./DateRangePicker";
import GridListView from "./GridListView";
import Select from "react-select";
import { formatLocalizedDate } from "../../../../../common/utils/utils";
import Skeleton from "../../../../../components/skeleton";

function Titlebar({
  tags,
  title,
  subTitle,
  projectType,
  setDateRangePayload,
  projectStartDate = "",
  projectEndDate = "",
  dateLabel,
  showListView = false,
  showDatePicker = true,
  tabs = [],
  activeSummaryTab = {},
  setActiveSummaryTab = () => {},
  isSummaryListLoading = false,
  ...props
}) {
  const [modalShow, setModalShow] = useState(false);
  const [selectedRange, setSelectedRange] = useState(null);

  const today = new Date();
  let endDateLimit = new Date(projectEndDate);

  if (endDateLimit.getTime() > today.getTime()) {
    endDateLimit = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() - 1
    );
  }

  const handleChange = (event) => {
    setActiveSummaryTab(event);
  };

  const formattedTabs =
    tabs?.map((tab) => {
      const content_title = tab?.fetch_with_image[0]?.content_title;
      const id = tab?.content_id;
      return {
        value: id,
        label: content_title,
      };
    }) || [];

  const customFilterOption = (option, searchText) => {
    const labelMatch = option.label
      .toLowerCase()
      .includes(searchText.toLowerCase());

    const valueMatch = option.value
      .toString()
      .toLowerCase()
      .includes(searchText.toLowerCase());
    return labelMatch || valueMatch;
  };

  const customStyles = {
    container: (provided) => ({
      ...provided,
      width: "400px",
    }),
    control: (provided) => ({
      ...provided,
      borderRadius: "0.375rem",
      borderColor: "#ced4da",
      boxShadow: "none",
      "&:hover": {
        borderColor: "#80bdff",
      },
    }),
    menu: (provided) => ({
      ...provided,
      width: "400px",
    }),
    singleValue: (provided) => ({
      ...provided,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      maxWidth: "100%",
    }),
    valueContainer: (provided) => ({
      ...provided,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    }),
  };

  return (
    <div className="header-title mb-3 mb-md-3 d-flex flex-column flex-md-row justify-content-between align-items-md-center">
      <div className="d-flex flex-column gap-1">
        <div className="d-flex flex-row align-items-center justify-content-md-center mb-3 mb-md-0">
          <h4 className="fw-semibold mb-0 mb-md-0">{title}</h4>
          <Badge pill bg="primary" className="ms-2 me-2 px-2 py-1">
            {projectType}
          </Badge>
          <Badge
            pill
            bg="secondary light text-success"
            className="ms-2 fs-7 bg-opacity-10 px-2"
          >
            Start Date:{" "}
            {projectStartDate
              ? formatLocalizedDate(projectStartDate, "DD-MM-YYYY")
              : "N/A"}
          </Badge>
          {/* {tags && tags?.length > 0 &&
            tags?.map((item, index) => {
              return (
                <Badge key={index} pill bg="success light text-success" className="ms-2 fs-7 bg-opacity-10 px-2">{item}</Badge>
              );
            })
          } */}
        </div>
        {/* <h6 className="mt-2 fs-6">{subTitle}</h6> */}
      </div>
      {["Analytics", "Audience", "Location"].includes(title) ? (
        <div>
          <Select
            options={formattedTabs}
            placeholder={activeSummaryTab?.label}
            name={activeSummaryTab?.label}
            isLoading={isSummaryListLoading}
            value={activeSummaryTab?.value}
            isSearchable
            onChange={handleChange}
            styles={customStyles}
            filterOption={customFilterOption}
          />
        </div>
      ) : null}

      <div className="form-group mb-0 d-flex align-items-center justify-content-between">
        <div>
          {!isSummaryListLoading ? (
            showDatePicker && (
              <DateRangePicker
                startDate={projectStartDate}
                endDate={endDateLimit}
                setDateRangePayload={setDateRangePayload}
                dateLabel={dateLabel}
              />
            )
          ) : (
            <Skeleton width={220} height={55} className="m-0" />
          )}
        </div>
        {showListView && (
          <div>
            <GridListView
              isListView={props.isListView}
              setIsListView={props.setIsListView}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default Titlebar;
