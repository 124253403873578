import React, { useEffect, useState } from "react";
import { Card, Row, Col, Button, Badge } from "react-bootstrap";
import Chart from "react-apexcharts";
import Titlebar from "../components/Titlebar";
import PolarChart from "../../../../../components/chart/PolarChart";
import BasicColumnChart from "../../../../../components/chart/BasicColumnChart";
import ReactTable from "../../../../../lib/table/react-table";
import { findIcon } from "../../../../dashboard/icons";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import SparlLineBar from "../../../../../components/chart/SparlLineBar";
import TableHeaderSwiper from "../components/TableHeaderSwiper";
import { initialDate } from "./../constant/date-range-constant";
import { useParams } from "react-router-dom";
import {
  useGetLandingPageCategory,
  useGetLandingPageCategoryReport,
  useGetLandingPageCategoryReportCtr,
  useGetLandingPageCategoryType,
  useGetSummaryCampaignGroupList,
} from "../../../../../queries/query-hooks/workspace/brand-ads-hook";
import {
  formatLocalizedDate,
  titleCase,
  transformGraphData,
} from "../../../../../common/utils/utils";
import SquareChartSkeleton from "../../../../../skeleton/workspace/clirnet/brand-ads/square-chart-skeleton";
import BarChartSkeleton from "../../../../../skeleton/workspace/clirnet/brand-ads/bar-chart-skeleton";
import CommonTableLoader from "../../../../../skeleton/common-table-loader";
import NavSwiperSkeleton from "../../../../../skeleton/workspace/clirnet/brand-ads/nav-swiper-skeleton";
import PageNotFound from "../../../../PageNotFound";
import { fallbackImages } from "../../../../../utilities/fallback-images";
import { useGetProjectDetails } from "../../../../../queries/query-hooks/workspace/workspace-hook";
import Skeleton from "../../../../../components/skeleton";
import useCsvDownloader from "../../../../../components/custom-hooks/use-csv-downloader";
import { GRAPH_FILL_COLOR } from "../constant/graph-color-scheme";
import PieChart from "../../../../../components/chart/PieChart";
import * as dayjs from "dayjs";
const DAYS_OF_WEEK = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

function LandingPage() {
  const { project_id } = useParams();

  const { downloadCSV } = useCsvDownloader();

  const handleTableCsvDownload = () => {
    downloadCSV(tableCsvFormat);
  };

  const [activeCampaignGroup, setActiveCampaignGroup] = useState({});

  function getGroupIdsCommaSeparated(data) {
    return data.map((item) => item.group_id).join(",");
  }

  const { data: projectDetails, isLoading: isProjectDetailsLoading } =
    useGetProjectDetails(project_id);

  const [dateRangePayload, setDateRangePayload] = useState({
    start_date:
      projectDetails?.[0]?.project_start_date || initialDate.start_date,
    end_date: projectDetails?.[0]?.project_end_date || initialDate.end_date,
  });

  const { data: landingPageCategory, isLoading: isLandingPageCategoryLoading } =
    useGetLandingPageCategory(project_id, {
      start_date: dateRangePayload.start_date,
      end_date: dateRangePayload.end_date,
      campaign_list:
        projectDetails && projectDetails?.length > 0
          ? getGroupIdsCommaSeparated(projectDetails[0]?.project_group_data)
          : "",
    });

  const {
    data: landingPageCategoryType,
    isLoading: isLandingPageCategoryTypeLoading,
  } = useGetLandingPageCategoryType(project_id, {
    start_date: dateRangePayload.start_date,
    end_date: dateRangePayload.end_date,
    campaign_list:
      projectDetails && projectDetails?.length > 0
        ? getGroupIdsCommaSeparated(projectDetails[0]?.project_group_data)
        : "",
  });

  const { data: landingPageReport, isLoading: isLandingPageReportLoading } =
    useGetLandingPageCategoryReport(project_id, {
      start_date: dateRangePayload.start_date,
      end_date: dateRangePayload.end_date,
      campaign_list: activeCampaignGroup?.id,
    });

  const {
    data: landingPageReportCtr,
    isLoading: isLandingPageReportLoadingCtr,
  } = useGetLandingPageCategoryReportCtr(project_id, {
    start_date: dateRangePayload.start_date,
    end_date: dateRangePayload.end_date,
    campaign_list: activeCampaignGroup?.id,
  });

  const { data: campaignGroupList, isLoading: isCampaignGroupListLoading } =
    useGetSummaryCampaignGroupList(project_id, {
      campaign_list:
        projectDetails && projectDetails?.length > 0
          ? getGroupIdsCommaSeparated(projectDetails[0]?.project_group_data)
          : "",
    });

  const pageTypeChartOptions = {
    labels:
      landingPageCategoryType && landingPageCategoryType?.length > 0
        ? transformGraphData(
            landingPageCategoryType,
            "pct_count",
            "Landing_page_type",
            false
          )?.label
        : [],
    series:
      landingPageCategoryType && landingPageCategoryType?.length > 0
        ? transformGraphData(
            landingPageCategoryType,
            "pct_count",
            "Landing_page_type"
          )?.series
        : [],
  };

  const extractWeekData = (data) => {
    if (data?.length <= 0) {
      return [];
    }
    const days = ["day1", "day2", "day3", "day4", "day5", "day6", "day7"];
    return days.map((day) => data[day]);
  };

  const TableColumnData = [
    {
      Header: "Landing Page Type",
      columnId: 1,
      accessor: "Landing_page_type",
      disableFilters: true,
      Cell: ({ value }) => `${value ? titleCase(value, false) : "-"}`,
    },

    {
      Header: "Viewed",
      columnId: 3,
      accessor: "views",
      disableFilters: true,
      Cell: ({ value }) => `${value ? value : 0}`,
    },
    // {
    //   Header: "Mobile Impression",
    //   columnId: 4,
    //   accessor: "mobile_imp",
    //   disableFilters: true,
    //   Cell: ({ value }) => `${value ? value : 0}`,
    // },
    // {
    //   Header: "Web Impression",
    //   columnId: 5,
    //   accessor: "web_imp",
    //   disableFilters: true,
    //   Cell: ({ value }) => `${value ? value : 0}`,
    // },
    // {
    //   Header: "Total Impression",
    //   columnId: 6,
    //   accessor: "total_impr",
    //   disableFilters: true,
    //   Cell: ({ value }) => `${value ? value : 0}`,
    // },
    {
      Header: "Total CTR (%)",
      columnId: 7,
      accessor: "ctr",
      disableFilters: true,
      Cell: ({ value, row }) => {
        return value == "loading" ? (
          <Skeleton width={100} />
        ) : value ? (
          `${value} %`
        ) : (
          "0 %"
        );
      },
    },
    {
      Header: "Trends",
      columnId: 8,
      accessor: "date_Data",
      disableFilters: true,
      Cell: ({ cell: { value } }) => {
        if (value && value.split(",")?.length > 0) {
          return (
            <SparlLineBar
              series={value?.split(",") || []}
              labels={DAYS_OF_WEEK}
              height={50}
            />
          );
        } else {
          return "N/A";
        }
      },
    },
  ];
  const getCategories = (data) => {
    const categories = data?.map((item) => item?.Landing_page_type);
    const uniqueCategories = [...new Set(categories)];

    const colors = [
      "#13388c",
      "#159a6c",
      "#15607a",
      "#1d81a2",
      "#cecdcd",
      "#636E72",
    ];

    const series = uniqueCategories.map((item, index) => {
      const filteredData = data?.filter(
        (data) => data?.Landing_page_type == item
      );
      return {
        name: item,
        data:
          filteredData && filteredData?.length > 0
            ? transformGraphData(filteredData, "Count_value", "formatted_date")
                ?.series
            : [],
        color: colors[index],
      };
    });

    return series;
  };

  const pageCategoryChartOption = {
    series: getCategories(landingPageCategory),
    labels:
      landingPageCategory && landingPageCategory?.length > 0
        ? [
            ...new Set(
              transformGraphData(
                landingPageCategory,
                "Count_value",
                "formatted_date"
              )?.label
            ),
          ]
        : [],
  };

  function mergeLandingPageData(pageList, pageWiseCtr) {
    if (!pageWiseCtr && pageList?.length > 0) {
      return pageList.map((items) => ({
        ...items,
        unique_views: "loading",
        unique_clicks: "loading",
        ctr: "loading",
      }));
    } else if (
      pageList &&
      pageList?.length > 0 &&
      pageWiseCtr &&
      pageWiseCtr?.length > 0
    ) {
      const mergedData = pageList.map((items) => {
        const matchingMatrix = pageWiseCtr.find(
          (matrix) => matrix.Landing_page_type === items.Landing_page_type
        );
        // console.log("matchingMatrix", matchingMatrix);
        return {
          ...(matchingMatrix || {}),
          ...items,
        };
      });
      return mergedData.sort((a, b) => b.views - a.views);
    } else {
      return pageList && Array.isArray(pageList)
        ? pageList.sort((a, b) => b.views - a.views)
        : pageList;
    }
  }

  const landingPageTableData = mergeLandingPageData(
    landingPageReport,
    landingPageReportCtr
  );

  console.log(
    "landingPageReport",
    landingPageReport,
    landingPageReportCtr,
    landingPageTableData
  );

  const tableCsvFormat = {
    header: [
      "Landing page type",
      "views",
      "Total CTR (%)",
      // "mobile imp",
      // "web imp",
      // "total impr",
    ],
    column: [
      "Landing_page_type",
      "views",
      "ctr",
      // "mobile_imp",
      // "web_imp",
      // "total_impr",
    ],
    // chart: {
    //   header: "Trends",
    //   column: "date_Data",
    // },
    rows: landingPageTableData,
  };

  // function removeString (arr) {
  //   return arr?.map((item) => {

  //   })
  // }
  useEffect(() => {
    if (projectDetails && projectDetails?.length > 0) {
      const today = dayjs();
      const projectEndDate = dayjs(projectDetails[0]?.project_end_date);
      let result = projectDetails[0]?.project_end_date;

      if (projectEndDate && projectEndDate.isAfter(today)) {
        result = today.subtract(1, "day");
      } else {
        result = projectEndDate;
      }

      setDateRangePayload({
        start_date: formatLocalizedDate(
          new Date(projectDetails[0]?.project_start_date),
          "YYYY-MM-DD[T]00:00:00.000Z"
        ),
        end_date: formatLocalizedDate(
          new Date(result),
          "YYYY-MM-DD[T]23:59:59.SSSZ"
        ),
      });
    }
  }, [projectDetails]);
  return (
    <>
      <Row>
        <Col md={12} sm={12}>
          <Titlebar
            title={"Landing Page"}
            projectType={"Brand Ads"}
            setDateRangePayload={setDateRangePayload}
            projectStartDate={
              projectDetails?.length > 0
                ? projectDetails[0]?.project_start_date
                : "   "
            }
            projectEndDate={
              projectDetails?.length > 0
                ? projectDetails[0]?.project_end_date
                : "   "
            }
            dateLabel={dateRangePayload}
            isProjectDetailsLoading={isProjectDetailsLoading}
          />
        </Col>

        <Col md={4} sm={12} className="mb-4">
          {!isProjectDetailsLoading &&
          !isLandingPageCategoryTypeLoading &&
          landingPageCategoryType?.length > 0 ? (
            <PieChart
              labels={pageTypeChartOptions.labels}
              series={pageTypeChartOptions.series}
              title={"Impression by Landing Page Type"}
              graphColor={GRAPH_FILL_COLOR}
              height={400}
              width={320}
            />
          ) : isProjectDetailsLoading || isLandingPageCategoryTypeLoading ? (
            <SquareChartSkeleton />
          ) : (
            <PageNotFound
              title="No data found"
              // message="Upload one to start analyzing data!"
              backgroundImage={findIcon("Empty-folder", "dual-tone", 100)}
            />
          )}
        </Col>

        <Col md={8} sm={12} className="mb-4">
          {!isProjectDetailsLoading &&
          !isLandingPageCategoryLoading &&
          landingPageCategory?.length > 0 ? (
            <BasicColumnChart
              title={"Impression by Landing Page Category "}
              series={pageCategoryChartOption.series}
              labels={pageCategoryChartOption.labels}
            />
          ) : isProjectDetailsLoading || isLandingPageCategoryLoading ? (
            <BarChartSkeleton />
          ) : (
            <PageNotFound
              title="No data found"
              // message="Upload one to start analyzing data!"
              backgroundImage={findIcon("Empty-folder", "dual-tone", 100)}
            />
          )}
        </Col>

        <Col md={12} sm={12}>
          <div className="d-flex">
            <h5 className="m-0 text-primary border-bottom border-primary border-2 w-auto px-3 pb-2 ms-4">
              Landing page
            </h5>
          </div>
          <Card>
            <Card.Header className="d-flex flex-row flex-md-row">
              {!isCampaignGroupListLoading && campaignGroupList.length > 0 ? (
                <TableHeaderSwiper
                  title={campaignGroupList}
                  activeCampaignGroup={activeCampaignGroup}
                  setActiveCampaignGroup={setActiveCampaignGroup}
                />
              ) : (
                <NavSwiperSkeleton />
              )}

              {!isLandingPageReportLoading && landingPageReport?.length > 0 ? (
                <div className="d-flex flex-row gap-3 flex-shrink-0">
                  {/* <div className="text-success">
                  {findIcon("DownloadReport", "dual-tone", "18")}
                  <span className="text-black fs-7 px-2 d-none d-md-inline-block">
                    Download Report
                  </span>
                </div> */}
                  <div
                    className="text-success cursor-pointer"
                    onClick={handleTableCsvDownload}
                  >
                    {findIcon("ExportReport", "dual-tone", "20")}
                    <span className="text-black fs-7 px-2 d-none d-md-inline-block">
                      Export
                    </span>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </Card.Header>
            <Card.Body>
              {!isProjectDetailsLoading &&
              !isLandingPageReportLoading &&
              landingPageReport?.length > 0 ? (
                <ReactTable
                  data={
                    // landingPageTableData?.length > 30
                    //   ? landingPageTableData.slice(0, 30)
                    //   : landingPageTableData

                    landingPageTableData
                  }
                  columns={TableColumnData}
                  isPaginationShow={false}
                  noDataText="no data"
                  noDataImage={fallbackImages.noActionData}
                />
              ) : isLandingPageReportLoading || isProjectDetailsLoading ? (
                <CommonTableLoader tableRowLength={5} />
              ) : (
                <PageNotFound
                  title="No data found"
                  // message="Upload one to start analyzing data!"
                  backgroundImage={findIcon("Empty-folder", "dual-tone", 100)}
                />
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default LandingPage;
