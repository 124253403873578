import React, { useState, Fragment } from "react";
import { Card, Row, Col } from "react-bootstrap";
import Chart from "react-apexcharts";
import { analyticsdateFilterOptions } from "../../Constant/date-filter-constant";
import DropDownMenu from "../DropDownMenu";
import DatamapsIndia from "react-datamaps-india";
import { useGetChannelAnalytics } from "../../../../../../../queries/query-hooks/workspace/workspace-hook";
import { GRAPH_FILL_COLOR } from "../../Constant/graph-color-scheme";
function AnalyticsAudience({ data: audienceData }) {
  const [payload, setPayload] = useState({
    type: analyticsdateFilterOptions.initial,
  });

  const { device, os, browser, state } =
    audienceData && Object.values(audienceData)?.length > 0
      ? audienceData
      : {
          device: {},
          os: {},
          browser: {},
          state: {},
        };

  const mobileDistributionOptions = {
    series:
      os?.length > 0 && Object?.values(os[0])?.length > 0
        ? Object.values(os[0]).every((item) => item === 0)
          ? []
          : Object.values(os[0])
        : [], // Represents Android and iOS respectively
    options: {
      colors: GRAPH_FILL_COLOR,
      chart: {
        type: "donut",
      },
      legend: {
        position: "bottom",
      },
      labels:
        os?.length > 0 && Object?.values(os[0])?.length > 0
          ? Object.keys(os[0])
          : [], // Data labels for Android and iOS
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
      noData: {
        text: "No Data Available", // This provides a message in case the data is empty
        align: "center",
        verticalAlign: "middle",
        style: {
          color: "#999",
          fontWeight: 600,
          fontSize: "18px",
        },
      },
    },
  };

  // Browser Distribution
  const browserDistributionOptions = {
    series:
      browser?.length > 0 && Object?.values(browser[0])?.length > 0
        ? Object.values(browser[0]).every((item) => item === 0)
          ? []
          : Object.values(browser[0])
        : [],
    options: {
      colors: GRAPH_FILL_COLOR,
      chart: {
        type: "donut",
      },
      stroke: {
        colors: ["#fff"],
      },
      fill: {
        opacity: 0.8,
      },
      legend: {
        position: "bottom",
      },
      labels:
        browser?.length > 0 && Object.keys(browser[0])?.length > 0
          ? Object.keys(browser[0])
          : [],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
              height: 300,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
      noData: {
        text: "No Data Available", // This provides a message in case the data is empty
        align: "center",
        verticalAlign: "middle",
        style: {
          color: "#999",
          fontWeight: 600,
          fontSize: "18px",
        },
      },
    },
  };

  // device distribution
  const deviceDistribution = {
    series:
      device?.length > 0 && Object.values(device[0])?.length > 0
        ? Object.values(device[0]).every((item) => item === 0)
          ? []
          : Object.values(device[0])
        : [],
    options: {
      colors: GRAPH_FILL_COLOR,
      chart: {
        type: "donut",
      },
      legend: {
        show: true,
        position: "bottom",
      },
      labels:
        device?.length > 0 && Object.keys(device[0])?.length > 0
          ? Object.keys(device[0])
          : [],
      plotOptions: {
        radialBar: {
          dataLabels: {
            total: {
              show: false,
              label: "Total",
            },
          },
        },
      },
      noData: {
        text: "No Data Available", // This provides a message in case the data is empty
        align: "center",
        verticalAlign: "middle",
        style: {
          color: "#999",
          fontWeight: 600,
          fontSize: "18px",
        },
      },
    },
  };

  function transferStateData(input) {
    if (!input || Object.keys(input)?.length <= 0) {
      return {};
    } else {
      return Object.keys(input).map((key) => ({
        [key]: { value: input[key] },
      }));
    }
  }

  const formatMapData = (mapData) => {
    const output = {};
    for (const [key, value] of Object.entries(mapData)) {
      const formattedKey = key.replace(/_/g, " ");
      output[formattedKey] = {
        value: value || 0,
      };
    }
    return output;
  };

  return (
    <Fragment>
      <Row>
        <Col md={4} sm={12}>
          <Card>
            <Card.Header className="align-items-center justify-content-between">
              <h5 className="text-center">Device Distribution </h5>
            </Card.Header>
            <Card.Body className="p-2">
              <div id="chart">
                <Chart
                  options={deviceDistribution.options}
                  series={deviceDistribution.series}
                  type="donut"
                  height={300}
                />
              </div>
            </Card.Body>
          </Card>
        </Col>

        <Col md={4} sm={12}>
          <Card>
            <Card.Header className=" align-items-center justify-content-between">
              <h5 className="text-center">Browser Distribution </h5>
            </Card.Header>
            <Card.Body className="p-2">
              <div
                id="chart"
                className="d-flex align-items-center justify-content-center"
              >
                <Chart
                  options={browserDistributionOptions.options}
                  series={browserDistributionOptions.series}
                  type="pie"
                  height={300}
                />
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4} sm={12}>
          <Card>
            <Card.Header className=" align-items-center justify-content-between">
              <h5 className="text-center">Mobile Device Distribution </h5>
            </Card.Header>
            <Card.Body className="p-2">
              <div
                id="chart"
                className="d-flex align-items-center justify-content-center"
              >
                {audienceData && Object.values(audienceData)?.length > 0 && (
                  <Chart
                    options={mobileDistributionOptions.options}
                    series={mobileDistributionOptions.series}
                    type="donut"
                    height={300}
                  />
                )}
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Card>
        <Card.Body>
          <Row>
            <Card.Header className=" align-items-center justify-content-center">
              <h5 className="">State Wise View Duration</h5>
            </Card.Header>

            {state &&
            state?.length > 0 &&
            Object.values(state[0]).every((item) => item === 0) ? (
              <h6 className="text-center text-secondary fw-bold mt-4 mb-4 p-3">
                No Data Available
              </h6>
            ) : (
              <>
                <Col md={6} sm={12}>
                  <Card.Body className="d-flex h-100 flex-column justify-content-center">
                    {state &&
                      state?.length > 0 &&
                      Object.values(state[0])
                        .sort((a, b) => b - a)
                        .map(
                          (data, key, arr) =>
                            data > 0 &&
                            key < 7 && (
                              <div
                                key={key}
                                className="d-flex align-items-center justify-content-between mt-2"
                              >
                                {" "}
                                <h6 className="mb-0 flex-shrink-0">
                                  {Object.keys(state[0]).find(
                                    (key) => state[0][key] === data
                                  ) ?? 0}{" "}
                                </h6>
                                {/* {console.log("data", arr)} */}
                                <div
                                  className="progress bg-soft-primary shadow-none flex-grow-1 ms-3"
                                  style={{ height: "6px" }}
                                >
                                  <progress
                                    role
                                    className="progress-bar bg-secondary w-100"
                                    data-toggle="progress-bar"
                                    value={data}
                                    max="100"
                                  ></progress>
                                </div>
                                <span className="mb-0 ms-3 flex-shrink-0">
                                  {data} %
                                </span>
                              </div>
                            )
                        )}
                  </Card.Body>
                </Col>

                <Col md={6} lg={6} sm={12}>
                  <Card.Body className="p-1 text-center india-map position-relative">
                    {state && state?.length > 0 && (
                      <>
                        <DatamapsIndia
                          style={{ postion: "relative", left: "25%" }}
                          regionData={formatMapData(state[0])}
                          hoverComponent={({ value }) => {
                            return (
                              <div>
                                {value.name} {Math.round(value?.value) ?? 0} %
                              </div>
                            );
                          }}
                          mapLayout={{
                            legendTitle: "Darker colour is higher",
                            startColor: "white",
                            endColor: "#005ce6",
                            hoverTitle: "Total",
                            noDataColor: "#13388C",
                            borderColor: "#15607A",
                            hoverColor: "#15946C",
                            hoverBorderColor: "gray",
                            height: 10,
                            weight: 30,
                          }}
                        />
                        {/* <div className="map-legend">Darker colour is higher</div> */}
                      </>
                    )}
                  </Card.Body>
                </Col>
              </>
            )}
          </Row>
        </Card.Body>
      </Card>
    </Fragment>
  );
}

export default AnalyticsAudience;
