// export const GRAPH_FILL_COLOR = ['#13388C', '#15946C', '#BCBCBC', '#15607A', '#1D81A2']
export const GRAPH_FILL_COLOR = [
  "#13388C",
  "#15946C",
  "#4682B4",
  "#70A0C2",
  "#B2DFDB",
  "#FF6B6B",
  "#FFC0CB",
  "#90EE90",
  "#FFD700",
  "#D9663B",
  "#636E72",
];
