import dayjs from "dayjs";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { Badge, Button, Col, Form, Modal, Table } from "react-bootstrap";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import Select from "react-select";
import {
  UPDATE_ACTION_POST,
  UPLOAD_FILES_POST,
} from "../../../../api/endpoints/action-endpoints";
import postService from "../../../../api/services/post-service";
import uploadService from "../../../../api/services/upload-service";
import Card from "../../../../components/bootstrap/card";
import useRedirect from "../../../../components/custom-hooks/use-redirect";
import SubmitButton from "../../../../components/form-components/submit-button";
import { useFetchActionEdit } from "../../../../queries/query-hooks/action-hook";
import {
  useFetchOnboardingCities,
  useFetchOnboardingStates,
  useFetchOnboardingZones,
  useOnboardingEdit,
} from "../../../../queries/query-hooks/onboarding-hook";
import { findIcon } from "../../../dashboard/icons";
import { STATUS_CONSTANTS } from "../constants/action-constants";
import { fullInitialValues, fullSchema } from "../constants/form-helpers";
import useBackdrop from "../../../../components/custom-hooks/use-backdrop";
import Skeleton from "../../../../components/skeleton";
import MediaRenderer from "../components/MediaRenderer";
import AddLogModal from "../components/add-log-modal";
import { ONBOARDING_ADD_LOG } from "../../../../api/endpoints/onboarding-endpoints";
import AddCommentsModal from "../components/AddCommentsModal";
import app_config from "../../../../common/data/app_config";

const IMAGE_BASE_URL = app_config.upload_file_destination;
const OnboardingApprovalEditable = () => {
  const { redirectTo } = useRedirect();
  const [viewModalShow, setViewModalShow] = useState(false);
  const [isRejecting, setIsRejecting] = useState(false);
  const [previousValues, setPreviousValues] = useState({});
  const [addedLogs, setAddedLogs] = useState([]);

  const [comment, setComment] = useState("");
  const { actionId } = useParams();

  const [showAddlogModal, setShowAddlogModal] = useState(false);
  const [commentsModal, setCommentsModal] = useState(false);

  const formik = useFormik({
    initialValues: fullInitialValues,
    validationSchema: fullSchema,
    onSubmit: (values) => {
      if (
        (actionDetails?.action_type_id?.action_type_code ==
          "onboarding_review_edit" ||
          actionDetails?.action_type_id?.action_type_code ==
            "re_onboarding_review_edit") &&
        (!addedLogs || addedLogs?.length <= 0)
      ) {
        toast.error("Please add at least one log");
        return false;
      }

      handleOnboardingUpdate(
        values,
        isRejecting ? STATUS_CONSTANTS.REJECTED : STATUS_CONSTANTS.APPROVED
      );
    },
  });
  const {
    values,
    setValues,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
  } = formik;

  const {
    data: actionDetails,
    isLoading: isActionDetailsLoading,
    refetch: refetchAction,
  } = useFetchActionEdit(actionId);
  const { data: onboardingDropdownValues } = useOnboardingEdit();
  const { origin: hcpInformation } = actionDetails || {
    origin: null,
  };
  const {
    action_to_onboarding: chnagedInformation,
    status: actionStatus,
    logs: actionLogs,
  } = actionDetails || {
    action_to_onboarding: null,
    status: "",
    logs: [],
  };

  const actionInfo = {
    priority: actionDetails?.action_priority_id?.priority_name ?? "",
    status: actionDetails?.status?.value ?? "",
    deadline: actionDetails?.action_due_date
      ? dayjs(actionDetails?.action_due_date).format("DD MMM YYYY")
      : "",
    name: actionDetails?.action_name ?? "",
    description: actionDetails?.action_description ?? "",
    type: actionDetails?.action_type_id?.action_type_name ?? "",
    createdBy: actionDetails?.action_assign_by?.name ?? "",
  };

  const closeAddLogModal = () => setShowAddlogModal(false);

  // useEffect(() => {
  //   console.log("actionDetails", actionDetails);
  //   if (
  //     actionDetails &&
  //     (actionDetails?.action_type_id?.action_type_code ==
  //       "onboarding_approval" ||
  //       actionDetails?.action_type_id?.action_type_code ==
  //         "re_onboarding_approval" ||
  //       actionDetails?.action_type_id?.action_type_code ==
  //         "onboarding_review_edit" ||
  //       actionDetails?.action_type_id?.action_type_code ==
  //         "re_onboarding_review_edit") &&
  //     actionDetails?.status?.id != STATUS_CONSTANTS.PENDING
  //   ) {
  //     redirectTo("action/all-action");
  //   }
  // }, [actionDetails]);

  function filterDocumentsByType(allData, type) {
    const filteredDocuments = allData
      .filter((document) => document.hcp_document_type === type)
      .map((document) => document.hcp_document_path);
    console.log("filteredDocuments:", filteredDocuments);
    return filteredDocuments;
  }

  useEffect(() => {
    if (hcpInformation && typeof hcpInformation == "object") {
      const changedInfoValues = {
        ...fullInitialValues,
        firstName: hcpInformation?.hcp_first_name || "",
        middleName: hcpInformation?.hcp_middle_name || "",
        lastName: hcpInformation?.hcp_last_name || "",
        age: hcpInformation?.hcp_age || "",
        gender: hcpInformation?.hcp_gender || "",
        email: hcpInformation?.hcp_email || "",
        mobileNo: hcpInformation?.hcp_mobile || "",
        association: hcpInformation?.hcp_to_association?.association_id || "",
        degree:
          hcpInformation.hcp_to_medical_degrees?.length > 0
            ? hcpInformation.hcp_to_medical_degrees?.map(
                (item) => item.medical_degree.degree_id
              )
            : [], //hcpInformation?.hcp_medical_degree_id || "",
        typeOfPractice: hcpInformation?.hcp_to_type?.hcp_type_id || "",
        council: hcpInformation?.hcp_to_medical_council?.council_id || "",
        registrationNo: hcpInformation?.hcp_register_number || "",
        primarySpeciality:
          hcpInformation?.hcp_to_primary_speciality?.speciality_id || "",
        city: hcpInformation?.hcp_to_city?.city_id || "",
        state: hcpInformation?.hcp_to_state?.state_id || "",
        zone: hcpInformation?.hcp_to_zone?.zone_id || "",
        country: hcpInformation?.hcp_to_country?.country_id || "",
        utmSource: hcpInformation?.hcp_utmsource || "",
        interestSpeciality:
          hcpInformation?.hcp_to_interest_speciality?.length > 0
            ? hcpInformation.hcp_to_interest_speciality?.map(
                (item) => item.speciality.speciality_id
              )
            : [],
        hcp_medium: hcpInformation?.hcp_medium?.value || "",
        user_type: hcpInformation?.hcp_to_type?.hcp_type_id || "",
        documentOthers:
          hcpInformation.hcp_to_document?.length > 0
            ? filterDocumentsByType(hcpInformation.hcp_to_document, "other")
            : [],
        documentOthersTwo:
          hcpInformation.hcp_to_document?.length > 0
            ? filterDocumentsByType(hcpInformation.hcp_to_document, "other_two")
            : [],
        documentRegistrations:
          hcpInformation.hcp_to_document?.length > 0
            ? filterDocumentsByType(
                hcpInformation.hcp_to_document,
                "registration"
              )
            : [],
        documentRegistrationsTwo:
          hcpInformation.hcp_to_document?.length > 0
            ? filterDocumentsByType(
                hcpInformation.hcp_to_document,
                "registration_two"
              )
            : [],
        documentSpecialities:
          hcpInformation.hcp_to_document?.length > 0
            ? filterDocumentsByType(
                hcpInformation.hcp_to_document,
                "speciality"
              )
            : [],
        documentSpecialitiesTwo:
          hcpInformation.hcp_to_document?.length > 0
            ? filterDocumentsByType(
                hcpInformation.hcp_to_document,
                "speciality_two"
              )
            : [],

        callRecording:
          hcpInformation.hcp_to_document?.length > 0
            ? filterDocumentsByType(hcpInformation.hcp_to_document, "recording")
            : [],
        callRecordingTwo:
          hcpInformation.hcp_to_document?.length > 0
            ? filterDocumentsByType(
                hcpInformation.hcp_to_document,
                "recording_two"
              )
            : [],

        socialLinks: hcpInformation?.hcp_to_social_links || [
          { type: "", link: "" },
        ],
      };
      setPreviousValues(changedInfoValues);
    }
  }, [hcpInformation]);

  useEffect(() => {
    if (chnagedInformation && typeof chnagedInformation == "object") {
      const { onboarding_details } = chnagedInformation;

      let modifiedData =
        onboarding_details && typeof onboarding_details
          ? JSON.parse(onboarding_details)
          : fullInitialValues;

      const prevInfoValues = {
        ...fullInitialValues,
        firstName: modifiedData?.hcp_first_name || "",
        middleName: modifiedData?.hcp_middle_name || "",
        lastName: modifiedData?.hcp_last_name || "",
        age: modifiedData?.hcp_age || "",
        gender: modifiedData?.hcp_gender || "",
        email: modifiedData?.hcp_email || "",
        mobileNo: modifiedData?.hcp_mobile || "",
        association: modifiedData?.hcp_association_id || "",
        degree: modifiedData?.hcp_medical_degree_id || "",
        typeOfPractice: modifiedData?.hcp_type_id || "",
        council: modifiedData?.hcp_medical_council_id || "",
        registrationNo: modifiedData?.hcp_register_number || "",
        primarySpeciality: modifiedData?.hcp_primary_speciality_id || "",
        interestSpeciality: modifiedData?.hcp_interest_speciality_id || [],
        city: modifiedData?.hcp_city_id || "",
        state: modifiedData?.hcp_state_id || "",
        zone: modifiedData?.hcp_zone_id || "",
        country: modifiedData?.hcp_country_id || "",
        utmSource: modifiedData?.hcp_utmsource || "",
        socialLinks: modifiedData?.hcp_social_links || [{ type: "", link: "" }],
        documentSpecialities: modifiedData?.hcp_document_speciality || [],
        documentSpecialitiesTwo:
          modifiedData?.hcp_document_speciality_two || [],
        documentRegistrations: modifiedData?.hcp_document_registration || [],
        documentRegistrationsTwo:
          modifiedData?.hcp_document_registration_two || [],
        documentOthers: modifiedData?.hcp_document_others || [],
        documentOthersTwo: modifiedData?.hcp_document_others_two || [],
        callRecording: modifiedData?.hcp_document_recording || [],
        callRecordingTwo: modifiedData?.hcp_document_recording_two || [],
        medium: modifiedData?.hcp_medium || "",
        user_type: modifiedData?.hcp_type_id || "",
      };

      setValues(prevInfoValues);
    }
  }, [chnagedInformation]);

  const {
    association: associationOptions,
    degree: degreeOptions,
    speciality: specialityOptions,
    council: councilOptions,
    country: countryOptions,
    user_type: userTypeOptions,
    medium: mediumOptions,
    social_link: socialLinkTypeOptions,
  } = onboardingDropdownValues || {};

  const { data: zoneOptions } = useFetchOnboardingZones(values?.country);
  const { data: stateOptions } = useFetchOnboardingStates(values?.zone);
  const { data: cityOptions } = useFetchOnboardingCities(values?.state);
  const { data: cityOptionsOld } = useFetchOnboardingCities(
    previousValues?.state
  );
  const adjustedSpecialityOptions = specialityOptions?.map((speciality) => ({
    value: speciality.speciality_id,
    label: speciality.speciality_name,
  }));

  const adjustedDegreeOptions = degreeOptions?.map((data) => ({
    value: data.degree_id,
    label: data.degree_name,
  }));

  const handleInterestSpecialityChange = (selectedOptions) => {
    console.log("selectedOptions", selectedOptions);
    setFieldValue(
      "interestSpeciality",
      selectedOptions?.map((option) => option.value)
    );
  };

  const handleDegreeChange = (selectedOptions) => {
    console.log("selectedOptions", selectedOptions);
    setFieldValue(
      "degree",
      selectedOptions?.map((option) => option.value)
    );
  };

  function calculateAge(dateOfBirth) {
    const today = dayjs();
    const birthDate = dayjs(dateOfBirth);

    const yearsDiff = today.diff(birthDate, "year");

    // If the birth date hasn't occurred yet this year, subtract 1 from the age
    if (today.isBefore(birthDate.add(yearsDiff, "year"), "day")) {
      return yearsDiff - 1;
    }

    return yearsDiff;
  }

  const addSocialMedia = () => {
    formik.setFieldValue("socialLinks", [
      ...formik.values.socialLinks,
      { type: "", link: "" },
    ]);
  };

  const handleSocialDropdownChange = (e, index) => {
    const { name, value } = e.target;
    const socialLink = [...formik.values.socialLinks];
    socialLink[index][name] = value;
    formik.setFieldValue("socialLinks", socialLink);
  };

  const removeSocialMedia = (index) => {
    formik.setFieldValue("socialLinks", [
      ...formik.values.socialLinks.slice(0, index),
      ...formik.values.socialLinks.slice(index + 1),
    ]);
  };

  function uploadFiles(files) {
    showBackdrop();
    return new Promise(async (resolve, reject) => {
      // Check if files array is empty
      if (files.length === 0) {
        hideBackdrop();
        reject("No files to upload.");
        return;
      }
      //console.log("uploading files", files);
      let formdata = new FormData();
      //console.log("FORMDATA", files);
      files.forEach((file) => {
        formdata.append("files[]", file);
      });

      // 1. Upload files to server
      let response = await uploadService(UPLOAD_FILES_POST, formdata);
      if (response.data?.success) {
        hideBackdrop();
        //console.log("RESPONSE", response);
        // 2. Simulating successful upload and generating URLs
        const urls = response.data.data.map((file) => file.url);
        //console.log("URLS", urls);
        resolve(urls);
      } else {
        hideBackdrop();
        reject("Something went wrong");
      }
    });
  }

  const changeFileUpload = async (fieldName, file) => {
    if (Array.isArray(file)) {
      try {
        const urls = await uploadFiles(file);
        if (urls?.length > 0) {
          setFieldValue(fieldName, urls);
        }
      } catch (e) {
        console.log(e);
      }
    }
  };

  function onCrossImageClick(index, fieldName) {
    let tempValues = fieldName in values ? values[fieldName] : [];
    tempValues = tempValues?.length > 0 ? tempValues.splice(1, index) : [];
    setValues({ ...values, [fieldName]: tempValues });
  }

  const tableData = [
    {
      label: "First Name",
      oldValue: previousValues.firstName,
      props: {
        name: "firstName",
        label: "First Name",
        value: values.firstName,
        onChange: handleChange,
        onBlur: handleBlur,
        error: errors.firstName,
        isMandatory: true,
      },
    },
    {
      label: "Middle Name",
      oldValue: previousValues.middleName,
      props: {
        name: "middleName",
        label: "Middle Name",
        value: values.middleName,
        onChange: handleChange,
        onBlur: handleBlur,
        error: errors.middleName,
        isMandatory: true,
      },
    },
    {
      label: "Last Name",
      oldValue: previousValues.lastName,
      props: {
        name: "lastName",
        label: "Last Name",
        value: values.lastName,
        onChange: handleChange,
        onBlur: handleBlur,
        error: errors.lastName,
        isMandatory: true,
      },
    },
    {
      label: "Age",
      oldValue: previousValues.age,
      props: {
        name: "age",
        label: "Age",
        value: values.age,
        onChange: handleChange,
        onBlur: handleBlur,
        error: errors.age,
        isMandatory: true,
      },
    },
    {
      label: "Gender",
      oldValue: previousValues.gender,
      props: {
        name: "gender",
        label: "Gender",
        value: values.gender,
        onChange: handleChange,
        onBlur: handleBlur,
        options: [
          {
            value: "male",
            name: "Male",
          },
          {
            value: "female",
            name: "Female",
          },
        ],
        option: {
          value: "value",
          name: "name",
        },
        error: errors.gender,
        isMandatory: true,
      },
    },
    {
      label: "Email",
      oldValue: previousValues.email,
      props: {
        name: "email",
        label: "Email",
        value: values.email,
        onChange: handleChange,
        onBlur: handleBlur,
        error: errors.email,
        isMandatory: true,
      },
    },
    {
      label: "Mobile No.",
      oldValue: previousValues.mobileNo,
      props: {
        name: "mobileNo",
        label: "Mobile No",
        value: values.mobileNo,
        onChange: handleChange,
        onBlur: handleBlur,
        error: errors.mobileNo,
        isMandatory: true,
      },
    },
    {
      label: "Degree",
      // oldValue: degreeOptions?.find(
      //   (d) => d?.degree_id == previousValues.degree
      // )?.degree_name,
      oldValue:
        degreeOptions
          ?.filter((s) => previousValues?.degree?.includes(s?.degree_id))
          ?.map((s) => s?.degree_name)
          ?.join() || "",
      Field: (props) => {
        return (
          <>
            <Select
              className="react-select-customize"
              placeholder={"Degree"}
              options={adjustedDegreeOptions}
              onChange={handleDegreeChange}
              value={adjustedDegreeOptions?.filter((s) =>
                values?.degree?.includes(s?.value)
              )}
              isMulti
            />
            <Form.Control.Feedback type="invalid" className="d-block">
              {errors.degree}
            </Form.Control.Feedback>
          </>
        );
      },
    },
    {
      label: "Type of Practice",
      oldValue: userTypeOptions?.find(
        (u) => u?.hcp_type_id == previousValues.typeOfPractice
      )?.hcp_type_name,
      props: {
        name: "typeOfPractice",
        label: "Type of practice",
        value: values.typeOfPractice,
        onChange: handleChange,
        onBlur: handleBlur,
        error: errors.typeOfPractice,
        isMandatory: true,
        options: userTypeOptions,
        option: {
          value: "hcp_type_id",
          name: "hcp_type_name",
        },
      },
    },
    {
      label: "Council",
      oldValue: councilOptions?.find(
        (c) => c.council_id == previousValues.council
      )?.council_name,
      props: {
        name: "council",
        label: "Council",
        value: values.council,
        onChange: handleChange,
        onBlur: handleBlur,
        error: errors.council,
        isMandatory: true,
        options: councilOptions,
        option: {
          value: "council_id",
          name: "council_name",
        },
      },
    },
    {
      label: "Association",
      oldValue: associationOptions?.find(
        (c) => c.association_id == previousValues.association
      )?.association_name,
      props: {
        name: "association",
        label: "Association",
        value: values.association,
        onChange: handleChange,
        onBlur: handleBlur,
        error: errors.association,
        isMandatory: false,
        options: associationOptions,
        option: {
          value: "association_id",
          name: "association_name",
        },
      },
    },
    {
      label: "Registration No.",
      oldValue: previousValues.registrationNo,
      props: {
        name: "registrationNo",
        label: "Registration No.",
        value: values.registrationNo,
        onChange: handleChange,
        onBlur: handleBlur,
        error: errors.registrationNo,
        isMandatory: true,
      },
    },
    {
      label: "Practice Speciality",
      oldValue: specialityOptions?.find(
        (s) => s.speciality_id == previousValues.primarySpeciality
      )?.speciality_name,
      props: {
        name: "primarySpeciality",
        label: "Practice Speciality",
        value: values.primarySpeciality,
        onChange: handleChange,
        onBlur: handleBlur,
        error: errors.primarySpeciality,
        isMandatory: false,
        options: specialityOptions,
        option: {
          value: "speciality_id",
          name: "speciality_name",
        },
      },
    },
    {
      label: "Interest Speciality",
      oldValue: (
        <>
          {specialityOptions?.length > 0
            ? specialityOptions
                .filter((item) =>
                  values.interestSpeciality.includes(item.speciality_id)
                )
                .map((item) => item.speciality_name)
                .join(", ")
            : "NA"}
        </>
      ),
      Field: (props) => {
        return (
          <>
            <Select
              className="react-select-customize"
              placeholder={"Interest Speciality"}
              options={adjustedSpecialityOptions}
              onChange={handleInterestSpecialityChange}
              value={adjustedSpecialityOptions?.filter((s) =>
                values?.interestSpeciality?.includes(s?.value)
              )}
              isMulti
            />
            <Form.Control.Feedback type="invalid" className="d-block">
              {errors.interestSpeciality}
            </Form.Control.Feedback>
          </>
        );
      },
    },

    {
      label: "Country",
      oldValue: countryOptions?.find(
        (c) => c.country_id == previousValues.country
      )?.country_name,
      props: {
        name: "country",
        label: "Country",
        value: values.country,
        onChange: handleChange,
        onBlur: handleBlur,
        error: errors.country,
        isMandatory: true,
        options: countryOptions,
        option: {
          name: "country_name",
          value: "country_id",
        },
      },
    },
    {
      label: "Zone",
      oldValue: zoneOptions?.find((z) => z.zone_id == previousValues.zone)
        ?.zone_name,
      props: {
        name: "zone",
        label: "Zone",
        value: values.zone,
        onChange: handleChange,
        onBlur: handleBlur,
        error: errors.zone,
        isMandatory: true,
        options: zoneOptions,
        option: {
          name: "zone_name",
          value: "zone_id",
        },
      },
    },
    {
      label: "State",
      oldValue: stateOptions?.find((s) => s.state_id == previousValues.state)
        ?.state_name,
      props: {
        name: "state",
        label: "State",
        value: values.state,
        onChange: handleChange,
        onBlur: handleBlur,
        error: errors.state,
        isMandatory: true,
        options: stateOptions,
        option: {
          name: "state_name",
          value: "state_id",
        },
      },
    },
    {
      label: "City",
      oldValue: cityOptionsOld?.find((c) => c.city_id == previousValues.city)
        ?.city_name,
      props: {
        name: "city",
        label: "City",
        value: values.city,
        onChange: handleChange,
        onBlur: handleBlur,
        error: errors.city,
        isMandatory: true,
        options: cityOptions,
        option: {
          name: "city_name",
          value: "city_id",
        },
      },
    },

    {
      label: "Medium",
      oldValue: mediumOptions?.find((m) => m.level == previousValues.hcp_medium)
        ?.level,
      props: {
        name: "medium",
        label: "Medium",
        value: values.medium,
        onChange: handleChange,
        onBlur: handleBlur,
        error: errors.medium,
        isMandatory: true,
        options: mediumOptions,
        option: {
          name: "level",
          value: "value",
        },
      },
    },
    {
      label: "User Type",
      oldValue: userTypeOptions?.find(
        (h) => h.hcp_type_id == previousValues.user_type
      )?.hcp_type_name,
      props: {
        name: "user_type",
        label: "User Type",
        value: values.user_type,
        onChange: handleChange,
        onBlur: handleBlur,
        error: errors.user_type,
        isMandatory: true,
        options: userTypeOptions,
        option: {
          name: "hcp_type_name",
          value: "hcp_type_id",
        },
      },
    },
    {
      label: "Document Registrations",
      oldValue: (
        <Images
          withCloseButton={false}
          fieldName="documentRegistrations"
          images={previousValues.documentRegistrations}
          onCrossImageClick={onCrossImageClick}
        />
      ),
      props: {
        name: "documentRegistrations",
        error: errors?.documentRegistrations,
        selectedValue: values.documentRegistrations,
        onCrossImageClick: onCrossImageClick,
        changeFileUpload: changeFileUpload,
        fileTypes: "(.jpg,.png,.jpeg,.pdf,.doc,.docx)",
        validMimeTypes: [
          "image/jpeg",
          "image/png",
          "application/pdf",
          "application/msword",
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        ],
      },
    },
    {
      label: "Document Registrations Two",
      oldValue: (
        <Images
          withCloseButton={false}
          fieldName="documentRegistrationsTwo"
          images={previousValues.documentRegistrationsTwo}
          onCrossImageClick={onCrossImageClick}
        />
      ),
      props: {
        name: "documentRegistrationsTwo",
        error: errors?.documentRegistrationsTwo,
        selectedValue: values.documentRegistrationsTwo,
        onCrossImageClick: onCrossImageClick,
        changeFileUpload: changeFileUpload,
        fileTypes: "(.jpg,.png,.jpeg,.pdf,.doc,.docx)",
        validMimeTypes: [
          "image/jpeg",
          "image/png",
          "application/pdf",
          "application/msword",
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        ],
      },
    },
    {
      label: "Document Specialities",
      oldValue: (
        <Images
          withCloseButton={false}
          fieldName="documentSpecialities"
          images={previousValues.documentSpecialities}
          onCrossImageClick={onCrossImageClick}
        />
      ),
      props: {
        name: "documentSpecialities",
        error: errors?.documentSpecialities,
        selectedValue: values.documentSpecialities,
        onCrossImageClick: onCrossImageClick,
        changeFileUpload: changeFileUpload,
        fileTypes: "(.jpg,.png,.jpeg,.pdf,.doc,.docx)",
        validMimeTypes: [
          "image/jpeg",
          "image/png",
          "application/pdf",
          "application/msword",
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        ],
      },
    },
    {
      label: "Document Specialities Two",
      oldValue: (
        <Images
          withCloseButton={false}
          fieldName="documentSpecialitiesTwo"
          images={previousValues.documentSpecialitiesTwo}
          onCrossImageClick={onCrossImageClick}
        />
      ),
      props: {
        name: "documentSpecialitiesTwo",
        error: errors?.documentSpecialitiesTwo,
        selectedValue: values.documentSpecialitiesTwo,
        onCrossImageClick: onCrossImageClick,
        changeFileUpload: changeFileUpload,
        fileTypes: "(.jpg,.png,.jpeg,.pdf,.doc,.docx)",
        validMimeTypes: [
          "image/jpeg",
          "image/png",
          "application/pdf",
          "application/msword",
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        ],
      },
    },
    {
      label: "Document Others",
      oldValue: (
        <>
          {console.log("others docs", previousValues.documentOthers)}
          <Images
            withCloseButton={false}
            fieldName="documentOthers"
            images={previousValues.documentOthers}
            onCrossImageClick={onCrossImageClick}
          />
        </>
      ),
      props: {
        name: "documentOthers",
        error: errors?.documentOthers,
        selectedValue: values.documentOthers,
        onCrossImageClick: onCrossImageClick,
        changeFileUpload: changeFileUpload,
        fileTypes: "(.jpg,.png,.jpeg,.pdf,.doc,.docx)",
        validMimeTypes: [
          "image/jpeg",
          "image/png",
          "application/pdf",
          "application/msword",
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        ],
      },
    },
    {
      label: "Document Others Two",
      oldValue: (
        <>
          {console.log("others docs", previousValues.documentOthersTwo)}
          <Images
            withCloseButton={false}
            fieldName="documentOthersTwo"
            images={previousValues.documentOthersTwo}
            onCrossImageClick={onCrossImageClick}
          />
        </>
      ),
      props: {
        name: "documentOthersTwo",
        error: errors?.documentOthersTwo,
        selectedValue: values.documentOthersTwo,
        onCrossImageClick: onCrossImageClick,
        changeFileUpload: changeFileUpload,
        fileTypes: "(.jpg,.png,.jpeg,.pdf,.doc,.docx)",
        validMimeTypes: [
          "image/jpeg",
          "image/png",
          "application/pdf",
          "application/msword",
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        ],
      },
    },
    {
      label: "Call Recording",
      oldValue:
        previousValues.callRecording?.length > 0 ? (
          <audio controls>
            <source src={previousValues.callRecording} />
          </audio>
        ) : (
          "N/A"
        ),

      props: {
        accept: "audio",
        name: "callRecording",
        error: errors?.callRecording,
        selectedValue: values.callRecording,
        onCrossImageClick: onCrossImageClick,
        changeFileUpload: changeFileUpload,
        fileTypes: "(.mp3,.wav,.ogg)",
        validMimeTypes: ["audio/mpeg", "audio/wav", "audio/ogg"],
      },
    },
    {
      label: "Call Recording Two",
      oldValue:
        previousValues.callRecordingTwo?.length > 0 ? (
          <audio controls>
            <source src={previousValues.callRecordingTwo} />
          </audio>
        ) : (
          "N/A"
        ),

      props: {
        accept: "audio",
        name: "callRecordingTwo",
        error: errors?.callRecordingTwo,
        selectedValue: values.callRecordingTwo,
        onCrossImageClick: onCrossImageClick,
        changeFileUpload: changeFileUpload,
        fileTypes: "(.mp3,.wav,.ogg)",
        validMimeTypes: ["audio/mpeg", "audio/wav", "audio/ogg"],
      },
    },
    {
      label: "Social Links",
      oldValue: (
        <>
          <ol>
            {previousValues?.socialLinks?.length > 0 &&
              previousValues?.socialLinks?.map((sl, idx) =>
                sl.social_link_type?.social_link_type_id && sl.social_link ? (
                  <li key={idx + 1} className="d-block">
                    {sl.social_link_type.social_link_type_name}:{" "}
                    {sl.social_link}
                  </li>
                ) : null
              )}
          </ol>
        </>
      ),
      Field: () => (
        <>
          {console.log("social link", values.socialLinks)}
          {values?.socialLinks?.map((_sl, idx) => (
            <div className="d-flex gap-3 mb-3" key={idx + 1}>
              <Form.Floating className="custom-form-floating">
                <Form.Select
                  className="w-auto flex-grow-1"
                  value={_sl.type}
                  name="type"
                  isInvalid={
                    errors?.socialLinks?.length > 0 &&
                    !!errors.socialLinks[idx]?.type
                  }
                  onChange={(e) => handleSocialDropdownChange(e, idx)}
                >
                  <option value="">Social Media</option>
                  {socialLinkTypeOptions?.map(
                    ({ social_link_type_id, social_link_type_name }) => (
                      <option
                        key={social_link_type_id}
                        value={social_link_type_id}
                      >
                        {social_link_type_name}
                      </option>
                    )
                  )}
                </Form.Select>

                {errors?.socialLinks?.length > 0 && (
                  <Form.Control.Feedback type="invalid">
                    {errors.socialLinks[idx]?.type}
                  </Form.Control.Feedback>
                )}
              </Form.Floating>
              <Form.Floating className="custom-form-floating">
                <Form.Control
                  type="url"
                  name="link"
                  placeholder="Please enter link (Ex: www.clirnet.ai)"
                  value={_sl.link}
                  isInvalid={
                    errors?.socialLinks?.length > 0 &&
                    !!errors.socialLinks[idx]?.link
                  }
                  className="w-auto flex-grow-1"
                  onChange={(e) => handleSocialDropdownChange(e, idx)}
                />
                <Form.Label htmlFor="floatingInput1">Link</Form.Label>
                {errors?.socialLinks?.length > 0 && (
                  <Form.Control.Feedback type="invalid">
                    {errors.socialLinks[idx]?.link}
                  </Form.Control.Feedback>
                )}
              </Form.Floating>
              {values.socialLinks.length > 1 && (
                <Button
                  variant="danger"
                  disabled={values.socialLinks.length === 1}
                  className="gap-1 d-flex align-items-center justify-content-center px-3"
                  onClick={() => removeSocialMedia(idx)}
                >
                  {findIcon("Trash", "solid", "20")}
                </Button>
              )}
            </div>
          ))}
          <Button
            variant="outline-info"
            className="gap-1 d-flex align-items-center border-dashed justify-content-center px-3"
            onClick={addSocialMedia}
          >
            {findIcon("Plus", "solid", "20")}
            Add More
          </Button>
        </>
      ),
    },
    {
      label: "UTM Source",
      oldValue: previousValues.utmSource ?? "N/A",
      props: {
        name: "utmSource",
        label: "UTM Source",
        value: values.utmSource,
        error: errors.utmSource,
        onChange: handleChange,
        onBlur: handleBlur,
        isMandatory: false,
        disabled: true,
      },
    },
  ];

  const { showBackdrop, hideBackdrop } = useBackdrop();

  const extractFilenames = (urls) => {
    return urls.map((url) => {
      let desiredPart = url.replace(/^.*\/([^/]+\/[^/]+)$/, "$1");
      return desiredPart;
    });
  };

  const handleOnboardingUpdate = async (values, status) => {
    console.log("values", values);
    showBackdrop();
    let onboardingDetailsPayload = {
      hcp_first_name: values.firstName,
      hcp_middle_name: values.middleName,
      hcp_last_name: values.lastName,
      hcp_age: values.age ? values.age : 0,
      hcp_gender: values.gender ? values.gender : 0,
      hcp_email: values.email,
      hcp_mobile: values.mobileNo,
      hcp_register_number: values.registrationNo ? values.registrationNo : 0,
      hcp_medical_degree_id: values.degree,
      hcp_association_id: values.association ? values.association : 0,
      hcp_primary_speciality_id: values.primarySpeciality
        ? values.primarySpeciality
        : 0,
      hcp_medical_council_id: values.council ? values.council : 0,
      hcp_country_id: values.country ? values.country : 0,
      hcp_zone_id: values.zone ? values.zone : 0,
      hcp_state_id: values.state ? values.state : 0,
      hcp_city_id: values.city ? values.city : 0,
      hcp_type_id: values.typeOfPractice ? values.typeOfPractice : 0,
      hcp_utmsource: values.utmSource ? values.utmSource : 0,
      hcp_document_others:
        values.documentOthers?.length > 0
          ? extractFilenames(values.documentOthers)
          : [],
      hcp_document_others_two:
        values.documentOthersTwo?.length > 0
          ? extractFilenames(values.documentOthersTwo)
          : [],
      hcp_social_links:
        values.socialLinks?.length > 0
          ? values.socialLinks.filter(
              (item) => item.link !== "" && item.type !== ""
            )
          : 0,
      hcp_document_speciality:
        values.documentSpecialities?.length > 0
          ? extractFilenames(values.documentSpecialities)
          : [],
      hcp_document_speciality_two:
        values.documentSpecialitiesTwo?.length > 0
          ? extractFilenames(values.documentSpecialitiesTwo)
          : [],
      hcp_document_registration:
        values.documentRegistrations?.length > 0
          ? extractFilenames(values.documentRegistrations)
          : [],
      hcp_document_registration_two:
        values.documentRegistrationsTwo?.length > 0
          ? extractFilenames(values.documentRegistrationsTwo)
          : [],
      hcp_document_recording:
        values.callRecording?.length > 0
          ? extractFilenames(values.callRecording)
          : [],
      hcp_document_recording_two:
        values.callRecordingTwo?.length > 0
          ? extractFilenames(values.callRecordingTwo)
          : [],
      hcp_interest_speciality_id: values.interestSpeciality,
      hcp_medium: values.medium,
    };

    let payload = {
      status,
      action_id: actionId,
      onboarding_details: onboardingDetailsPayload,
      comment: values.comment ?? "",
    };
    let response = await postService(UPDATE_ACTION_POST + actionId, payload);
    if (response.isError) {
      hideBackdrop();
      toast.error(response.error);
      return;
    } else {
      if (response.data.success) {
        hideBackdrop();
        toast.success(response.data.message);
        refetchAction();
        redirectTo("action/all-action");
      } else {
        hideBackdrop();
        toast.error("Something went wrong");
      }
    }
  };

  const onCommentsChange = (e) => {
    setFieldValue("comment", e.target.value);
    setComment(e.target.value);
  };

  const ActionStatus = ({ status }) => {
    switch (status.id) {
      case STATUS_CONSTANTS.COMPLETED:
      case STATUS_CONSTANTS.APPROVED:
        return (
          <Card>
            <Card.Body>
              <h5 className="mb-0 text-center text-success">
                This Action is Approved
              </h5>
            </Card.Body>
          </Card>
        );
      case STATUS_CONSTANTS.REJECTED:
        return (
          <Card>
            <Card.Body>
              <h5 className="mb-0 text-center text-danger">
                This Action is Rejected
              </h5>
            </Card.Body>
          </Card>
        );
      default:
        return <></>;
    }
  };

  const onLogSubmit = async (data) => {
    console.log("data", data);
    showBackdrop();
    let payload = {
      ...data,
      action_id: actionId,
      hcp_id: hcpInformation?.hcp_id,
    };
    let response = await postService(ONBOARDING_ADD_LOG, payload);
    if (response.isError) {
      hideBackdrop();
      toast.error(response.error);
    } else {
      hideBackdrop();
      toast.success("Log added successfully");
      let newData = response?.data?.data ?? {};
      console.log("newData", newData, [...addedLogs, newData]);
      closeAddLogModal();
      setAddedLogs((prev) => [...prev, newData]);
    }
  };

  const handleOnboardingReject = async (payload) => {
    console.log("payload", payload);
    console.log("action details", actionDetails);
    if (
      (actionDetails?.action_type_id?.action_type_code ==
        "onboarding_review_edit" ||
        actionDetails?.action_type_id?.action_type_code ==
          "re_onboarding_review_edit") &&
      !payload.comment
    ) {
      toast.error("Please give reason for rejection");
      return false;
    } else if (
      (actionDetails?.action_type_id?.action_type_code ==
        "onboarding_review_edit" ||
        actionDetails?.action_type_id?.action_type_code ==
          "re_onboarding_review_edit") &&
      (!addedLogs || addedLogs?.length <= 0)
    ) {
      toast.error("Please add at least one log");
      setCommentsModal(false);
      return false;
    }

    showBackdrop();
    let response = await postService(UPDATE_ACTION_POST + actionId, payload);
    console.log("response", response);
    if (response.isError) {
      hideBackdrop();
      toast.error(response.error);
      return;
    } else {
      hideBackdrop();
      if (response.data.success) {
        toast.success(response.data.message);
        redirectTo("action/all-action");
      } else {
        toast.error("Something went wrong!");
      }
    }
  };
  const onCommentsSubmit = (value) => {
    let payload = {
      ...value,
      status: 6,
    };
    handleOnboardingReject(payload);
  };

  return (
    <>
      <ActionInfoCard
        actionInfo={actionInfo}
        isLoading={isActionDetailsLoading}
      />
      {!isActionDetailsLoading &&
      actionDetails &&
      (actionStatus?.id != STATUS_CONSTANTS.REJECTED ||
        actionStatus?.id != STATUS_CONSTANTS.COMPLETED) ? (
        <Card>
          <Card.Body>
            <div className="d-flex justify-content-between mb-4 align-items-center">
              <h5 className="mb-0">Onboarding Approval</h5>
            </div>
            <Table responsive className="table-bordered">
              <thead>
                <tr>
                  <th className="text-dark fw-semibold">Label</th>
                  <th className="text-dark fw-semibold">Old Record</th>
                  <th className="text-dark fw-semibold">Updated Record</th>
                </tr>
              </thead>
              <tbody>
                {tableData &&
                  tableData.map((item, key) => {
                    let { label, oldValue, Field } = item;
                    return (
                      <tr key={key}>
                        <td className="text-dark fw-semibold">{label}</td>
                        <td className="text-dark fw-normal">{oldValue}</td>
                        <td className="fw-normal text-dark">
                          {item?.Field ? (
                            <Field />
                          ) : item.props?.option ? (
                            <DropDown {...item.props} />
                          ) : item.props?.changeFileUpload ? (
                            <>
                              <FileUpload {...item.props} />
                              {item.props.fileTypes && (
                                <p className="my-2 text-muted">
                                  {item.props.fileTypes}
                                </p>
                              )}
                            </>
                          ) : (
                            <TextBox {...item.props} />
                          )}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      ) : (
        <></>
      )}
      {actionStatus && <ActionStatus status={actionStatus} />}
      {addedLogs && addedLogs.length > 0 ? (
        <Card>
          <Card.Header>New Added Logs</Card.Header>
          <Card.Body>
            <Table responsive className="table-bordered">
              <thead>
                <tr>
                  <th className="text-dark fw-semibold">Log Added On</th>
                  <th className="text-dark fw-semibold">Log Type</th>
                  <th className="text-dark fw-semibold">Comment</th>
                  <th className="text-dark fw-semibold">Logged By</th>
                </tr>
              </thead>
              <tbody>
                {addedLogs?.length > 0 &&
                  addedLogs.map((item, key) => {
                    let {
                      comment,
                      id,
                      onboarding_log_type,
                      created_at,
                      user_id,
                      loged_by,
                    } = item || {
                      id: "",
                      comment: "",
                      created_at: "",
                      user_id: "",
                      loged_by: "",
                    };
                    return (
                      <tr key={`${key}-${user_id}`}>
                        <td className="text-dark fw-semibold">{created_at}</td>
                        <td className="text-dark fw-normal">
                          {onboarding_log_type?.name}
                        </td>
                        <td className="text-dark fw-normal">{comment}</td>
                        <td className="fw-normal text-dark">
                          {loged_by?.name}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      ) : (
        <></>
      )}
      {actionLogs?.length > 0 && (
        <Card>
          <Card.Header>Hcp Review Logs</Card.Header>
          <Card.Body>
            <Table responsive className="table-bordered">
              <thead>
                <tr>
                  <th className="text-dark fw-semibold">Log added on</th>
                  <th className="text-dark fw-semibold">Log type</th>
                  <th className="text-dark fw-semibold">Comment</th>
                  <th className="text-dark fw-semibold">Commented By</th>
                </tr>
              </thead>
              <tbody>
                {actionLogs?.length > 0 &&
                  actionLogs.map((item, key) => {
                    console.log("item", item);
                    let {
                      comment,
                      created_at,
                      user_id,
                      loged_by,
                      onboarding_log_type,
                    } = item || {
                      comment: "",
                      created_at: "",
                      user_id: "",
                      loged_by: "",
                    };
                    return (
                      <tr key={`${key}-${user_id}`}>
                        <td className="text-dark ">{created_at}</td>
                        <td className="text-dark ">
                          {onboarding_log_type?.name}
                        </td>
                        <td className="text-dark fw-normal">{comment}</td>
                        <td className="fw-normal text-dark">
                          {loged_by?.name}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      )}

      {actionDetails?.comments?.length > 0 && (
        <Card>
          <Card.Header>Approver Comment's</Card.Header>
          <Card.Body>
            <Table responsive className="table-bordered">
              <thead>
                <tr>
                  <th className="text-dark ">Commented On</th>
                  <th className="text-dark ">Comment</th>
                  <th className="text-dark ">Commented By</th>
                  <th className="text-dark ">Attachment</th>
                </tr>
              </thead>
              <tbody>
                {actionDetails?.comments?.length > 0 &&
                  actionDetails?.comments.map((item, key) => {
                    let { comment, created_at, user_id, path } = item || {
                      comment: "",
                      created_at: "",
                      user_id: "",
                      path: "",
                    };
                    return (
                      <tr key={`${key}-${user_id}`}>
                        <td className="text-dark fw-semibold">{created_at}</td>
                        <td className="text-dark fw-normal">{comment}</td>
                        <td className="fw-normal text-dark">{user_id?.name}</td>
                        <td className="fw-normal text-dark">
                          <MediaRenderer url={path} />
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      )}
      {!isActionDetailsLoading &&
        (actionStatus?.id != STATUS_CONSTANTS.REJECTED ||
          actionStatus?.id != STATUS_CONSTANTS.COMPLETED) && (
          <Col lg="12" className="d-flex justify-content-end gap-2 mb-5">
            {(actionDetails?.action_type_id?.action_type_code ==
              "onboarding_review_edit" ||
              actionDetails?.action_type_id?.action_type_code ==
                "re_onboarding_review_edit") && (
              <Button
                onClick={() => {
                  setShowAddlogModal(true);
                }}
                variant="success"
              >
                Add Log
              </Button>
            )}
            {actionDetails?.action_type_id?.action_type_code ==
              "onboarding_review_edit" ||
            actionDetails?.action_type_id?.action_type_code ==
              "re_onboarding_review_edit" ? (
              <Button
                onClick={() => {
                  setCommentsModal(true);
                }}
                variant="danger"
              >
                Reject
              </Button>
            ) : (
              <Button
                onClick={() => {
                  setViewModalShow(true);
                  setIsRejecting(true);
                }}
                variant="danger"
              >
                Reject
              </Button>
            )}
            <SubmitButton
              onClick={() => {
                handleSubmit();
                setIsRejecting(false);
              }}
              variant="primary"
            >
              {actionDetails?.action_type_id?.action_type_code ==
                "onboarding_review_edit" ||
              actionDetails?.action_type_id?.action_type_code ==
                "re_onboarding_review_edit"
                ? "Update Info"
                : "Approve"}
            </SubmitButton>
          </Col>
        )}

      <AddCommentsModal
        onboardingDropdownValues={onboardingDropdownValues}
        show={commentsModal}
        onSubmit={onCommentsSubmit}
        onHide={() => setCommentsModal(false)}
      />

      <AddLogModal
        show={showAddlogModal}
        modalClose={closeAddLogModal}
        handleSubmit={onLogSubmit}
      />
      <Modal
        show={viewModalShow}
        onHide={() => setViewModalShow(false)}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Reson For Rejection
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Col lg={12} className="mb-4">
            <Form.Floating
              controlId="floatingTextarea2"
              label="Comments"
              className="custom-form-floating"
            >
              <Form.Control
                as="textarea"
                placeholder="Leave a comment here"
                onChange={onCommentsChange}
              />
              <Form.Label>Comments</Form.Label>
            </Form.Floating>
          </Col>
          <Col lg={12} className="d-flex justify-content-end">
            <SubmitButton type="button" onClick={handleSubmit}>
              Submit
            </SubmitButton>
          </Col>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default OnboardingApprovalEditable;

function ActionInfoCard({ actionInfo, isLoading }) {
  return (
    <Card>
      <Card.Body>
        <div>
          <div className="d-flex gap-2">
            {!isLoading ? (
              <Badge pill bg="danger" className="fw-normal">
                {actionInfo.priority}
              </Badge>
            ) : (
              <Skeleton width={100} />
            )}
            {!isLoading ? (
              <Badge pill bg="info" className="fw-normal">
                {actionInfo.status}
              </Badge>
            ) : (
              <Skeleton width={100} />
            )}
            {!isLoading ? (
              <Badge pill bg="soft-warning" className="fw-normal">
                Deadline {actionInfo.deadline}
              </Badge>
            ) : (
              <Skeleton width={100} />
            )}
          </div>
          {!isLoading ? (
            <h4 className="mt-4 mb-0">{actionInfo.name}</h4>
          ) : (
            <Skeleton width={200} />
          )}

          {!isLoading ? (
            <p className="mt-1 mb-0">
              Onboard and collect consent from this user. Also, try to verify
              and update information.
            </p>
          ) : (
            <Skeleton width={500} />
          )}
          <div className="d-flex justify-content-end gap-3 mt-1">
            {!isLoading ? (
              <span className="text-dark fs-6">{actionInfo.type}</span>
            ) : (
              <Skeleton width={100} />
            )}
            <span> | </span>
            {!isLoading ? (
              <span className="text-dark fs-6">
                Assigned By {actionInfo.createdBy}
              </span>
            ) : (
              <Skeleton width={100} />
            )}
          </div>
        </div>
      </Card.Body>
    </Card>
  );
}
function Images({
  images,
  fieldName,
  withCloseButton = true,
  onCrossImageClick,
}) {
  const fileType = images?.at(0)?.split(".").pop();

  return (
    <div className="d-flex flex-wrap flex-row gap-3 mt-3">
      {images?.length > 0 &&
        images?.map((file, index) => (
          <div className="position-relative" key={index + 1}>
            <a href={file} target="_blank" rel="noopener noreferrer">
              {fileType === "pdf" ? (
                findIcon("pdf", "solid", 80)
              ) : ["doc", "docx"].includes(fileType) ? (
                findIcon("doc", "solid", 80)
              ) : (
                <img
                  className="rounded img-fluid avatar-120"
                  src={file}
                  alt="profile"
                  loading="lazy"
                  onError={(e) => {
                    e.target.onerror = null;
                  }}
                />
              )}
            </a>
            {withCloseButton ? (
              <span
                className="position-absolute top-0 end-0"
                onClick={() => onCrossImageClick(index, fieldName)}
              >
                {findIcon("Square X", "solid", 20)}
              </span>
            ) : null}
          </div>
        ))}
    </div>
  );
}
function TextBox({
  name = "",
  label = "",
  value = "",
  onChange,
  onBlur,
  error = "",
  isMandatory = true,
  ...rest
}) {
  return (
    <Form.Floating className="custom-form-floating">
      <Form.Control
        type="text"
        id="floatingInput1"
        autoComplete={label}
        placeholder={"Enter " + label}
        name={name}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        isInvalid={!!error}
        {...rest}
      />
      <Form.Label htmlFor="floatingInput">
        {label} {isMandatory && <span className="text-danger">*</span>}
      </Form.Label>
      <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
    </Form.Floating>
  );
}
function DropDown({
  name = "",
  label = "",
  value = "",
  onChange,
  onBlur,
  error = "",
  isMandatory = true,
  options = [],
  option = {
    value: "",
    name: "",
  },
}) {
  return (
    <Form.Floating className="custom-form-floating">
      <Form.Select
        id="floatingSelectGrid"
        name={name}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        isInvalid={!!error}
      >
        <option key={"default"} value={-1}>
          Select {label} from below
        </option>
        {options?.map((item) => (
          <option key={item[option.value]} value={item[option.value]}>
            {item[option.name]}
          </option>
        ))}
      </Form.Select>
      <Form.Label htmlFor="floatingInput">
        {label} {isMandatory && <span className="text-danger">*</span>}
      </Form.Label>
      <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
    </Form.Floating>
  );
}
function FileUpload({
  name,
  error,
  changeFileUpload,
  selectedValue = [],
  onCrossImageClick,
  accept = "",
  fileTypes = "",
  validMimeTypes = [],
}) {
  return (
    <>
      {selectedValue?.length > 0 && accept != "audio" ? (
        <Images
          fieldName={name}
          images={selectedValue}
          onCrossImageClick={onCrossImageClick}
        />
      ) : selectedValue?.length > 0 && accept == "audio" ? (
        <>
          <audio controls>
            <source src={selectedValue[0]} />
          </audio>
        </>
      ) : (
        <></>
      )}
      <Form.Control
        className="mt-3"
        type="file"
        accept={`${accept ?? "image"}/*`}
        isInvalid={!!error}
        name={name}
        multiple
        onChange={(e) => {
          const file = e.target.files[0];
          if (file && validMimeTypes.includes(file.type)) {
            changeFileUpload(name, Object.values(e.target.files));
          } else {
            toast.error(`Only ${fileTypes} files are allowed`);
            e.target.value = "";
          }
        }}
      />
    </>
  );
}
