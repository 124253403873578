import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Card, CardBody, Col, Row } from "react-bootstrap";
import { useParams, useSearchParams } from "react-router-dom";
import useRedirect from "../../../../../../components/custom-hooks/use-redirect";
import ActionButtonBar from "../../../../../../components/partials/common/action-button-bar";
import ReactTable from "../../../../../../lib/table/react-table";
import { useGetAllVideoList } from "../../../../../../queries/query-hooks/workspace/workspace-hook";
import VideoListSkeleton from "../../../../../../skeleton/workspace/doctube/channel/video-list-skeleton";
import PageNotFound from "../../../../../PageNotFound";
import { getService } from "../../../../../../api/services/get-services";
import { findIcon } from "../../../../../dashboard/icons";
import {
  dateFilterOptions,
  privacyOptions,
  videoListSortOption,
} from "../Constant/date-filter-constant";
import TabNavigation from "../components/TabNavigation";
import ShareModal from "../components/VideoShareModal";
import VideoCard from "../components/card/VideoCard";
import {
  changeTableStateToPayload,
  formatLocalizedDate,
  getRelativeDate,
} from "../../../../../../common/utils/utils";
import SubmitButton from "../../../../../../components/form-components/submit-button";
import toast from "react-hot-toast";
import FloatingButton from "../../../../../../components/form-components/floating-button";
import { DELETE_VIDEO } from "../../../../../../api/endpoints/channel-endpoint";
import useBackdrop from "../../../../../../components/custom-hooks/use-backdrop";
import dayjs from "dayjs";
import { selectProject } from "../../../../../../store/project/project.selector";
import { useSelector } from "react-redux";

const VideoListContanier = ({ title, videoType }) => {
  const { showBackdrop, hideBackdrop } = useBackdrop();
  const project = useSelector(selectProject);
  const { redirectTo } = useRedirect();
  const { channel_id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedList, setSelectedList] = useState({});

  const initialDateFilterOption = videoListSortOption.find(
    (item) => item.initial
  );
  const selectedSortOptions =
    searchParams.get("sort") || initialDateFilterOption.value;
  const selectedFilter =
    selectedSortOptions == "draft"
      ? { content_publishing_status: { type: "eq", value: 1 } }
      : selectedSortOptions == "publish_date"
      ? {}
      : {};

  const innitialPlayload = useRef({
    default: "all",
    pagination: {
      limit: 10,
      offset: 0,
    },
    filter: {
      content_category_id: {
        type: "eq",
        value: videoType == 0 ? "33" : "30",
      },
      status: {
        type: "eq",
        value: 1,
      },
      // ...selectedFilter,
    },
    join: {
      content_to_contents: {
        reletion: {
          prefix: "content_id",
          suffix: "content_child_id",
        },
        condition: {
          content_parent_id: {
            type: "eq",
            value: channel_id,
          },
        },
      },
    },
    sort: {
      content_id: selectedSortOptions == "publish_date" ? "desc" : "asc",
    },
    project_id: project?.project_id,
    video_type: videoType, //reel = 0 || video = 1
    parent_id: channel_id, //437//328
  });

  const [videoState, setVideoState] = useState(innitialPlayload.current);

  useEffect(() => {
    if (selectedSortOptions == "publish_date") {
      let appliedFilter = {
        content_category_id: {
          type: "eq",
          value: videoType == 0 ? "33" : "30",
        },
        status: {
          type: "eq",
          value: 1,
        },
      };
      if (videoState.filter.content_title) {
        appliedFilter = {
          ...appliedFilter,
          content_title: videoState.filter.content_title,
        };
      }
      setVideoState((prev) => ({
        ...prev,
        filter: appliedFilter,
        sort: {
          content_id: selectedSortOptions == "publish_date" ? "desc" : "asc",
        },
        // pagination: {
        //   limit: 10,
        //   offset: 0,
        // },
      }));
    } else if (selectedSortOptions == "draft") {
      setVideoState((prev) => ({
        ...prev,
        sort: {
          content_id: "asc",
        },
        filter: {
          ...prev.filter,
          ...selectedFilter,
        },
        // pagination: {
        //   limit: 10,
        //   offset: 0,
        // },
      }));
    } else {
    }
  }, [selectedSortOptions]);

  const {
    data: channelVideo,
    isLoading: isChannelVideoLoading,
    refetch: refetchVideo,
  } = useGetAllVideoList(videoState);

  const { data: channelVideoData, total: totalVideoCount } = channelVideo || {
    data: [],
    total: 0,
  };
  const onPrivacyUpdate = useCallback(() => {
    refetchVideo();
  }, []);

  const videoColumns = [
    // {
    //   Header: "#",
    //   columnId: 1,
    //   accessor: "content_id",
    //   disableSortBy: true,
    //   disableFilters: true,
    //   Cell: ({ value }) => {
    //     return <>{parseInt(value)}</>;
    //   },
    // },
    // {
    //   Header: "Sl no",
    //   columnId: 1,
    //   accessor: "index",
    //   disableSortBy: true,
    //   disableFilters: true,
    // },
    {
      Header: "Title",
      columnId: 2,
      accessor: "content_title",
      disableSortBy: true,
      disableFilters: false,
      Cell: ({ value, row }) => {
        const video = row.original;
        const key = video.content_id;
        return (
          <VideoCard
            key={key + "videolist"}
            videoId={video.content_id}
            title={video.content_title}
            description={video.content_description}
            commentsCount={video.channel_tracking_data}
            likesCount={video.channel_tracking_data?.total_like_count ?? 0}
            viewsCount={video?.channel_tracking_data?.total_views_count}
            publishDate={video.content_publication_date}
            isUploading={video.isUploading}
            coverImage={video?.video_image_data?.content_image_id?.thumbnail}
            privacy_status={video.content_privacy_status}
            publishStatus={video.content_publishing_status?.value?.value ?? 0}
            videoType={videoType}
            processingStatus={video.ml_process_status_id?.value?.value ?? 0}
            onPrivacyUpdate={onPrivacyUpdate}
          />
        );
      },
    },
    {
      Header: "Date",
      accessor: "content_publication_date",
      disableFilters: true,
      columnId: 3,
      Cell: ({ value, row }) => {
        const {
          content_publication_date,
          content_publishing_status,
          ml_process_status_id,
        } = row.original || {
          ml_process_status_id: "",
          content_publication_date: "",
          content_publishing_status: "",
        };
        let now = dayjs(new Date()); //todays date
        let end = dayjs(content_publication_date); // another date
        let dateDifference = now.diff(end, "s");
        return (
          <>
            <span className="d-block">
              {formatLocalizedDate(content_publication_date, "MMMM D, YYYY")}{" "}
            </span>
            <span
              className={`fs-7 ${
                dateDifference < 0
                  ? "text-warning"
                  : ml_process_status_id?.value?.value == 0 ||
                    ml_process_status_id?.value?.value != 2
                  ? "text-warning"
                  : ml_process_status_id?.value?.value == 1 ||
                    ml_process_status_id?.value?.value != 2
                  ? "text-warning"
                  : content_publishing_status?.value?.value == 1
                  ? "text-warning"
                  : content_publishing_status?.value?.value == 2
                  ? "text-warning"
                  : content_publishing_status?.value?.value == 3
                  ? "text-primary"
                  : "text-secondary"
              }`}
            >
              {dateDifference < 0
                ? "Scheduled"
                : ml_process_status_id?.value?.value == 0 ||
                  ml_process_status_id?.value?.value != 2
                ? "Processing Pending"
                : ml_process_status_id?.value?.value == 1 ||
                  ml_process_status_id?.value?.value != 2
                ? "In Process"
                : content_publishing_status?.value?.value == 1
                ? "Draft"
                : content_publishing_status?.value?.value == 2
                ? "Pendiing Approval"
                : content_publishing_status?.value?.value == 3
                ? "Published"
                : "Status Not Found"}
            </span>
          </>
        );
        // }
      },
    },
    {
      Header: "Views",
      disableFilters: true,
      columnId: 4,
      Cell: ({ value, row }) => {
        const video = row.original;
        return video.channel_tracking_data[0]?.total_views_count
          ? parseInt(
              video.channel_tracking_data[0]?.total_views_count
            ).toLocaleString()
          : 0;
      },
    },
    {
      Header: "Likes",
      disableFilters: true,
      columnId: 5,
      Cell: ({ value, row }) => {
        const video = row.original;
        return video.channel_tracking_data[0]?.total_like_count
          ? parseInt(
              video.channel_tracking_data[0]?.total_like_count
            ).toLocaleString()
          : 0;
      },
    },
    {
      columnId: 6,
      Header: "Comments",
      disableFilters: true,
      accessor: "channel_all_video_comment_count",
      Cell: ({ value }) => {
        console.log("comment", value);
        return value?.length > 0 ? value[0]?.total_comment : 0;
      },
    },
    {
      columnId: 7,
      Header: "Actions",
      accessor: "action",
      disableFilters: true,
      Cell: ({ row, value }) => {
        const {
          content_publication_date,
          content_publishing_status,
          ml_process_status_id,
          isUploading,
        } = row?.original || {};
        let now = dayjs(new Date()); //todays date
        let end = dayjs(content_publication_date); // another date
        let dateDifference = now.diff(end, "s");

        let actionButton = [];
        if (dateDifference < 0) {
          actionButton = ["draft", "copy"];
        } else if (content_publishing_status?.value?.value == 3) {
          actionButton = ["view", "delete"];
        } else if (
          !content_publishing_status?.value?.value ||
          content_publishing_status?.value?.value == 1 ||
          content_publishing_status?.value?.value == 2 ||
          ml_process_status_id?.value?.value == 2
        ) {
          actionButton = ["draft", "copy"];
        } else if (isUploading) {
          actionButton = ["review"];
        } else {
        }
        return row?.original?.content_id == 0 ? (
          <></>
        ) : (
          <ActionButtonBar
            buttons={actionButton}
            row={row}
            onView={(data) => {
              if (
                dateDifference > 0 &&
                (ml_process_status_id?.value?.value == 0 ||
                  ml_process_status_id?.value?.value != 2)
              ) {
                toast.error("Please wait while the video is being processed.");
                return;
              }
              redirectTo("channel/video/" + row?.original?.content_id);
            }}
            onDelete={() => {
              deleteVideo(row?.original?.content_id);
            }}
            onCopy={(value) => {
              if (value?.content_id) {
                navigator.clipboard.writeText(value?.content_id);
                toast.success("Video id copied to clipboard.");
              }
            }}
            title="Copy Video ID"
          />
        );
      },
    },
  ];

  const onUploadFile = () => {
    redirectTo(`channel/file-upload/${channel_id}`);
  };

  const onTableStateChange = (tempState) => {
    console.group("onTableStateChange");
    console.log("tempState", tempState);
    let state = { ...tempState };
    if (state.sortBy.length == 0) {
      state.sortBy = [
        {
          id: "content_id",
          [selectedSortOptions == "publish_date" ? "desc" : "asc"]: true,
        },
      ];
    }
    let payload = changeTableStateToPayload(state);

    console.log("state", state);
    console.log("payload", payload);
    setVideoState((prev) => ({
      ...prev,
      ...payload,
    }));
    console.groupEnd("onTableStateChange");
  };
  const deleteVideo = async (id) => {
    showBackdrop();
    const response = await getService(`${DELETE_VIDEO}/${id}`);
    if (response.data) {
      hideBackdrop();
      toast.success("Video deleted successfully");
    }
    if (response.isError) {
      toast.error(response?.error);
      hideBackdrop();
    }
    refetchVideo();
  };

  const onDelete = () => {
    for (const key in selectedList) {
      if (Object.hasOwnProperty.call(selectedList, key)) {
        if (selectedList[key]) {
          const data = channelVideoData[key];
          deleteVideo(data?.content_id);
        }
      }
    }
    toast.success("Videos deleted successfully");
  };
  return (
    <>
      {!isChannelVideoLoading ? (
        <Card>
          <CardBody>
            {Object.keys(selectedList)?.length > 0 && (
              <SubmitButton
                className="position-absolute top-0 end-0 text-danger z-3 mt-n3 me-n2 cursor-pointer bg-white rounded-circle shadow-sm p-2"
                onClick={onDelete}
                as="span"
              >
                {findIcon("Trash", "dual-tone", 30)}
              </SubmitButton>
            )}
            <Row className="">
              <Col>
                {/* {channelVideoData?.length > 0 && totalVideoCount > 0 && ( */}
                <ReactTable
                  prrntClassName="border channel__videos__table"
                  data={channelVideoData}
                  columns={videoColumns}
                  onTableStateChange={onTableStateChange}
                  recordsTotal={totalVideoCount}
                  initialState={videoState}
                  onRowSelectionChange={setSelectedList}
                  recordsPerPage={innitialPlayload.current.pagination.limit}
                />
                {/* )} */}
              </Col>
            </Row>
          </CardBody>
        </Card>
      ) : isChannelVideoLoading ? (
        <VideoListSkeleton />
      ) : (
        <PageNotFound
          title="Your content library is empty"
          buttonText="Upload Video"
          message="Start creating and sharing your video today!"
          backgroundImage={findIcon("Empty-folder", "dual-tone", 100)}
          onFallbackClick={onUploadFile}
        />
      )}
    </>
  );
};

export default function ChannelVideos() {
  const [modalShow, setModalShow] = useState(false);
  const { channel_id } = useParams();
  const { redirectTo } = useRedirect();
  const tabs = [
    {
      title: "Videos",
      eventKey: "video-tab",
      Content: () => <VideoListContanier videoType={1} key={"videolist"} />,
    },
    {
      title: "Reels",
      eventKey: "reels-tab",
      Content: () => <VideoListContanier videoType={0} key={"reelslist"} />,
    },
  ];

  const memorizedTabs = useMemo(() => tabs, []);

  return (
    <>
      <FloatingButton
        title="Upload Video"
        onClick={() => redirectTo("channel/file-upload/" + channel_id)}
      />
      <Col className="" md={12}>
        <h4 className="fw-semibold mb-0">Channel Content</h4>
      </Col>
      <TabNavigation
        tabs={memorizedTabs}
        withSorting
        sortOptions={videoListSortOption}
      />
      <ShareModal show={modalShow} onHide={() => setModalShow(false)} />
    </>
  );
}
