import React, { memo, useCallback, useEffect, useState } from "react";
import {
  Accordion,
  AccordionButton,
  Badge,
  Button,
  Card,
  Col,
  Form,
  FormCheck,
  Row,
  Table,
} from "react-bootstrap";
import MediaRenderer from "../components/MediaRenderer";
import { findIcon } from "../../../dashboard/icons";
import Select from "react-select";
import ReactTable from "../../../../lib/table/react-table";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  useFetchOnboardingCities,
  useFetchOnboardingStates,
  useFetchOnboardingZones,
} from "../../../../queries/query-hooks/onboarding-hook";
import dayjs from "dayjs";
import { toast } from "react-hot-toast";
import FormSteps from "../../../../components/form-components/form-steps";
import {
  campaignInfoInitialValues,
  campaignInfoValidationSchema,
  onboardingInfoInitialValues,
  onboardingInfoValidationSchema,
  personalInfoInitialValues,
  personalInfoValidationSchema,
} from "../constants/form-helpers";
import uploadService from "../../../../api/services/upload-service";
import { UPLOAD_FILES_POST } from "../../../../api/endpoints/action-endpoints";
import { STATUS_CONSTANTS } from "../constants/action-constants";
import useBackdrop from "../../../../components/custom-hooks/use-backdrop";
import { Link } from "react-router-dom";
import AddCommentsModal from "./AddCommentsModal";
const OnboardingView = ({
  actionStatus,
  isConsentAreaOpen,
  consentLogs,
  addedLogs,
  handleAddlog,
  addedLogColumns,
  consentLogColumns,
  hcpInformation,
  onboardingDropdownValues,
  handleOnboardingUpdate,
  handleOnboardingReject,
  actionInfo,
  actionDetails,
}) => {
  const [step, setStep] = React.useState(1);
  const [postObj, setPostObj] = React.useState({});

  const [comment, setComment] = useState("");
  const [commentsModal, setCommentsModal] = useState(false);

  const onStepClick = useCallback(
    (key) => {
      console.log("key");
      // setStep(key);
    },
    [step]
  );

  const updateClick = (values) => {
    if (
      step == 3 &&
      !(
        actionStatus.id == STATUS_CONSTANTS.PENDING ||
        actionStatus.id == STATUS_CONSTANTS.IN_PROGRESS
      )
    ) {
      console.log(
        "check ob-view",
        step,
        actionStatus.id,
        STATUS_CONSTANTS.IN_PROGRESS
      );

      toast.error(`Action already completed `);
      return false;
    }
    console.log("update_click", step, values);
    // alert("update click");
    if (addedLogs?.length <= 0 && step == 3) {
      toast.error("You have to add at least one log");
      return false;
    } else if (step == 3) {
      handleOnboardingUpdate({ ...postObj, ...values });
    } else {
      // alert("hi")
      setPostObj((prev) => ({
        ...prev,
        ...values,
      }));
      setStep(step + 1);
    }
  };

  const rejectClick = (values) => {
    if (
      !(
        actionStatus?.id == STATUS_CONSTANTS.PENDING ||
        actionStatus?.id == STATUS_CONSTANTS.IN_PROGRESS
      )
    ) {
      toast.error("Action already completed");
      return false;
    }
    setCommentsModal(true);
  };

  const onCommentsSubmit = (value) => {
    let payload = {
      ...value,
      status: 6,
    };
    handleOnboardingReject(payload);
  };

  console.log("previous click", hcpInformation);
  const previousClick = (values) => {
    console.log("Previous click ", values);
    if (step > 3 && step <= 1) {
      return false;
    } else {
      setPostObj((prev) => ({
        ...prev,
        ...values,
      }));
      setStep(step - 1);
    }
  };
  return (
    <>
      <ActionInfoCard actionInfo={actionInfo} />
      <FormSteps
        onStepClick={onStepClick}
        steps={[
          {
            name: "Campaign Information",
            active: step == 1,
            completed: step > 1,
          },
          {
            name: "Personal Information",
            active: step == 2,
            completed: step > 2,
          },
          {
            name: "Onboarding Information",
            active: step == 3,
            completed: step > 3,
          },
        ]}
      />
      {step == 1 && (
        <CampaignInformation
          onboardingDropdownValues={onboardingDropdownValues}
          handleAddlog={handleAddlog}
          hcpInformation={hcpInformation}
          onSubmit={updateClick}
          modifedValue={postObj}
          // onPreviousClick={previousClick}
          onReject={rejectClick}
          // step={step}
          key={"campaign-info"}
        />
      )}
      {step == 2 && (
        <PersonalInformation
          onboardingDropdownValues={onboardingDropdownValues}
          handleAddlog={handleAddlog}
          hcpInformation={hcpInformation}
          modifedValue={postObj}
          onSubmit={updateClick}
          onPreviousClick={previousClick}
          onReject={rejectClick}
          key={"personal-info"}
          step={step}
        />
      )}
      {step == 3 && (
        <OnboardingInformation
          onboardingDropdownValues={onboardingDropdownValues}
          handleAddlog={handleAddlog}
          hcpInformation={hcpInformation}
          modifedValue={postObj}
          onSubmit={updateClick}
          onPreviousClick={previousClick}
          onReject={rejectClick}
          key={"onboarding-info"}
          step={step}
        />
      )}

      {actionDetails?.comments?.length > 0 && (
        <Card>
          <Card.Header>Comments</Card.Header>
          <Card.Body>
            <Table responsive className="table-bordered">
              <thead>
                <tr>
                  <th className="text-dark fw-semibold">Commented On</th>
                  <th className="text-dark fw-semibold">Comment</th>
                  <th className="text-dark fw-semibold">Commented By</th>
                  <th className="text-dark fw-semibold">Attachment</th>
                </tr>
              </thead>
              <tbody>
                {actionDetails?.comments?.length > 0 &&
                  actionDetails?.comments.map((item, key) => {
                    let { comment, created_at, user_id, path } = item || {
                      comment: "",
                      created_at: "",
                      user_id: "",
                      path: "",
                    };
                    return (
                      <tr key={`${key}-${user_id}`}>
                        <td className="text-dark fw-semibold">{created_at}</td>
                        <td className="text-dark fw-normal">{comment}</td>
                        <td className="fw-normal text-dark">{user_id?.name}</td>
                        <td className="fw-normal text-dark">
                          <MediaRenderer url={path} />
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      )}

      {addedLogs && addedLogs.length > 0 && (
        // <div ref={targetRef}>
        <Card className="mb-5">
          <Card.Header>
            <Card.Title as="h5">Log History</Card.Title>
          </Card.Header>
          <Card.Body>
            <ReactTable
              data={addedLogs}
              columns={addedLogColumns}
              recordsPerPage={100}
              recordsTotal={addedLogs.length}
            />
          </Card.Body>
        </Card>
        // </div>
      )}

      {isConsentAreaOpen && (
        <ReactTable data={consentLogs} columns={consentLogColumns} />
      )}
      <AddCommentsModal
        onboardingDropdownValues={onboardingDropdownValues}
        show={commentsModal}
        onSubmit={onCommentsSubmit}
        onHide={() => setCommentsModal(false)}
      />
    </>
  );
};

export default memo(OnboardingView);
function PersonalInformation({
  hcpInformation,
  onboardingDropdownValues,
  handleAddlog,
  onSubmit,
  onReject,
  modifedValue,
  onPreviousClick,
  step,
}) {
  console.log("hcpInformation", hcpInformation);
  console.log("hcpInformation::", modifedValue);
  //useFormik hook
  const formik = useFormik({
    initialValues: personalInfoInitialValues,
    validationSchema: personalInfoValidationSchema,
    onSubmit: (values) => {
      console.log("selected values", values);
      if (values.degree?.length <= 0) {
        toast.error("Please select degree ");
        return false;
      } else if (values.interestSpeciality.length <= 0) {
        toast.error("Please select interest speciality ");
        return false;
      } else if (!values.primarySpeciality) {
        toast.error("Please select primary speciality ");
        return false;
      } else {
        onSubmit(values);
      }
    },
  });
  const {
    values,
    setValues,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
  } = formik;

  useEffect(() => {
    if (modifedValue && Object.keys(modifedValue)?.length > 0) {
      console.log("onboarding info modified", modifedValue);
      setValues({ ...values, ...modifedValue });
    } else {
    }
  }, [modifedValue, hcpInformation]);

  const {
    association: associationOptions,
    degree: degreeOptions,
    speciality: specialityOptions,
    council: councilOptions,
    country: countryOptions,
    user_type: userTypeOptions,
  } = onboardingDropdownValues || {};
  const { data: zoneOptions } = useFetchOnboardingZones(values?.country);
  const { data: stateOptions } = useFetchOnboardingStates(values?.zone);
  const { data: cityOptions } = useFetchOnboardingCities(values?.state);

  const adjustedSpecialityOptions = specialityOptions?.map((speciality) => ({
    value: speciality.speciality_id,
    label: speciality.speciality_name,
  }));

  const adjustedDegreeOptions = degreeOptions?.map((degree) => ({
    value: degree.degree_id,
    label: degree.degree_name,
  }));

  const handleInterestSpecialityChange = (selectedOptions) => {
    setFieldValue(
      "interestSpeciality",
      selectedOptions?.map((option) => option.value)
    );
  };

  const handleDegreeChange = (selectedOptions) => {
    setFieldValue(
      "degree",
      selectedOptions?.map((option) => option.value)
    );
  };

  function calculateAge(dateOfBirth) {
    const today = dayjs();
    const birthDate = dayjs(dateOfBirth);

    const yearsDiff = today.diff(birthDate, "year");

    // If the birth date hasn't occurred yet this year, subtract 1 from the age
    if (today.isBefore(birthDate.add(yearsDiff, "year"), "day")) {
      return yearsDiff - 1;
    }

    return yearsDiff;
  }

  const onRejectClick = () => {
    setFieldValue("status", 6);
    let data = {};
    data = { ...values, status: 6 };
    onReject(data);
  };
  const onPrevious = () => {
    onPreviousClick(values);
  };

  return (
    <>
      <Card>
        <Card.Body>
          <Row>
            <Col lg="12" className="d-flex gap-4 mb-3 border-bottom">
              <div className="avatar-100 lh-1 rounded-pill overflow-hidden position-relative flex-shrink-0 bg-soft-primary">
                <div className="d-flex align-items-center justify-content-center h-100 w-100">
                  {findIcon("User", "dual-tone", "60")}
                </div>
              </div>
              <div className="flex-grow-1">
                <Row>
                  <Col lg="4" className="mb-4">
                    <Form.Floating className="custom-form-floating">
                      <Form.Control
                        type="text"
                        id="floatingInput1"
                        autoComplete="Doctor Name"
                        placeholder="Enter First Name"
                        name="firstName"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.firstName}
                        isInvalid={touched.firstName && !!errors.firstName}
                      />
                      <Form.Label htmlFor="floatingInput">
                        First Name <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control.Feedback type="invalid">
                        {errors.firstName}
                      </Form.Control.Feedback>
                    </Form.Floating>
                  </Col>
                  <Col lg="4" className="mb-4">
                    <Form.Floating className="custom-form-floating">
                      <Form.Control
                        type="text"
                        id="floatingInput1"
                        autoComplete="Doctor Name"
                        placeholder="Enter Middle Name"
                        name="middleName"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.middleName}
                        isInvalid={touched.middleName && !!errors.middleName}
                      />
                      <Form.Label htmlFor="floatingInput">
                        Middle Name
                      </Form.Label>
                      <Form.Control.Feedback type="invalid">
                        {errors.middleName}
                      </Form.Control.Feedback>
                    </Form.Floating>
                  </Col>
                  <Col lg="4" className="mb-4">
                    <Form.Floating className="custom-form-floating">
                      <Form.Control
                        type="text"
                        id="floatingInput1"
                        autoComplete="Doctor Name"
                        placeholder="Enter Last Name"
                        name="lastName"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.lastName}
                        isInvalid={!!errors.lastName && touched.lastName}
                      />
                      <Form.Label htmlFor="floatingInput">
                        Last Name <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control.Feedback type="invalid">
                        {errors.lastName}
                      </Form.Control.Feedback>
                    </Form.Floating>
                  </Col>
                  <Col lg="4" className="mb-4 d-flex justify-content-between">
                    <Form.Floating className="custom-form-floating w-100">
                      <Form.Control
                        type="text"
                        className=""
                        id="floatingInput1"
                        autoComplete="DOB"
                        placeholder="Age"
                        name="age"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.age}
                        isInvalid={!!errors.age && touched.age}
                      />
                      <Form.Label htmlFor="floatingInput">Age</Form.Label>
                      <Form.Control.Feedback type="invalid">
                        {errors.age}
                      </Form.Control.Feedback>
                    </Form.Floating>
                  </Col>

                  <Col lg="4" className="d-flex align-items-center mb-4">
                    <h6 className="me-3 mb-0">Gender:</h6>
                    <Form.Check className=" form-check-inline">
                      <FormCheck.Input
                        type="radio"
                        className=""
                        id="exampleRadio1"
                        value={"male"}
                        name="gender"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        checked={values.gender === "male"}
                        isInvalid={!!errors.gender && touched.gender}
                      />
                      <FormCheck.Label className="" htmlFor="exampleRadio1">
                        Male
                      </FormCheck.Label>
                    </Form.Check>
                    <Form.Check className=" form-check-inline">
                      <FormCheck.Input
                        type="radio"
                        className=""
                        id="exampleRadio2"
                        value={"female"}
                        name="gender"
                        onChange={handleChange}
                        checked={values.gender === "female"}
                        onBlur={handleBlur}
                        isInvalid={!!errors.gender && touched.gender}
                      />
                      <FormCheck.Label className="" htmlFor="exampleRadio2">
                        Female
                      </FormCheck.Label>
                    </Form.Check>
                  </Col>
                </Row>
              </div>
            </Col>
            {/* Contact Preference */}
            <Col lg="12" className="mb-3 mt-3">
              <h5 className="mb-0">Contact Preference</h5>
            </Col>
            <Col lg="4" className="mb-4">
              <Form.Floating className="custom-form-floating">
                <Form.Control
                  type="email"
                  id="floatingInput1"
                  name="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  isInvalid={!!errors.email && touched.email}
                  autoComplete="Email"
                  placeholder="Enter Email"
                  // disabled={type == "consent-collection"}
                />
                <Form.Label htmlFor="floatingInput">Email</Form.Label>
                <Form.Control.Feedback type="invalid">
                  {errors.email}
                </Form.Control.Feedback>
              </Form.Floating>
            </Col>
            <Col lg="4" className="mb-4">
              <Form.Floating className="custom-form-floating">
                <Form.Control
                  type="text"
                  id="floatingInput1"
                  name="mobileNo"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.mobileNo}
                  isInvalid={!!errors.mobileNo && touched.mobileNo}
                  autoComplete="Mobile No."
                  placeholder="Enter Mobile No."
                />
                <Form.Label htmlFor="floatingInput">Mobile No.</Form.Label>
                <Form.Control.Feedback type="invalid">
                  {errors.mobileNo}
                </Form.Control.Feedback>
              </Form.Floating>
            </Col>
            {/* Contact Preference End*/}
            {/* Medical Information*/}
            <Col lg="12" className="mb-3 mt-3">
              <h5 className="mb-0">Medical Information</h5>
            </Col>
            <Col lg="4" className="mb-4">
              <Form.Floating className="custom-form-floating">
                <Form.Select
                  id="floatingSelectGrid"
                  name="association"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.association}
                  isInvalid={!!errors.association && touched.association}
                >
                  <option value={0}>Choose Association</option>
                  {associationOptions?.map(
                    ({ association_id, association_name }) => {
                      return (
                        <option key={association_id} value={association_id}>
                          {association_name}
                        </option>
                      );
                    }
                  )}
                </Form.Select>
                <Form.Label htmlFor="floatingInput">
                  Association Name
                </Form.Label>
                <Form.Control.Feedback type="invalid">
                  {errors.association}
                </Form.Control.Feedback>
              </Form.Floating>
            </Col>
            <Col lg="4" className="mb-4">
              {adjustedDegreeOptions && (
                <Select
                  // className="react-select-customize"
                  placeholder={"Degree"}
                  options={adjustedDegreeOptions}
                  value={adjustedDegreeOptions?.filter((s) =>
                    values?.degree?.includes(s?.value)
                  )}
                  isInvalid={!!errors.degree && touched.degree}
                  onChange={handleDegreeChange}
                  isMulti
                  styles={{
                    control: (styles, state) => ({
                      ...styles,
                      borderColor: !!errors.degree ? "red" : "",
                    }),
                  }}
                />
              )}

              <Form.Control.Feedback type="invalid" className="d-block">
                {errors.degree}
              </Form.Control.Feedback>
            </Col>
            <Col lg="4" md="6" className="mb-4">
              <Form.Floating className="custom-form-floating">
                <Form.Select
                  id="floatingSelectGrid"
                  name="typeOfPractice"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.typeOfPractice}
                  isInvalid={!!errors.typeOfPractice && touched.typeOfPractice}
                >
                  <option value="">Select User Type </option>
                  {userTypeOptions?.map(({ hcp_type_id, hcp_type_name }) => (
                    <option key={hcp_type_id} value={hcp_type_id}>
                      {hcp_type_name}
                    </option>
                  ))}
                </Form.Select>

                <Form.Label htmlFor="floatingSelectGrid">
                  Type of Practice
                </Form.Label>
                <Form.Control.Feedback type="invalid">
                  {errors.typeOfPractice}
                </Form.Control.Feedback>
              </Form.Floating>
            </Col>
            <Col lg="4" md="6" className="mb-4">
              <Form.Floating className="custom-form-floating">
                <Form.Select
                  id="floatingSelectGrid"
                  name="council"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.council}
                  isInvalid={!!errors.council && touched.council}
                >
                  <option value="">Select council</option>
                  {councilOptions?.map(({ council_id, council_name }) => (
                    <option key={council_id} value={council_id}>
                      {council_name}
                    </option>
                  ))}
                </Form.Select>
                <Form.Label htmlFor="floatingSelectGrid">Council</Form.Label>
                <Form.Control.Feedback type="invalid">
                  {errors.council}
                </Form.Control.Feedback>
              </Form.Floating>
            </Col>
            <Col lg="4" className="mb-4">
              <Form.Floating className="custom-form-floating">
                <Form.Control
                  type="text"
                  id="floatingInput1"
                  autoComplete="Registration No."
                  placeholder="Enter Registration No."
                  name="registrationNo"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.registrationNo}
                  isInvalid={!!errors.registrationNo && touched.registrationNo}
                />
                <Form.Label htmlFor="floatingInput">
                  Registration No.
                </Form.Label>
                <Form.Control.Feedback type="invalid">
                  {errors.registrationNo}
                </Form.Control.Feedback>
              </Form.Floating>
            </Col>
            <Col lg="4" md="6" className="mb-4">
              <Form.Floating className="custom-form-floating">
                <Form.Select
                  id="floatingSelectGrid"
                  name="primarySpeciality"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.primarySpeciality}
                  isInvalid={
                    !!errors.primarySpeciality && touched.primarySpeciality
                  }
                >
                  <option value={0}>Select primary speciality</option>
                  {specialityOptions?.map(
                    ({ speciality_id, speciality_name }) => (
                      <option key={speciality_id} value={speciality_id}>
                        {speciality_name}
                      </option>
                    )
                  )}
                </Form.Select>
                <Form.Label htmlFor="floatingSelectGrid">
                  Practice Speciality
                </Form.Label>
                <Form.Control.Feedback type="invalid">
                  {errors.primarySpeciality}
                </Form.Control.Feedback>
              </Form.Floating>
            </Col>

            <Col lg="4" md="6" className="mb-4">
              <Select
                className="react-select-customize"
                placeholder={"Interest Speciality"}
                options={adjustedSpecialityOptions}
                value={adjustedSpecialityOptions?.filter((s) =>
                  values?.interestSpeciality?.includes(s?.value)
                )}
                onChange={handleInterestSpecialityChange}
                isMulti
              />
              <Form.Control.Feedback type="invalid" className="d-block">
                {errors.interestSpeciality}
              </Form.Control.Feedback>
            </Col>

            {/* Medical Information*/}
            <Col lg="12" className="mb-3 mt-3">
              <h5 className="mb-0">Location</h5>
            </Col>
            <Col lg="3" md="6">
              <Form.Floating className="custom-form-floating">
                <Form.Select
                  id="floatingSelectGrid"
                  name="country"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.country}
                  isInvalid={!!errors.country && touched.country}
                >
                  <option value="">Select Country</option>
                  {countryOptions?.map(({ country_id, country_name }) => (
                    <option key={country_id} value={country_id}>
                      {country_name}
                    </option>
                  ))}
                </Form.Select>
                <Form.Label htmlFor="floatingSelectGrid">Country</Form.Label>
                <Form.Control.Feedback type="invalid">
                  {errors.country}
                </Form.Control.Feedback>
              </Form.Floating>
            </Col>
            <Col lg="3" md="6" className="mb-4">
              <Form.Floating className="custom-form-floating">
                <Form.Select
                  id="floatingSelectGrid"
                  name="zone"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.zone}
                  disabled={!zoneOptions}
                  isInvalid={!!errors.zone && touched.zone}
                >
                  <option value="">Select</option>
                  {zoneOptions?.length > 0 &&
                    zoneOptions?.map(({ zone_id, zone_name }) => (
                      <option key={zone_id} value={zone_id}>
                        {zone_name}
                      </option>
                    ))}
                </Form.Select>
                <Form.Label htmlFor="floatingSelectGrid">Zone</Form.Label>
                <Form.Control.Feedback type="invalid">
                  {errors.zone}
                </Form.Control.Feedback>
              </Form.Floating>
            </Col>
            <Col lg="3" md="6" className="mb-4">
              <Form.Floating className="custom-form-floating">
                <Form.Select
                  id="floatingSelectGrid"
                  name="state"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.state}
                  disabled={!stateOptions}
                  isInvalid={!!errors.state && touched.state}
                >
                  <option value="">Select</option>
                  {stateOptions?.length > 0 &&
                    stateOptions?.map(({ state_id, state_name }) => (
                      <option key={state_id} value={state_id}>
                        {state_name}
                      </option>
                    ))}
                </Form.Select>
                <Form.Label htmlFor="floatingSelectGrid">State</Form.Label>
                <Form.Control.Feedback type="invalid">
                  {errors.state}
                </Form.Control.Feedback>
              </Form.Floating>
            </Col>
            <Col lg="3" md="6" className="mb-4">
              <Form.Floating className="custom-form-floating">
                <Form.Select
                  id="floatingSelectGrid"
                  name="city"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.city}
                  disabled={!cityOptions}
                  isInvalid={!!errors.city && touched.city}
                >
                  <option value="">Select</option>
                  {cityOptions?.length > 0 &&
                    cityOptions?.map(({ city_id, city_name }) => (
                      <option key={city_id} value={city_id}>
                        {city_name}
                      </option>
                    ))}
                </Form.Select>
                <Form.Label htmlFor="floatingSelectGrid">City</Form.Label>
                <Form.Control.Feedback type="invalid">
                  {errors.city}
                </Form.Control.Feedback>
              </Form.Floating>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <ButtonArea
        addLogClick={handleAddlog}
        updateClick={handleSubmit}
        rejectClick={onRejectClick}
        previousClick={onPrevious}
        step={step}
      />
    </>
  );
}

function OnboardingInformation({
  onboardingDropdownValues,
  hcpInformation,
  modifedValue,
  handleAddlog,
  onPreviousClick,
  onSubmit,
  onReject,
  step,
  logs,
}) {
  const { showBackdrop, hideBackdrop } = useBackdrop();

  const formik = useFormik({
    initialValues: onboardingInfoInitialValues,
    validationSchema: onboardingInfoValidationSchema,
    onSubmit: (values) => {
      console.log("on submit called:", values);
      if (values.medium === "2" && values.callRecording?.length <= 0) {
        toast.error("Please upload call recording");
        return false;
      } else {
        onSubmit(values);
      }
    },
  });

  const {
    medium: mediumOptions,
    user_type: userTypeOptions,
    social_link: socialLinkTypeOptions,
  } = onboardingDropdownValues || {
    medium: "",
    user_type: "",
    social_link: "",
  };

  const {
    values,
    touched,
    setValues,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
  } = formik;

  console.log("formik", formik);
  useEffect(() => {
    if (modifedValue && Object.keys(modifedValue)?.length > 0) {
      // console.log("onboarding info modified", modifedValue);
      setValues({ ...values, ...modifedValue });
    } else {
      // setValues({
      //   ...values,
      // });
    }
  }, [modifedValue, hcpInformation]);

  const addSocialMedia = () => {
    console.log("add social media", formik.values);
    formik.setFieldValue("socialLinks", [
      ...formik.values.socialLinks,
      { type: "", link: "" },
    ]);
  };

  const handleSocialDropdownChange = (e, index) => {
    const { name, value } = e.target;

    const socialLink = [...formik.values.socialLinks];
    socialLink[index][name] = value;
    formik.setFieldValue("socialLinks", socialLink);
  };

  const removeSocialMedia = (index) => {
    formik.setFieldValue("socialLinks", [
      ...formik.values.socialLinks.slice(0, index),
      ...formik.values.socialLinks.slice(index + 1),
    ]);
  };

  function uploadFiles(files) {
    showBackdrop();
    return new Promise(async (resolve, reject) => {
      // Check if files array is empty
      if (files.length === 0) {
        hideBackdrop();
        reject("No files to upload.");
        return;
      }
      //console.log("uploading files", files);
      let formdata = new FormData();
      //console.log("FORMDATA", files);
      files.forEach((file) => {
        formdata.append("files[]", file);
      });

      // 1. Upload files to server
      let response = await uploadService(UPLOAD_FILES_POST, formdata);
      if (response.data?.success) {
        hideBackdrop();
        //console.log("RESPONSE", response);
        // 2. Simulating successful upload and generating URLs
        const urls = response.data.data.map((file) => file.url);
        //console.log("URLS", urls);
        resolve(urls);
      } else {
        hideBackdrop();
        reject("Something went wrong");
      }
    });
  }

  const changeFileUpload = async (fieldName, file) => {
    if (Array.isArray(file)) {
      try {
        const urls = await uploadFiles(file);
        if (urls?.length > 0) {
          setFieldValue(fieldName, urls);
        }
      } catch (e) {
        console.log(e);
      }
    }
  };

  const onPrevious = () => {
    onPreviousClick(values);
  };

  const onRejectClick = () => {
    setFieldValue("status", 6);
    let data = {};
    data = { ...values, status: 6 };
    onReject(data);
  };

  return (
    <>
      <Card>
        <Card.Body>
          <Row>
            <Col lg="4" className="mb-4">
              <Form.Label htmlFor="exampleInputEmail1" className="">
                Select Medium
              </Form.Label>
              <Form.Floating className="custom-form-floating">
                <Form.Select
                  id="floatingSelectGrid"
                  name="medium"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.medium}
                  isInvalid={!!errors.medium}
                >
                  <option value="">Select Medium</option>
                  {mediumOptions?.map(({ value, level }) => {
                    return (
                      <option key={value} value={value}>
                        {level}
                      </option>
                    );
                  })}
                </Form.Select>
                <Form.Label htmlFor="floatingInput1">
                  Medium <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control.Feedback type="invalid">
                  {errors.medium}
                </Form.Control.Feedback>
              </Form.Floating>
            </Col>
            {values?.medium == 2 && (
              <>
                <Col lg="4" className="mb-4">
                  <Form.Label htmlFor="exampleInputEmail1" className="">
                    Upload Call Recording*{" "}
                    <span className="text-muted">(.mp3,.wav,.ogg)</span>
                  </Form.Label>
                  <Form.Control
                    type="file"
                    accept="audio/*"
                    name="callRecording"
                    isInvalid={!!errors.callRecording}
                    onChange={(e) => {
                      const file = e.target.files[0];
                      const validMimeTypes = [
                        "audio/mpeg",
                        "audio/wav",
                        "audio/ogg",
                      ];
                      if (file && validMimeTypes.includes(file.type)) {
                        changeFileUpload(
                          "callRecording",
                          Object.values(e.target.files)
                        );
                      } else {
                        toast.error("Only .mp3, .wav, .ogg files are allowed");
                        e.target.value = "";
                      }
                    }}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.callRecording}
                  </Form.Control.Feedback>
                  {values.callRecording?.length > 0 && (
                    <Link
                      to={values.callRecording[0]}
                      target="_blank"
                      className="text-justify"
                    >
                      {values.callRecording[0]}
                    </Link>
                  )}
                </Col>
                <Col lg="4" className="mb-4">
                  <Form.Label htmlFor="exampleInputEmail1" className="">
                    Upload Call Recording Two{" "}
                    <span className="text-muted">(.mp3,.wav,.ogg)</span>
                  </Form.Label>
                  <Form.Control
                    type="file"
                    accept="audio/*"
                    name="callRecordingTwo"
                    onChange={(e) => {
                      const file = e.target.files[0];
                      const validMimeTypes = [
                        "audio/mpeg",
                        "audio/wav",
                        "audio/ogg",
                      ];

                      if (file && validMimeTypes.includes(file.type)) {
                        changeFileUpload(
                          "callRecordingTwo",
                          Object.values(e.target.files)
                        );
                      } else {
                        toast.error("Only .mp3, .wav, .ogg files are allowed");
                        e.target.value = ""; // Clear the file input if invalid
                      }
                    }}
                  />
                  {values.callRecordingTwo?.length > 0 && (
                    <Link
                      to={values.callRecordingTwo[0]}
                      target="_blank"
                      className="text-justify"
                    >
                      {values.callRecordingTwo[0]}
                    </Link>
                  )}
                </Col>
              </>
            )}

            <Col lg="4" className="mb-4">
              <Form.Label htmlFor="exampleInputEmail1" className="">
                Document Specialities{" "}
                <span className="text-muted" style={{ fontSize: "12px" }}>
                  (.jpg,.png,.jpeg,.pdf,.doc,.docx)
                </span>
              </Form.Label>
              <Form.Control
                type="file"
                accept="image/*,.pdf,.doc,.docx"
                name="documentSpecialities"
                isInvalid={!!errors.documentSpecialities}
                onChange={(e) => {
                  const file = e.target.files[0];
                  const validMimeTypes = [
                    "image/jpeg", // For .jpg and .jpeg
                    "image/png", // For .png
                    "application/pdf", // For .pdf
                    "application/msword", // For .doc
                    "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // For .docx
                  ];

                  if (file && validMimeTypes.includes(file.type)) {
                    changeFileUpload(
                      "documentSpecialities",
                      Object.values(e.target.files)
                    );
                  } else {
                    toast.error(
                      "Only .jpg, .png, .jpeg, .pdf, .doc, .docx files are allowed"
                    );
                    e.target.value = "";
                  }
                }}
              />
              <Form.Control.Feedback type="invalid">
                {errors.documentSpecialities}
              </Form.Control.Feedback>
              {values.documentSpecialities?.length > 0 && (
                <Link
                  to={values.documentSpecialities[0]}
                  target="_blank"
                  className="text-justify"
                >
                  {values.documentSpecialities[0]}
                </Link>
              )}
            </Col>
            <Col lg="4" className="mb-4">
              <Form.Label htmlFor="exampleInputEmail1" className="">
                Document Specialities Two{" "}
                <span className="text-muted" style={{ fontSize: "12px" }}>
                  (.jpg,.png,.jpeg,.pdf,.doc,.docx)
                </span>
              </Form.Label>
              <Form.Control
                type="file"
                accept="image/*,.pdf,.doc,.docx"
                name="documentSpecialitiesTwo"
                onChange={(e) => {
                  const file = e.target.files[0];
                  const validMimeTypes = [
                    "image/jpeg", // For .jpg and .jpeg
                    "image/png", // For .png
                    "application/pdf", // For .pdf
                    "application/msword", // For .doc
                    "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // For .docx
                  ];

                  if (file && validMimeTypes.includes(file.type)) {
                    changeFileUpload(
                      "documentSpecialitiesTwo",
                      Object.values(e.target.files)
                    );
                  } else {
                    toast.error(
                      "Only .jpg, .png, .jpeg, .pdf, .doc, .docx files are allowed"
                    );
                    e.target.value = ""; // Clear the file input if invalid
                  }
                }}
              />
              {values.documentSpecialitiesTwo?.length > 0 && (
                <Link
                  to={values.documentSpecialitiesTwo[0]}
                  target="_blank"
                  className="text-justify"
                >
                  {values.documentSpecialitiesTwo[0]}
                </Link>
              )}
            </Col>

            <Col lg="4" className="mb-4">
              <Form.Label htmlFor="exampleInputEmail1" className="">
                Document Registrations{" "}
                <span className="text-muted" style={{ fontSize: "12px" }}>
                  (.jpg,.png,.jpeg,.pdf,.doc,.docx)
                </span>
              </Form.Label>
              <Form.Control
                type="file"
                accept="image/*,.pdf,.doc,.docx"
                isInvalid={!!errors.documentRegistrations}
                name="documentRegistrations"
                onChange={(e) => {
                  const file = e.target.files[0];
                  const validMimeTypes = [
                    "image/jpeg", // For .jpg and .jpeg
                    "image/png", // For .png
                    "application/pdf", // For .pdf
                    "application/msword", // For .doc
                    "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // For .docx
                  ];

                  if (file && validMimeTypes.includes(file.type)) {
                    changeFileUpload(
                      "documentRegistrations",
                      Object.values(e.target.files)
                    );
                  } else {
                    toast.error(
                      "Only .jpg, .png, .jpeg, .pdf, .doc, .docx files are allowed"
                    );
                    e.target.value = ""; // Clear the file input if invalid
                  }
                }}
              />
              <Form.Control.Feedback type="invalid">
                {errors.documentRegistrations}
              </Form.Control.Feedback>
              {values.documentRegistrations?.length > 0 && (
                <Link
                  to={values.documentRegistrations[0]}
                  target="_blank"
                  className="text-justify"
                >
                  {values.documentRegistrations[0]}
                </Link>
              )}
            </Col>
            <Col lg="4" className="mb-4">
              <Form.Label htmlFor="exampleInputEmail1" className="">
                Document Registrations Two{" "}
                <span className="text-muted" style={{ fontSize: "12px" }}>
                  (.jpg,.png,.jpeg,.pdf,.doc,.docx)
                </span>
              </Form.Label>
              <Form.Control
                type="file"
                accept="image/*,.pdf,.doc,.docx"
                name="documentRegistrationsTwo"
                onChange={(e) => {
                  const file = e.target.files[0];
                  const validMimeTypes = [
                    "image/jpeg", // For .jpg and .jpeg
                    "image/png", // For .png
                    "application/pdf", // For .pdf
                    "application/msword", // For .doc
                    "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // For .docx
                  ];

                  if (file && validMimeTypes.includes(file.type)) {
                    changeFileUpload(
                      "documentRegistrationsTwo",
                      Object.values(e.target.files)
                    );
                  } else {
                    toast.error(
                      "Only .jpg, .png, .jpeg, .pdf, .doc, .docx files are allowed"
                    );
                    e.target.value = ""; // Clear the file input if invalid
                  }
                }}
              />
              {values.documentRegistrationsTwo?.length > 0 && (
                <Link
                  to={values.documentRegistrationsTwo[0]}
                  target="_blank"
                  className="text-justify"
                >
                  {values.documentRegistrationsTwo[0]}
                </Link>
              )}
            </Col>

            <Col lg="4" className="mb-4">
              <Form.Label htmlFor="exampleInputEmail1" className="">
                Document Others{" "}
                <span className="text-muted" style={{ fontSize: "12px" }}>
                  (.jpg,.png,.jpeg,.pdf,.doc,.docx)
                </span>
              </Form.Label>
              <Form.Control
                type="file"
                accept="image/*,.pdf,.doc,.docx"
                name="documentOthers"
                isInvalid={!!errors.documentOthers}
                onChange={(e) => {
                  const file = e.target.files[0];
                  const validMimeTypes = [
                    "image/jpeg", // For .jpg and .jpeg
                    "image/png", // For .png
                    "application/pdf", // For .pdf
                    "application/msword", // For .doc
                    "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // For .docx
                  ];

                  if (file && validMimeTypes.includes(file.type)) {
                    changeFileUpload(
                      "documentOthers",
                      Object.values(e.target.files)
                    );
                  } else {
                    toast.error(
                      "Only .jpg, .png, .jpeg, .pdf, .doc, .docx files are allowed"
                    );
                    e.target.value = ""; // Clear the file input if invalid
                  }
                }}
              />
              <Form.Control.Feedback type="invalid">
                {errors.documentOthers}
              </Form.Control.Feedback>
              {values.documentOthers?.length > 0 && (
                <Link
                  to={values.documentOthers[0]}
                  target="_blank"
                  className="text-justify"
                >
                  {values.documentOthers[0]}
                </Link>
              )}
            </Col>
            <Col lg="4" className="mb-4">
              <Form.Label htmlFor="exampleInputEmail1" className="">
                Document Others Two{" "}
                <span className="text-muted" style={{ fontSize: "12px" }}>
                  (.jpg,.png,.jpeg,.pdf,.doc,.docx)
                </span>
              </Form.Label>
              <Form.Control
                type="file"
                accept="image/*,.pdf,.doc,.docx"
                name="documentOthersTwo"
                onChange={(e) => {
                  const file = e.target.files[0];
                  const validMimeTypes = [
                    "image/jpeg", // For .jpg and .jpeg
                    "image/png", // For .png
                    "application/pdf", // For .pdf
                    "application/msword", // For .doc
                    "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // For .docx
                  ];

                  if (file && validMimeTypes.includes(file.type)) {
                    changeFileUpload(
                      "documentOthersTwo",
                      Object.values(e.target.files)
                    );
                  } else {
                    toast.error(
                      "Only .jpg, .png, .jpeg, .pdf, .doc, .docx files are allowed"
                    );
                    e.target.value = ""; // Clear the file input if invalid
                  }
                }}
              />
              {values.documentOthersTwo?.length > 0 && (
                <Link
                  to={values.documentOthersTwo[0]}
                  target="_blank"
                  className="text-justify"
                >
                  {values.documentOthersTwo[0]}
                </Link>
              )}
            </Col>

            <Col lg="6">
              <Form.Label htmlFor="exampleInputEmail1" className="">
                Social Media Links
              </Form.Label>
              {values.socialLinks?.map((_sl, idx) => (
                <div className="d-flex gap-3 mb-3" key={idx + 1}>
                  <Form.Floating className="custom-form-floating">
                    <Form.Select
                      className="w-auto flex-grow-1"
                      value={_sl.type}
                      name="type"
                      isInvalid={
                        errors?.socialLinks?.length > 0 &&
                        !!errors.socialLinks[idx]?.type
                      }
                      onChange={(e) => handleSocialDropdownChange(e, idx)}
                    >
                      <option value="">Select Social Media Type</option>
                      {socialLinkTypeOptions?.map(
                        ({ social_link_type_id, social_link_type_name }) => (
                          <option
                            key={social_link_type_id}
                            value={social_link_type_id}
                          >
                            {social_link_type_name}
                          </option>
                        )
                      )}
                    </Form.Select>

                    {errors?.socialLinks?.length > 0 && (
                      <Form.Control.Feedback type="invalid">
                        {errors.socialLinks[idx]?.type}
                      </Form.Control.Feedback>
                    )}
                  </Form.Floating>
                  <Form.Floating className="custom-form-floating">
                    <Form.Control
                      type="text"
                      name="link"
                      value={_sl.link}
                      isInvalid={
                        errors?.socialLinks?.length > 0 &&
                        !!errors.socialLinks[idx]?.link
                      }
                      className="w-auto flex-grow-1"
                      onChange={(e) => handleSocialDropdownChange(e, idx)}
                    />
                    <Form.Label htmlFor="floatingInput1">Link</Form.Label>
                    {errors?.socialLinks?.length > 0 && (
                      <Form.Control.Feedback type="invalid">
                        {errors.socialLinks[idx]?.link}
                      </Form.Control.Feedback>
                    )}
                  </Form.Floating>
                  {idx > 0 && (
                    <Button
                      variant="danger"
                      disabled={values.socialLinks.length === 1}
                      className="gap-1 d-flex align-items-center justify-content-center px-3"
                      onClick={() => removeSocialMedia(idx)}
                    >
                      {findIcon("Trash", "solid", "20")}
                    </Button>
                  )}
                </div>
              ))}
              <Button
                variant="outline-info"
                className="gap-1 d-flex align-items-center border-dashed justify-content-center px-3"
                onClick={addSocialMedia}
              >
                {findIcon("Plus", "solid", "20")}
                Add More
              </Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <ButtonArea
        addLogClick={handleAddlog}
        updateClick={handleSubmit}
        previousClick={onPrevious}
        rejectClick={onRejectClick}
        step={step}
        // isUpdateDisabled={}
      />
    </>
  );
}
function CampaignInformation({
  hcpInformation,
  handleAddlog,
  onSubmit,
  modifedValue,
  // onPreviousClick,
  onReject,
  step,
}) {
  const formik = useFormik({
    initialValues: campaignInfoInitialValues,
    validationSchema: campaignInfoValidationSchema,
    onSubmit: (values) => {
      console.log("onSubmit", values);
      onSubmit(values);
    },
  });

  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    setValues,
  } = formik;

  function filterDocumentsByType(allData, type) {
    const filteredDocuments = allData
      .filter((document) => document.hcp_document_type === type)
      .map((document) => document.hcp_document_path);
    return filteredDocuments;
  }

  function transformSocialLinks(input) {
    return input.map((item) => ({
      type: item.social_link_type.social_link_type_id,
      link: item.social_link,
    }));
  }

  useEffect(() => {
    if (modifedValue && Object.keys(modifedValue)?.length > 0) {
      console.log("available", modifedValue);
      setValues(modifedValue);
    } else {
      console.log("hcp info", hcpInformation);
      console.log("not available", modifedValue);
      setValues({
        ...values,
        firstName: hcpInformation?.hcp_first_name || "",
        middleName: hcpInformation?.hcp_middle_name || "",
        lastName: hcpInformation?.hcp_last_name || "",
        age: hcpInformation?.hcp_age || "",
        gender: hcpInformation?.hcp_gender || "",
        email: hcpInformation?.hcp_email || "",
        mobileNo: hcpInformation?.hcp_mobile || "",
        association: hcpInformation?.hcp_to_association?.association_id || "",
        utmSource: hcpInformation?.hcp_utmsource,
        degree:
          hcpInformation.hcp_to_medical_degrees?.length > 0
            ? hcpInformation.hcp_to_medical_degrees?.map(
                (item) => item.medical_degree.degree_id
              )
            : [], //hcpInformation?.hcp_medical_degree_id || "",
        typeOfPractice: hcpInformation?.hcp_to_type?.hcp_type_id || "",
        council: hcpInformation?.hcp_to_medical_council?.council_id || "",
        registrationNo: hcpInformation?.hcp_register_number || "",
        primarySpeciality:
          hcpInformation?.hcp_to_primary_speciality?.speciality_id || "",
        city: hcpInformation?.hcp_to_city?.city_id || "",
        state: hcpInformation?.hcp_to_state?.state_id || "",
        zone: hcpInformation?.hcp_to_zone?.zone_id || "",
        country: hcpInformation?.hcp_to_country?.country_id || "",
        interestSpeciality:
          hcpInformation?.hcp_to_interest_speciality?.length > 0
            ? hcpInformation.hcp_to_interest_speciality?.map(
                (item) => item.speciality.speciality_id
              )
            : [],
        medium: hcpInformation?.hcp_medium.id || "",
        user_type: hcpInformation?.hcp_to_type?.hcp_type_id || "",
        documentOthers:
          hcpInformation.hcp_to_document?.length > 0
            ? filterDocumentsByType(hcpInformation.hcp_to_document, "other")
            : [],
        documentRegistrations:
          hcpInformation.hcp_to_document?.length > 0
            ? filterDocumentsByType(
                hcpInformation.hcp_to_document,
                "registration"
              )
            : [],
        documentSpecialities:
          hcpInformation.hcp_to_document?.length > 0
            ? filterDocumentsByType(
                hcpInformation.hcp_to_document,
                "speciality"
              )
            : [],

        callRecording:
          hcpInformation.hcp_to_document?.length > 0
            ? filterDocumentsByType(hcpInformation.hcp_to_document, "recording")
            : [],
        socialLinks:
          hcpInformation?.hcp_to_social_links?.length > 0
            ? transformSocialLinks(hcpInformation?.hcp_to_social_links)
            : [{ type: "", link: "" }],
      });
    }
  }, [modifedValue, hcpInformation]);

  const onRejectClick = () => {
    setFieldValue("status", 6);
    let data = {};
    data = { ...values, status: 6 };
    onReject(data);
  };

  const onUpdateClick = () => {
    setFieldValue("status", 5);
    handleSubmit();
  };

  return (
    <>
      <Card>
        <Card.Body>
          <Row>
            <Col lg="12" className="mb-12">
              <Form.Floating className="custom-form-floating">
                <Form.Control
                  name="utmSource"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.utmSource}
                  disabled
                  isInvalid={!!errors.utmSource}
                />
                <Form.Label htmlFor="floatingInput1">UTM Source</Form.Label>
                <Form.Control.Feedback type="invalid">
                  {errors.utmSource}
                </Form.Control.Feedback>
              </Form.Floating>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <ButtonArea
        addLogClick={handleAddlog}
        updateClick={onUpdateClick}
        // previousClick={onPrevious}
        rejectClick={onRejectClick}
        step={step}
      />
    </>
  );
}
function ButtonArea({
  isUpdateDisabled,
  addLogClick,
  updateClick,
  previousClick,
  rejectClick,
  step,
}) {
  return (
    <div className="d-flex justify-content-end mb-4 gap-2">
      <Button
        variant="info"
        className="m-0 d-flex align-items-center gap-2 justify-content-center pointer-cursor"
        onClick={addLogClick}
      >
        {findIcon("Plus", "solid", "20")}
        Add Log
      </Button>

      <Button
        className="m-0 d-flex align-items-center gap-2 justify-content-center pointer-cursor bg-danger"
        onClick={rejectClick}
      >
        Reject
      </Button>

      {step > 1 && step < 4 && (
        <Button
          className="m-0 d-flex align-items-center gap-2 justify-content-center pointer-cursor"
          onClick={previousClick}
        >
          Previous
        </Button>
      )}
      <Button
        disabled={isUpdateDisabled}
        className="m-0 d-flex align-items-center gap-2 justify-content-center pointer-cursor"
        onClick={updateClick}
      >
        {step >= 3 ? "Update Info" : "Next"}
      </Button>
    </div>
  );
}
function ActionInfoCard({ actionInfo }) {
  console.log("actionInfo", actionInfo);
  return (
    <Card>
      <Card.Body>
        <div>
          <div className="d-flex gap-2">
            <Badge pill bg="danger" className="fw-normal">
              {actionInfo.priority}
            </Badge>
            <Badge
              pill
              bg={`${actionInfo.status == "Rejected" ? "danger" : "info"}`}
              className="fw-normal"
            >
              {actionInfo.status}
            </Badge>
            <Badge pill bg="soft-warning" className="fw-normal">
              Deadline {actionInfo.deadline}
            </Badge>
          </div>
          <h4 className="mt-4 mb-0">{actionInfo.name}</h4>

          <div className="d-flex justify-content-end gap-3 mt-1">
            <span className="text-dark fs-6 justify-content-end">
              {actionInfo.type}
            </span>
            <span> | </span>
            <span className="text-dark fs-6 justify-content-end">
              Assigned By {actionInfo.createdBy}
            </span>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
}
