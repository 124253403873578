import { memo, Fragment, useEffect, useState } from "react";
// React-bootstrap
import { Row, Col, Form, Button, Container, Image } from "react-bootstrap";

//Components
import Autheffect from "../components/auth-effect";
import Card from "../../../../components/bootstrap/card";
import ciplaUnityLogo from "../../../../assets/images/client-logo/ciplaUnityLogo.png";

import postService from "../../../../api/services/post-service";
import {
  RESET_PASSWORD,
  RESET_PASSWORD_POST,
} from "../../../../api/endpoints/auth-endpoints";
import PaginationLoader from "../../../../components/loader/pagination-loader";
import { updateStorage } from "../../../../utilities/setting";
import { storages } from "../../../../common/utils/utils";
import useRedirect from "../../../../components/custom-hooks/use-redirect";
import toast from "react-hot-toast";
import { useFormik } from "formik";
import * as yup from "yup";
import app_config from "../../../../common/data/app_config";
import ReactPlayer from "react-player";
import { Link } from "react-router-dom";
const bgvideo =
  "https://client.clirnet.com/themes/client//videos/WhatsApp%20Video%202022-11-09%20at%207.21.28%20PM%20(1).mp4";

const Resetpassword = memo(() => {
  const [loader, showLoader] = useState(false);

  const { redirectTo } = useRedirect();

  const initialValues = {
    email: "",
  };
  const schema = yup.object().shape({
    // email: yup.string().email().required("Please enter your email")
    email: yup
      .string()
      .required("Please enter your email")
      .test("Validate Email", "Please enter valid email", (value) => {
        const re =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(value).toLowerCase());
      }),
  });
  const { values, errors, handleChange, handleSubmit } = useFormik({
    initialValues: initialValues,
    validationSchema: schema,
    onSubmit: (value, action) => {
      resetPassword(value, action);
    },
  });

  const resetPassword = async (value, action) => {
    showLoader(true);
    // console.log('my url==>', window.location);
    let base_url = window.location.href;
    let actualUrl = base_url.replace("reset-password", "change-password");
    // console.log('url==>', actualUrl);
    let data = {
      email: value.email,
      frontend_url: actualUrl,
    };

    let response = await postService(RESET_PASSWORD_POST, data);
    if (response.isError) {
      showLoader(false);
      toast.success(response?.error);
    } else {
      showLoader(false);
      // console.log('reset pass data', response.data);
      // redirectTo('dashboard')
      toast.success(
        "We have sent an email to your email address. Please check your email."
      );
      action.resetForm();
    }
  };

  return (
    <Fragment>
      <div className="iq-auth-page h-auto">
        <div className="landing">
          <div className="landing__banner_area w-100 d-flex position-relative overflow-hidden py-5">
            <ReactPlayer
              url={bgvideo}
              muted
              controls={false}
              playing={true}
              loop
              className="w-100 h-100 top-0 start-0 object_fit_cover position-absolute"
            />
            <div className="overlay"></div>
            <div className="landing__bannerContent px-md-0 px-5  w-100 position-relative">
              <div className="container h-100 position-relative">
                <Row className="d-flex align-items-center iq-auth-container justify-content-center">
                  <div className="col-md-6 col-12">
                    <Image
                      src="https://ik.imagekit.io/clirnetPlatform/client-admin/logo_Oxadv6aGVq.png?ik-sdk-version=javascript-1.4.3&updatedAt=1668528150127"
                      className="landing_logo d-inline mb-md-5 mb-4"
                      alt="logo"
                    />
                    <h1 className="fw-light fs-3 text-white bannerContentTtl text-uppercase">
                      Create on India's
                      <br />{" "}
                      <span className="fw-medium">
                        fastest growing community
                      </span>{" "}
                      of doctors today!
                    </h1>
                  </div>

                  <div className="col-md-6 d-flex col-12 align-items-center justify-content-md-end justify-content-center mt-md-0 mt-5">
                    <div className="position-relative loginArea">
                      <div className="loginAreaIn radius-10 bgColorWhite">
                        <h4 className="text-black fw-medium mb-3 fs-3">
                          Reset Password
                        </h4>
                        <p className="text-black fs-7">
                          Enter your email address and we’ll send you an email
                          with instructions to reset your password.
                        </p>

                        <Form onSubmit={handleSubmit}>
                          <Col lg="12" className="mb-3">
                            <Form.Group>
                              <Form.Control
                                type="email"
                                placeholder="Enter your email address"
                                name="email"
                                value={values.email}
                                onChange={handleChange}
                                isInvalid={!!errors.email}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.email}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                          <Col lg="12" className="mb-3">
                            <Button
                              className="btn btn-primary w-100"
                              onClick={handleSubmit}
                            >
                              {loader ? <PaginationLoader /> : "Reset"}
                            </Button>
                          </Col>
                          <Col
                            lg="12"
                            className="mb-3 d-flex justify-content-end align-items-center"
                          >
                            <Link to="/">Login</Link>
                          </Col>
                        </Form>
                      </div>
                    </div>
                  </div>
                </Row>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
});

Resetpassword.displayName = "Resetpassword";
export default Resetpassword;
