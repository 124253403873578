import React, { Fragment } from "react";
import { Badge, Card, Image } from "react-bootstrap";
import { findIcon } from "../../../../dashboard/icons";
import useRedirect from "../../../../../components/custom-hooks/use-redirect";
import { useParams } from "react-router-dom";
import ImageWithFallback from "../../../../../components/ImageWithFallback";
import Skeleton from "../../../../../components/skeleton";
import { checkProjectAccess } from "../utils/common";
import { useGetProjectDetails } from "../../../../../queries/query-hooks/workspace/workspace-hook";
function ContentListingCard({
  contentTags,
  contentDate,
  contentTitle,
  contentReads,
  contentViews,
  contentType,
  contentLogo,
  contentImage,
  fallbackContentImage,
  contentId,
}) {
  const { redirectTo } = useRedirect();
  const { project_id } = useParams();

  const handleOnClick = () => {
    redirectTo(`sponsored-content/${project_id}/content/details/${contentId}`);
  };
  const { data: projectDetails, isLoading: isProjectDetailsLoading } =
    useGetProjectDetails(project_id);

  const hasViewAccess = checkProjectAccess("show_view", projectDetails);
  const hasReadAccess = checkProjectAccess("show_read", projectDetails);

  return (
    <Fragment>
      <div
        className="bg-white d-flex rounded-3 pb-1 gap-3 mb-3 cursor-pointer"
        onClick={handleOnClick}
      >
        <div className="d-flex flex-column w-100">
          <div className="position-relative">
            <div className="w-100 bg-dark text-center rounded-3 d-flex justify-content-center flex-shrink-0 overflow-hidden">
              <ImageWithFallback
                alt={""}
                src={contentImage}
                fallbackSrc={fallbackContentImage}
                className="h-rem-10 w-auto mw-100 object-fit-cover"
              />
            </div>

            <div className="position-absolute end-0 bottom-0 bg-white d-flex px-2 rounded-pill mb-3 me-3">
              {contentReads == -1 ? (
                <Skeleton variant="text" width={20} />
              ) : contentReads != "N/A" && hasReadAccess ? (
                <div className="px-2 py-1">
                  <span className="transform-rotate-45 text-success">
                    {findIcon("Arrow Up", "outline", 22)}
                  </span>{" "}
                  <span className="text-success fw-bold">{contentReads}</span>{" "}
                  Reads
                </div>
              ) : (
                <></>
              )}
              {contentViews == -1 ? (
                <Skeleton variant="text" width={20} />
              ) : contentReads != "N/A" && hasViewAccess ? (
                <div className="px-2 py-1">
                  <span className="transform-rotate-45 text-primary">
                    {findIcon("Arrow Up", "outline", 22)}
                  </span>{" "}
                  <span className="text-primary fw-bold">{contentViews}</span>{" "}
                  Views
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>

          <div className="px-3 pt-3 pb-2">
            <div className="d-flex justify-content-between align-items-center mb-2">
              <div className="">
                <img src={contentLogo} alt="" className="icon-22" />
                {contentType}
              </div>
              <div className="cardLogo icon-30">
                <Image
                  className="w-100 h-100 object-fit-cover rounded-circle"
                  src={
                    "https://cdn1.vectorstock.com/i/1000x1000/11/10/international-yoga-day-concept-banner-21-june-vector-37751110.jpg"
                  }
                />
              </div>
            </div>

            <h6 className="two-line-clamp lh-base mb-2 fw-semibold">
              {contentTitle}
            </h6>
            <p className="fs-7 mb-2">{contentDate}</p>

            <hr className="hr-horizontal" />

            <div className="d-flex gap-1 flex-wrap mb-1 mt-2">
              {contentTags &&
                contentTags.map((item, index) => {
                  return (
                    <Badge
                      pill
                      bg="success light text-success"
                      className="me-2 fs-7 bg-opacity-10 px-3 lh-base"
                      key={index}
                    >
                      {item?.name}
                    </Badge>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default ContentListingCard;
