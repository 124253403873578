export const GET_CHANNEL_DETAILS_KEY = "GET_CHANNEL_DETAILS_KEY";
export const GET_CHANNEL_STATE_ANALYTICS = "GET_CHANNEL_STATE_ANALYTICS";
export const GET_CHANNEL_LIST = "GET_CHANNEL_LIST";
export const GET_PROJECT_LIST = "GET_PROJECT_LIST";
export const GET_CHANNEL_VIDEO_LIST = "GET_CHANNEL_VIDEO_LIST";
export const GET_CHANNEL_ALL_VIDEO_LIST = "GET_CHANNEL_ALL_VIDEO_LIST";
export const GET_SEARCH_LIST = "GET_SEARCH_LIST";
export const GET_VIDEO_ANALYTICS = "GET_VIDEO_ANALYTICS";
export const GET_CAMPAIGN_ANALYTICS = "GET_CAMPAIGN_ANALYTICS";
export const GET_CHANNEL_ANALYTICS = "GET_CHANNEL_ANALYTICS";
export const GET_ALL_COMMENT_LIST = "GET_ALL_COMMENT_LIST";
export const POST_PUBLISH_COMMENT = "POST_PUBLISH_COMMENT";
export const CAMPAIGN_LIST = "CAMPAIGN_LIST";
export const CAMPAIGN_LIST_BY_CHANNEL = "CAMPAIGN_LIST_BY_CHANNEL";
export const GET_CHANNEL_VIDEO_CREATE = "GET_CHANNEL_VIDEO_CREATE";
export const GET_CHANNEL_VIDEO_DETAILS = "GET_CHANNEL_VIDEO_DETAILS";
export const GET_CHANNEL_VIDEO_EDIT = "GET_CHANNEL_VIDEO_EDIT";
export const GET_CHANNEL_EDIT = "GET_CHANNEL_EDIT";
export const GET_CHANNEL_REACH = "GET_CHANNEL_REACH";
export const GET_TARGET_CATEGORIES = "GET_TARGET_CATEGORIES";
export const GET_TARGET_REGION = "GET_TARGET_REGION";
export const GET_CHECK_CHANNEL_NAME = "GET_CHECK_CHANNEL_NAME";

export const GET_PROJECT_DETAILS_KEY = "GET_PROJECT_DETAILS_KEY";
export const EDIT_PROJECT_KEY = "EDIT_PROJECT_KEY";
export const CREATE_PROJECT_KEY = "CREATE_PROJECT_KEY";
export const GET_BANNER_GROUP_KEY = "GET_BANNER_GROUP";

export const GET_CONTENT_CATEGORY_LIST_KEY = "GET_CONTENT_CATEGORY_LIST_KEY";

export const POST_STATE_LIST_KEY = "POST_STATE_LIST_KEY";
