import React, { useEffect, useRef, useState } from "react";
import Titlebar from "../components/Titlebar";
import { initialDate } from "../constant/date-range-constant";
import { Card, Col, Row } from "react-bootstrap";
import TableHeaderSwiper from "../components/TableHeaderSwiper";
import NavSwiperSkeleton from "../../../../../skeleton/workspace/clirnet/brand-ads/nav-swiper-skeleton";
import {
  downloadTablePNG,
  formatLocalizedDate,
  getWeeksBetween,
  transformGraphData,
  transformHeatMapData,
} from "../../../../../common/utils/utils";
import HeatMapsChart from "../../../../../components/chart/HeatMapsChart";
import TreeMapChart from "../../../../../components/chart/TreeMapChart";
import PolarChart from "../../../../../components/chart/PolarChart";
import DonutChart from "../../../../../components/chart/DonutChart";
import { GRAPH_FILL_COLOR } from "../constant/graph-color-scheme";
import PieChart from "../../../../../components/chart/PieChart";
import CommonTableLoader from "../../../../../skeleton/common-table-loader";
import PageNotFound from "../../../../PageNotFound";
import ReactTable from "../../../../../lib/table/react-table";
import { findIcon } from "../../../../dashboard/icons";
import { fallbackImages } from "../../../../../utilities/fallback-images";
import useCsvDownloader from "../../../../../components/custom-hooks/use-csv-downloader";
import SparlLineBar from "../../../../../components/chart/SparlLineBar";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";

import {
  useGetAudienceDaywisePerformance,
  useGetAudienceZonalDistribution,
  useGetAudienceViewsByScreensize,
  useGetAudienceDeviceDistribution,
  useGetAudienceSpecialityBreakup,
  useGetAudienceSpecialitiesReport,
  useGetSummaryList,
  useGetAudienceSpecialitiesReportCTA,
} from "../../../../../queries/query-hooks/workspace/sponsored-content-hook";
import SquareChartSkeleton from "../../../../../skeleton/workspace/clirnet/brand-ads/square-chart-skeleton";
import { useGetProjectDetails } from "../../../../../queries/query-hooks/workspace/workspace-hook";
import { checkProjectAccess } from "../utils/common";

function Audience() {
  const [tableColumnData, setTableColumnData] = useState([]);
  const { project_id } = useParams();
  const { downloadCSV } = useCsvDownloader();
  const [analysisType, setAnalysisType] = useState("week");

  const { data: projectDetails, isLoading: isProjectDetailsLoading } =
    useGetProjectDetails(project_id);

  const [dateRangePayload, setDateRangePayload] = useState({
    start_date:
      projectDetails?.[0]?.project_start_date || initialDate.start_date,
    end_date: projectDetails?.[0]?.project_end_date || initialDate.end_date,
  });

  const [activeCampaignGroup, setActiveCampaignGroup] = useState({});

  const { data: summaryList, isLoading: isSummaryListLoading } =
    useGetSummaryList(project_id, {
      start_date: dateRangePayload.start_date,
      end_date: dateRangePayload.end_date,
    });

  const { data: daywisePerformance, isLoading: isDaywisePerformanceLoading } =
    useGetAudienceDaywisePerformance(project_id, {
      start_date: dateRangePayload.start_date,
      end_date: dateRangePayload.end_date,
      content_id: activeCampaignGroup?.value,
      analysis_type: analysisType,
    });

  const { data: zoneWiseDistribution, isLoading: isZonalDistributionLoading } =
    useGetAudienceZonalDistribution(project_id, {
      start_date: dateRangePayload.start_date,
      end_date: dateRangePayload.end_date,
      content_id: activeCampaignGroup?.value,
    });

  const { data: screenSizeData, isLoading: isViewsByScreensizeLoading } =
    useGetAudienceViewsByScreensize(project_id, "views", {
      start_date: dateRangePayload.start_date,
      end_date: dateRangePayload.end_date,
      content_id: activeCampaignGroup?.value,
    });

  const { data: deviceChartData, isLoading: isDeviceDistributionLoading } =
    useGetAudienceDeviceDistribution(project_id, {
      start_date: dateRangePayload.start_date,
      end_date: dateRangePayload.end_date,
      content_id: activeCampaignGroup?.value,
    });

  const { data: specialityBreakup, isLoading: isSpecialityBreakupLoading } =
    useGetAudienceSpecialityBreakup(project_id, {
      start_date: dateRangePayload.start_date,
      end_date: dateRangePayload.end_date,
      content_id: activeCampaignGroup?.value,
    });

  const { data: specialitiesReport, isLoading: isSpecialitiesReportLoading } =
    useGetAudienceSpecialitiesReport(project_id, {
      start_date: dateRangePayload.start_date,
      end_date: dateRangePayload.end_date,
      content_id: activeCampaignGroup?.value,
    });
  const {
    data: specialitiesReportCTA,
    isLoading: isSpecialitiesReportCTALoading,
  } = useGetAudienceSpecialitiesReportCTA(project_id, {
    start_date: dateRangePayload.start_date,
    end_date: dateRangePayload.end_date,
    content_id: activeCampaignGroup?.value,
  });

  const hasViewAccess = checkProjectAccess("show_view", projectDetails);
  const hasReadAccess = checkProjectAccess("show_read", projectDetails);
  const hasCTAAccess = checkProjectAccess("show_CTA", projectDetails);

  function mergeContentData(data1, data2) {
    // Ensure data1 and data2 are arrays
    data1 = Array.isArray(data1) ? data1 : [];
    data2 = Array.isArray(data2) ? data2 : [];

    // Create a map from data2 for efficient lookup
    const data2Map = new Map(data2.map((item) => [item.speciality_name, item]));

    // Get all possible keys from both data1 and data2
    const allKeys = new Set([
      ...data1.flatMap(Object.keys),
      ...data2.flatMap(Object.keys),
    ]);

    // Merge data1 and data2
    const mergedData = data1.map((item) => {
      const matchingData = data2Map.get(item.name);
      const mergedItem = { ...item };

      allKeys.forEach((key) => {
        if (!(key in mergedItem)) {
          if (matchingData && key in matchingData) {
            mergedItem[key] = matchingData[key];
          } else {
            mergedItem[key] = "N/A";
          }
        }
      });

      return mergedItem;
    });

    // Add any items from data2 that weren't merged
    data2.forEach((item) => {
      if (
        !mergedData.some(
          (mergedItem) => mergedItem.name === item.speciality_name
        )
      ) {
        const newItem = {
          name: item.speciality_name,
          primary_specialty: "N/A",
          views: "N/A",
          reads: "N/A",
          date_Data: "N/A",
          ...item,
        };

        allKeys.forEach((key) => {
          if (!(key in newItem)) {
            newItem[key] = "N/A";
          }
        });

        mergedData.push(newItem);
      }
    });

    return mergedData;
  }

  const tableData = mergeContentData(
    specialitiesReport,
    specialitiesReportCTA
  )?.filter((item) => {
    if (
      (hasViewAccess &&
        hasReadAccess &&
        (item.views !== 0 || item.reads !== 0)) ||
      (!hasViewAccess && item.reads !== 0) ||
      (!hasReadAccess && item.views !== 0)
    ) {
      return item;
    }
    return null;
  });

  // json
  const sponsoredContentList =
    summaryList && Array.isArray(summaryList) && summaryList.length > 0
      ? summaryList?.map((item) => {
          return {
            id: item?.content_id,
            group_title: item?.fetch_with_image[0]?.content_title,
          };
        })
      : [];

  const formatDaywiseReportData = (data) => {
    const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

    const result = data.reduce((acc, { Day, label, Reach }) => {
      if (!acc[label]) {
        acc[label] = {
          name: analysisType === "week" ? `Week ${label}` : label,
          data: daysOfWeek.map((day) => ({ x: day, y: 0 })),
        };
      }
      const dayIndex = daysOfWeek.indexOf(Day);
      if (acc[label].data.length >= dayIndex && dayIndex >= 0) {
        acc[label].data[dayIndex].y = Reach;
      }
      return acc;
    }, {});

    return Object.values(result)
      .sort((a, b) =>
        a.name.toLocaleString().localeCompare(b.name, undefined, {
          numeric: true,
        })
      )
      .map((week) => ({
        name: week.name,
        data: week.data,
      }));
  };

  const performanceDistribution = {
    series:
      daywisePerformance && daywisePerformance?.length > 0
        ? formatDaywiseReportData(daywisePerformance)
        : [],
  };

  const zonalChartOptions =
    zoneWiseDistribution && zoneWiseDistribution?.length > 0
      ? transformHeatMapData(zoneWiseDistribution, "name", "total_count")
      : [];

  const screenSizeChartOptions = {
    label:
      screenSizeData && screenSizeData?.length > 0
        ? transformGraphData(
            screenSizeData,
            "impression_percentage",
            "screen_ratio"
          )?.label
        : [],
    series:
      screenSizeData && screenSizeData?.length > 0
        ? transformGraphData(
            screenSizeData,
            "impression_percentage",
            "screen_ratio"
          )?.series
        : [],
  };

  const deviceChartOptions = {
    series:
      deviceChartData?.length > 0
        ? transformGraphData(
            deviceChartData,
            "percentagebrowser_count",
            "Device"
          )?.series
        : [],
    label:
      deviceChartData?.length > 0
        ? transformGraphData(
            deviceChartData,
            "percentagebrowser_count",
            "Device"
          )?.label
        : [],
  };

  const userSpecialtyChartOptions = {
    series:
      specialityBreakup && specialityBreakup?.length > 0
        ? specialityBreakup?.map((item) => item?.user_speciality_breakup)
        : [],
    label:
      specialityBreakup && specialityBreakup?.length > 0
        ? specialityBreakup?.map((item) => item?.name)
        : [],
  };

  const tableCsvFormat = {
    header: ["Specialities", "Viewed", "Reads"],
    column: ["name", "views", "reads"],
    // chart: {
    //   header: "Trends",
    //   column: "date_Data",
    // },
    rows: specialitiesReport,
  };

  const tableRef = useRef(null);

  const handleTableCsvDownload = () => {
    downloadCSV(tableCsvFormat);
  };

  useEffect(() => {
    if (projectDetails && projectDetails?.length > 0) {
      const today = dayjs();
      const projectEndDate = dayjs(projectDetails[0]?.project_end_date);
      let result = projectDetails[0]?.project_end_date;

      if (projectEndDate && projectEndDate.isAfter(today)) {
        result = today.subtract(1, "day");
      } else {
        result = projectEndDate;
      }

      setDateRangePayload({
        start_date: formatLocalizedDate(
          new Date(projectDetails[0]?.project_start_date),
          "YYYY-MM-DD[T]00:00:00.000Z"
        ),
        end_date: formatLocalizedDate(
          new Date(result),
          "YYYY-MM-DD[T]23:59:59.SSSZ"
        ),
      });
    }
  }, [projectDetails]);

  useEffect(() => {
    const baseColumns = [
      {
        Header: "Specialities",
        columnId: 1,
        accessor: "name",
        disableFilters: true,
      },
    ];

    const viewColumn = {
      Header: "Viewed",
      columnId: 2,
      accessor: "views",
      disableFilters: true,
    };

    const readColumn = {
      Header: "Reads",
      columnId: 3,
      accessor: "reads",
      disableFilters: true,
    };

    const ctaColumn = {
      Header: "CTA",
      columnId: 4,
      accessor: "user_count",
      disableFilters: true,
    };

    const trendColumn = {
      Header: "Trends",
      columnId: 5,
      accessor: "date_Data",
      disableFilters: true,
      Cell: ({ cell: { value } }) => {
        if (value === null) value = "0,0,0,0,0,0,0";
        return (
          <SparlLineBar
            series={value && value?.length > 0 ? value.split(",") : []}
            labels={[]}
            height={50}
          />
        );
      },
    };

    let columns = [...baseColumns];

    if (hasViewAccess) {
      columns.push(viewColumn);
    }

    if (hasReadAccess) {
      columns.push(readColumn);
    }

    if (hasCTAAccess) {
      columns.push(ctaColumn);
    }

    columns.push(trendColumn);

    // Reassign columnId to ensure sequential numbering
    columns.forEach((column, index) => {
      column.columnId = index + 1;
    });

    setTableColumnData(columns);
  }, [hasViewAccess, hasReadAccess, hasCTAAccess]);

  useEffect(() => {
    if (sponsoredContentList && sponsoredContentList?.length > 0) {
      setActiveCampaignGroup({
        value: sponsoredContentList[0].id,
        label: sponsoredContentList[0].group_title,
      });
    }
  }, [summaryList]);

  return (
    <>
      <Row>
        <Col md={12}>
          {!isProjectDetailsLoading && (
            <Titlebar
              title={"Audience"}
              projectType={"Sponsored Content"}
              setDateRangePayload={(e) => {
                setDateRangePayload(e);
                if (getWeeksBetween(e.start_date, e.end_date) > 16) {
                  setAnalysisType("month");
                } else {
                  setAnalysisType("week");
                }
              }}
              projectStartDate={projectDetails[0]?.project_start_date || ""}
              projectEndDate={projectDetails[0]?.project_end_date || ""}
              dateLabel={dateRangePayload}
              tabs={summaryList}
              activeSummaryTab={activeCampaignGroup}
              setActiveSummaryTab={setActiveCampaignGroup}
              isSummaryListLoading={isSummaryListLoading}
            />
          )}
        </Col>

        {/* <Col md={12} className="mb-3">
          {!isProjectDetailsLoading &&
          !isSummaryListLoading &&
          sponsoredContentList.length > 0 ? (
            <TableHeaderSwiper
              title={sponsoredContentList}
              activeCampaignGroup={activeCampaignGroup}
              setActiveCampaignGroup={setActiveCampaignGroup}
            />
          ) : (
            <NavSwiperSkeleton />
          )}
        </Col> */}

        <Col md={hasViewAccess ? 6 : 12} className="mb-4">
          {!isDaywisePerformanceLoading && daywisePerformance?.length > 0 ? (
            <HeatMapsChart
              series={performanceDistribution.series}
              title={"Day wise performance distribution"}
            />
          ) : isDaywisePerformanceLoading ? (
            <SquareChartSkeleton />
          ) : (
            <PageNotFound
              title="No data found"
              // message="Upload one to start analyzing data!"
              backgroundImage={findIcon("Empty-folder", "dual-tone", 100)}
            />
          )}
        </Col>

        <Col md={hasViewAccess ? 6 : 8} className="mb-4">
          {!isZonalDistributionLoading && zoneWiseDistribution?.length > 0 ? (
            <TreeMapChart
              title={"Zonal Distribution"}
              series={zonalChartOptions}
            />
          ) : isZonalDistributionLoading ? (
            <SquareChartSkeleton />
          ) : (
            <PageNotFound
              title="No data found"
              // message="Upload one to start analyzing data!"
              backgroundImage={findIcon("Empty-folder", "dual-tone", 100)}
            />
          )}
        </Col>

        <Col md={4} sm={12} className={`mb-4 ${hasViewAccess ? "" : "d-none"}`}>
          {!isViewsByScreensizeLoading && screenSizeData?.length > 0 ? (
            <PolarChart
              labels={screenSizeChartOptions.label}
              series={screenSizeChartOptions.series}
              title={"View by Screen Size"}
              graphColor={GRAPH_FILL_COLOR}
              height={335}
            />
          ) : isViewsByScreensizeLoading ? (
            <SquareChartSkeleton />
          ) : (
            <PageNotFound
              title="No data found"
              // message="Upload one to start analyzing data!"
              backgroundImage={findIcon("Empty-folder", "dual-tone", 100)}
            />
          )}
        </Col>

        <Col md={4} sm={12} className={`mb-4 ${hasViewAccess ? "" : "d-none"}`}>
          {!isDeviceDistributionLoading && deviceChartData?.length > 0 ? (
            <DonutChart
              series={deviceChartOptions.series}
              labels={deviceChartOptions.label}
              title={"View by Device"}
              graphColor={GRAPH_FILL_COLOR}
              height={335}
            />
          ) : isDeviceDistributionLoading ? (
            <SquareChartSkeleton />
          ) : (
            <PageNotFound
              title="No data found"
              // message="Upload one to start analyzing data!"
              backgroundImage={findIcon("Empty-folder", "dual-tone", 100)}
            />
          )}
        </Col>

        <Col md={4} sm={12} className="mb-4">
          {!isSpecialityBreakupLoading && specialityBreakup?.length > 0 ? (
            <PieChart
              graphColor={GRAPH_FILL_COLOR}
              series={userSpecialtyChartOptions.series}
              labels={userSpecialtyChartOptions.label}
              title={"User Specialty Breakup"}
              height={400}
            />
          ) : isSpecialityBreakupLoading ? (
            <SquareChartSkeleton />
          ) : (
            <PageNotFound
              title="No data found"
              // message="Upload one to start analyzing data!"
              backgroundImage={findIcon("Empty-folder", "dual-tone", 100)}
            />
          )}
        </Col>

        <Col md={12} sm={12}>
          <div className="d-flex">
            <h5 className="m-0 text-primary border-bottom border-primary border-2 w-auto px-3 pb-2 ms-4">
              Specialities
            </h5>
          </div>
          <Card>
            <Card.Header className="d-flex flex-column justify-content-end flex-md-row">
              {!isProjectDetailsLoading &&
              !isSpecialitiesReportLoading &&
              Array.isArray(specialitiesReport) &&
              specialitiesReport?.length > 0 ? (
                <div className="d-flex flex-row gap-3 flex-shrink-0">
                  <div
                    className="text-success  cursor-pointer"
                    onClick={() => downloadTablePNG(tableRef)}
                  >
                    {findIcon("DownloadReport", "dual-tone", "18")}
                    <span className="text-success fs-7 px-2 d-none d-md-inline-block fw-semibold">
                      Download Report
                    </span>
                  </div>
                  <div
                    className="text-primary cursor-pointer"
                    onClick={handleTableCsvDownload}
                  >
                    {findIcon("ExportReport", "dual-tone", "20")}
                    <span className="text-primary fs-7 px-2 d-none d-md-inline-block fw-semibold">
                      Export
                    </span>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </Card.Header>
            <Card.Body>
              <div ref={tableRef}>
                {" "}
                {!isSpecialitiesReportLoading &&
                Array.isArray(specialitiesReport) &&
                specialitiesReport.length > 0 ? (
                  <ReactTable
                    data={tableData}
                    columns={tableColumnData}
                    isPaginationShow={false}
                    bordered
                    noDataText="no data"
                    noDataImage={fallbackImages.noActionData}
                  />
                ) : isSpecialitiesReportLoading ? (
                  <CommonTableLoader tableRowLength={5} />
                ) : (
                  <PageNotFound
                    title="No data found"
                    // message="Upload one to start analyzing data!"
                    backgroundImage={findIcon("Empty-folder", "dual-tone", 100)}
                  />
                )}
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default Audience;
