import React, { useEffect, useState } from "react";
import Titlebar from "../components/Titlebar";
import TwoSemiCircleGauge from "../../../../../components/chart/TwoSemiCircleGauge";
import ReactTable from "../../../../../lib/table/react-table";
import { Card, Row, Col, Button } from "react-bootstrap";
import CreativeDetailsSwiper from "../components/CreativeDetailsSwiper";
import { findIcon } from "../../../../dashboard/icons";
import SparlLineBar from "../../../../../components/chart/SparlLineBar";
import { initialDate } from "../constant/date-range-constant";
import useRedirect from "../../../../../components/custom-hooks/use-redirect";
import { Skeleton } from "@mui/material";
import CardSkeleton from "../../../../../skeleton/workspace/clirnet/brand-ads/card-skeleton";
import CommonTableLoader from "../../../../../skeleton/common-table-loader";
import { useParams } from "react-router-dom";
import {
  useGetCreativeDetails,
  useGetCreativePreview,
  useGetLandingPageReport,
} from "../../../../../queries/query-hooks/workspace/brand-ads-hook";
import PageNotFound from "../../../../PageNotFound";
import { fallbackImages } from "../../../../../utilities/fallback-images";
import { useGetProjectDetails } from "../../../../../queries/query-hooks/workspace/workspace-hook";
import useCsvDownloader from "../../../../../components/custom-hooks/use-csv-downloader";
import { formatLocalizedDate } from "../../../../../common/utils/utils";
const DAYS_OF_WEEK = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

function CreativeDetails() {
  const { creative_id, project_id, campaign_id } = useParams();
  const { redirectTo } = useRedirect();
  const { downloadCSV } = useCsvDownloader();

  const [dateRangePayload, setDateRangePayload] = useState({
    start_date: initialDate.start_date,
    end_date: initialDate.end_date,
  });

  const { data: creativePreview, isLoading: isCreativePreviewLoading } =
    useGetCreativePreview(project_id, {
      creative_id: creative_id,
      campaign_list: campaign_id,
    });

  const { data: creativeDetails, isLoading: isCreatriveDetailsLoading } =
    useGetCreativeDetails(project_id, creative_id, {
      start_date: dateRangePayload.start_date,
      end_date: dateRangePayload.end_date,
      campaign_list: campaign_id,
    });
  const { data: projectDetails, isLoading: isProjectDetailsLoading } =
    useGetProjectDetails(project_id);

  function getGroupIdsCommaSeparated(data) {
    return data.map((item) => item.group_id).join(",");
  }

  const {
    data: creativeLandingPageReport,
    isLoading: isCreativeLandingLoading,
  } = useGetLandingPageReport(project_id, creative_id, {
    start_date: dateRangePayload.start_date,
    end_date: dateRangePayload.end_date,
    campaign_id: creative_id,
    campaign_list: campaign_id,
  });

  useEffect(() => {
    if (projectDetails && projectDetails?.length > 0) {
      setDateRangePayload({
        start_date: formatLocalizedDate(
          new Date(projectDetails[0]?.project_start_date),
          "YYYY-MM-DD[T]00:00:00.000Z"
        ),
        end_date: initialDate.end_date,
      });
    }
  }, [projectDetails]);

  console.log("creativeDetails", creativeDetails?.creative_data);

  const crativeSizeChartData = {
    title: "Creative Size",
    subTitle: `${
      creativeDetails && creativeDetails?.length > 0
        ? `${creativeDetails[0]?.creative_size} KB`
        : "N/A"
    }`,
    labels: ["Mobile", "Web"],
    series: `${
      creativeDetails && creativeDetails?.length > 0
        ? creativeDetails[0]?.creative_size
        : []
    }`,
  };

  const avgThroughputChartData = {
    title: "Average Throughput",
    subTitle: `${
      creativeDetails && creativeDetails?.length > 0
        ? `${creativeDetails[0]?.avg_throughput} KB/s`
        : "N/A"
    }`,
    labels: ["Mobile", "Web"],
    series: `${
      creativeDetails && creativeDetails?.length > 0
        ? creativeDetails[0]?.avg_throughput
        : []
    }`,
  };

  const creativeLatencyChartData = {
    title: "Creative Latency",
    subTitle: `${
      creativeDetails && creativeDetails?.length > 0
        ? `${creativeDetails[0]?.creative_latency} ms`
        : "N/A"
    }`,
    labels: ["Mobile", "Web"],
    series: `${
      creativeDetails && creativeDetails?.length > 0
        ? creativeDetails[0]?.creative_latency
        : []
    }`,
  };

  console.log("avgThroughputChartData", avgThroughputChartData);

  const TableColumnData = [
    {
      Header: "Landing Page Type",
      columnId: 1,
      accessor: "Landing_page_type",
      disableFilters: true,
    },
    {
      Header: "Viewed",
      columnId: 3,
      accessor: "views",
      disableFilters: true,
    },
    // {
    //   Header: "Mobile impr",
    //   columnId: 4,
    //   accessor: "mobile_imp",
    //   disableFilters: true,
    // },
    // {
    //   Header: "Web impr (%)",
    //   columnId: 5,
    //   accessor: "web_imp",
    //   disableFilters: true,
    // },
    // {
    //   Header: "Total impr",
    //   columnId: 6,
    //   accessor: "total_impr",
    //   disableFilters: true,
    // },
    {
      Header: "Trends",
      columnId: 8,
      accessor: "date_Data",
      disableFilters: true,
      Cell: ({ value }) =>
        value && value?.length > 0 ? (
          <SparlLineBar series={value.split(",")} labels={DAYS_OF_WEEK} />
        ) : (
          "-"
        ),
    },
  ];

  const tableCsvFormat = {
    header: [
      "Landing Page Type",
      "Viewed",
      // "Mobile impr",
      // "Web impr",
      // "Total impr",
    ],
    column: [
      "Landing_page_type",
      "views",
      // "mobile_imp",
      // "web_imp",
      // "total_impr",
    ],
    // chart: {
    //   header: "Trends",
    //   column: "date_Data",
    // },

    rows: creativeLandingPageReport,
  };

  const handleTableCsvDownload = () => {
    downloadCSV(tableCsvFormat);
  };

  return (
    <>
      <Row>
        <Col md={12} sm={12}>
          <Titlebar
            title={"Creative Details"}
            projectType={"Brand Ads"}
            projectStartDate={
              projectDetails?.length > 0
                ? projectDetails[0]?.project_start_date
                : "   "
            }
            projectEndDate={
              projectDetails?.length > 0
                ? projectDetails[0]?.project_end_date
                : ""
            }
            setDateRangePayload={setDateRangePayload}
            dateLabel={dateRangePayload}
            isProjectDetailsLoading={isProjectDetailsLoading}
          />
        </Col>

        {/*  */}
        <Col md={12} className="mb-4">
          <Card className="h-100">
            <Card.Body>
              {!isCreatriveDetailsLoading && creativeDetails?.length > 0 ? (
                <CreativeDetailsSwiper
                  bannerAdImage={creativeDetails}
                  buttonText={"Creative Preview"}
                  handleOnclick={() =>
                    redirectTo(
                      `brand-ads/creative/preview/${project_id}/${creative_id}/${campaign_id}`
                    )
                  }
                  creativesList={creativeDetails}
                  showButton={
                    creativePreview?.length > 0 && !isCreativePreviewLoading
                  }
                />
              ) : isCreatriveDetailsLoading ? (
                <Skeleton
                  variant="square"
                  width={"100%"}
                  height={340}
                  className="rounded-3"
                />
              ) : (
                <PageNotFound
                  title="No data found"
                  // message="Upload one to start analyzing data!"
                  backgroundImage={findIcon("Empty-folder", "dual-tone", 100)}
                />
              )}
            </Card.Body>
          </Card>
        </Col>

        {/*  */}
        <Col md={4} className="mb-4">
          {!isCreatriveDetailsLoading &&
          creativeDetails?.length > 0 &&
          "creative_size" in creativeDetails[0] ? (
            <TwoSemiCircleGauge
              title={crativeSizeChartData.title}
              subTitle={crativeSizeChartData.subTitle}
              labels={crativeSizeChartData.labels}
              series={crativeSizeChartData.series}
            />
          ) : isCreatriveDetailsLoading ? (
            <CardSkeleton height={100} />
          ) : (
            <PageNotFound
              title="No data found"
              // message="Upload one to start analyzing data!"
              backgroundImage={findIcon("Empty-folder", "dual-tone", 100)}
            />
          )}
        </Col>
        <Col md={4} className="mb-4">
          {!isCreatriveDetailsLoading &&
          creativeDetails?.length > 0 &&
          "avg_throughput" in creativeDetails[0] ? (
            <TwoSemiCircleGauge
              title={avgThroughputChartData.title}
              subTitle={avgThroughputChartData.subTitle}
              labels={avgThroughputChartData.labels}
              series={avgThroughputChartData.series}
            />
          ) : isCreatriveDetailsLoading ? (
            <CardSkeleton height={100} />
          ) : (
            <PageNotFound
              title="No data found"
              // message="Upload one to start analyzing data!"
              backgroundImage={findIcon("Empty-folder", "dual-tone", 100)}
            />
          )}
        </Col>
        <Col md={4} className="mb-4">
          {!isCreatriveDetailsLoading &&
          creativeDetails?.length > 0 &&
          "creative_latency" in creativeDetails[0] ? (
            <TwoSemiCircleGauge
              title={creativeLatencyChartData.title}
              subTitle={creativeLatencyChartData.subTitle}
              labels={creativeLatencyChartData.labels}
              series={creativeLatencyChartData.series}
            />
          ) : isCreatriveDetailsLoading ? (
            <CardSkeleton height={100} />
          ) : (
            <PageNotFound
              title="No data found"
              // message="Upload one to start analyzing data!"
              backgroundImage={findIcon("Empty-folder", "dual-tone", 100)}
            />
          )}
        </Col>

        {/* table */}
        <Col md={12} sm={12}>
          <div className="d-flex">
            <h5 className="m-0 text-primary border-bottom border-primary border-2 w-auto px-3 pb-2 ms-4">
              Landing page
            </h5>
          </div>
          <Card>
            <Card.Header className="d-flex flex-column justify-content-end flex-md-row">
              {!isCreativeLandingLoading &&
              creativeLandingPageReport?.length > 0 ? (
                <div className="d-flex flex-row gap-3 flex-shrink-0">
                  {/* <div className="text-success">
                    {findIcon("DownloadReport", "dual-tone", "18")}
                    <span className="text-black fs-7 px-2 d-none d-md-inline-block">
                      Download Report
                    </span>
                  </div> */}
                  <div
                    className="text-success cursor-pointer"
                    onClick={handleTableCsvDownload}
                  >
                    {findIcon("ExportReport", "dual-tone", "20")}
                    <span className="text-black fs-7 px-2 d-none d-md-inline-block">
                      Export
                    </span>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </Card.Header>
            <Card.Body>
              {!isCreativeLandingLoading &&
              creativeLandingPageReport?.length > 0 ? (
                <ReactTable
                  data={creativeLandingPageReport}
                  columns={TableColumnData}
                  isPaginationShow={false}
                  bordered
                  noDataText="no data"
                  noDataImage={fallbackImages.noActionData}
                />
              ) : isCreativeLandingLoading ? (
                <CommonTableLoader tableRowLength={5} />
              ) : (
                <PageNotFound
                  title="No data found"
                  // message="Upload one to start analyzing data!"
                  backgroundImage={findIcon("Empty-folder", "dual-tone", 100)}
                />
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default CreativeDetails;
