import { memo, useEffect, useState } from "react";

//react-bootstrap
import {
  Button,
  Container,
  Dropdown,
  Form,
  Image,
  Nav,
  Navbar,
} from "react-bootstrap";

//router
import { Link, useLocation } from "react-router-dom";

//component
import CustomToggle from "../../../dropdowns";

//img

// logo

// Redux Selector / Action
import { useSelector } from "react-redux";
import jwt_decode from "jwt-decode";
// Import selectors & action from setting store
import * as SettingSelector from "../../../../store/setting/selectors";

import ciplaUnityLogo from "../../../../assets/images/client-logo/ciplaUnityLogo.png";

import { useDispatch } from "react-redux";
import toast from "react-hot-toast";
import { LOGOUT_POST } from "../../../../api/endpoints/auth-endpoints";
import postService from "../../../../api/services/post-service";
import { logoutSuccess } from "../../../../store/auth/actions";
import useRedirect from "../../../custom-hooks/use-redirect";
import * as dayjs from "dayjs";

const Headerpro = memo((props) => {
  const dispatch = useDispatch();
  const navbarHide = useSelector(SettingSelector.navbar_show); // array
  const themeFontSize = useSelector(SettingSelector.theme_font_size);
  const headerNavbar = useSelector(SettingSelector.header_navbar);
  useEffect(() => {
    if (headerNavbar === "navs-sticky" || headerNavbar === "nav-glass") {
      window.onscroll = () => {
        if (document.documentElement.scrollTop > 50) {
          document.getElementsByTagName("nav")[0].classList.add("menu-sticky");
        } else {
          document
            .getElementsByTagName("nav")[0]
            .classList.remove("menu-sticky");
        }
      };
    }

    document.getElementsByTagName("html")[0].classList.add(themeFontSize);

    //offcanvase code
    const result = window.matchMedia("(max-width: 1200px)");
    window.addEventListener("resize", () => {
      if (result.matches === true) {
        if (show1 === true) {
          document.documentElement.style.setProperty("overflow", "hidden");
        } else {
          document.documentElement.style.removeProperty("overflow");
        }
      } else {
        document.documentElement.style.removeProperty("overflow");
      }
    });
    if (window.innerWidth <= "1200") {
      if (show1 === true) {
        document.documentElement.style.setProperty("overflow", "hidden");
      } else {
        document.documentElement.style.removeProperty("overflow");
      }
    } else {
      document.documentElement.style.removeProperty("overflow");
    }
  });
  const token = useSelector((state) => state.auth.token);
  useEffect(() => {
    const decodedToken = token ? jwt_decode(token) : null;
    const isExpired = decodedToken
      ? dayjs.unix(decodedToken.exp).diff(dayjs()) < 1
      : true;
    if (isExpired) {
      localStorage.clear();
      sessionStorage.clear();
      redirectTo("");
    }
  }, [token]);

  const [show, setShow] = useState(true);

  const [show1, setShow1] = useState(false);

  //collapse
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);
  const [open5, setOpen5] = useState(false);
  const [open6, setOpen6] = useState(false);
  const [open7, setOpen7] = useState(false);
  const [open8, setOpen8] = useState(false);
  const [open9, setOpen9] = useState(false);
  const [open10, setOpen10] = useState(false);
  const [open11, setOpen11] = useState(false);
  const [open12, setOpen12] = useState(false);
  const [open13, setOpen13] = useState(false);
  const [open14, setOpen14] = useState(false);
  const [open15, setOpen15] = useState(false);
  const [open16, setOpen16] = useState(false);
  const [open17, setOpen17] = useState(false);

  //fullscreen
  const fullscreen = () => {
    if (
      !document.fullscreenElement &&
      !document.mozFullScreenElement &&
      !document.webkitFullscreenElement &&
      !document.msFullscreenElement
    ) {
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      } else if (document.documentElement.msRequestFullscreen) {
        document.documentElement.msRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    }
  };

  const minisidebar = () => {
    document.getElementsByTagName("ASIDE")[0].classList.toggle("sidebar-mini");
  };

  let location = useLocation();

  const [show3, setShow3] = useState(false);

  const handleClose = () => setShow3(false);
  const handleShow = () => setShow3(true);

  const [show4, setShow4] = useState(false);

  const handleClose1 = () => setShow4(false);
  const handleShow1 = () => setShow4(true);

  const { redirectTo } = useRedirect();

  const logoutHandler = async () => {
    let response = await postService(LOGOUT_POST, "");
    // console.log('logout data', response);
    if (response.isError) {
      toast.error(response?.error);
    } else {
      localStorage.clear();
      sessionStorage.clear();
      dispatch(logoutSuccess());
      redirectTo();
    }
  };

  return (
    <Navbar
      expand="xl"
      className={`nav iq-navbar header-hover-menu left-border ${headerNavbar} ${navbarHide.join(
        " "
      )}`}
    >
      <Container fluid className="navbar-inner">
        <Link to="/dashboard" className="navbar-brand">
          <Image
            src={ciplaUnityLogo}
            alt="Unity"
            className="ciplaLogo logo-title m-0 icon-24 w-auto"
          />
          {/* </div> */}
        </Link>
        <div
          className="sidebar-toggle"
          data-toggle="sidebar"
          data-active="true"
          onClick={minisidebar}
          title="Toggle Sidebar"
        >
          <i className="icon d-flex">
            <svg width="20px" viewBox="0 0 24 24" className="icon-20">
              <path
                fill="currentColor"
                d="M4,11V13H16L10.5,18.5L11.92,19.92L19.84,12L11.92,4.08L10.5,5.5L16,11H4Z"
              />
            </svg>
          </i>
        </div>
        <div className="d-flex align-items-center justify-content-between product-offcanvas">
          <div className="breadcrumb-title border-end me-3 pe-3 d-none d-xl-block">
            <small className="mb-0 text-capitalize">{`${
              location.pathname === "/dashboard"
                ? "home"
                : "" || location.pathname === "/dashboard/alternate-dashboard"
                ? "alternate dashboard"
                : "" ||
                  location.pathname === "/e-commerce/admin/admin-dashboard"
                ? "admin dashboard"
                : "" || location.pathname === "/e-commerce/user/vendor-dashbord"
                ? "vendor dashboard"
                : "" || location.pathname === "/e-commerce/shop-main"
                ? "shop main"
                : "" || location.pathname === "/e-commerce/shop-left-filter"
                ? "shop - left filter"
                : "" || location.pathname === "/e-commerce/shop-right-filter"
                ? "shop - right filter"
                : "" || location.pathname === "/e-commerce/product-full-grid"
                ? "product grid view"
                : "" || location.pathname === "/e-commerce/product-list"
                ? "product list view"
                : "" || location.pathname === "/e-commerce/categories-list"
                ? "categories list"
                : "" || location.pathname === "/e-commerce/product-details"
                ? "product detail"
                : "" || location.pathname === "/e-commerce/product-details-3d"
                ? "3d product detail"
                : "" || location.pathname === "/e-commerce/product-details-360"
                ? "360 product detail"
                : "" || location.pathname === "/e-commerce/order-process"
                ? "order process"
                : "" || location.pathname === "/e-commerce/invoice"
                ? "invoice"
                : "" || location.pathname === "/e-commerce/wishlist"
                ? "wishlist"
                : "" || location.pathname === "/e-commerce/app/user-profile"
                ? "user profile"
                : "" || location.pathname === "/e-commerce/app/user-list"
                ? "user list"
                : "" || location.pathname === "/social/dashboard"
                ? "dashboard"
                : "" || location.pathname === "/social/newsfeed"
                ? "newsfeed"
                : "" || location.pathname === "/social/friend-list"
                ? "friend list"
                : "" || location.pathname === "/social/friend-requset"
                ? "friend request"
                : "" || location.pathname === "/social/friend-profile"
                ? "friend profile"
                : "" || location.pathname === "/social/profile-badges"
                ? "profile badges"
                : "" || location.pathname === "/social/profile-images"
                ? "profile images"
                : "" || location.pathname === "/social/profile-video"
                ? "profile video"
                : "" || location.pathname === "/social/birthday"
                ? "birthday"
                : "" || location.pathname === "/social/notification"
                ? "notification"
                : "" || location.pathname === "/social/notification"
                ? "notification"
                : "" || location.pathname === "/social/notification"
                ? "notification"
                : "" || location.pathname === "/social/account-setting"
                ? "account setting"
                : "" || location.pathname === "/social/event-list"
                ? "event list"
                : "" || location.pathname === "/social/event-detail"
                ? "event detail"
                : "" || location.pathname === "/social/group"
                ? "group"
                : "" || location.pathname === "/social/group-detail"
                ? "group detail"
                : "" || location.pathname === "/social/social-profile"
                ? "social profile"
                : "" || location.pathname === "/blog"
                ? "dashboard"
                : "" || location.pathname === "/blog/blog-main"
                ? "blog main"
                : "" || location.pathname === "/blog/blog-details"
                ? "blog details"
                : "" || location.pathname === "/blog/blog-grid"
                ? "blog grid"
                : "" || location.pathname === "/blog/blog-list"
                ? "blog list"
                : "" || location.pathname === "/blog/blog-trending"
                ? "blog treding"
                : "" || location.pathname === "/blog/blog-comments"
                ? "comment list"
                : "" || location.pathname === "/blog/blog-category"
                ? "blog category"
                : "" || location.pathname === "/appointment/dashboard"
                ? "appointment"
                : "" || location.pathname === "/appointment/book-appointment"
                ? "book appointment"
                : "" || location.pathname === "/appointment/doctor-visit"
                ? "doctor visit"
                : "" || location.pathname === "/file-manager/dashboard"
                ? "appointment"
                : "" || location.pathname === "/file-manager/image-folder"
                ? "image"
                : "" || location.pathname === "/file-manager/video-folder"
                ? "video"
                : "" || location.pathname === "/file-manager/document-folder"
                ? "document"
                : "" || location.pathname === "/file-manager/all-file"
                ? "all file"
                : "" || location.pathname === "/file-manager/trash"
                ? "trash"
                : "" || location.pathname === "/mail/email"
                ? "email"
                : "" || location.pathname === "/mail/email-compose"
                ? "email compose"
                : "" || location.pathname === "/dashboard/table/bootstrap-table"
                ? "Bootstrap Table"
                : "" || location.pathname === "/dashboard/table/table-data"
                ? "Data Table"
                : "" || location.pathname === "/dashboard/table/border-table"
                ? "Border Table"
                : "" || location.pathname === "/dashboard/table/fancy-table"
                ? "Fancy Table"
                : "" || location.pathname === "/dashboard/table/fixed-table"
                ? "Fixed Table"
                : ""
            }`}</small>
          </div>

          <div
            className={`offcanvas-backdrop fade  ${
              show1 === true ? "show d-block" : "d-none"
            }`}
            onClick={() => setShow1(false)}
          ></div>
        </div>
        <div className="d-flex align-items-center">
          <Button
            id="navbar-toggle"
            bsPrefix="navbar-toggler"
            type="button"
            aria-expanded={show1}
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            onClick={() => setShow1(!show1)}
          >
            <span className="navbar-toggler-icon">
              <span className="mt-1 navbar-toggler-bar bar1"></span>
              <span className="navbar-toggler-bar bar2"></span>
              <span className="navbar-toggler-bar bar3"></span>
            </span>
          </Button>
        </div>
        <div
          className={` navbar-collapse ${
            show1 === true ? "collapse show" : "collapse"
          }`}
          id="navbarSupportedContent"
        >
          <ul className="mb-2 navbar-nav ms-auto align-items-center navbar-list mb-lg-0 gap-2">
            <Dropdown as="li" className="nav-item iq-responsive-menu  d-block">
              <Dropdown.Toggle as={CustomToggle} className="btn btn-sm bg-body">
                <span className="d-flex align-items-center justify-content-center">
                  {/* <b className="pe-2 pt-1">BY</b> */}
                  <svg
                    className="powered-by-logo"
                    version="1.1"
                    id="Layer_1"
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    viewBox="0 0 76.9 16.2"
                  >
                    <path
                      class="st0"
                      fill="#5CC2A8"
                      d="M1.3,9.2c0-0.1,0-0.3,0-0.4C1.5,7.3,2.1,6,3.2,4.9C4,4.2,4.9,3.8,6,3.6c1.4-0.2,2.7,0,4,0.6 c0.5,0.2,1,0.6,1.4,1c0.1,0,0.1,0.1,0,0.1c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.1,0.1-0.2,0c-0.5-0.4-1.1-0.8-1.8-1 C8.6,4.4,8,4.2,7.4,4.2C5.9,4.2,4.8,4.9,4,6.1C3.6,6.8,3.4,7.5,3.2,8.3c-0.1,0.5-0.1,1-0.1,1.5c0.1,1.2,0.4,2.4,1.2,3.4 c0.6,0.7,1.3,1.2,2.3,1.4c0.3,0.1,0.7,0.1,1,0c0.5,0,1-0.1,1.4-0.2c0.7-0.2,1.4-0.5,2-1c0.1-0.1,0.1-0.1,0.2,0 c0.1,0.1,0.2,0.2,0.2,0.3c0,0,0,0.1,0,0.1c-0.9,0.7-1.9,1.2-3.1,1.4c-0.6,0.1-1.2,0.1-1.8,0.1c-1.2-0.1-2.3-0.4-3.2-1.2 c-0.9-0.7-1.4-1.6-1.7-2.6C1.4,11,1.3,10.5,1.3,9.9c0,0,0,0,0-0.1C1.3,9.7,1.3,9.4,1.3,9.2z"
                    />
                    <path
                      class="st0"
                      fill="#5CC2A8"
                      d="M34.5,9.6c1.2,1.9,2.3,3.8,3.5,5.7c0,0-0.1,0-0.1,0c-0.6,0-1.1,0-1.7,0c-0.1,0-0.1,0-0.2-0.1 c-1-1.8-2-3.5-3.1-5.3c-0.1-0.2-0.2-0.4-0.4-0.7c0-0.1,0-0.1,0.1-0.1c0.6,0,1.2,0,1.8,0c0.6,0,1.1-0.2,1.5-0.6 c0.3-0.3,0.5-0.8,0.6-1.2c0.1-0.5,0.1-1-0.1-1.5c-0.3-0.8-0.9-1.2-1.7-1.4c-0.3-0.1-0.5-0.1-0.8-0.1c-0.5,0-1,0-1.6,0 c-0.1,0-0.1,0-0.1,0.1c0,3.6,0,7.2,0,10.8c0,0.2,0,0.1-0.1,0.1c-0.5,0-0.9,0-1.4,0c-0.1,0-0.1,0-0.1-0.1c0-3.8,0-7.6,0-11.3 c0-0.1,0-0.1,0.1-0.1c1.4,0,2.8,0,4.2,0c0.9,0,1.7,0.3,2.4,1c0.5,0.5,0.8,1.1,0.9,1.8c0,0.7-0.1,1.3-0.5,1.9 c-0.4,0.5-0.8,0.8-1.4,0.9c-0.4,0.1-0.8,0.2-1.2,0.2C34.8,9.6,34.7,9.6,34.5,9.6z"
                    />
                    <path
                      class="st1"
                      fill="#2F5186"
                      d="M50.9,12.2c0-0.1,0-0.1,0-0.1c0-2.7,0-5.4,0-8.2c0-0.1,0-0.1,0.1-0.1c0.1,0,0.2,0,0.3,0c0,0,0.1,0,0.1,0.1 c0,0,0,0,0,0.1c0,3.8,0,7.7,0,11.5c0,0,0,0.1,0,0.1c-2.6-3.3-5.1-6.6-7.7-9.9c0,0.1,0,0.1,0,0.1c0,3.1,0,6.2,0,9.4 c0,0.2,0,0.1-0.1,0.1c-0.1,0-0.2,0-0.3,0c-0.1,0-0.1,0-0.1-0.1c0,0,0,0,0-0.1c0-3.8,0-7.5,0-11.3c0-0.1,0-0.1,0.1-0.1 c0.3,0,0.6,0,0.9,0c0.1,0,0.1,0,0.2,0.1c2.2,2.7,4.3,5.5,6.5,8.2C50.8,12.2,50.8,12.2,50.9,12.2z"
                    />
                    <path
                      class="st1"
                      fill="#2F5186"
                      d="M56.9,9.6c0-1.9,0-3.8,0-5.7c0-0.1,0-0.1,0.1-0.1c1.8,0,3.5,0,5.3,0c0.1,0,0.1,0,0.1,0.1c0,0.1,0,0.2,0,0.3 c0,0.1,0,0.1-0.1,0.1c-0.7,0-1.4,0-2.1,0c-0.5,0-1,0-1.6,0c-0.1,0-0.1,0-0.1,0.1c0,1.5,0,3.1,0,4.6c0,0.1,0,0.1,0.1,0.1 c1.2,0,2.5,0,3.7,0c0.1,0,0.1,0,0.1,0.1c0,0.1,0,0.2,0,0.3c0,0.1,0,0.1-0.1,0.1c-0.3,0-0.7,0-1,0c-0.9,0-1.8,0-2.7,0 c-0.1,0-0.1,0-0.1,0.1c0,1.7,0,3.3,0,5c0,0.1,0,0.1,0.1,0.1c1.2,0,2.5,0,3.7,0c0.1,0,0.1,0,0.1,0.1c0,0.1,0,0.2,0,0.3 c0,0.1,0,0.1-0.1,0.1c0,0,0,0-0.1,0c-1.8,0-3.5,0-5.3,0c-0.2,0-0.1,0-0.1-0.1C56.9,13.4,56.9,11.5,56.9,9.6z"
                    />
                    <path
                      class="st0"
                      fill="#5CC2A8"
                      d="M14.6,9.5c0-1.9,0-3.8,0-5.7c0-0.1,0-0.1,0.1-0.1c0.5,0,0.9,0,1.4,0c0.2,0,0.1,0,0.1,0.1c0,3.6,0,7.2,0,10.8 c0,0,0,0.1,0,0.1c0,0,0,0.1,0.1,0.1c0,0,0.1,0,0.1,0c1.3,0,2.6,0,3.9,0c0.1,0,0.1,0,0.1,0.1c0,0.1,0,0.2,0,0.3c0,0.1,0,0.1-0.1,0.1 c0,0,0,0,0,0c-1.9,0-3.7,0-5.6,0c-0.1,0-0.1,0-0.1-0.1C14.6,13.3,14.6,11.4,14.6,9.5z"
                    />
                    <path
                      class="st1"
                      fill="#2F5186"
                      d="M69.2,9.8c0-1.8,0-3.6,0-5.4c0-0.1,0-0.1-0.1-0.1c-0.6,0-1.2,0-1.8,0c-0.1,0-0.1,0-0.1-0.1c0-0.1,0-0.2,0-0.3 c0-0.1,0-0.1,0.1-0.1c0,0,0,0,0,0c1.8,0,3.6,0,5.4,0c0.1,0,0.1,0,0.1,0.1c0,0.1,0,0.2,0,0.3c0,0.1,0,0.1-0.1,0.1c-0.6,0-1.2,0-1.8,0 c-0.1,0-0.1,0-0.1,0.1c0,3.6,0,7.2,0,10.8c0,0.1,0,0.1-0.1,0.1c-0.5,0-0.9,0-1.4,0c-0.2,0-0.1,0-0.1-0.1 C69.2,13.4,69.2,11.6,69.2,9.8z"
                    />
                    <path
                      class="st2"
                      fill="#424143"
                      d="M73.6,4c0-0.6,0.5-1.2,1.1-1.2c0.6,0,1.2,0.5,1.2,1.1c0,0.6-0.5,1.2-1.1,1.2C74.2,5.2,73.6,4.7,73.6,4z M73.8,4 c0,0.6,0.5,1,1,1.1c0.6,0,1.1-0.5,1.1-1c0-0.6-0.5-1-1-1C74.2,3,73.8,3.5,73.8,4z"
                    />
                    <path
                      class="st2"
                      fill="#424143"
                      d="M75,4.1c0.2,0.1,0.3,0.3,0.4,0.5c-0.2,0-0.2,0-0.3-0.1c-0.1-0.1-0.1-0.2-0.2-0.3c-0.1-0.1-0.3-0.1-0.4-0.1 c0,0,0,0,0,0.1c0,0.1,0,0.3,0,0.4c0,0.1,0,0.1-0.1,0.1c-0.1,0-0.1,0-0.1-0.1c0-0.4,0-0.7,0-1.1c0,0,0-0.1,0-0.1c0.2,0,0.4,0,0.6,0 c0.2,0,0.3,0.1,0.3,0.3C75.3,3.9,75.2,4,75,4.1C75,4.1,75,4.1,75,4.1z M74.8,3.6C74.8,3.6,74.8,3.6,74.8,3.6c-0.1,0-0.1,0-0.2,0 c0,0-0.1,0-0.1,0.1c0,0.1,0,0.2,0,0.3c0,0,0,0.1,0,0.1c0.1,0,0.3,0,0.4,0c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2 C74.9,3.6,74.8,3.6,74.8,3.6z"
                    />
                    <g>
                      <path
                        class="st3"
                        fill="#CCE6D3"
                        d="M24.8,6c0.3,0,0.6-0.1,0.8-0.4c0.1-0.1,0.1-0.2,0.1-0.3c0-0.1,0-0.1-0.1-0.1c-0.2,0.1-0.4,0-0.5-0.1 C25,5,25,5,25,4.9c0-0.1,0.1-0.1,0.2-0.1c0.4,0,0.7,0.2,0.8,0.7c0,0.3-0.1,0.4-0.3,0.6c-0.2,0.1-0.4,0.2-0.6,0.3c0,0-0.1,0-0.1,0 c0.1,0.1,0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.3,0.2,0.5c0,0.4,0,0.8-0.3,1.2c-0.1,0.1-0.1,0.1-0.2,0.2c0-0.3,0-0.6,0-0.9 c0-0.4-0.1-0.6-0.5-0.8c-0.3-0.2-0.5-0.2-0.8-0.3c-0.1,0-0.1,0-0.1,0.1c0,0.2,0,0.3,0,0.5c0,0.3,0.1,0.6,0.4,0.8 c0.2,0.2,0.5,0.4,0.7,0.6c0.1,0,0.1,0.1,0.2,0.1c0,0.1,0.2,0.2,0.2,0.3c0.3,0.4,0.3,0.8,0,1.3c-0.1,0.1-0.1,0.2-0.3,0.3 c0,0,0-0.1,0-0.1c0-0.2,0-0.5,0-0.7c0-0.1,0-0.2-0.1-0.3c-0.1-0.2-0.3-0.3-0.5-0.4c-0.2-0.2-0.5-0.3-0.7-0.5 c-0.1-0.2-0.3-0.3-0.4-0.5c-0.2-0.4-0.2-0.8,0.1-1.1c0.1-0.1,0.2-0.2,0.3-0.2c-0.2-0.1-0.3-0.1-0.5-0.2c-0.2-0.1-0.4-0.2-0.5-0.4 c-0.2-0.4,0.1-0.9,0.5-0.9c0.1,0,0.2,0,0.3,0c0.1,0,0.1,0,0.1,0.1c-0.1,0.2-0.3,0.3-0.6,0.2c0,0-0.1-0.1-0.1,0c0,0,0,0.1,0,0.1 c0.1,0.4,0.4,0.6,0.8,0.7c0.1,0,0.1,0,0.2,0c0.3,0,0.6,0.1,0.8,0.2c0.1,0,0.2,0.1,0.4,0.2C24.8,6.2,24.8,6.1,24.8,6z M25.4,5 C25.4,5,25.4,5,25.4,5c0.1-0.1,0-0.1,0-0.1C25.3,4.9,25.3,4.9,25.4,5C25.3,5,25.4,5,25.4,5z M23,4.9C23,4.9,22.9,4.9,23,4.9 C22.9,5,23,5,23,5C23,5,23.1,5,23,4.9C23.1,4.9,23,4.9,23,4.9z"
                      />
                      <path
                        class="st4"
                        fill="#CDE6D4"
                        d="M22.4,3.2l0,0.6c0,0.2-0.1,0.4-0.1,0.6c0,0,0,0.1-0.1,0.1c-0.1,0-0.2,0.1-0.3,0c-0.2-0.1-0.3-0.3-0.2-0.4 c0.2-0.3,0.3-0.6,0.4-0.9c0,0,0,0,0-0.1C22,3.4,21.9,3.7,21.7,4c-0.1,0.3-0.4,0.4-0.6,0.4c-0.2,0-0.4-0.1-0.5-0.3 c0-0.1,0-0.1,0.1-0.1c0.2-0.1,0.4-0.2,0.6-0.4c0.1-0.1,0.2-0.1,0.2-0.2c0.1,0,0.1-0.1,0.1-0.2c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0 c0,0,0,0,0,0c0,0,0,0,0,0c-0.1,0-0.1,0.1-0.2,0.1c-0.3,0.2-0.6,0.4-0.9,0.6C20.4,4,20.1,4,20,3.8c0,0,0-0.1-0.1-0.1 c-0.1-0.1-0.1-0.2,0.1-0.2c0.4,0,0.7-0.1,1.1-0.3c0,0,0.1,0,0.1,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c-0.2,0-0.3,0.1-0.5,0.1 c-0.4,0.1-0.8,0.2-1.2,0.2c-0.3,0-0.6-0.1-0.8-0.3c-0.1-0.1-0.1-0.1-0.2-0.2c0,0,0,0,0-0.1c0,0,0,0,0,0c0.3,0,0.7,0,1.1,0 c0.4,0,0.7,0,1.1,0c0.4,0,0.8-0.1,1.2-0.2c0.2-0.1,0.3-0.1,0.5-0.1c0.1,0,0.2,0.1,0.3,0.2c0,0,0.1,0.1,0.1,0.1 C22.9,3,23,3.1,23.1,3.1c0.1,0,0.1,0,0.1,0.1c0,0.4,0,0.8,0,1.2c0,0.1,0,0.1-0.1,0.1c-0.1,0-0.2,0.1-0.4,0.1 c-0.1,0.1-0.3,0.1-0.4,0c0,0-0.1-0.1,0-0.1c0.1-0.3,0.1-0.6,0.1-0.9L22.4,3.2z"
                      />
                      <path
                        class="st4"
                        fill="#CDE6D4"
                        d="M26.7,3.1c0,0.1,0.1,0.1,0.1,0.2c0.2,0.3,0.5,0.4,0.8,0.6c0.1,0.1,0.1,0.1,0,0.1c-0.2,0.3-0.5,0.3-0.8,0.2 c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1-0.2-0.2-0.4-0.3-0.7c0,0,0,0,0-0.1c0,0,0,0.1,0,0.1c0.1,0.3,0.2,0.6,0.4,0.8c0.1,0.2,0,0.4-0.2,0.4 c-0.1,0-0.2,0-0.3,0c0,0-0.1-0.1-0.1-0.1c-0.1-0.3-0.1-0.6-0.1-1c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0.3,0,0.7,0.1,1 c0,0.1,0.1,0.1,0,0.2c-0.1,0.1-0.2,0.1-0.3,0.1c-0.1,0-0.1,0-0.2-0.1c-0.1,0-0.2-0.1-0.3-0.1c-0.1,0-0.1-0.1-0.1-0.1 c0-0.3,0-0.6,0-0.9c0,0,0-0.1,0-0.1c0-0.2,0-0.3,0.2-0.3c0.1,0,0.2-0.1,0.2-0.2c0.2-0.3,0.3-0.3,0.6-0.2c0.3,0.1,0.6,0.2,0.9,0.3 c0.2,0,0.5,0.1,0.7,0.1c0.5,0,1,0,1.5,0c0.2,0,0.3,0,0.5,0c0,0,0,0,0.1,0c0,0,0,0,0,0.1c-0.3,0.3-0.6,0.5-1,0.5 c-0.4,0-0.9-0.1-1.3-0.2c-0.1,0-0.2-0.1-0.3-0.1c0.1,0.1,0.1,0.1,0.2,0.1c0.3,0.1,0.7,0.2,1,0.2c0.1,0,0.1,0,0.1,0.1 c-0.1,0.2-0.2,0.3-0.3,0.3c-0.2,0-0.4,0-0.6-0.1c-0.3-0.1-0.5-0.3-0.7-0.5C26.8,3.2,26.8,3.1,26.7,3.1L26.7,3.1z"
                      />
                      <path
                        class="st5"
                        fill="#5DC2A9"
                        d="M24.8,11.7c0,0.2,0,0.5,0,0.7c0,0.5-0.1,1.1-0.2,1.6c0,0.2-0.1,0.5-0.2,0.7c0,0.1-0.1,0.2-0.1,0.3 c-0.1,0.1-0.2,0.1-0.3,0c-0.1-0.1-0.1-0.2-0.2-0.4c-0.2-0.5-0.2-0.9-0.3-1.4c0-0.1-0.1-0.3,0-0.4c0.1,0.1,0.2,0.2,0.2,0.3 c0-0.1,0-0.2,0-0.3c0-0.2-0.1-0.4-0.2-0.5c-0.1-0.1-0.1-0.2-0.1-0.3c0.2,0.1,0.3,0.2,0.5,0.2c0.3,0.1,0.5,0.3,0.6,0.7 c0,0.1,0.1,0.1,0.1,0.2c0,0,0,0,0,0c0-0.1,0-0.2,0-0.4c0-0.2-0.1-0.4-0.3-0.6c-0.2-0.2-0.5-0.3-0.7-0.5c-0.2-0.2-0.3-0.3-0.3-0.6 c0-0.2,0-0.4,0-0.7c0-0.1,0-0.2,0-0.2c0.4,0.2,0.8,0.5,1.1,0.8c0.1,0.1,0.2,0.2,0.2,0.4C24.8,11.5,24.8,11.6,24.8,11.7z"
                      />
                      <path
                        class="st0"
                        fill="#5CC2A8"
                        d="M24.2,3.1c-0.6,0-1-0.5-1-1c0-0.6,0.5-1,1-1c0.6,0,1,0.5,1,1C25.2,2.7,24.8,3.1,24.2,3.1z"
                      />
                      <path
                        class="st5"
                        fill="#5DC2A9"
                        d="M24.8,6c0,0.1,0,0.3,0,0.4c-0.1-0.1-0.2-0.1-0.4-0.2C24.2,6.1,23.9,6,23.6,6c-0.1,0-0.1,0-0.2,0 c0-0.7,0-1.4,0-2.2c0-0.1,0-0.1,0.1-0.1c0.4,0,0.7,0,1.1,0c0.1,0,0.1,0,0.1,0.1c0,0.7,0,1.4,0,2.1C24.8,5.9,24.8,5.9,24.8,6z"
                      />
                      <path
                        class="st3"
                        fill="#CCE6D3"
                        d="M24.8,11.7c0-0.1,0-0.2,0-0.3c0-0.2-0.1-0.3-0.2-0.4c-0.3-0.3-0.7-0.5-1.1-0.8C23.3,10,23.1,9.8,23,9.6 c-0.1-0.4,0.1-0.8,0.4-1c0,0,0,0,0,0c0,0.2,0,0.4,0,0.6c0,0.2,0.1,0.4,0.2,0.5c0.2,0.2,0.3,0.3,0.5,0.4c0.2,0.1,0.4,0.2,0.6,0.3 c0.2,0.2,0.4,0.4,0.3,0.7C25.1,11.3,25,11.5,24.8,11.7C24.8,11.7,24.8,11.7,24.8,11.7z"
                      />
                      <path
                        class="st0"
                        fill="#5CC2A8"
                        d="M24.8,10.4c-0.2-0.1-0.4-0.2-0.6-0.3c-0.2-0.1-0.4-0.3-0.5-0.4c-0.1-0.2-0.2-0.3-0.2-0.5c0-0.2,0-0.4,0-0.6 c0-0.1,0-0.2,0-0.3c0.3,0.2,0.5,0.3,0.7,0.5c0.2,0.1,0.3,0.3,0.5,0.4c0.1,0.1,0.1,0.2,0.1,0.3c0,0.2,0,0.5,0,0.7 C24.8,10.3,24.8,10.3,24.8,10.4C24.8,10.4,24.8,10.4,24.8,10.4z"
                      />
                      <path
                        class="st6"
                        fill="#CBE7D4"
                        d="M23.5,10.4c0,0.2,0,0.4,0,0.7c0,0.3,0.1,0.4,0.3,0.6c0.2,0.2,0.5,0.3,0.7,0.5c0.2,0.1,0.3,0.3,0.3,0.6 c0,0.1,0,0.2,0,0.4c0,0,0,0,0,0c0-0.1-0.1-0.1-0.1-0.2c-0.1-0.3-0.3-0.5-0.6-0.7c-0.2-0.1-0.3-0.1-0.5-0.2c0,0.1,0,0.2,0.1,0.3 c0.1,0.2,0.2,0.3,0.2,0.5c0,0.1,0,0.2,0,0.3c-0.1-0.1-0.1-0.3-0.2-0.3c0-0.1-0.1-0.1-0.1-0.2c-0.1-0.2-0.1-0.4,0-0.6 c0.1-0.1,0-0.1,0-0.2c-0.4-0.3-0.5-1,0-1.4C23.4,10.5,23.4,10.4,23.5,10.4z"
                      />
                      <path
                        class="st5"
                        fill="#5DC2A9"
                        d="M24.8,8.5c-0.1,0-0.1-0.1-0.2-0.1c-0.2-0.2-0.5-0.4-0.7-0.6c-0.3-0.2-0.4-0.5-0.4-0.8c0-0.2,0-0.3,0-0.5 c0,0,0-0.1,0.1-0.1c0.3,0.1,0.6,0.2,0.8,0.3c0.3,0.2,0.5,0.5,0.5,0.8C24.8,7.9,24.8,8.2,24.8,8.5C24.8,8.5,24.8,8.5,24.8,8.5z"
                      />
                      <path
                        class="st7"
                        fill="#FFFFFF"
                        d="M21.1,3.2C21.2,3.2,21.2,3.2,21.1,3.2C21.2,3.2,21.2,3.2,21.1,3.2C21.2,3.2,21.2,3.2,21.1,3.2 C21.1,3.2,21.1,3.2,21.1,3.2z"
                      />
                      <path
                        class="st8"
                        fill="#EFF7F1"
                        d="M21.7,3.2C21.7,3.2,21.7,3.2,21.7,3.2C21.7,3.2,21.7,3.2,21.7,3.2C21.7,3.2,21.7,3.2,21.7,3.2 C21.7,3.2,21.7,3.2,21.7,3.2z"
                      />
                    </g>
                  </svg>
                </span>
              </Dropdown.Toggle>
              {/* <Dropdown.Menu
                variant="end"
                aria-labelledby="navbarDropdown-search-11"
                style={{ width: "25rem" }}
              >
                <div className="px-3 py-0">
                  <div className="form-group input-group mb-0">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search..."
                    />
                    <span className="input-group-text">
                      <svg
                        className="icon-20"
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          cx="11.7669"
                          cy="11.7666"
                          r="8.98856"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></circle>
                        <path
                          d="M18.0186 18.4851L21.5426 22"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></path>
                      </svg>
                    </span>
                  </div>
                </div>
              </Dropdown.Menu> */}
            </Dropdown>
            <Nav.Item
              className=" iq-full-screen d-none d-xl-block"
              onClick={() => setShow(!show)}
            >
              <Nav.Link
                id="btnFullscreen"
                onClick={fullscreen}
                title="Fullscreen"
              >
                <div className="btn btn-icon btn-md rounded-pill btn-soft-primary">
                  <span className="btn-inner">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="icon-40 fill-primary"
                      id="Bold"
                      viewBox="0 0 24 24"
                    >
                      <path d="M22.5,15.5A1.5,1.5,0,0,0,21,17v1.5A2.5,2.5,0,0,1,18.5,21H17a1.5,1.5,0,0,0,0,3h1.5A5.506,5.506,0,0,0,24,18.5V17A1.5,1.5,0,0,0,22.5,15.5Z" />
                      <path d="M7,0H5.5A5.506,5.506,0,0,0,0,5.5V7A1.5,1.5,0,0,0,3,7V5.5A2.5,2.5,0,0,1,5.5,3H7A1.5,1.5,0,0,0,7,0Z" />
                      <path d="M7,21H5.5A2.5,2.5,0,0,1,3,18.5V17a1.5,1.5,0,0,0-3,0v1.5A5.506,5.506,0,0,0,5.5,24H7a1.5,1.5,0,0,0,0-3Z" />
                      <path d="M18.5,0H17a1.5,1.5,0,0,0,0,3h1.5A2.5,2.5,0,0,1,21,5.5V7a1.5,1.5,0,0,0,3,0V5.5A5.506,5.506,0,0,0,18.5,0Z" />
                    </svg>
                    {/* <svg
                      className={`normal-screen ${
                        show === true ? "" : "d-none"
                      } icon-24`}
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M18.5528 5.99656L13.8595 10.8961"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeLinejoin="round"
                      ></path>
                      <path
                        d="M14.8016 5.97618L18.5524 5.99629L18.5176 9.96906"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeLinejoin="round"
                      ></path>
                      <path
                        d="M5.8574 18.896L10.5507 13.9964"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeLinejoin="round"
                      ></path>
                      <path
                        d="M9.60852 18.9164L5.85775 18.8963L5.89258 14.9235"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeLinejoin="round"
                      ></path>
                    </svg> */}
                    {/* <svg
                      className={`full-normal-screen ${
                        show === false ? "" : "d-none"
                      } icon-24`}
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13.7542 10.1932L18.1867 5.79319"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeLinejoin="round"
                      ></path>
                      <path
                        d="M17.2976 10.212L13.7547 10.1934L13.7871 6.62518"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinejoin="round"
                      ></path>
                      <path
                        d="M10.4224 13.5726L5.82149 18.1398"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeLinejoin="round"
                      ></path>
                      <path
                        d="M6.74391 13.5535L10.4209 13.5723L10.3867 17.2755"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinejoin="round"
                      ></path>
                    </svg> */}
                  </span>
                </div>
              </Nav.Link>
            </Nav.Item>
            <Dropdown
              as="li"
              className="nav-item iq-tour ps-3 ps-lg-0"
              title={"Profile"}
            >
              <Dropdown.Toggle
                as={CustomToggle}
                variant="py-0  d-flex align-items-center nav-link"
              >
                <div className="btn btn-icon btn-md rounded-pill btn-soft-primary">
                  <span className="btn-inner">
                    <svg
                      className="icon-40 fill-primary"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.997 15.1746C7.684 15.1746 4 15.8546 4 18.5746C4 21.2956 7.661 21.9996 11.997 21.9996C16.31 21.9996 19.994 21.3206 19.994 18.5996C19.994 15.8786 16.334 15.1746 11.997 15.1746Z"
                        fill="currentColor"
                      ></path>
                      <path
                        opacity="0.4"
                        d="M11.9971 12.5838C14.9351 12.5838 17.2891 10.2288 17.2891 7.29176C17.2891 4.35476 14.9351 1.99976 11.9971 1.99976C9.06008 1.99976 6.70508 4.35476 6.70508 7.29176C6.70508 10.2288 9.06008 12.5838 11.9971 12.5838Z"
                        fill="currentColor"
                      ></path>
                    </svg>
                  </span>
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu variant="end">
                <Dropdown.Item href="/profile">Profile</Dropdown.Item>
                {/* <Dropdown.Item href="/dashboard/app/user-privacy-setting">
                  Privacy Setting
                </Dropdown.Item> */}
                <hr className="dropdown-divider" />
                <Dropdown.Item
                  onClick={logoutHandler}
                  // href="/auth/sign-in"
                >
                  Logout
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </ul>
        </div>
      </Container>
    </Navbar>
  );
});

Headerpro.displayName = "Headerpro";
export default Headerpro;
