import * as Yup from "yup";
export const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const urlRegex = /^(https?:\/\/)?([a-zA-Z0-9.-]+)(\.[a-zA-Z]{2,})(\/[^\s]*)?$/;
const FILE_SIZE = 10 * 1024 * 1024;
const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];

export const personalInfoInitialValues = {
  firstName: "",
  middleName: "",
  lastName: "",
  dob: "",
  age: "",
  gender: "",
  email: "",
  mobileNo: "",
  association: "",
  degree: [],
  typeOfPractice: "",
  council: "",
  registrationNo: "",
  registrationState: "",
  classificationCoreSL: "",
  primarySpeciality: "",
  interestSpeciality: [],
  pincode: "",
  city: "",
  state: "",
  zone: "",
  country: "",
};
const personalSchema = {
  firstName: Yup.string().required("Required"),
  lastName: Yup.string().required("Required"),
  //one of dob or age is required
  dob: Yup.date(),
  age: Yup.number().required("Required"),
  gender: Yup.string()
    .required("Required")
    .notOneOf(["-1"], "Invalid gender selected"),
  email: Yup.string().email("Invalid email address").required("Required"),
  mobileNo: Yup.string()
    .required("Required")
    .matches(phoneRegExp, "Phone number is not valid")
    .min(10, "Phone number cannot be less than 10 digits")
    .max(10, "Phone number cannot be more than 10 digits"),
  association: Yup.string(), //.required("Required"),
  degree: Yup.array().required("Required"),
  typeOfPractice: Yup.string().required("Required"),
  council: Yup.string().required("Required"),
  registrationNo: Yup.string().required("Required"),
  registrationState: Yup.string(),
  classificationCoreSL: Yup.string(),
  primarySpeciality: Yup.string().required("Required"),
  interestSpeciality: Yup.array().min(1, "Required").of(Yup.number()),
  pincode: Yup.string(),
  city: Yup.string().required("Required"),
  state: Yup.string().required("Required"),
  zone: Yup.string().required("Required"),
  country: Yup.string().required("Required"),
};
export const personalInfoValidationSchema = Yup.object(personalSchema);
export const onboardingInfoInitialValues = {
  socialLinks: [{ type: "", link: "" }],
  documentSpecialities: [],
  documentRegistrations: [],
  documentOthers: [],
  callRecording: [],
};
const onboardingSchema = {
  socialLinks: Yup.array()
    .nullable()
    .of(
      Yup.object().shape({
        type: Yup.string().nullable(),
        link: Yup.string().nullable().matches(urlRegex, "Enter correct url!"),
      })
    ),
  //documentOthers will be array of files
  documentSpecialities: Yup.array("Please select file")
    .nullable()
    .of(Yup.string("Unsupported file type")),
  documentRegistrations: Yup.array("Please select file")
    .nullable()
    .of(Yup.string("Unsupported file type")),
  documentOthers: Yup.array("Please select file")
    .nullable()
    .of(Yup.string("Unsupported file type")),
  medium: Yup.string("Select medium").required("Required"),
  user_type: Yup.string("Select User Type").required("Required"),
  callRecording: Yup.array("Please upload call recording").when(
    "medium",
    (medium, schema) => {
      if (medium && medium?.length > 0 && medium[0] == 2) {
        return schema.of(
          Yup.string("Please upload call recording").required("Required")
        );
      } else {
        return schema.of(Yup.string().notRequired());
      }
    }
  ),
  // callRecording: Yup.array().when("medium", {
  //   is: (medium) => medium && medium.length > 0 && medium.includes("2"),
  //   then: Yup.array()
  //     .of(Yup.string().required("Please upload call recording"))
  //     .required("Please upload call recording"),
  //   otherwise: Yup.array().notRequired(),
  // }),
};
export const onboardingInfoValidationSchema = Yup.object(onboardingSchema);
export const campaignInfoInitialValues = {
  utmSource: "",
};
const campaignSchema = {
  utmSource: Yup.string(),
};
export const campaignInfoValidationSchema = Yup.object(campaignSchema);
export const fullInitialValues = {
  ...personalInfoInitialValues,
  ...onboardingInfoInitialValues,
  ...campaignInfoInitialValues,
};
export const fullSchema = Yup.object({
  ...personalSchema,
  ...onboardingSchema,
  ...campaignSchema,
});
