import React, { useEffect, useRef, useState } from "react";
import Titlebar from "../components/Titlebar";
import { initialDate } from "../constant/date-range-constant";
import NavSwiperSkeleton from "../../../../../skeleton/workspace/clirnet/brand-ads/nav-swiper-skeleton";
import SparlLineBar from "../../../../../components/chart/SparlLineBar";
import { Card, Col, Row } from "react-bootstrap";
import TableHeaderSwiper from "../components/TableHeaderSwiper";
import IndiaMap from "../../../../../components/chart/IndiaMap";
import TreeMapChart from "../../../../../components/chart/TreeMapChart";
import { findIcon } from "../../../../dashboard/icons";
import ReactTable from "../../../../../lib/table/react-table";
import { fallbackImages } from "../../../../../utilities/fallback-images";
import TextPillCard from "../components/TextPillCard";
import useRedirect from "../../../../../components/custom-hooks/use-redirect";

import {
  useGetLocationMapDistribution,
  useGetLocationMajorCitiesReport,
  useGetLocationZonalDistribution,
  useGetLocationReport,
  useGetSummaryList,
  useGetLocationReportCTA,
  useGetLocationStateMap,
} from "../../../../../queries/query-hooks/workspace/sponsored-content-hook";
import { useParams } from "react-router-dom";
import useCsvDownloader from "../../../../../components/custom-hooks/use-csv-downloader";
import {
  downloadTablePNG,
  formatLocalizedDate,
  transformHeatMapData,
} from "../../../../../common/utils/utils";
import CommonTableLoader from "../../../../../skeleton/common-table-loader";
import MapAnalyticsSkeleton from "../../../../../skeleton/workspace/clirnet/brand-ads/map-analytics-skeleton";
import SquareChartSkeleton from "../../../../../skeleton/workspace/clirnet/brand-ads/square-chart-skeleton";
import { useGetProjectDetails } from "../../../../../queries/query-hooks/workspace/workspace-hook";
import DashboardDropdown from "../../../../../components/DashboardDropdown";
import { filterOptions } from "../constant/filter-options";
import FilterBy from "../components/FilterBy";
import { checkProjectAccess } from "../utils/common";
import PageNotFound from "../../../../PageNotFound";
import dayjs from "dayjs";

function Location() {
  const { project_id } = useParams();
  const { redirectTo } = useRedirect();
  const { downloadCSV } = useCsvDownloader();
  const tableRef = useRef();

  const [type, setType] = useState(filterOptions?.initial);
  const [tableColumnData, setTableColumnData] = useState([]);

  const { data: projectDetails, isLoading: isProjectDetailsLoading } =
    useGetProjectDetails(project_id);

  const [dateRangePayload, setDateRangePayload] = useState({
    start_date:
      projectDetails?.[0]?.project_start_date || initialDate.start_date,
    end_date: projectDetails?.[0]?.project_end_date || initialDate.end_date,
  });
  const [activeCampaignGroup, setActiveCampaignGroup] = useState({});

  const { data: stateMapData, isLoading: isStateMapLoading } =
    useGetLocationStateMap(project_id, type.toLowerCase(), {
      start_date: dateRangePayload.start_date,
      end_date: dateRangePayload.end_date,
      content_id: activeCampaignGroup?.value,
      type: "percentage",
    });

  const { data: majorCities, isLoading: isMajorCitiesLoading } =
    useGetLocationMajorCitiesReport(project_id, type.toLowerCase(), {
      start_date: dateRangePayload.start_date,
      end_date: dateRangePayload.end_date,
      content_id: activeCampaignGroup?.value,
      limit: 10,
    });

  const { data: zonalDistributionData, isLoading: isZonalDistributionLoading } =
    useGetLocationZonalDistribution(project_id, type.toLowerCase(), {
      start_date: dateRangePayload.start_date,
      end_date: dateRangePayload.end_date,
      content_id: activeCampaignGroup?.value,
    });

  const { data: locationReportsCTA, isLoading: isLocationReportCTALoading } =
    useGetLocationReportCTA(project_id, {
      start_date: dateRangePayload.start_date,
      end_date: dateRangePayload.end_date,
      content_id: activeCampaignGroup?.value,
    });

  const { data: summaryList, isLoading: isSummaryListLoading } =
    useGetSummaryList(project_id, {
      start_date: dateRangePayload.start_date,
      end_date: dateRangePayload.end_date,
    });

  // json
  const sponsoredContentList =
    summaryList && Array.isArray(summaryList) && summaryList.length > 0
      ? summaryList?.map((item) => {
          return {
            id: item?.content_id,
            group_title: item?.fetch_with_image[0]?.content_title,
          };
        })
      : [];

  function mergeContentData(data1, data2) {
    // Ensure data1 and data2 are arrays
    data1 = Array.isArray(data1) ? data1 : [];
    data2 = Array.isArray(data2) ? data2 : [];

    // Create a map from data2 for efficient lookup
    const data2Map = new Map(data2.map((item) => [item.state_name, item]));

    // Get all possible keys from both data1 and data2
    const allKeys = new Set([
      ...data1.flatMap(Object.keys),
      ...data2.flatMap(Object.keys),
    ]);

    // Merge data1 and data2
    const mergedData = data1.map((item) => {
      const matchingData = data2Map.get(item.name);
      const mergedItem = { ...item };

      allKeys.forEach((key) => {
        if (!(key in mergedItem)) {
          if (matchingData && key in matchingData) {
            mergedItem[key] = matchingData[key];
          } else {
            mergedItem[key] = "N/A";
          }
        }
      });

      return mergedItem;
    });

    // Add any items from data2 that weren't merged
    data2.forEach((item) => {
      if (
        !mergedData.some((mergedItem) => mergedItem.name === item.state_name)
      ) {
        const newItem = {
          name: item.state_name,
          state: "N/A",
          views: "N/A",
          reads: "N/A",
          date_Data: "N/A",
          ...item,
        };

        allKeys.forEach((key) => {
          if (!(key in newItem)) {
            newItem[key] = "N/A";
          }
        });

        mergedData.push(newItem);
      }
    });

    return mergedData;
  }

  const tableData = mergeContentData(stateMapData, locationReportsCTA);

  const transformStateMapData = (data = [], key) => {
    return [
      data?.reduce((acc, item) => {
        if (item.name && item[key] !== null) {
          acc[item.name] = item[key];
        }
        return acc;
      }, {}),
    ];
  };

  const stateMapReadsData = transformStateMapData(
    stateMapData,
    "reads_percent"
  );
  const stateMapViewsData = transformStateMapData(
    stateMapData,
    "views_percent"
  );

  const zonalChartOptions =
    zonalDistributionData && zonalDistributionData?.length > 0
      ? transformHeatMapData(
          zonalDistributionData,
          "name",
          type === "Reads" ? "read_count" : "view_count"
        )
      : [];

  const tableCsvFormat = {
    header: ["State", "Viewed", "Reads", "Trends"],
    column: ["name", "views", "reads", "date_Data"],
    rows: stateMapData,
  };

  const handleTableCsvDownload = () => {
    var tableCsvFormat = {};

    if (hasViewAccess && !hasReadAccess) {
      tableCsvFormat = {
        header: ["State", "Viewed", "Trends"],
        column: ["name", "views", "date_Data"],
        rows: stateMapData,
      };
    } else if (!hasViewAccess && hasReadAccess) {
      tableCsvFormat = {
        header: ["State", "Reads", "Trends"],
        column: ["name", "reads", "date_Data"],
        rows: stateMapData,
      };
    } else if (hasViewAccess && hasReadAccess) {
      tableCsvFormat = {
        header: ["State", "Viewed", "Reads", "Trends"],
        column: ["name", "views", "reads", "date_Data"],
        rows: stateMapData,
      };
    }
    if (!isProjectDetailsLoading) {
      downloadCSV(tableCsvFormat);
    }
  };

  const handleDropdownChange = (type = "Views") => {
    setType(type);
  };

  useEffect(() => {
    if (projectDetails && projectDetails?.length > 0) {
      const today = dayjs();
      const projectEndDate = dayjs(projectDetails[0]?.project_end_date);
      let result = projectDetails[0]?.project_end_date;

      if (projectEndDate && projectEndDate.isAfter(today)) {
        result = today.subtract(1, "day");
      } else {
        result = projectEndDate;
      }

      setDateRangePayload({
        start_date: formatLocalizedDate(
          new Date(projectDetails[0]?.project_start_date),
          "YYYY-MM-DD[T]00:00:00.000Z"
        ),
        end_date: formatLocalizedDate(
          new Date(result),
          "YYYY-MM-DD[T]23:59:59.SSSZ"
        ),
      });
    }
  }, [projectDetails]);

  const hasViewAccess = checkProjectAccess("show_view", projectDetails);
  const hasReadAccess = checkProjectAccess("show_read", projectDetails);
  const hasCTAAccess = checkProjectAccess("show_CTA", projectDetails);
  useEffect(() => {
    const baseColumns = [
      {
        Header: "State",
        columnId: 1,
        accessor: "name",
        disableFilters: true,
      },
    ];

    const viewColumn = {
      Header: "Viewed",
      columnId: 2,
      accessor: "views",
      disableFilters: true,
    };

    const readColumn = {
      Header: "Reads",
      columnId: 3,
      accessor: "reads",
      disableFilters: true,
    };

    const ctaColumn = {
      Header: "CTA",
      columnId: 4,
      accessor: "user_count",
      disableFilters: true,
    };

    const trendColumn = {
      Header: "Trends",
      columnId: 5,
      accessor: "date_Data",
      disableFilters: true,
      Cell: ({ cell: { value } }) => {
        if (value === null) value = "0,0,0,0,0,0,0";
        return (
          <SparlLineBar
            series={value && value?.length > 0 ? value.split(",") : []}
            labels={[]}
            height={50}
          />
        );
      },
    };

    let columns = [...baseColumns];

    if (hasViewAccess) {
      columns.push(viewColumn);
    }

    if (hasReadAccess) {
      columns.push(readColumn);
    }

    if (hasCTAAccess) {
      columns.push(ctaColumn);
    }

    columns.push(trendColumn);

    // Reassign columnId to ensure sequential numbering
    columns.forEach((column, index) => {
      column.columnId = index + 1;
    });

    setTableColumnData(columns);
  }, [hasViewAccess, hasReadAccess, hasCTAAccess]);

  useEffect(() => {
    if (sponsoredContentList && sponsoredContentList?.length > 0) {
      setActiveCampaignGroup({
        value: sponsoredContentList[0].id,
        label: sponsoredContentList[0].group_title,
      });
    }
  }, [summaryList]);

  return (
    <>
      <Row>
        <Col md={12}>
          {!isProjectDetailsLoading && (
            <Titlebar
              title={"Location"}
              projectType={"Sponsored Content"}
              setDateRangePayload={setDateRangePayload}
              projectStartDate={projectDetails[0]?.project_start_date || ""}
              projectEndDate={projectDetails[0]?.project_end_date || ""}
              dateLabel={dateRangePayload}
              tabs={summaryList}
              activeSummaryTab={activeCampaignGroup}
              setActiveSummaryTab={setActiveCampaignGroup}
              isSummaryListLoading={isSummaryListLoading}
            />
          )}
        </Col>

        {/* <Col md={12} className="mb-3">
          {!isProjectDetailsLoading &&
          !isSummaryListLoading &&
          sponsoredContentList.length > 0 ? (
            <TableHeaderSwiper
              title={sponsoredContentList}
              activeCampaignGroup={activeCampaignGroup}
              setActiveCampaignGroup={setActiveCampaignGroup}
            />
          ) : (
            <NavSwiperSkeleton />
          )}
        </Col> */}

        <FilterBy handleDropdownChange={handleDropdownChange} type={type} />

        <Col md={12} lg={6} sm={12} className="d-flex flex-column pe-2 mb-4">
          {!isStateMapLoading && stateMapData?.length > 0 ? (
            <IndiaMap
              stateData={
                type === "Reads" ? stateMapReadsData : stateMapViewsData
              }
              title={"Location"}
              valueSuffix="%"
            />
          ) : isStateMapLoading ? (
            <MapAnalyticsSkeleton />
          ) : (
            <PageNotFound
              title="No data found"
              // message="Upload one to start analyzing data!"
              backgroundImage={findIcon("Empty-folder", "dual-tone", 100)}
            />
          )}
        </Col>

        <Col lg={6} md={12}>
          <Row>
            <Col md={12} sm={12} className="mb-2">
              {
                !isMajorCitiesLoading && majorCities?.length > 0 ? (
                  <TextPillCard data={majorCities} title={"Major Cities"} />
                ) : isMajorCitiesLoading ? (
                  <SquareChartSkeleton />
                ) : null
                // <PageNotFound
                //   title="No data found"
                //   // message="Upload one to start analyzing data!"
                //   backgroundImage={findIcon("Empty-folder", "dual-tone", 100)}
                // />
              }
            </Col>

            <Col md={12} sm={12} className="mb-4">
              {!isZonalDistributionLoading &&
              zonalDistributionData?.length > 0 ? (
                <TreeMapChart
                  title={"Zonal Distribution"}
                  series={zonalChartOptions}
                />
              ) : isZonalDistributionLoading ? (
                <SquareChartSkeleton />
              ) : (
                <PageNotFound
                  title="No data found"
                  // message="Upload one to start analyzing data!"
                  backgroundImage={findIcon("Empty-folder", "dual-tone", 100)}
                />
              )}
            </Col>
          </Row>
        </Col>

        <Col md={12} sm={12}>
          <div className="d-flex">
            <h5 className="m-0 text-primary border-bottom border-primary border-2 w-auto px-3 pb-2 ms-4">
              Location
            </h5>
          </div>
          <Card>
            <Card.Header className="d-flex flex-column justify-content-end flex-md-row">
              {!isProjectDetailsLoading &&
              !isStateMapLoading &&
              Array.isArray(stateMapData) &&
              stateMapData?.length > 0 ? (
                <div className="d-flex flex-row gap-3 flex-shrink-0">
                  <div
                    className="text-success cursor-pointer"
                    onClick={() => downloadTablePNG(tableRef)}
                  >
                    {findIcon("DownloadReport", "dual-tone", "18")}
                    <span className="text-succes fs-7 px-2 d-none d-md-inline-block fw-semibold">
                      Download Report
                    </span>
                  </div>
                  <div
                    className="text-primary cursor-pointer"
                    onClick={handleTableCsvDownload}
                  >
                    {findIcon("ExportReport", "dual-tone", "20")}
                    <span className="text-primary fs-7 px-2 d-none d-md-inline-block fw-semibold">
                      Export
                    </span>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </Card.Header>
            <Card.Body>
              {!isStateMapLoading &&
              Array.isArray(stateMapData) &&
              stateMapData.length > 0 ? (
                <div ref={tableRef}>
                  <ReactTable
                    data={tableData}
                    columns={tableColumnData}
                    isPaginationShow={false}
                    bordered
                    noDataText="no data"
                    noDataImage={fallbackImages.noActionData}
                  />
                </div>
              ) : isStateMapLoading ? (
                <CommonTableLoader tableRowLength={5} />
              ) : (
                <PageNotFound
                  title="No data found"
                  // message="Upload one to start analyzing data!"
                  backgroundImage={findIcon("Empty-folder", "dual-tone", 100)}
                />
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default Location;
