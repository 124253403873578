import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import DonutChart from "../../../../../components/chart/DonutChart";
import { GRAPH_FILL_COLOR } from "../constant/graph-color-scheme";
import {
  EVENTS_PILL_FILTERS,
  FULL_STACKED_BAR_DATA,
  INDIA_MAP_DATA,
  REGISTRATION_BY_DEVICE,
  REGISTRATION_BY_ZONE,
  VIEWERSHIP_WISE_ATTENDEE_SEGMENTATION,
} from "../data/audience-insights-data";
import LineChart from "../../../../../components/chart/LineChart";
import HeaderPillFilters from "./HeaderPillFilters";
import BasicColumnChart from "../../../../../components/chart/BasicColumnChart";
import IndiaMap from "../../../../../components/chart/IndiaMap";
import TreeMapChart from "../../../../../components/chart/TreeMapChart";
import FullStackedBar from "../../../../../components/chart/FullStackedBar";
import CardNotFound from "./CardNotFound";
import BarChartSkeleton from "../../../../../skeleton/workspace/clirnet/brand-ads/bar-chart-skeleton";
import {
  useGetAllProjectEventsList,
  useGetContentQuestion,
  useGetDeviceDistribution,
  useGetLocationDistribution,
  useGetViewBySegment,
  useGetZonalDistribution,
} from "../../../../../queries/query-hooks/workspace/events-hook";
import { useParams } from "react-router-dom";
import { transformGraphData } from "../../../../../common/utils/utils";
import Skeleton from "../../../../../components/skeleton";
import MapAnalyticsSkeleton from "../../../../../skeleton/workspace/clirnet/brand-ads/map-analytics-skeleton";
import {
  formatLivePollDataForMasterCluster,
  formatTimeForEvents,
  transformIndiaMapData,
} from "../utils/utils";
import SquareChartSkeleton from "../../../../../skeleton/workspace/clirnet/brand-ads/square-chart-skeleton";
import QuestionAnalyticsSkeleton from "../../../../../skeleton/workspace/clirnet/events/QuestionAnalyticsSkeleton";

const EventCompleted = ({ session_id, eventType }) => {
  const { project_id } = useParams();
  const [filterBy, setFilterBy] = useState("Registrations"); // Maybe required, for future use
  const [updatedAllProjectList, setUpdatedAllProjectList] = useState([]);
  const [activeFilter, setActiveFilter] = useState({
    id: 0,
    index: 0,
  });
  const [eventFilterPayload, setEventFilterPayload] = useState({
    type: "all",
  });

  const { data: allProjectList, isLoading: isAllProjectListLoading } =
    useGetAllProjectEventsList(project_id);

  const { data: viewBySegment, isLoading: isViewBySegmentLoading } =
    useGetViewBySegment(project_id, {
      ...eventFilterPayload,
      analysis_type: filterBy?.slice(0, -1)?.toLowerCase() || "registration", // participation || registration
    });

  const {
    data: locationDistribution,
    isLoading: isLocationDistributionLoading,
  } = useGetLocationDistribution(project_id, {
    ...eventFilterPayload,
    analysis_type: filterBy?.slice(0, -1)?.toLowerCase() || "registration", // participation || registration
  });

  const { data: deviceDistribution, isLoading: isDeviceDistributionLoading } =
    useGetDeviceDistribution(project_id, {
      ...eventFilterPayload,
      analysis_type: filterBy?.slice(0, -1)?.toLowerCase() || "registration", // participation || registration
    });

  const { data: zonalDistribution, isLoading: isZonalDistributionLoading } =
    useGetZonalDistribution(project_id, {
      ...eventFilterPayload,
      analysis_type: filterBy?.slice(0, -1)?.toLowerCase() || "registration", // participation || registration
    });

  const { data: contentQuestions, isLoading: isContentQuestionsLoading } =
    useGetContentQuestion(project_id);

  useEffect(() => {
    if (allProjectList?.event_list?.length > 0) {
      const updatedAllProjectListValue = [
        { event_name: "All", event_id: 0 },
        ...allProjectList?.event_list,
      ];

      setUpdatedAllProjectList(updatedAllProjectListValue);
    }
  }, [allProjectList]);

  useEffect(() => {
    if (activeFilter?.id === 0) {
      setEventFilterPayload({
        type: "all",
      });
    } else {
      setEventFilterPayload({
        event_id: activeFilter?.id,
      });
    }
  }, [activeFilter]);

  const viewBySegmentChartOptions = {
    colors: GRAPH_FILL_COLOR,
    series: [
      {
        name: "View Count",
        data:
          viewBySegment?.length > 0
            ? transformGraphData(viewBySegment, "count", "count")?.series
            : [],
        color: "#15946C",
      },
    ],

    labels:
      viewBySegment?.length > 0
        ? transformGraphData(
            viewBySegment,
            "time_slot_interval",
            "time_slot_interval"
          )?.label
        : [],
  };

  const deviceDistributionChartOptions = {
    series:
      deviceDistribution?.length > 0
        ? transformGraphData(deviceDistribution, "percentage", "level")?.series
        : [],
    labels:
      deviceDistribution?.length > 0
        ? transformGraphData(deviceDistribution, "percentage", "level")?.label
        : [],
  };

  function formatZonalDistibutionData(inputArray = []) {
    if (!Array.isArray(inputArray)) return [];
    return inputArray.map(({ level, percentage }) => ({
      x: level || "Other",
      y: percentage,
    }));
  }

  const indiaMapFormattedData = transformIndiaMapData(locationDistribution);

  const zonalDistributionChartOptions =
    formatZonalDistibutionData(zonalDistribution);

  const formattedPollDataForMasterCluster =
    eventType === 1
      ? formatLivePollDataForMasterCluster(contentQuestions)
      : contentQuestions;

  return (
    <div className="d-flex flex-column gap-4">
      <Card className="h-100 m-0">
        <Card.Body className="p-3">
          {!isContentQuestionsLoading ? (
            formattedPollDataForMasterCluster?.map((item, index) => {
              const formatedFullStackedBarData = item?.options?.map((value) => {
                return {
                  name: value.label,
                  data: [value.percentage],
                };
              });

              return (
                <Row key={index} className="mb-2">
                  <Col sm={12} className="fw-bold text-dark">
                    <h5 className="mb-2 mb-n3 ms-0 ms-md-3 fw-semibold fs-5">
                      {item.question}
                    </h5>
                  </Col>
                  <Col sm={12} className="mb-3">
                    <FullStackedBar
                      series={formatedFullStackedBarData}
                      colors={GRAPH_FILL_COLOR}
                    />
                  </Col>
                </Row>
              );
            })
          ) : (
            <QuestionAnalyticsSkeleton />
          )}

          {/* TODO: DATA NOT AVAILABE IN BACKEND */}
          {/* <Row>
            <Col sm={12}>
              <Row>
                <Col md={6} sm={12} className="mb-3 mb-md-0">
                  <DonutChart
                    title={`How Did You Hear About This Event?`}
                    graphColor={GRAPH_FILL_COLOR}
                    labels={REGISTRATION_BY_DEVICE.labels} // TODO:
                    series={REGISTRATION_BY_DEVICE.series} // TODO:
                    className="bg-light bg-opacity-25"
                    grayBackground={true}
                  />
                </Col>
                <Col md={6} sm={12}>
                  <LineChart
                    chartTitle={
                      "How Many Units Of Our New Sleep Aid Product Were Sold Each Month This Quarter?"
                    }
                    graphColor={GRAPH_FILL_COLOR}
                    labels={REGISTRATION_BY_DEVICE.labels} // TODO:
                    series={REGISTRATION_BY_DEVICE.series} // TODO:
                    showActionButtons={false}
                  />
                </Col>
              </Row>
            </Col>
          </Row> */}
        </Card.Body>
      </Card>

      <Row>
        <Col sm={12}>
          {!isAllProjectListLoading ? (
            <HeaderPillFilters
              titles={updatedAllProjectList}
              activeFilter={activeFilter}
              setActiveFilter={setActiveFilter}
              width="100%"
            />
          ) : (
            <div className="d-flex gap-3">
              <Skeleton
                height={35}
                width={40}
                className={"m-0 p-0 rounded-pill"}
              />
              <Skeleton
                height={35}
                width={80}
                className={"m-0 p-0 rounded-pill"}
              />
              <Skeleton
                height={35}
                width={80}
                className={"m-0 p-0 rounded-pill"}
              />
            </div>
          )}
        </Col>
      </Row>

      <Row>
        <Col sm={12}>
          {!isViewBySegmentLoading && viewBySegment?.length > 0 ? (
            <BasicColumnChart
              title={"Viewership wise attendee Segmentation"}
              labels={formatTimeForEvents(
                viewBySegmentChartOptions.labels || []
              )}
              series={viewBySegmentChartOptions.series}
              showYAxis={false}
              exportCSVHeading={"Time,"}
            />
          ) : isViewBySegmentLoading ? (
            <BarChartSkeleton />
          ) : (
            <CardNotFound
              title={"Viewership wise attendee Segmentation"}
              height={360}
            />
          )}
        </Col>
      </Row>

      {/* FIXME: data is not available in backend */}
      {/* <Row>
        <Col sm={12}>
          <div
            className="bg-primary-light border border-1 border-primary border-opacity-25 rounded-3 text-center text-primary mb-0 py-3 fs-5 fw-light"
            role="alert"
          >
            More than
            <span className="text-primary fw-semibold "> 340 </span>HCP's
            watched the full session spending a duration of
            <span className="text-primary fw-semibold "> 1 </span> hour
          </div>
        </Col>
      </Row> */}

      <Row className="mb-4">
        <Col md={7} sm={12} className="d-flex flex-column mb-4 mb-md-0">
          {!isLocationDistributionLoading && locationDistribution.length > 0 ? (
            <IndiaMap
              title={`${filterBy} by Location`}
              stateData={indiaMapFormattedData}
            />
          ) : isLocationDistributionLoading ? (
            <MapAnalyticsSkeleton />
          ) : (
            <CardNotFound
              title={`${filterBy} by Location`}
              isTitleCenter={true}
            />
          )}
        </Col>

        <Col md={5} sm={12} className="d-flex flex-column gap-3">
          <Col md={12}>
            {!isDeviceDistributionLoading && deviceDistribution.length > 0 ? (
              <DonutChart
                title={`Device Distribution`}
                graphColor={GRAPH_FILL_COLOR}
                labels={deviceDistributionChartOptions.labels}
                series={deviceDistributionChartOptions.series}
              />
            ) : isDeviceDistributionLoading ? (
              <SquareChartSkeleton />
            ) : (
              <CardNotFound
                title={`Device Distribution`}
                isTitleCenter={true}
                height={300}
              />
            )}
          </Col>

          <Col md={12}>
            {!isZonalDistributionLoading && zonalDistribution.length > 0 ? (
              <TreeMapChart
                title={`Zonal Distribution`}
                series={zonalDistributionChartOptions}
              />
            ) : isZonalDistributionLoading ? (
              <SquareChartSkeleton />
            ) : (
              <CardNotFound
                title={`Zonal Distribution`}
                isTitleCenter={true}
                height={300}
              />
            )}
          </Col>
        </Col>
      </Row>

      {/* FIXME: data is not available in backend */}
      {/* <Row>
        <Col sm={12} className="mb-4">
          <div
            className="bg-primary-light border border-1 border-primary border-opacity-25 rounded-3 text-center text-primary mb-0 py-3 fs-5 fw-light"
            role="alert"
          >
            <span className="text-primary fw-semibold "> 40% </span> of the
            HCP's registered from tier 1 cities of INDIA
          </div>
        </Col>
      </Row> */}
    </div>
  );
};

export default EventCompleted;
