import React from "react";

const SummeryMatrixSvgUI = ({ page = "content-details" }) => {
  return (
    <>
      {page === "content-details" ? (
        <svg
          viewBox="0 0 415 206"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="rounded-3 h-100 object-fit-cover position-absolute start-0 end-0 top-0 z-1"
        >
          <g clip-path="url(#clip0_8133_27265)">
            <path
              d="M227.829 -121H-72V206H415L227.829 -121Z"
              fill="url(#paint0_linear_8133_27265)"
              fill-opacity="0.1"
            />
            <path
              d="M190.829 -121H-109V206H378L190.829 -121Z"
              fill="url(#paint1_linear_8133_27265)"
            />
            <path
              d="M207.829 -121H-92V206H395L207.829 -121Z"
              fill="url(#paint2_linear_8133_27265)"
              fill-opacity="0.5"
            />
          </g>
          <defs>
            <linearGradient
              id="paint0_linear_8133_27265"
              x1="14.0269"
              y1="42.5"
              x2="415"
              y2="42.5"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#13388C" />
              <stop offset="1" stop-color="#159A6C" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_8133_27265"
              x1="134.5"
              y1="-121"
              x2="134.5"
              y2="206"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#EEEEEE" />
              <stop offset="1" stop-color="#888888" />
            </linearGradient>
            <linearGradient
              id="paint2_linear_8133_27265"
              x1="-5.97306"
              y1="42.5"
              x2="395"
              y2="42.5"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#13388C" />
              <stop offset="1" stop-color="#159A6C" />
            </linearGradient>
            <clipPath id="clip0_8133_27265">
              <rect width="415" height="206" fill="white" />
            </clipPath>
          </defs>
        </svg>
      ) : (
        <svg
          viewBox="0 0 415 208"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="rounded-3 h-100 object-fit-cover position-absolute start-0 end-0 top-0 z-1"
        >
          <g clip-path="url(#clip0_8140_27274)">
            <path
              d="M260 0H0V208H415L260 0Z"
              fill="url(#paint0_linear_8140_27274)"
              fill-opacity="0.1"
            />
            <path
              d="M247 0H0V208H397L247 0Z"
              fill="url(#paint1_linear_8140_27274)"
              fill-opacity="0.5"
            />
            <path
              d="M232.722 0H0V208H381L232.722 0Z"
              fill="url(#paint2_linear_8140_27274)"
            />
          </g>
          <defs>
            <linearGradient
              id="paint0_linear_8140_27274"
              x1="73.3084"
              y1="104"
              x2="415"
              y2="104"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#13388C" />
              <stop offset="1" stop-color="#159A6C" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_8140_27274"
              x1="69.7755"
              y1="104"
              x2="395"
              y2="104"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#13388C" />
              <stop offset="1" stop-color="#159A6C" />
            </linearGradient>
            <linearGradient
              id="paint2_linear_8140_27274"
              x1="66.7724"
              y1="104"
              x2="378"
              y2="104"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#13388C" />
              <stop offset="1" stop-color="#159A6C" />
            </linearGradient>
            <clipPath id="clip0_8140_27274">
              <rect width="415" height="208" fill="white" />
            </clipPath>
          </defs>
        </svg>
      )}
    </>
  );
};

export default SummeryMatrixSvgUI;
