import React from "react";
import { Button } from "react-bootstrap";
import { findIcon } from "../../../views/dashboard/icons";
import SubmitButton from "../../form-components/submit-button";
const defaultButtons = ["view", "edit", "delete"];
const ActionButtonBar = ({
  buttons = defaultButtons,
  row,
  onView,
  onEdit,
  onDelete,
  onSelect,
  onAnalytics,
  onCopy,
  title = "",
}) => {
  return (
    <div className="d-flex justify-content-center align-items-center gap-2">
      {buttons.includes("view") && (
        <Button
          variant="soft-primary"
          className="rounded-pill btn-sm px-3 d-flex align-items-center justify-content-center gap-1 flex-shrink-0 text-nowrap"
          role="button"
          onClick={() => onView(row.original)}
        >
          <span>{findIcon("Eye", "solid", 16)}</span>
          View
        </Button>
      )}
      {buttons.includes("draft") && (
        <Button
          variant="soft-warning"
          className="rounded-pill btn-sm px-3 d-flex align-items-center justify-content-center gap-1 flex-shrink-0 text-nowrap"
          role="button"
          onClick={() => onView(row.original)}
        >
          <span>{findIcon("Eye", "solid", 16)}</span>
          View Draft
        </Button>
      )}

      {buttons.includes("review") && (
        <Button
          variant="light"
          className="rounded-pill btn-sm px-3 d-flex align-items-center justify-content-center gap-1 flex-shrink-0 text-nowrap"
          to="#"
          role="button"
          onClick={() => onView(row.original)}
        >
          <span>{findIcon("Eye", "outline", 16)}</span>
          Review
        </Button>
      )}

      {buttons.includes("publish") && (
        <Button
          variant="primary"
          className="rounded-pill btn-sm px-3 d-flex align-items-center justify-content-center gap-1 flex-shrink-0 text-nowrap"
          to="#"
          role="button"
          onClick={() => onView(row.original)}
        >
          <span>{findIcon("Location Arrow", "outline", 16)}</span>
          Publish
        </Button>
      )}

      {buttons.includes("approve") && (
        <Button
          variant="soft-success"
          className="rounded-pill btn-sm px-3 d-flex align-items-center justify-content-center gap-1 flex-shrink-0 text-nowrap"
          to="#"
          role="button"
          onClick={() => onView(row.original)}
        >
          <span>{findIcon("Check", "outline", 16)}</span>
          Approve
        </Button>
      )}

      {buttons.includes("edit") && (
        <Button
          className="btn-icon btn-sm rounded-pill"
          to="#"
          role="button"
          onClick={() => onEdit(row.original)}
        >
          <span className="btn-inner">{findIcon("Pencil", "dual-tone")}</span>
        </Button>
      )}
      {buttons.includes("delete") && (
        // <Button
        //   className="btn-icon btn-sm rounded-pill"
        //   variant="danger"
        //   to="#"
        //   role="button"
        //   onClick={() => onDelete(row.original)}
        // >
        //   <span className="btn-inner">
        //     {findIcon("Trash", "dual-tone", 32)}
        //   </span>
        // </Button>
        <SubmitButton
          className="btn-icon btn-sm rounded-pill"
          onClick={() => onDelete(row.original)}
          as="span"
        >
          {findIcon("Trash", "dual-tone", 30)}
        </SubmitButton>
      )}
      {buttons.includes("select") && (
        <Button
          className="btn-icon btn-sm rounded-pill"
          variant="success"
          to="#"
          role="button"
          onClick={() => onSelect(row.original)}
        >
          <span className="btn-inner">
            {findIcon("Arrow Right", "dual-tone", 32)}
          </span>
        </Button>
      )}
      {buttons.includes("analytics") && (
        <Button
          className="btn-icon btn-sm rounded-pill"
          variant="primary"
          to="#"
          role="button"
          onClick={() => onAnalytics(row.original)}
        >
          <span className="btn-inner">
            {findIcon("Analytics", "dual-tone", 32)}
          </span>
        </Button>
      )}
      {buttons.includes("copy") && (
        <Button
          className="btn-icon btn-sm rounded-pill"
          variant="success"
          to="#"
          role="button"
          onClick={() => onCopy(row.original)}
          title={title}
        >
          <span className="btn-inner">
            {findIcon("Document", "dual-tone", 32)}
          </span>
        </Button>
      )}
    </div>
  );
};

export default ActionButtonBar;
