import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { Dropdown } from "react-bootstrap";
import CustomToggle from "../../../../../components/dropdowns";
import Chart from "react-apexcharts";
import { useAnalyticsOnOnboardedDate } from "../../../../../queries/query-hooks/hcp-profile-hook";
import Skeleton from "../../../../../components/skeleton";
import HcpAnalysisSkeleton from "../../../../../skeleton/hcp/HcpAnalysisSkeleton";
// import DropDownMenu from "../DropDownMenu";
import { dateFilterOptions } from "../../constant/hcp-constatnt";
import DashboardDropdown from "../../../../../components/DashboardDropdown";

export default function UserDataAnalyticsOnboardedDate() {
  const [payload, setPayload] = useState({
    employee_id: "",
    type: dateFilterOptions.initial,
    next: "",
    prev: "",
    startdate: "",
    enddate: "",
  });

  const { data, isLoading } = useAnalyticsOnOnboardedDate(payload);

  const handleDropdownChange = (type) => {
    setPayload({
      ...payload,
      type: type,
    });
  };

  const [chartOptions, setChartOptions] = useState({
    chart: {
      id: "basic-bar",
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        columnWidth: "50%",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
    },
    fill: {
      opacity: 1,
    },
    xaxis: {
      categories: [],
    },
    markers: {
      size: 6,
      strokeWidth: 3,
      fillOpacity: 0,
      strokeOpacity: 0,
      hover: {
        size: 8,
      },
    },
  });
  const [chartSeries, setChartSeries] = useState([]);

  useEffect(() => {
    if (data && data?.data) {
      let mixedSeries = [
        {
          name: "onboarded",
          type: "column",
          data: data?.data?.onboarded ? data?.data.onboarded : [],
        },
        {
          name: "Approved",
          type: "area",
          data: data?.data?.approved ? data?.data.approved : [],
        },
        {
          name: "Disapproved",
          type: "area",
          data: data?.data?.disapproved ? data?.data.disapproved : [],
        },
      ];
      setChartSeries(mixedSeries);
      setChartOptions({
        ...chartOptions,
        xaxis: { categories: data.data.labels },
      });
    }
  }, [data]);

  //  if loading return skeleton
  if (isLoading) {
    return <HcpAnalysisSkeleton />;
  }

  return (
    <Card className="flex-grow-1">
      <Card.Header className="flex-wrap d-flex align-items-center justify-content-between text-capitalize">
        <Card.Title>
          <h5 className="mb-0">User Data Analytics On Onboarded Date</h5>
          <p className="mb-0 fs-7 text-capitalize mt-2">{payload?.type}</p>
        </Card.Title>
        <DashboardDropdown
          constantFilterOption={dateFilterOptions?.options}
          handleDropdownChange={handleDropdownChange}
          type={payload?.type}
        />
      </Card.Header>
      <Card.Body className="p-2 p-md-4">
        {/* {!isLoading && chartSeries ? (
          <Chart options={chartOptions} series={chartSeries} type="area" />
        ) : (
          <Skeleton height={350} width={550} />
        )} */}
        <Chart options={chartOptions} series={chartSeries} type="area" />
      </Card.Body>
    </Card>
  );
}
